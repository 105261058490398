import React from 'react';
import { View, Text } from 'react-native';

import { storiesOf } from '@storybook/react-native';
import { textStyles } from './light/typography';

storiesOf('Typography', module).add('examples', () => {
  return (
    <View>
      {Object.entries(textStyles).map((object) => {
        const key = object[0];
        const value = object[1];

        return (
          <View
            style={{
              borderWidth: 1,
              borderColor: '#ccc',
              backgroundColor: '#ddd',
            }}
            key={key}
          >
            <Text style={value}>{key}</Text>
          </View>
        );
      })}
    </View>
  );
});
