import React, { FC } from 'react';
import {
  Text,
  TouchableOpacity,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import { ThemeConfig } from '../../hooks/use-theme';
import { borderStyles2 } from '../../theme/borders';

interface Button2Props {
  text: string;
  onPress: () => void;
  theme: ThemeConfig;
  style?: TextStyle;
  disabled?: boolean;
}

export const Button2: FC<Button2Props> = (props) => {
  const { text, onPress, style, theme, disabled } = props;
  const { textStyles2, colors2 } = theme;
  const curStyle = StyleSheet.create({
    color: {
      backgroundColor: colors2.secondary,
      borderColor: colors2.secondary,
    },
  });

  const curStyles: ViewStyle[] = [
    styles.container,
    curStyle.color,
    borderStyles2.borderRadiusSmall,
  ];
  if (disabled) {
    curStyles.push(styles.disabled);
  }

  if (style) {
    curStyles.push(style);
  }

  return (
    <TouchableOpacity onPress={onPress} disabled={disabled}>
      <View style={curStyles}>
        <Text style={textStyles2.ButtonCenter} numberOfLines={1}>
          {text}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 48,
  },
  disabled: {
    opacity: 0.3,
  },
});
