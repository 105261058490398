import { takeEvery } from 'redux-saga/effects';
import { ApiType } from '../../api';
import {
  fetchNetworkAggregatedDownlineActions,
  fetchNetworkDirectSponsoredActions,
} from '../actions/network';
import { callApiEndpoint } from './api';

export const watchFetchNetworkAggregatedDownline = (Api: ApiType) =>
  takeEvery(
    fetchNetworkAggregatedDownlineActions.types.trigger,
    fetchNetworkAggregatedDownline,
    Api
  );

function* fetchNetworkAggregatedDownline(
  Api: ApiType,
  _: ReturnType<typeof fetchNetworkAggregatedDownlineActions.trigger>
) {
  yield callApiEndpoint(
    fetchNetworkAggregatedDownlineActions,
    Api.getNetworkAggregatedDownline,
    undefined,
    Api
  );
}

export const watchFetchNetworkDirectSponsored = (Api: ApiType) =>
  takeEvery(
    fetchNetworkDirectSponsoredActions.types.trigger,
    fetchNetworkDirectSponsored,
    Api
  );

function* fetchNetworkDirectSponsored(
  Api: ApiType,
  _: ReturnType<typeof fetchNetworkDirectSponsoredActions.trigger>
) {
  yield callApiEndpoint(
    fetchNetworkDirectSponsoredActions,
    Api.getNetworkDirectSponsored,
    undefined,
    Api
  );
}
