import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../navigation/types';

import { RootState } from '../../services/redux/reducers';
import { UnplacedMembersCountState } from '../../services/redux/reducers/unplaced-members-count';
import { fetchUnplacedMembersCountActions } from '../../services/redux/actions/unplaced-members-count';
import {
  DASHBOARD_TILE_HEIGHT_XS,
  DASHBOARD_TILE_WIDTH,
} from '../../theme/fixed-sizes';
import { useTheme } from '../../hooks/use-theme';
import { DashboardTileLoading } from '../../components/dashboard-tile-loading/dashboard-tile-loading';

export const DashboardUnplacedMembers: FC = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const unplacedMembersCount = useSelector<
    RootState,
    UnplacedMembersCountState
  >((state) => state.unplacedMembersCount);
  const { loading, error, data } = unplacedMembersCount;
  const { theme } = useTheme();
  const { textStyles } = theme;

  const dispatch = useDispatch();
  const refetch = () => {
    dispatch(fetchUnplacedMembersCountActions.trigger(undefined));
  };
  const navigateToMembers = () => {
    navigation.navigate('MembersOverview', { selectedTabIndex: 0 });
  };

  const navigateToUnplacedMembers = () => {
    navigation.navigate('Wip', {
      headline: 'Place Members',
    });
  };

  const buttonsConfig = () => {
    return [
      {
        onPress: navigateToMembers,
        title: 'See all members',
      },
      {
        onPress: navigateToUnplacedMembers,
        title: 'Place now',
      },
    ];
  };

  const count = useMemo(() => {
    return data && data.count;
  }, [data]);
  const renderTile = useCallback(() => {
    return count ? count > 0 : false;
  }, [count]);

  return (
    <DashboardTileLoading
      loading={loading}
      error={error}
      data={data}
      refetch={refetch}
      renderIf={renderTile}
      style={styles.container}
      icon="placement"
      buttons={buttonsConfig()}
      theme={theme}
      placeholderLoaderType="DASHBOARD_UNPLACED_MEMBERS"
    >
      <Text style={textStyles.H3Left}>
        <Text>You have </Text>
        <Text style={textStyles.H3LeftBold}>{count}</Text>
        <Text> unplaced members</Text>
      </Text>
    </DashboardTileLoading>
  );
};

const styles = StyleSheet.create({
  container: {
    height: DASHBOARD_TILE_HEIGHT_XS,
    width: DASHBOARD_TILE_WIDTH,
  },
});
