import React, { FC, useCallback, useMemo } from 'react';
import { Text, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserActions } from '../../services/redux/actions/user-info';
import { RootState } from '../../services/redux/reducers';
import { UserState } from '../../services/redux/reducers/user-info';

import {
  DASHBOARD_TILE_HEIGHT_XS,
  DASHBOARD_TILE_WIDTH,
} from '../../theme/fixed-sizes';
import { useTheme } from '../../hooks/use-theme';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../navigation/types';
import { getUserFastStartBonus } from '../../services/redux/selectors/user-bonus';
import { DashboardTileLoading } from '../../components/dashboard-tile-loading/dashboard-tile-loading';
import { User } from '../../services/api/types';

export const DashboardUserBonusProgram: FC = () => {
  const user = useSelector<RootState, UserState>((state) => state.user);
  const { loading, error, data } = user;
  const { theme } = useTheme();
  const { textStyles } = theme;

  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const dispatch = useDispatch();
  const refetch = () => {
    dispatch(fetchUserActions.trigger(undefined));
  };

  const userBonus = useMemo(() => {
    const bonus =
      data && data.bonusProgram
        ? getUserFastStartBonus(data.bonusProgram)
        : undefined;
    return bonus;
  }, [data]);

  const renderTile = useCallback(() => {
    return userBonus ? userBonus.remainingDays > 0 : false;
  }, [userBonus]);

  const getButtonConfig = () => {
    return [
      {
        onPress: () => {
          navigation.navigate('Wip', {
            headline: 'Fast Start Bonus Details',
          });
        },
        title: 'See bonus details',
      },
      {
        onPress: () => {
          navigation.navigate('Wip', {
            headline: 'Add New Member',
          });
        },
        title: 'Add now',
      },
    ];
  };

  return (
    <DashboardTileLoading<User | undefined>
      loading={loading}
      error={error}
      data={data}
      refetch={refetch}
      renderIf={renderTile}
      style={styles.container}
      icon="rocket"
      buttons={getButtonConfig()}
      theme={theme}
      placeholderLoaderType="DASHBOARD_USER_BONUS_PROGRAM"
      showBadge={'static-data'}
    >
      {userBonus && (
        <Text style={textStyles.H3Left}>
          <Text>{userBonus.name} - Add</Text>
          <Text style={textStyles.H3LeftBold}>
            {' '}
            {userBonus.remainingMembersToAcquire}{' '}
          </Text>
          <Text>new member in </Text>
          <Text style={textStyles.H3LeftBold}>{userBonus.remainingDays}</Text>
          <Text> days</Text>
        </Text>
      )}
    </DashboardTileLoading>
  );
};

const styles = StyleSheet.create({
  container: {
    height: DASHBOARD_TILE_HEIGHT_XS,
    width: DASHBOARD_TILE_WIDTH,
  },
});
