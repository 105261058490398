import React, { FC } from 'react';
import { WipScreen as WipScreenComponent } from '../../components/wip-screen/wip-screen';

export interface WipScreenProps {
  headline?: string;
  bodyText?: string;
}

export const MembersTreeScreen: FC = () => {
  return <WipScreenComponent headline={'Dual Genealogy Tree'} bodyText={''} />;
};
