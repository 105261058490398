import React, { FC } from 'react';
import { View, StyleSheet } from 'react-native';

export type StaticDataBadgeType = 'static-data' | 'infotrax';
export interface StaticDataBadgeProps {
  size: 'small' | 'full-width';
  type: StaticDataBadgeType;
}
const blue1 = '#0B86FF';
const purple1 = '#5700EA';

export const StaticDataBadge: FC<StaticDataBadgeProps> = (props) => {
  const { size, type } = props;

  const style = size === 'full-width' ? styles.fullWidth : styles.small;

  let backgroundColor: string;
  switch (type) {
    case 'infotrax':
      backgroundColor = purple1;
      break;
    case 'static-data':
      backgroundColor = blue1;
      break;
    default:
      backgroundColor = blue1;
      break;
  }

  return <View style={[styles.container, style, { backgroundColor }]} />;
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: blue1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullWidth: {
    height: 4,
    width: 5000, // TODO: FIXME: fix this hack + line below
    overflow: 'hidden',
  },
  small: {
    height: 4,
    width: 80,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    borderBottomColor: blue1,
  },
});
