import { ApiError } from '../api/types';
export interface ApiActionCreators<
  ParamsType,
  ResponseType,
  TriggerType extends string = string,
  StartType extends string = string,
  SuccessType extends string = string,
  FailureType extends string = string
> {
  trigger: (
    params: ParamsType
  ) => {
    type: TriggerType;
    payload: { params: ParamsType };
  };
  start: () => {
    type: StartType;
  };
  success: (
    params: ParamsType,
    response: ResponseType
  ) => {
    type: SuccessType;
    payload: {
      params: ParamsType;
      response: ResponseType;
    };
  };
  failure: (
    params: ParamsType,
    error: ApiError,
    data?: ResponseType
  ) => {
    type: FailureType;
    payload: {
      params: ParamsType;
      error: ApiError;
      data?: ResponseType;
    };
  };
  types: {
    trigger: TriggerType;
    start: StartType;
    success: SuccessType;
    failure: FailureType;
  };
}

interface ApiActionCreatorsConstructor<
  TriggerType extends string,
  StartType extends string,
  SuccessType extends string,
  FailureType extends string
> {
  <ParamsType, ResponseType>(): ApiActionCreators<
    ParamsType,
    ResponseType,
    TriggerType,
    StartType,
    SuccessType,
    FailureType
  >;
}

export function createApiActions<
  TriggerType extends string,
  StartType extends string,
  SuccessType extends string,
  FailureType extends string
>(
  triggerType: TriggerType,
  startType: StartType,
  successType: SuccessType,
  failureType: FailureType
): ApiActionCreatorsConstructor<
  TriggerType,
  StartType,
  SuccessType,
  FailureType
> {
  function constructor<ParamsType, ResponseType>(): ApiActionCreators<
    ParamsType,
    ResponseType,
    TriggerType,
    StartType,
    SuccessType,
    FailureType
  > {
    return {
      trigger: (params) => ({ type: triggerType, payload: { params } }),
      start: () => ({ type: startType }),
      success: (params, response) => ({
        type: successType,
        payload: { params, response },
      }),
      failure: (params, error, data) => ({
        type: failureType,
        payload: { params, error, data },
      }),
      types: {
        trigger: triggerType,
        start: startType,
        success: successType,
        failure: failureType,
      },
    };
  }

  return constructor;
}

export type ExtractActionTypes<T> = T extends ApiActionCreators<
  infer ParamsType,
  infer ResponseType,
  infer TriggerType,
  infer StartType,
  infer SuccessType,
  infer FailureType
>
  ?
      | ReturnType<
          ApiActionCreators<
            ParamsType,
            ResponseType,
            TriggerType,
            StartType,
            SuccessType,
            FailureType
          >['trigger']
        >
      | ReturnType<
          ApiActionCreators<
            ParamsType,
            ResponseType,
            TriggerType,
            StartType,
            SuccessType,
            FailureType
          >['start']
        >
      | ReturnType<
          ApiActionCreators<
            ParamsType,
            ResponseType,
            TriggerType,
            StartType,
            SuccessType,
            FailureType
          >['success']
        >
      | ReturnType<
          ApiActionCreators<
            ParamsType,
            ResponseType,
            TriggerType,
            StartType,
            SuccessType,
            FailureType
          >['failure']
        >
  : never;
