import React, { FC } from 'react';
import { RouteProp, useRoute } from '@react-navigation/native';
import { WipScreen } from '../../components/wip-screen/wip-screen';
import { RootStackParamList } from '../../navigation/types';

export type PlaceMemberScreenProps = RouteProp<
  RootStackParamList,
  'PlaceMember'
>;

export const PlaceMembersScreen: FC = () => {
  const route = useRoute<PlaceMemberScreenProps>();

  return (
    <WipScreen
      headline={'PlaceMembersScreen'}
      bodyText={`Placeholder for Screen: placement of member in dual tree. member id=${route.params.id}`}
    />
  );
};
