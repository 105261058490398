import React, { FC, useCallback, useEffect } from 'react';
import { Text, StyleSheet, View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../services/redux/reducers';
import { CommissionsState } from '../../services/redux/reducers/commissions';
import { fetchLatestCommissionsActions } from '../../services/redux/actions/commissions';

import {
  DASHBOARD_TILE_FIRST_COLUMN_WIDTH,
  DASHBOARD_TILE_HEIGHT_LARGE,
  DASHBOARD_TILE_WIDTH,
  TABLE_COLUMN_WIDTH_CHECKBOX,
  TABLE_COLUMN_WIDTH_CURRENCY_SYMBOL_RIGHT,
  TABLE_COLUMN_WIDTH_DATE,
} from '../../theme/fixed-sizes';
import { useTheme } from '../../hooks/use-theme';
import { formatDateStandard } from '../../utils/format-date';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../navigation/types';
import { DashboardTileLoading } from '../../components/dashboard-tile-loading/dashboard-tile-loading';
import { CommissionType, Commission } from '../../services/api/types';
import { Table, TableConfigItem } from '../../components/table/table';
import { spacing } from '../../theme/spacing';
import { Currency } from '../../components/currency/currency';
import { StatusIcon } from '../../components/status-icon/status-icon';

export const commissionDisplayName = (commissionType: CommissionType) => {
  switch (commissionType) {
    case 'cashBonus':
      return 'Cash Bonus';
    case 'directBonus':
      return 'Direct Bonus';
    case 'dualBonus':
      return 'Dual Bonus';
    case 'leaderShipBonus':
      return 'Leadership Bonus';
    case 'matchingBonus':
      return 'Matching Bonus';
    case 'mobilityBonus':
      return 'Mobility Bonus';
    case 'rankAchievementBonus':
      return 'Rank Achievement Bonus';
  }
};

const COMMISSIONS_TABLE_CONFIG: TableConfigItem<Commission>[] = [
  {
    width: TABLE_COLUMN_WIDTH_DATE,
    render: (commission) => `${formatDateStandard(commission.calculateDate)}`,
  },
  {
    width: 140,
    render: (commission) => `${commissionDisplayName(commission.type)}`,
  },
  {
    width: TABLE_COLUMN_WIDTH_CURRENCY_SYMBOL_RIGHT,
    align: 'right',
    render: (commission) => {
      return (
        <Currency
          amount={commission.amount}
          currency={'EUR'}
          symbolPosition={'right'}
        />
      );
    },
  },
  {
    width: TABLE_COLUMN_WIDTH_CHECKBOX,
    align: 'right',
    render: (commission) => (
      <StatusIcon
        status={commission.status}
        activeStatus={'confirmed'}
        inactiveSvg={'clock'}
        align={'right'}
      />
    ),
  },
];

export const DashboardLatestCommissions: FC = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const commissions = useSelector<RootState, CommissionsState>(
    (state) => state.commissions
  );
  const {
    latestLoading: loading,
    latestError: error,
    latestData: data,
  } = commissions;

  const { theme } = useTheme();
  const { textStyles } = theme;

  const dispatch = useDispatch();
  const refetch = useCallback(() => {
    dispatch(fetchLatestCommissionsActions.trigger(undefined));
  }, [dispatch]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const getTotalButtonConfig = () => {
    return [
      {
        onPress: () => {
          navigation.navigate('Commissions', { selectedTabIndex: 0 });
        },
        title: 'Show all commissions',
      },
    ];
  };

  // const MAX_LIMIT_ROWS = 3;
  // const dataSlice = data ? data.slice(0, MAX_LIMIT_ROWS) : [];

  // const today = new Date();
  // const startPeriod = beginningOfLastMonth(today);
  return (
    <DashboardTileLoading
      loading={loading}
      error={error}
      data={data}
      refetch={refetch}
      style={styles.container}
      icon={'calendar'}
      buttons={getTotalButtonConfig()}
      theme={theme}
      placeholderLoaderType="DASHBOARD_TOTAL_COMMISSIONS"
    >
      <Text style={[styles.margin, textStyles.H3Left]}>Latest Commissions</Text>
      {/* {dataSlice.length === 0 ? (
        <Text style={textStyles.BodyBigTextLeft}>
          You have not received any commissions between{' '}
          {formatDateStandard(startPeriod)} - {formatDateStandard(today)}
        </Text>
      ) : null}

      {dataSlice.length > 0 ? ( */}
      <View
        style={{
          marginLeft: -DASHBOARD_TILE_FIRST_COLUMN_WIDTH,
        }}
      >
        <Table<Commission>
          data={data}
          // onRowPress={navigateToSubscription}
          config={COMMISSIONS_TABLE_CONFIG}
          theme={theme}
        />
      </View>
      {/* ) : null} */}
      {/* {data && (
        <>
          <Text style={textStyles.H3Left}>latest commission</Text>
          <View style={styles.margin}>
            <Currency amount={data.total} currency={data.currency} textStyle={textStyles.CurrencyBigLeft} symbolPosition={'right'}/>
          </View>
          <Text style={textStyles.BodyTextLeft}>
            Updated: {formatDateStandard(new Date().toString())}
          </Text>
        </>
      )} */}
    </DashboardTileLoading>
  );
};

const styles = StyleSheet.create({
  container: {
    height: DASHBOARD_TILE_HEIGHT_LARGE,
    width: DASHBOARD_TILE_WIDTH,
  },
  margin: {
    paddingBottom: spacing[1],
  },
});
