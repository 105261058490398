import React, { FC } from 'react';
import { Text, StyleSheet } from 'react-native';

import { spacing } from '../../theme/spacing';
import {
  DASHBOARD_TILE_HEIGHT_MEDIUM,
  DASHBOARD_TILE_WIDTH,
} from '../../theme/fixed-sizes';
import { useTheme } from '../../hooks/use-theme';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../navigation/types';
import { DashboardTileLoading } from '../../components/dashboard-tile-loading/dashboard-tile-loading';

export const NetworkCustomers: FC = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const { theme } = useTheme();
  const { textStyles } = theme;

  // NOTE: TODO: implement
  const loading = false;
  const error = undefined;
  const data = [{}];
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const refetch = () => {};

  const getButtonConfig = () => {
    return [
      {
        onPress: () => {
          navigation.navigate('Wip', {
            headline: 'Network - Customers',
          });
        },
        title: 'Show all my customers',
      },
    ];
  };

  return (
    <DashboardTileLoading
      loading={loading}
      error={error}
      data={data}
      refetch={refetch}
      style={styles.container}
      icon={'human-network-on'}
      buttons={getButtonConfig()}
      theme={theme}
      placeholderLoaderType="DASHBOARD_TOTAL_COMMISSIONS"
      showBadge={'static-data'}
    >
      <Text style={[styles.margin, textStyles.H3Left]}>Customers</Text>
      <Text style={[styles.margin, textStyles.BodyTextLeft]}>
        <Text>Total: you have </Text>
        <Text style={textStyles.H3LeftBold}>10</Text>
        <Text> customers</Text>
      </Text>
      <Text style={[styles.margin, textStyles.BodyTextLeft]}>
        <Text>This month: you have </Text>
        <Text style={textStyles.H3LeftBold}>2</Text>
        <Text> new customers</Text>
      </Text>
    </DashboardTileLoading>
  );
};

const styles = StyleSheet.create({
  container: {
    height: DASHBOARD_TILE_HEIGHT_MEDIUM,
    width: DASHBOARD_TILE_WIDTH,
  },
  margin: {
    marginBottom: spacing[1],
  },
});
