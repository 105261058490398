import * as React from 'react';
import Svg, { Rect, Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={29} height={29} viewBox="0 0 29 29" fill="none" {...props}>
      <Path
        d="M12.916 10.41l.736-1.933c.314-.824 1.464-.868 1.84-.07l1.006 2.137a2 2 0 001.64 1.142l2.29.194c.882.075 1.24 1.174.57 1.753l-1.71 1.479a2 2 0 00-.639 1.969l.513 2.19c.202.861-.733 1.541-1.49 1.083l-1.931-1.166a2 2 0 00-2.069 0l-1.918 1.159c-.76.458-1.695-.224-1.49-1.087l.516-2.176a2 2 0 00-.638-1.974l-1.707-1.477c-.67-.579-.313-1.678.57-1.753l2.212-.188a2 2 0 001.7-1.281z"
        stroke="#2D9CDB"
      />
      <Rect x={14} width={1} height={4} rx={0.5} fill="#D95BB7" />
      <Rect x={14} y={25} width={1} height={4} rx={0.5} fill="#D95BB7" />
      <Rect
        y={15}
        width={1}
        height={4}
        rx={0.5}
        transform="rotate(-90 0 15)"
        fill="#D95BB7"
      />
      <Rect
        x={25}
        y={15}
        width={1}
        height={4}
        rx={0.5}
        transform="rotate(-90 25 15)"
        fill="#D95BB7"
      />
      <Rect
        x={3.893}
        y={4.6}
        width={1}
        height={4}
        rx={0.5}
        transform="rotate(-45 3.893 4.6)"
        fill="#D95BB7"
      />
      <Rect
        x={21.571}
        y={22.278}
        width={1}
        height={4}
        rx={0.5}
        transform="rotate(-45 21.571 22.278)"
        fill="#D95BB7"
      />
      <Rect
        x={24.399}
        y={3.893}
        width={1}
        height={4}
        rx={0.5}
        transform="rotate(45 24.4 3.893)"
        fill="#D95BB7"
      />
      <Rect
        x={6.722}
        y={21.571}
        width={1}
        height={4}
        rx={0.5}
        transform="rotate(45 6.722 21.57)"
        fill="#D95BB7"
      />
    </Svg>
  );
};

export default SvgComponent;
