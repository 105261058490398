import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <Path fill="#fff" d="M.93 2.343L2.344.929l12.728 12.728-1.414 1.414z" />
      <Path fill="#fff" d="M13.658.929l1.414 1.414L2.344 15.071.93 13.657z" />
    </Svg>
  );
};

export default SvgComponent;
