import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FC, useEffect, useCallback, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { ApiErrorInfo } from '../../components/api-error-info/api-error-info';
import { PlaceHolderLoader } from '../../components/placeholder-loader/placeholder-loader';
import { RootStackParamList } from '../../navigation/types';
import { Order } from '../../services/api/types';
import { fetchOrdersActions } from '../../services/redux/actions/orders';
import { RootState } from '../../services/redux/reducers';
import { OrdersState } from '../../services/redux/reducers/orders';

import { PaginationBar } from '../../components/pagination-bar/pagination-bar';
import {
  Table,
  TableConfigItem,
  TableSortConfig,
} from '../../components/table/table';
import { formatDateStandard } from '../../utils/format-date';
import { Screen } from '../../components/screen/screen';
import { usePagination } from '../../hooks/use-pagination';
import { useTheme } from '../../hooks/use-theme';
import { spacing } from '../../theme/spacing';
import {
  getTableDefaultHeight,
  getTableDefaultWidth,
  TABLE_COLUMN_WIDTH_S,
} from '../../theme/fixed-sizes';
import { StatusBadge } from '../../components/status-badge/status-badge';

const ORDERS_TABLE_CONFIG: TableConfigItem<Order>[] = [
  {
    header: 'Date',
    sortField: 'date',
    width: TABLE_COLUMN_WIDTH_S,
    render: (order) => formatDateStandard(order.date),
  },
  {
    header: 'Status',
    sortField: 'status',
    width: 90,
    render: (order) => (
      <StatusBadge
        status={order.status}
        width={80}
        colorMap={{
          completed: '#56BF99',
          Failed: '#FF6A6A',
        }}
        defaultColor={'#C4C4C4'}
      />
    ),
  },
  {
    header: 'Product',
    sortField: 'productName',
    width: 180,
    render: (order) => order.productName,
  },
];

export const OrdersScreen: FC = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const ordersSelector = useSelector<RootState, OrdersState>(
    (state) => state.orders
  );
  const {
    loading,
    error,
    data,
    entities,
    queryParams,
    onPaginate,
    onChangePageSize,
    onChangeSort,
  } = usePagination<Order>(ordersSelector, 'date', 'desc');

  const { theme } = useTheme();

  const dispatch = useDispatch();
  const refetchOrders = useCallback(() => {
    dispatch(fetchOrdersActions.trigger(queryParams));
  }, [dispatch, queryParams]);

  useEffect(() => {
    refetchOrders();
  }, [refetchOrders]);

  const navigateToOrder = (order: Order) => {
    navigation.navigate('Wip', {
      headline: 'Order Details',
      bodyText: formatDateStandard(order.date) + ': ' + order.productName,
    });
  };

  const tableSortConfig: TableSortConfig = useMemo(() => {
    return {
      default: {
        sortBy: queryParams.sortBy,
        sortOrder: queryParams.sortOrder,
      },
      onChangeSort,
    };
  }, [onChangeSort, queryParams.sortBy, queryParams.sortOrder]);

  if (error) {
    return (
      <View style={styles.container}>
        <ApiErrorInfo error={error} onButtonPress={refetchOrders} />
      </View>
    );
  }

  return (
    <Screen>
      <View style={styles.container}>
        <View style={styles.innerContainer}>
          {loading && <PlaceHolderLoader displayType="ORDERS_SCREEN" />}
          {!loading && (
            <Table<Order>
              data={entities}
              onRowPress={navigateToOrder}
              config={ORDERS_TABLE_CONFIG}
              horizontalScroll
              theme={theme}
              sortConfig={tableSortConfig}
            />
          )}
        </View>
        <View style={styles.bottomBar}>
          <PaginationBar
            page={queryParams.page}
            pageSize={queryParams.pageSize}
            sliceSize={5}
            totalCount={data.totalCount || 0}
            onPaginate={onPaginate}
            onChangePageSize={onChangePageSize}
            theme={theme}
          />
        </View>
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: spacing[2],
    justifyContent: 'center',
  },
  innerContainer: {
    flexDirection: 'column',
    width: getTableDefaultWidth(),
    minHeight: getTableDefaultHeight(),
    alignItems: 'center',
  },
  bottomBar: {
    flex: 1,
  },
});
