import * as React from 'react';
import Svg, { SvgProps, Rect, Path, Circle } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={31} height={31} viewBox="0 0 31 31" fill="none" {...props}>
      <Rect
        x={10.054}
        y={20.27}
        width={8.054}
        height={1}
        rx={0.5}
        transform="rotate(-45 10.054 20.27)"
        fill="#FFFFFF"
      />
      <Path
        d="M21.732 9.874l-1.89 7.562a.5.5 0 01-.839.232l-5.67-5.671a.5.5 0 01.231-.839l7.562-1.89a.5.5 0 01.606.606z"
        stroke="#FFFFFF"
      />
      <Circle cx={15.5} cy={15.5} r={15} stroke="#FFFFFF" />
    </Svg>
  );
};

export default SvgComponent;
