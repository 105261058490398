import produce from 'immer';
import { Reducer } from 'redux';
import { ApiError, Subscription } from '../../api/types';
import { HashMap, normalize } from '../../data/normalize';
import {
  fetchLatestSubscriptionsActions,
  SubscriptionsActions,
} from '../actions/subscriptions';

const initialState: SubscriptionsState = {
  loading: false,
  data: {
    entities: {},
    ids: [],
    totalCount: 0,
  },
};

export interface SubscriptionsState {
  readonly loading: boolean;
  readonly error?: ApiError;
  readonly data: {
    entities: HashMap<Subscription>;
    ids: number[];
    totalCount: number;
  };
}

export const subscriptionsReducer: Reducer<
  SubscriptionsState,
  SubscriptionsActions
> = (state = initialState, action) =>
  produce(state, (d) => {
    switch (action.type) {
      case fetchLatestSubscriptionsActions.types.start:
        d.error = undefined;
        d.loading = true;
        return;
      case fetchLatestSubscriptionsActions.types.success:
        const { ids, entities } = normalize<Subscription>(
          action.payload.response.data
        );
        d.data.ids = ids;
        d.data.totalCount = action.payload.response.total;
        d.data.entities = { ...state.data.entities, ...entities };
        d.loading = false;
        return;
      case fetchLatestSubscriptionsActions.types.failure:
        d.error = action.payload.error;
        d.loading = false;
        return;
    }
  });
