import { useState, useEffect } from 'react';
import { Dimensions } from 'react-native';

// NOTE: returns browser window width or mobile phone width
export const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState(Dimensions.get('window'));

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onChange = (result: any) => {
      setDimensions(result.window);
    };

    Dimensions.addEventListener('change', onChange);

    return () => Dimensions.removeEventListener('change', onChange);
  });

  return dimensions;
};
