import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FC } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { useDispatch } from 'react-redux';
import {
  CTABox,
  LinkBox,
  LinkBoxToggler,
} from '../../components/link-box/link-box';
import { Screen2 } from '../../components/screen/screen2';
import { useTheme } from '../../hooks/use-theme';
import { RootStackParamList } from '../../navigation/types';
import { doLogoutUserAction } from '../../services/redux/actions/auth';
import {
  getNavigationTileWidth,
  NAVIGATION_HEADLINE_HEIGHT,
} from '../../theme/fixed-sizes';
import { spacing } from '../../theme/spacing';

export interface WipScreenProps {
  headline?: string;
  bodyText?: string;
}

export const MoreNavigationScreen: FC = () => {
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const { colors2, textStyles2 } = theme;

  const navigateToDownloads = () => {
    navigation.navigate('Wip', { headline: 'Downloads & Videos' });
  };
  const navigateToAcademy = () => {
    navigation.navigate('Wip', { headline: 'Academy' });
  };

  const navigateToShopAddresses = () => {
    navigation.navigate('Wip', { headline: 'Shop Addresses' });
  };

  const navigateToShopPaymentMethods = () => {
    navigation.navigate('Wip', { headline: 'Shop Payment Methods' });
  };

  const navigateToActivation = () => {
    navigation.navigate('Wip', { headline: 'Healy Activation' });
  };

  const navigateToFaq = () => {
    navigation.navigate('Wip', { headline: 'Faq' });
  };

  const navigateToSettings = () => {
    navigation.navigate('Settings');
  };

  const navigateToPrivacy = () => {
    navigation.navigate('Wip', { headline: 'Privacy' });
  };

  const navigateToImprint = () => {
    navigation.navigate('Wip', { headline: 'Imprint' });
  };

  const navigateToOrders = () => {
    navigation.navigate('Orders');
  };
  const navigateToSubscriptions = () => {
    navigation.navigate('Subscriptions');
  };

  const logoutUser = () => {
    dispatch(doLogoutUserAction());
  };

  return (
    <Screen2>
      <View style={[styles.container, { backgroundColor: colors2.background }]}>
        <View style={styles.headlineContainer}>
          <Text style={textStyles2.SmallHeaderTextLeft}>Further links</Text>
        </View>
        <LinkBox
          label={'Downloads & Videos'}
          type="light"
          onPress={navigateToDownloads}
        />
        <LinkBox label={'Academy'} type="light" onPress={navigateToAcademy} />

        <LinkBoxToggler label={'Shop Data'}>
          <LinkBox
            label={'Shop Orders'}
            type="dark"
            onPress={navigateToOrders}
          />
          <LinkBox
            label={'Shop Subscriptions'}
            type="dark"
            onPress={navigateToSubscriptions}
          />
          <LinkBox
            label={'Shop Addresses'}
            type="dark"
            onPress={navigateToShopAddresses}
          />
          <LinkBox
            label={'Shop Payment Methods'}
            type="dark"
            onPress={navigateToShopPaymentMethods}
          />
        </LinkBoxToggler>

        <LinkBox
          label={'Healy Activation'}
          type="light"
          onPress={navigateToActivation}
        />
        <LinkBox label={'Faq'} type="light" onPress={navigateToFaq} />
        <LinkBox label={'Settings'} type="light" onPress={navigateToSettings} />
        <LinkBox label={'Privacy'} type="light" onPress={navigateToPrivacy} />
        <LinkBox label={'Imprint'} type="light" onPress={navigateToImprint} />
        <CTABox
          label={'Logout'}
          type="light"
          icon={'power-button-grey'}
          onPress={logoutUser}
        />
      </View>
    </Screen2>
  );
};

const styles = StyleSheet.create({
  container: {
    width: getNavigationTileWidth(),
  },
  headlineContainer: {
    height: NAVIGATION_HEADLINE_HEIGHT,
    paddingLeft: spacing[3],
    paddingBottom: spacing[2],
    justifyContent: 'flex-end',
  },
});
