import * as React from 'react';
import Svg, { Path, SvgProps, Circle } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={30} height={32} viewBox="0 0 30 32" fill="none" {...props}>
      <Path
        d="M24.21 23.531v-.5c0-.573.105-1.05.313-1.43.209-.38.573-.773 1.094-1.18.62-.489 1.018-.869 1.195-1.14.183-.27.274-.593.274-.968 0-.438-.146-.774-.438-1.008-.291-.235-.71-.352-1.257-.352-.495 0-.953.07-1.375.211-.422.14-.834.31-1.235.508l-.656-1.375a6.877 6.877 0 013.398-.883c1.021 0 1.831.25 2.43.75.6.5.899 1.19.899 2.07 0 .391-.058.74-.172 1.047-.115.302-.29.591-.524.867-.229.276-.627.636-1.195 1.079-.484.38-.81.695-.977.945-.161.25-.242.586-.242 1.008v.351h-1.531zm-.32 2.492c0-.786.383-1.18 1.15-1.18.374 0 .66.105.858.313.198.203.297.492.297.867 0 .37-.101.665-.304.883-.198.214-.482.32-.852.32s-.654-.104-.852-.312c-.197-.213-.296-.51-.296-.89z"
        fill="#2D9CDB"
      />
      <Circle cx={7} cy={18} r={4.5} stroke="#D95BB7" />
      <Path
        d="M13 31a6 6 0 00-12 0M9 18a2 2 0 11-4 0M25 10V8a3 3 0 00-3-3H10a3 3 0 00-3 3v2"
        stroke="#D95BB7"
      />
      <Path fill="#D95BB7" d="M16 0h1v5h-1z" />
    </Svg>
  );
};

export default SvgComponent;
