import * as React from 'react';
import Svg, { Circle, Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={26} height={18} viewBox="0 0 26 18" fill="none" {...props}>
      <Circle cx={7} cy={5} r={4.5} stroke="#fff" />
      <Circle cx={19} cy={5} r={4.5} stroke="#fff" />
      <Path
        d="M13 18a6 6 0 00-12 0M25 18a6 6 0 00-12 0M9 5a2 2 0 11-4 0M21 5a2 2 0 11-4 0"
        stroke="#fff"
      />
    </Svg>
  );
};

export default SvgComponent;
