import React, { FC } from 'react';
import {
  View,
  Image,
  Pressable,
  StyleSheet,
  Platform,
  StatusBar,
  Dimensions,
} from 'react-native';

import { SvgImage } from '../components/svg-image/svg-image';
import { ThemeConfig } from '../hooks/use-theme';
import {
  HEADER_HEIGHT,
  NAVIGATION_HEADER_TOP_PADDING,
} from '../theme/fixed-sizes';
import { hitSlop1 } from '../theme/hitslop';
import { shadows } from '../theme/shadows';
import { spacing } from '../theme/spacing';

const HEADER_MARGIN_TOP = 5;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NavigationType = any;

export const getStandardHeader = (
  headerTitle: string,
  navigation: NavigationType,
  theme: ThemeConfig
) => {
  const onIconPress = () => {
    navigation.goBack();
  };
  const { colors, textStyles } = theme;
  const headerTitleAlign = 'center' as const; // NOTE: fixes typescript expecting the type 'center', otherwise would get type string

  return {
    headerShown: true,
    headerTintColor: colors.text,
    headerTitleStyle: textStyles.H3Left,
    headerTitle,
    headerTitleAlign,
    headerStyle: {
      backgroundColor: colors.background,
      opacity: theme.name === 'dark' ? 1 : 1,
      height: HEADER_HEIGHT,
    },
    headerLeft: () => {
      return (
        <Pressable
          onPress={onIconPress}
          style={styles.pressable}
          hitSlop={hitSlop1}
        >
          <SvgImage
            type={'chevron-left'}
            width={'18'}
            height={'18'}
            variant={theme.name}
          />
        </Pressable>
      );
    },
  };
};

export const getPopupHeader = (
  headerTitle: string,
  navigation: NavigationType,
  theme: ThemeConfig
) => {
  const { colors, textStyles } = theme;
  const onIconPress = () => {
    navigation.popToTop();
  };
  const headerTitleAlign = 'center' as const; // NOTE: fixes typescript expecting the type 'center', otherwise would get type string
  return {
    // headerTransparent: true, // NOTE: enabling this results in the header not being clickable on android
    headerTintColor: colors.text,
    headerTitleStyle: textStyles.H3Left,
    headerTitle,
    headerTitleAlign,
    headerStyle: {
      backgroundColor: colors.background,
      opacity: theme.name === 'dark' ? 1 : 1,
    },
    headerLeft: () => {
      return (
        <Pressable
          onPress={onIconPress}
          style={styles.pressable}
          hitSlop={hitSlop1}
        >
          <SvgImage
            type={'close-x'}
            width={'18'}
            height={'18'}
            variant={theme.name}
          />
        </Pressable>
      );
    },
  };
};

export const getDashboardHeader = (
  navigation: NavigationType,
  theme: ThemeConfig
) => {
  return {
    headerTransparent: true,
    headerShown: true,
    header: () => <DashboardHeader theme={theme} navigation={navigation} />,
  };
};

export const DashboardHeader: FC<{
  navigation: NavigationType;
  theme: ThemeConfig;
}> = (props) => {
  const { theme } = props;
  const { colors, images: themeImages } = theme;

  return (
    <>
      <View
        style={[
          theme.name !== 'dark' ? shadows.shadowGreyBig : null,
          styles.container,
          {
            backgroundColor: colors.backgroundOpacity2,
            paddingTop: NAVIGATION_HEADER_TOP_PADDING,
            height: HEADER_HEIGHT,
          },
        ]}
      >
        <Image
          source={themeImages.healyLogoHorizontal}
          style={styles.healyIcon}
        />
      </View>
    </>
  );
};

export function getStatusBarHeight(skipAndroid?: boolean): number {
  const DEFAULT_HEIGHT = 20;
  return Platform.select({
    // ios: getStaticIosStatusBarHeight(),
    android: skipAndroid ? DEFAULT_HEIGHT : StatusBar.currentHeight,
    default: DEFAULT_HEIGHT,
  });
}

export const getLoginScreenHeader = (theme: ThemeConfig) => {
  const { images: themeImages, colors2 } = theme;
  return {
    // headerTransparent: true,
    headerShown: true,
    header: () => (
      <View
        style={[
          styles.headerLoginScreen,
          { backgroundColor: colors2.backgroundHeader },
        ]}
      >
        <View
          style={[
            styles.headerTopRow,
            {
              backgroundColor: colors2.backgroundHeader,
            },
          ]}
        >
          <Image source={themeImages.healyLogo1} style={styles.healyLogo1} />
        </View>
        <View
          style={[
            styles.headerBottomRow,
            { backgroundColor: colors2.background },
          ]}
        >
          <Image
            source={themeImages.backgroundWave}
            style={styles.backgroundWave}
          />
        </View>
      </View>
    ),
  };
};
export const getOverviewScreenHeader = (
  routeName: string,
  navigation: NavigationType,
  theme: ThemeConfig
) => {
  const { colors, textStyles } = theme;
  const headerTitleAlign = 'center' as const; // NOTE: fixes typescript expecting the type 'center', otherwise would get type string

  let headerTitle = '';
  switch (routeName) {
    case 'NetworkOverview':
      headerTitle = 'Network';
      break;
    case 'CommissionsOverview':
      headerTitle = 'Commissions';
      break;
    case 'InboxOverview':
      headerTitle = 'Inbox';
      break;
    case 'MoreNavigation':
      headerTitle = 'More';
      break;
  }

  return {
    headerShown: true,
    headerTintColor: colors.text,
    headerTitleStyle: textStyles.H3Left,
    headerTitle,
    headerTitleAlign,
    headerStyle: {
      backgroundColor: colors.background,
      opacity: theme.name === 'dark' ? 1 : 1,
      height: HEADER_HEIGHT,
    },
  };
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pressable: {
    marginLeft: spacing[2],
    width: 30,
    height: 30,
  },
  healyIcon: { width: 86, height: 33 },
  headerLoginScreen: {
    paddingTop: getStatusBarHeight() + HEADER_MARGIN_TOP,
    marginTop: -5,
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerTopRow: {
    // height: 44,

    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerBottomRow: {
    marginTop: -3, // TODO: CLEANUP
  },
  backgroundWave: {
    width: Dimensions.get('window').width,
    maxHeight: 20,
  },
  healyLogo1: { width: 123, height: 34 },
});
