import React, { FC, useCallback, useMemo } from 'react';
import { Text, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../services/redux/reducers';
import { MembersState } from '../../services/redux/reducers/members';
import { fetchTopCommissionMembersActions } from '../../services/redux/actions/members';

import { spacing } from '../../theme/spacing';
import {
  DASHBOARD_TILE_HEIGHT_LARGE,
  DASHBOARD_TILE_WIDTH,
} from '../../theme/fixed-sizes';
import { useTheme } from '../../hooks/use-theme';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../navigation/types';
import { MemberCommission } from '../../services/api/types';
import { deNormalize } from '../../services/data/normalize';
import { DashboardTileLoading } from '../../components/dashboard-tile-loading/dashboard-tile-loading';
import { UserState } from '../../services/redux/reducers/user-info';
import { Currency } from '../../components/currency/currency';
import { Table, TableConfigItem } from '../../components/table/table';

const TABLE_CONFIG: TableConfigItem<MemberCommission>[] = [
  {
    width: 170,
    render: (member) => member.username,
  },
  {
    width: 100,
    align: 'right',
    render: (member) => (
      <Currency
        amount={member.total}
        currency={member.currency}
        symbolPosition={'right'}
      />
    ),
  },
];

export const DashboardLeaderboardCommission: FC = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const membersState = useSelector<RootState, MembersState>(
    (state) => state.members
  );
  const userState = useSelector<RootState, UserState>((state) => state.user);
  const { data: userData } = userState;

  const { topCommissionLoading, topCommissionError, data } = membersState;
  const { theme } = useTheme();
  const { textStyles } = theme;

  const topCommissionEntities = useMemo(() => {
    return deNormalize<MemberCommission>(
      data.topCommission,
      data.topCommissionIds
    );
  }, [data.topCommission, data.topCommissionIds]);

  const dispatch = useDispatch();
  const refetch = useCallback(() => {
    dispatch(fetchTopCommissionMembersActions.trigger(undefined));
  }, [dispatch]);

  const getEarnersButtonConfig = () => {
    return [
      {
        onPress: () => {
          navigation.navigate('MembersCommissionLeaderboard');
        },
        title: 'Show earnings',
      },
    ];
  };

  // NOTE: TODO: api does not handle limit parameter yet
  const MAX_LIMIT_ROWS = 3;

  // TODO: remove GDoellers/4biohackers hack
  const filteredUsers = topCommissionEntities.filter((u) => {
    if (
      userData?.user?.username === 'GDoellers' &&
      u.username === '4biohackers'
    ) {
      return false;
    }
    return true;
  });

  return (
    <DashboardTileLoading
      loading={topCommissionLoading}
      error={topCommissionError}
      data={filteredUsers}
      refetch={refetch}
      style={styles.container}
      icon="glowing-money-bill"
      buttons={getEarnersButtonConfig()}
      theme={theme}
      placeholderLoaderType="DASHBOARD_MEMBER_LEADERBOARD"
    >
      <>
        <Text style={[styles.margin, textStyles.H3Left]}>Top earners</Text>
        <Table<MemberCommission>
          data={filteredUsers.slice(0, MAX_LIMIT_ROWS)}
          config={TABLE_CONFIG}
          theme={theme}
        />
      </>
    </DashboardTileLoading>
  );
};

const styles = StyleSheet.create({
  container: {
    height: DASHBOARD_TILE_HEIGHT_LARGE,
    width: DASHBOARD_TILE_WIDTH,
  },
  margin: {
    paddingBottom: spacing[1],
  },
});
