import React, { FC, useCallback } from 'react';
import { Animated, Platform, View, Image, StyleSheet } from 'react-native';
import { useTheme } from '../../hooks/use-theme';
import { getBackgroundImageSize } from '../../theme/fixed-sizes';

export const BackgroundParallax: FC<{
  width: number;
  height: number;
  scrollY: Animated.Value;
}> = (props) => {
  const { theme } = useTheme();
  const { images } = theme;
  const { scrollY, width: screenWidth, height: screenHeight } = props;
  const {
    height: backgroundImageHeight,
    width: backgroundImageWidth,
  } = getBackgroundImageSize(theme);

  // console.log({
  //   scrollY,
  //   screenWidth,
  //   screenHeight,
  //   backgroundImageWidth,
  //   backgroundImageHeight,
  //   bgImage: images.background,
  // });
  const xFactor =
    Platform.OS === 'android' || Platform.OS === 'ios' ? 0.5 : 0.5;
  const yFactor =
    Platform.OS === 'android' || Platform.OS === 'ios' ? 0.5 : 0.3;

  const translateX = scrollY.interpolate({
    inputRange: [0, screenHeight],
    outputRange: [0, -screenHeight * xFactor],
  });
  const translateY = scrollY.interpolate({
    inputRange: [0, screenHeight],
    outputRange: [0, -screenHeight * yFactor],
  });

  const ImageTile = useCallback(
    () => (
      <Image
        source={images.background}
        style={{
          width: backgroundImageWidth,
          height: backgroundImageHeight,
        }}
      />
    ),
    [backgroundImageHeight, backgroundImageWidth, images.background]
  );

  const Row: FC<{ isWeb: boolean }> = (curProps) => (
    <View style={styles.row}>
      <ImageTile />
      <ImageTile />
      <ImageTile />
      <ImageTile />
      <ImageTile />
      {curProps.isWeb && <ImageTile />}
      {curProps.isWeb && <ImageTile />}
      {curProps.isWeb && <ImageTile />}
    </View>
  );

  const isWeb = Platform.OS === 'web';

  return (
    <Animated.View
      style={[
        styles.backgroundImageContainer,
        {
          width: screenWidth,
          height: screenHeight,
          transform: [{ translateX }, { translateY }],
        },
      ]}
    >
      <Row isWeb={isWeb} />
      <Row isWeb={isWeb} />
      {isWeb && <Row isWeb={isWeb} />}
      {isWeb && <Row isWeb={isWeb} />}
    </Animated.View>
  );
};
const styles = StyleSheet.create({
  backgroundImageContainer: {
    zIndex: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
});
