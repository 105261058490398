// NOTE: date calculations could be replaced by https://date-fns.org/ or another lightweight alternative to momentum.js
type DateInputType = Date | string | undefined | null;

// 1.12.20
export const formatDateShort = (dateString: string | undefined | null) => {
  if (!dateString) {
    return '';
  }
  const date = new Date(dateString);
  if (date.toString() !== 'Invalid Date') {
    return `${date.getDate()}.${date.getMonth() + 1}.${
      date.getFullYear() % 100
    }`;
  }
  return '';
};

export const addDays = (date: DateInputType, numOfDays: number) => {
  const curDate = parseDate(date);
  if (!curDate) {
    return undefined;
  }
  curDate.setDate(curDate.getDate() + numOfDays);
  return curDate;
};

export const addMonths = (date: DateInputType, numOfMonths: number) => {
  const curDate = parseDate(date);
  if (!curDate) {
    return undefined;
  }
  curDate.setMonth(curDate.getMonth() + numOfMonths);
  return curDate;
};

const addLeadingZero = (num: number) => {
  if (num < 10) {
    return `0${num}`;
  } else {
    return `${num}`;
  }
};

const isValidDate = (date: DateInputType) =>
  date &&
  date.toString() !== 'Invalid Date' &&
  date.toString() !== '1970-01-01' &&
  date.toString() !== 'NaN';

export const parseDate = (date: DateInputType) => {
  if (date === undefined || date === null) {
    return undefined;
  }
  let curDate = new Date(date);
  if (isValidDate(curDate)) {
    return curDate;
  }

  curDate = new Date(('' + date).substr(0, 10));
  if (isValidDate(curDate)) {
    return curDate;
  }
  return undefined;
};

export const nextDay = (date: DateInputType) => {
  const curDate = parseDate(date);
  if (!curDate) {
    return undefined;
  }
  curDate.setDate(curDate.getDate() + 1);
  return curDate;
};

// 1.12.2020
export const formatDateStandard = (
  dateString: Date | string | undefined | null
) => {
  if (!dateString) {
    return '';
  }
  const date = new Date(dateString);

  if (date.toString() !== 'Invalid Date') {
    // I18n: de return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    // I18n: standard
    return `${date.getFullYear()}/${addLeadingZero(
      date.getMonth() + 1
    )}/${addLeadingZero(date.getDate())}`;
  }
  return '';
};

export const formatDateDb = (dateString: Date | string | undefined) => {
  const date = parseDate(dateString);
  if (!date) {
    return '';
  }
  return `${date.getFullYear()}-${addLeadingZero(
    date.getMonth() + 1
  )}-${addLeadingZero(date.getDate())}`;
};

export const numberOfDays = (
  startDateString: Date | string | undefined,
  endDateString: Date | string | undefined
) => {
  if (!endDateString || !startDateString) {
    return 0;
  }
  const endDate = parseDate(endDateString);
  const startDate = parseDate(startDateString);
  if (!endDate || !startDate) {
    return 0;
  }
  const timeDifference = endDate.getTime() - startDate.getTime();
  return Math.round(timeDifference / (1000 * 3600 * 24));
};

export const beginningOfLastMonth = (startDate?: string | Date) => {
  let d: Date;
  if (startDate) {
    d = new Date(startDate);
  } else {
    d = new Date(); // now
  }
  d.setDate(1);
  d.setHours(0, 0, 0);
  d.setMonth(d.getMonth() - 1);
  return d;
};

export const shortDate = (date: Date | string | null | undefined) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const curDate = parseDate(date);
  if (curDate) {
    const day =
      curDate.getDate() < 10 ? `0${curDate.getDate()}` : curDate.getDate();
    return `${monthNames[curDate.getMonth()]} ${day}`;
  }
  return '';
};

export const isBefore = (date1: string, date2: string) => {
  const d1 = parseDate(date1);
  const d2 = parseDate(date2);
  return (
    d1 !== undefined && d2 !== undefined && formatDateDb(d1) < formatDateDb(d2)
  );
};

export const isSameDay = (
  date1: Date | string | undefined,
  date2: Date | string | undefined
) => {
  const d1 = parseDate(date1);
  const d2 = parseDate(date2);
  return (
    d1 !== undefined &&
    d2 !== undefined &&
    formatDateDb(d1) === formatDateDb(d2)
  );
};

export const getPreviousThreeMonths = (today: Date) => {
  const startDate = formatDateDb(beginningOfLastMonth(addMonths(today, -1)));
  const endDate = formatDateDb(
    beginningOfLastMonth(addDays(addMonths(today, 2), 1))
  );
  return { startDate, endDate };
};
