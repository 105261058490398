import React, { FC } from 'react';
import { WipScreen as WipScreenComponent } from '../../components/wip-screen/wip-screen';
import { RouteProp, useRoute } from '@react-navigation/native';
import { RootStackParamList } from '../../navigation/types';

export interface WipScreenProps {
  headline?: string;
  bodyText?: string;
}

export const WipScreen: FC = (props) => {
  const { children } = props;
  const route = useRoute<RouteProp<RootStackParamList, 'Wip'>>();
  const { headline, bodyText } = route.params;

  return (
    <WipScreenComponent headline={headline} bodyText={bodyText}>
      {children}
    </WipScreenComponent>
  );
};
