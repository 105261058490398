import * as Client from './client';
import { endpoints } from './endpoints';

export const createApi = (getBaseURL: () => string) => {
  const client = Client.create({ getBaseURL });
  const apiEndpoints = endpoints(client.sendRequest);
  return {
    ...apiEndpoints,
    client,
  };
};

export type ApiType = ReturnType<typeof createApi>;
