import {
  NetworkAggregatedDownline,
  NetworkDirectSponsored,
} from './../../api/types';
import { createApiActions, ExtractActionTypes } from '../create-api-actions';

export const fetchNetworkAggregatedDownlineActions = createApiActions(
  'FETCH_NETWORK_AGGREGATED_DOWNLINE_TRIGGER',
  'FETCH_NETWORK_AGGREGATED_DOWNLINE_START',
  'FETCH_NETWORK_AGGREGATED_DOWNLINE_SUCCESS',
  'FETCH_NETWORK_AGGREGATED_DOWNLINE_FAILURE'
)<undefined, NetworkAggregatedDownline>();

export const fetchNetworkDirectSponsoredActions = createApiActions(
  'FETCH_NETWORK_DIRECT_SPONSORED_TRIGGER',
  'FETCH_NETWORK_DIRECT_SPONSORED_START',
  'FETCH_NETWORK_DIRECT_SPONSORED_SUCCESS',
  'FETCH_NETWORK_DIRECT_SPONSORED_FAILURE'
)<undefined, NetworkDirectSponsored>();

export type NetworkActions =
  | ExtractActionTypes<typeof fetchNetworkAggregatedDownlineActions>
  | ExtractActionTypes<typeof fetchNetworkDirectSponsoredActions>;
