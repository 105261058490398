import { User } from '../../api/types';
import { createApiActions, ExtractActionTypes } from '../create-api-actions';

export const fetchUserActions = createApiActions(
  'FETCH_USER_INFO_TRIGGER',
  'FETCH_USER_INFO_START',
  'FETCH_USER_INFO_SUCCESS',
  'FETCH_USER_INFO_FAILURE'
)<undefined, User>();

export type UserActions = ExtractActionTypes<typeof fetchUserActions>;
