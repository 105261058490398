import React, { FC, useCallback, useMemo } from 'react';
import { View, Text, StyleSheet } from 'react-native';

import { spacing } from '../../theme/spacing';
import {
  DASHBOARD_TILE_FIRST_COLUMN_WIDTH,
  DASHBOARD_TILE_HEIGHT_XL,
  DASHBOARD_TILE_WIDTH,
} from '../../theme/fixed-sizes';
import { useTheme } from '../../hooks/use-theme';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../navigation/types';
import { DashboardTileLoading } from '../../components/dashboard-tile-loading/dashboard-tile-loading';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNetworkAggregatedDownlineActions } from '../../services/redux/actions/network';
import { RootState } from '../../services/redux/reducers';
import { NetworkState } from '../../services/redux/reducers/network';
import { Table, TableConfigItem } from '../../components/table/table';
import { useDataBadge } from './use-data-badge';
import { NetworkSize } from './network-size';
import { DualTreeItem, StringHash } from './types';
import { extractDualTreeData } from './selectors';

const LABEL_MAPPING: StringHash = {
  total: 'Total Members',
  newLastMonth: 'New Members Last Month',
  newLastThreeMonths: 'New Members Last 3 Months',
};

const TABLE_CONFIG: TableConfigItem<DualTreeItem>[] = [
  {
    header: ' ',
    align: 'left',
    width: 190,
    render: (data) => data.label,
  },
  {
    header: 'Left Leg',
    align: 'right',
    width: 70,
    render: (d) => <NetworkSize amount={d.left} />,
  },
  {
    header: 'Right Leg',
    align: 'right',
    width: 70,
    render: (d) => <NetworkSize amount={d.right} />,
  },
];

export const NetworkDualGenealogy: FC = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const commissions = useSelector<RootState, NetworkState>(
    (state) => state.network
  );
  const {
    downlineLoading: loading,
    downlineError: error,
    downlineData: data,
  } = commissions;
  const { theme } = useTheme();
  const { textStyles } = theme;

  const dispatch = useDispatch();
  const refetch = useCallback(() => {
    dispatch(fetchNetworkAggregatedDownlineActions.trigger(undefined));
  }, [dispatch]);

  const tableData = useMemo<DualTreeItem[] | null>(() => {
    return extractDualTreeData({
      data,
      labelMapping: LABEL_MAPPING,
    });
  }, [data]);

  const getButtonConfig = () => {
    return [
      {
        onPress: () => {
          navigation.navigate('MembersOverview', {
            selectedTabIndex: 1,
          });
        },
        title: 'See dual genealogy tree',
      },
    ];
  };

  const badgeType = useDataBadge(data?.isLiveData);

  return (
    <DashboardTileLoading
      loading={loading}
      error={error}
      data={data}
      refetch={refetch}
      style={styles.container}
      icon={'human-network-on'}
      buttons={getButtonConfig()}
      theme={theme}
      placeholderLoaderType="DASHBOARD_TOTAL_COMMISSIONS"
      showBadge={badgeType}
    >
      <Text style={[styles.margin, textStyles.H3Left]}>Dual Genealogy</Text>
      <View
        style={{
          marginLeft: -DASHBOARD_TILE_FIRST_COLUMN_WIDTH,
        }}
      >
        {tableData ? (
          <Table<DualTreeItem>
            data={tableData}
            config={TABLE_CONFIG}
            theme={theme}
          />
        ) : null}
      </View>
    </DashboardTileLoading>
  );
};

const styles = StyleSheet.create({
  container: {
    height: DASHBOARD_TILE_HEIGHT_XL,
    width: DASHBOARD_TILE_WIDTH,
  },
  margin: {
    marginBottom: spacing[2],
  },
});
