import { networkReducer } from './network';
import { subscriptionsReducer } from './subscriptions';
import { ordersReducer } from './orders';
import { payoutsReducer } from './payouts';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { setAutoFreeze } from 'immer';

import { supportTicketsReducer } from './support-tickets';
import { userCommissionsReducer } from './commissions';
import { userReducer } from './user-info';
import { messagesReducer } from './messages';
import { membersReducer } from './members';
import { newsReducer } from './news';
import { unplacedMembersCountReducer } from './unplaced-members-count';
import { settingsReducer } from './settings-reducer';
import { resetRootState } from '../actions/reset';
import { authReducer } from './auth';
import { REDUX_PERSIST_CONFIG } from '../redux-persist-config';

// to mitigate problems with immer and persist we disable auto freeze as
// suggested here - when the issues are resolved, this could be removed
// https://github.com/rt2zz/redux-persist/issues/747#issuecomment-425126732
// https://github.com/esamattis/immer-reducer/issues/38
// https://github.com/immerjs/immer#auto-freezing
setAutoFreeze(false);

const state = {
  auth: authReducer,
  user: userReducer,
  commissions: userCommissionsReducer,
  unplacedMembersCount: unplacedMembersCountReducer,
  news: newsReducer,
  messages: messagesReducer,
  members: membersReducer,
  supportTickets: supportTicketsReducer,
  payouts: payoutsReducer,
  orders: ordersReducer,
  subscriptions: subscriptionsReducer,
  settings: settingsReducer,
  network: networkReducer,
};

export const reducers = combineReducers(state);
const persistConfig = REDUX_PERSIST_CONFIG.storeConfig;
const appReducer = persistReducer(persistConfig, reducers);

export type RootState = ReturnType<typeof appReducer>;

export const rootReducer = (
  nextState: RootState | undefined,
  action: ReturnType<typeof resetRootState>
) => {
  if (action.type === resetRootState.getType()) {
    nextState = undefined;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore //TODO: FIXME: typescript issue
  return appReducer(nextState, action);
};
