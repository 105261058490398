import { fetchUserActions, UserActions } from './../actions/user-info';
import produce from 'immer';
import { Reducer } from 'redux';
import { ApiError, User } from '../../api/types';

const initialState: UserState = {
  loading: false,
  data: undefined,
};

export interface UserState {
  readonly loading: boolean;
  readonly error?: ApiError;
  readonly data?: User;
}

export const userReducer: Reducer<UserState, UserActions> = (
  state = initialState,
  action
) =>
  produce(state, (d) => {
    switch (action.type) {
      case fetchUserActions.types.start:
        d.error = undefined;
        d.loading = true;
        return;
      case fetchUserActions.types.success:
        d.data = action.payload.response;
        d.loading = false;
        return;
      case fetchUserActions.types.failure:
        d.error = action.payload.error;
        d.loading = false;
        return;
    }
  });
