import React, {
  Context,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  TouchableOpacity,
  Dimensions,
  Text,
  View,
  StyleSheet,
} from 'react-native';
import { SceneMap, TabView } from 'react-native-tab-view';

import { useTheme } from '../../hooks/use-theme';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenericRouteType = any;

const TabBar: FC<{
  index: number;
  routes: GenericRouteType[];
  onNavigate: (index: number) => void;
  titlesMapping: { [key: string]: string };
}> = (props) => {
  const { index: routeIndex, routes, onNavigate, titlesMapping } = props;
  const { theme } = useTheme();
  const { colors, textStyles } = theme;
  return (
    <View style={styles.row}>
      {routes.map((route, index) => {
        const tabTitle = titlesMapping[route.key] || route.key;
        const isFocused = routeIndex === index;
        const onPress = () => {
          onNavigate(index);
        };

        return (
          <TouchableOpacity
            key={route.key}
            accessibilityRole="button"
            accessibilityState={isFocused ? { selected: true } : {}}
            onPress={onPress}
            style={[
              styles.topBarItem,
              {
                backgroundColor: isFocused ? colors.link : colors.background,
                borderBottomColor: colors.border,
              },
            ]}
          >
            <Text
              style={[
                textStyles.BodyTextLinkLeft,
                { color: isFocused ? colors.background : colors.link },
              ]}
            >
              {tabTitle}
            </Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

interface TabViewBarConfig {
  key: string;
  title: string;
  component: FC;
}

export interface TabViewBarProps {
  selectedTabIndex: number;
  config: TabViewBarConfig[];
}

export interface ContextTabViewBarProps {
  selectedTabContext: Context<number>;
  config: TabViewBarConfig[];
}
interface SceneMapConfig {
  [k: string]: FC;
}

interface TilesMappingConfig {
  [k: string]: string;
}

export const ContextTabViewBar: FC<ContextTabViewBarProps> = (props) => {
  const { selectedTabContext, config } = props;
  const selectedTabIndex = useContext(selectedTabContext || undefined);
  return <TabViewBar selectedTabIndex={selectedTabIndex} config={config} />;
};

export const TabViewBar: FC<TabViewBarProps> = (props) => {
  const { selectedTabIndex, config } = props;

  const [index, setIndex] = useState<number>(selectedTabIndex);
  const routesState = useMemo(
    () =>
      config.map((c) => ({
        key: c.key,
        title: c.title,
      })),
    [config]
  );
  const [routes] = useState(routesState);

  useEffect(() => {
    setIndex(selectedTabIndex);
  }, [selectedTabIndex]);

  const sceneMap = config.reduce((memo: SceneMapConfig, c) => {
    memo[c.key] = c.component;
    return memo;
  }, {});
  const renderScene = SceneMap(sceneMap);

  const titlesMapping = useMemo(
    () =>
      config.reduce((memo: TilesMappingConfig, c) => {
        memo[c.key] = c.title;
        return memo;
      }, {}),
    [config]
  );

  const renderTabBar = useCallback(
    (tabProps) => {
      const {
        navigationState: { index: navigationIndex, routes: navigationRoutes },
      } = tabProps;

      return (
        <TabBar
          routes={navigationRoutes}
          index={navigationIndex}
          onNavigate={setIndex}
          titlesMapping={titlesMapping}
        />
      );
    },
    [setIndex, titlesMapping]
  );
  const initialLayout = { width: Dimensions.get('window').width };

  return (
    <TabView
      lazy
      swipeEnabled={false}
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
      initialLayout={initialLayout}
      renderTabBar={renderTabBar}
    />
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  topBarItem: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    borderBottomColor: '#ededed',
    borderBottomWidth: 1,
  },
});
