import { Dimensions, Platform } from 'react-native';
import { SMALL_SCREEN_MAX_WIDTH } from './fixed-sizes';

// TODO: IMPROVE-ME: website should reload when changing screen width
//       otherwise the fonts will stay in the sizes specified for web
const isSmallScreen = () =>
  Dimensions.get('window').width < SMALL_SCREEN_MAX_WIDTH;

interface GetPixelProps {
  web: number;
  native: number;
}
const getPixel = (props: GetPixelProps) => {
  const { native, web } = props;
  if (isSmallScreen()) {
    return native;
  }
  switch (Platform.OS) {
    case 'ios':
    case 'android':
      return native;

    default:
      return web;
  }
};

// NOTE: WEB: WIP
// export const H1_FONT_SIZE = getHeight({ native: 20, web: 25 });
// export const H1_LINE_HEIGHT = getHeight({ native: 30, web: 38 });
// export const H2_FONT_SIZE = getHeight({ native: 17, web: 20 });
// export const H2_LINE_HEIGHT = getHeight({ native: 26, web: 30 });
export const H3_FONT_SIZE = getPixel({ native: 24, web: 24 });
export const H3_LINE_HEIGHT = getPixel({ native: 32, web: 32 }); // 28?
// export const TAB_FONT_SIZE = 11;
export const BODY_FONT_SIZE = getPixel({ native: 14, web: 14 });
export const BODY_LINE_HEIGHT = getPixel({ native: 21, web: 21 });
export const BODY_BOLD_FONT_SIZE = getPixel({ native: 16, web: 16 });
export const BODY_BOLD_LINE_HEIGHT = getPixel({ native: 24, web: 24 });

// export const BODY_XS_FONT_SIZE = 10;
// export const BODY_XS_LINE_HEIGHT = 15;
// export const BODY_SMALL_FONT_SIZE = 12;
// export const BODY_SMALL_LINE_HEIGHT = 18;
// export const BODY_BIG_FONT_SIZE = 16;
// export const BODY_BIG_LINE_HEIGHT = 24;
// export const CURRENCY_SIZE = 22;
// export const CURRENCY_LINE_HEIGHT = 27;
// export const CURRENCY_BIG_GREEN_SIZE = 37;
// export const CURRENCY_BIG_GREEN_LINE_HEIGHT = 50;
export const BUTTON_FONT_SIZE = getPixel({ native: 18, web: 18 });
export const BUTTON_LINE_HEIGHT = getPixel({ native: 27, web: 27 }); // 25?

export const LETTER_SPACING1 = -0.3;
