/* eslint-disable no-console */
import React from 'react';
import { storiesOf } from '@storybook/react-native';
import { Button2 } from './button2';
import { lightTheme, darkTheme } from '../../hooks/use-theme';

storiesOf('Button2', module)
  .add('light', () => {
    return (
      <Button2
        text={'Login'}
        onPress={() => {
          console.warn('pressed');
        }}
        theme={lightTheme}
      />
    );
  })
  .add('light disabled', () => {
    return (
      <Button2 disabled text={'Login'} onPress={() => {}} theme={lightTheme} />
    );
  })
  .add('dark', () => {
    return (
      <Button2
        text={'Login'}
        onPress={() => {
          console.warn('pressed');
        }}
        theme={darkTheme}
      />
    );
  })
  .add('dark disabled', () => {
    return (
      <Button2 disabled text={'Login'} onPress={() => {}} theme={lightTheme} />
    );
  });
