import React, { FC, useCallback, useMemo } from 'react';
import { Text, StyleSheet, View } from 'react-native';

import { spacing } from '../../theme/spacing';
import {
  DASHBOARD_TILE_FIRST_COLUMN_WIDTH,
  DASHBOARD_TILE_HEIGHT_XL,
  DASHBOARD_TILE_WIDTH,
} from '../../theme/fixed-sizes';
import { useTheme } from '../../hooks/use-theme';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../navigation/types';
import { DashboardTileLoading } from '../../components/dashboard-tile-loading/dashboard-tile-loading';
import { NetworkState } from '../../services/redux/reducers/network';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/redux/reducers';
import { fetchNetworkDirectSponsoredActions } from '../../services/redux/actions/network';
import { Table, TableConfigItem } from '../../components/table/table';
import { NetworkDirectSponsored } from '../../services/api/types';
import { useDataBadge } from './use-data-badge';
import { NetworkSize } from './network-size';
import { TreeItem } from './types';

const TABLE_CONFIG: TableConfigItem<TreeItem>[] = [
  {
    align: 'left',
    width: 260,
    render: (data) => data.label,
  },
  {
    align: 'right',
    width: 70,
    render: (d) => <NetworkSize amount={d.amount} />,
  },
];

const buildTableData = (
  data: NetworkDirectSponsored | undefined,
  fieldLabels: { [key: string]: string }
) => {
  if (data === undefined) {
    return [];
  }

  return Object.entries(fieldLabels).reduce((memo: TreeItem[], entry) => {
    const label = fieldLabels[entry[0]];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    const amount = data[entry[0]];
    memo.push({ label, amount });
    return memo;
  }, []);
};

export const NetworkDirectOverview: FC = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const commissions = useSelector<RootState, NetworkState>(
    (state) => state.network
  );
  const {
    directSponsoredLoading: loading,
    directSponsoredError: error,
    directSponsoredData: data,
  } = commissions;
  const { theme } = useTheme();
  const { textStyles } = theme;

  const tableData = useMemo(() => {
    const fieldLabels = {
      total: 'Directly Sponsored Members',
      newLastMonth: 'New Members Last 3 Months',
      newLastThreeMonths: 'New Members Last 3 Months',
    };

    return buildTableData(data, fieldLabels);
  }, [data]);

  const dispatch = useDispatch();
  const refetch = useCallback(() => {
    dispatch(fetchNetworkDirectSponsoredActions.trigger(undefined));
  }, [dispatch]);

  const getButtonConfig = () => {
    return [
      {
        onPress: () => {
          navigation.navigate('MembersOverview', { selectedTabIndex: 0 });
        },
        title: 'Show my members',
      },
    ];
  };

  const badgeType = useDataBadge(data?.isLiveData);

  return (
    <DashboardTileLoading
      loading={loading}
      error={error}
      data={data}
      refetch={refetch}
      style={styles.container}
      icon={'human-network-on'}
      buttons={getButtonConfig()}
      theme={theme}
      placeholderLoaderType="DASHBOARD_TOTAL_COMMISSIONS"
      showBadge={badgeType}
    >
      <Text style={[styles.margin, textStyles.H3Left]}>Organization</Text>
      <View
        style={{
          marginLeft: -DASHBOARD_TILE_FIRST_COLUMN_WIDTH,
        }}
      >
        {tableData ? (
          <Table<TreeItem>
            data={tableData}
            config={TABLE_CONFIG}
            theme={theme}
          />
        ) : null}
      </View>
    </DashboardTileLoading>
  );
};

const styles = StyleSheet.create({
  container: {
    height: DASHBOARD_TILE_HEIGHT_XL,
    width: DASHBOARD_TILE_WIDTH,
  },
  margin: {
    marginBottom: spacing[2],
  },
});
