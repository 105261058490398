import React, { FC } from 'react';
import {
  TouchableOpacity,
  StyleSheet,
  View,
  ViewStyle,
  StyleProp,
} from 'react-native';
import { SvgProps } from 'react-native-svg';
import { spacing } from '../../theme/spacing';

// light
import AddMemberSvg from '../../assets/theme/svg-images/light/add-member';
import CalendarSvg from '../../assets/theme/svg-images/light/calendar';
import FlagSvg from '../../assets/theme/svg-images/light/flag';
import MessageSvg from '../../assets/theme/svg-images/light/message';
import OrdersSvg from '../../assets/theme/svg-images/light/orders';
import PlacementSvg from '../../assets/theme/svg-images/light/placement';
import SubscriptionsSvg from '../../assets/theme/svg-images/light/subscriptions';
import TimeSvg from '../../assets/theme/svg-images/light/time';
import MoneyBillSvg from '../../assets/theme/svg-images/light/money-bill';
import MoneyBillCircleSvg from '../../assets/theme/svg-images/light/money-bill-circle';
import MoneyBillDownArrowSvg from '../../assets/theme/svg-images/light/money-bill-down-arrow';
import MoneyBillArrowSvg from '../../assets/theme/svg-images/light/money-bill-down-arrow';
import MoneyGoalSvg from '../../assets/theme/svg-images/light/money-goal';
import SupportTicketSvg from '../../assets/theme/svg-images/light/support-ticket';
import NewsSvg from '../../assets/theme/svg-images/light/news';
import ExamSvg from '../../assets/theme/svg-images/light/exam';
import GlowingStarSvg from '../../assets/theme/svg-images/light/glowing-star';
import LinkSvg from '../../assets/theme/svg-images/light/link';
import DummySvg from '../../assets/theme/svg-images/light/question-mark';
import BurgerMenuSvg from '../../assets/theme/svg-images/light/burger-menu';
import RocketSvg from '../../assets/theme/svg-images/light/rocket';
import ArrowCircleSvg from '../../assets/theme/svg-images/light/arrow-circle';
import MoneyBillDottedSvg from '../../assets/theme/svg-images/light/money-bill-dotted';
import ShareSvg from '../../assets/theme/svg-images/light/share';
import HumanShareSvg from '../../assets/theme/svg-images/light/human-share';
import ChevronLeftSvg from '../../assets/theme/svg-images/light/chevron-left';
import ChevronRightSvg from '../../assets/theme/svg-images/light/chevron-right';
import CloseXSvg from '../../assets/theme/svg-images/light/close-x';
import SettingsSvg from '../../assets/theme/svg-images/light/settings';

import GlowingMoneyBillSvg from '../../assets/theme/svg-images/light/glowing-money-bill';
import DoubleBurgerOffSvg from '../../assets/theme/svg-images/light/double-burger-off';
import DoubleBurgerOnSvg from '../../assets/theme/svg-images/light/double-burger-on';
import HumanNetworkOffSvg from '../../assets/theme/svg-images/light/human-network-off';
import HumanNetworkOnSvg from '../../assets/theme/svg-images/light/human-network-on';

import MoneyBillOffSvg from '../../assets/theme/svg-images/light/money-bill-off';
import MoneyBillOnSvg from '../../assets/theme/svg-images/light/money-bill-on';
import MessageOffSvg from '../../assets/theme/svg-images/light/message-off';
import MessageOnSvg from '../../assets/theme/svg-images/light/message-on';
import ThreeDotsHorizontalOffSvg from '../../assets/theme/svg-images/light/three-dots-horizontal-off';
import ThreeDotsHorizontalOnSvg from '../../assets/theme/svg-images/light/three-dots-horizontal-on';

import ArrowUpSolidSvg from '../../assets/theme/svg-images/light/arrow-up-solid';
import ArrowDownSolidSvg from '../../assets/theme/svg-images/light/arrow-down-solid';
import ArrowDownOutlineSvg from '../../assets/theme/svg-images/light/arrow-down-outline';

// dark
import AddMemberDarkSvg from '../../assets/theme/svg-images/dark/add-member';
import CalendarDarkSvg from '../../assets/theme/svg-images/dark/calendar';
import FlagDarkSvg from '../../assets/theme/svg-images/dark/flag';
import MessageDarkSvg from '../../assets/theme/svg-images/dark/message';
import OrdersDarkSvg from '../../assets/theme/svg-images/dark/orders';
import PlacementDarkSvg from '../../assets/theme/svg-images/dark/placement';
import SubscriptionsDarkSvg from '../../assets/theme/svg-images/dark/subscriptions';
import TimeDarkSvg from '../../assets/theme/svg-images/dark/time';
import MoneyBillDarkSvg from '../../assets/theme/svg-images/dark/money-bill';
import MoneyBillCircleDarkSvg from '../../assets/theme/svg-images/dark/money-bill-circle';
import MoneyBillDownArrowDarkSvg from '../../assets/theme/svg-images/dark/money-bill-down-arrow';
import MoneyBillArrowDarkSvg from '../../assets/theme/svg-images/dark/money-bill-down-arrow';
import MoneyGoalDarkSvg from '../../assets/theme/svg-images/dark/money-goal';
import SupportTicketDarkSvg from '../../assets/theme/svg-images/dark/support-ticket';
import NewsDarkSvg from '../../assets/theme/svg-images/dark/news';
import ExamDarkSvg from '../../assets/theme/svg-images/dark/exam';
import GlowingStarDarkSvg from '../../assets/theme/svg-images/dark/glowing-star';
import LinkDarkSvg from '../../assets/theme/svg-images/dark/link';
import DummyDarkSvg from '../../assets/theme/svg-images/dark/question-mark';
import BurgerMenuDarkSvg from '../../assets/theme/svg-images/dark/burger-menu';
import RocketDarkSvg from '../../assets/theme/svg-images/dark/rocket';
import ArrowCircleDarkSvg from '../../assets/theme/svg-images/dark/arrow-circle';
import MoneyBillDottedDarkSvg from '../../assets/theme/svg-images/dark/money-bill-dotted';
import ShareDarkSvg from '../../assets/theme/svg-images/dark/share';
import HumanShareDarkSvg from '../../assets/theme/svg-images/dark/human-share';
import ChevronLeftDarkSvg from '../../assets/theme/svg-images/dark/chevron-left';
import ChevronRightDarkSvg from '../../assets/theme/svg-images/dark/chevron-right';
import CloseXDarkSvg from '../../assets/theme/svg-images/dark/close-x';
import SettingsDarkSvg from '../../assets/theme/svg-images/dark/settings';

import DoubleBurgerOffDarkSvg from '../../assets/theme/svg-images/dark/double-burger-off';
import DoubleBurgerOnDarkSvg from '../../assets/theme/svg-images/dark/double-burger-on';
import HumanNetworkOffDarkSvg from '../../assets/theme/svg-images/dark/human-network-off';
import HumanNetworkOnDarkSvg from '../../assets/theme/svg-images/dark/human-network-on';
import MoneyBillOffDarkSvg from '../../assets/theme/svg-images/dark/money-bill-off';
import MoneyBillOnDarkSvg from '../../assets/theme/svg-images/dark/money-bill-on';
import MessageOffDarkSvg from '../../assets/theme/svg-images/dark/message-off';
import MessageOnDarkSvg from '../../assets/theme/svg-images/dark/message-on';
import ThreeDotsHorizontalOffDarkSvg from '../../assets/theme/svg-images/dark/three-dots-horizontal-off';
import ThreeDotsHorizontalOnDarkSvg from '../../assets/theme/svg-images/dark/three-dots-horizontal-on';
import GlowingMoneyBillDarkSvg from '../../assets/theme/svg-images/dark/glowing-money-bill';

import ArrowUpSolidDarkSvg from '../../assets/theme/svg-images/dark/arrow-up-solid';
import ArrowDownSolidDarkSvg from '../../assets/theme/svg-images/dark/arrow-down-solid';
import ArrowDownOutlineDarkSvg from '../../assets/theme/svg-images/dark/arrow-down-outline';

// generic: light & dark
import CheckmarkP3Svg from '../../assets/theme/svg-images/generic/checkmark-p3';
import ClockSvg from '../../assets/theme/svg-images/generic/clock';
import WarningSvg from '../../assets/theme/svg-images/generic/warning';
import UserOutlineSvg from '../../assets/theme/svg-images/generic/user-outline';
import LockSvg from '../../assets/theme/svg-images/generic/lock';
import VisibleOnSvg from '../../assets/theme/svg-images/generic/visible-on';
import VisibleOffSvg from '../../assets/theme/svg-images/generic/visible-off';
import GenericChevronRightSvg from '../../assets/theme/svg-images/generic/chevron-right';
import GenericChevronDownSvg from '../../assets/theme/svg-images/generic/chevron-down';
import PowerButtonGreySvg from '../../assets/theme/svg-images/generic/power-button-grey';
import PersonGreenSvg from '../../assets/theme/svg-images/generic/person-green';
import SunBlueSvg from '../../assets/theme/svg-images/generic/sun-blue';

import { Themes } from '../../hooks/use-theme';

type DashboardSvgNames =
  | 'add-member'
  | 'calendar'
  | 'flag'
  | 'message'
  | 'orders'
  | 'placement'
  | 'subscriptions'
  | 'support-ticket'
  | 'time'
  | 'dummy'
  | 'money-bill'
  | 'money-bill-circle'
  | 'money-bill-down-arrow'
  | 'money-bill-arrow'
  | 'money-goal'
  | 'news'
  | 'exam'
  | 'glowing-star'
  | 'glowing-money-bill'
  | 'link'
  | 'burger-menu'
  | 'rocket'
  | 'arrow-circle'
  | 'money-bill-dotted'
  | 'share'
  | 'human-share'
  | 'chevron-left'
  | 'chevron-right'
  | 'close-x'
  | 'settings';

type BottomBarSvgNames =
  | 'double-burger-on'
  | 'double-burger-off'
  | 'human-network-on'
  | 'human-network-off'
  | 'money-bill-on'
  | 'money-bill-off'
  | 'message-on'
  | 'message-off'
  | 'three-dots-horizontal-on'
  | 'three-dots-horizontal-off';

type TableIconSvgNames =
  | 'arrow-up-solid'
  | 'arrow-down-solid'
  | 'arrow-down-outline';

type GenericSvgNames =
  | 'checkmark-p3'
  | 'clock'
  | 'warning'
  | 'user-outline'
  | 'lock'
  | 'visible-on'
  | 'visible-off'
  | 'generic-chevron-right'
  | 'generic-chevron-down'
  | 'power-button-grey'
  | 'person-green'
  | 'sun-blue';

export type SvgNames =
  | DashboardSvgNames
  | BottomBarSvgNames
  | TableIconSvgNames
  | GenericSvgNames;

type SvgImageType = FC<SvgProps>;

type SvgMapping = {
  [key in SvgNames]: SvgImageType;
};

const LIGHT_SVG_MAPPING: SvgMapping = {
  'add-member': AddMemberSvg,
  calendar: CalendarSvg,
  flag: FlagSvg,
  message: MessageSvg,
  orders: OrdersSvg,
  placement: PlacementSvg,
  subscriptions: SubscriptionsSvg,
  'support-ticket': SupportTicketSvg,
  time: TimeSvg,
  dummy: DummySvg,
  'money-bill': MoneyBillSvg,
  'money-bill-circle': MoneyBillCircleSvg,
  'money-bill-down-arrow': MoneyBillDownArrowSvg,
  'money-bill-arrow': MoneyBillArrowSvg,
  'money-goal': MoneyGoalSvg,
  news: NewsSvg,
  'glowing-star': GlowingStarSvg,
  'glowing-money-bill': GlowingMoneyBillSvg,
  exam: ExamSvg,
  link: LinkSvg,
  'burger-menu': BurgerMenuSvg,
  rocket: RocketSvg,
  'arrow-circle': ArrowCircleSvg,
  'money-bill-dotted': MoneyBillDottedSvg,
  share: ShareSvg,
  'human-share': HumanShareSvg,
  'chevron-left': ChevronLeftSvg,
  'chevron-right': ChevronRightSvg,
  'close-x': CloseXSvg,
  settings: SettingsSvg,
  'double-burger-off': DoubleBurgerOffSvg,
  'double-burger-on': DoubleBurgerOnSvg,
  'human-network-off': HumanNetworkOffSvg,
  'human-network-on': HumanNetworkOnSvg,
  'money-bill-on': MoneyBillOnSvg,
  'money-bill-off': MoneyBillOffSvg,
  'message-on': MessageOnSvg,
  'message-off': MessageOffSvg,
  'three-dots-horizontal-on': ThreeDotsHorizontalOnSvg,
  'three-dots-horizontal-off': ThreeDotsHorizontalOffSvg,
  'arrow-up-solid': ArrowUpSolidSvg,
  'arrow-down-solid': ArrowDownSolidSvg,
  'arrow-down-outline': ArrowDownOutlineSvg,
  'checkmark-p3': CheckmarkP3Svg,
  clock: ClockSvg,
  warning: WarningSvg,
  'user-outline': UserOutlineSvg,
  lock: LockSvg,
  'visible-on': VisibleOnSvg,
  'visible-off': VisibleOffSvg,
  'generic-chevron-down': GenericChevronDownSvg,
  'generic-chevron-right': GenericChevronRightSvg,
  'power-button-grey': PowerButtonGreySvg,
  'person-green': PersonGreenSvg,
  'sun-blue': SunBlueSvg,
};

const DARK_SVG_MAPPING: SvgMapping = {
  'add-member': AddMemberDarkSvg,
  calendar: CalendarDarkSvg,
  flag: FlagDarkSvg,
  message: MessageDarkSvg,
  orders: OrdersDarkSvg,
  placement: PlacementDarkSvg,
  subscriptions: SubscriptionsDarkSvg,
  'support-ticket': SupportTicketDarkSvg,
  time: TimeDarkSvg,
  dummy: DummyDarkSvg,
  'money-bill': MoneyBillDarkSvg,
  'money-bill-circle': MoneyBillCircleDarkSvg,
  'money-bill-down-arrow': MoneyBillDownArrowDarkSvg,
  'money-bill-arrow': MoneyBillArrowDarkSvg,
  'money-goal': MoneyGoalDarkSvg,
  news: NewsDarkSvg,
  'glowing-money-bill': GlowingMoneyBillDarkSvg,
  'glowing-star': GlowingStarDarkSvg,
  exam: ExamDarkSvg,
  link: LinkDarkSvg,
  'burger-menu': BurgerMenuDarkSvg,
  rocket: RocketDarkSvg,
  'arrow-circle': ArrowCircleDarkSvg,
  'money-bill-dotted': MoneyBillDottedDarkSvg,
  share: ShareDarkSvg,
  'human-share': HumanShareDarkSvg,
  'chevron-left': ChevronLeftDarkSvg,
  'chevron-right': ChevronRightDarkSvg,
  'close-x': CloseXDarkSvg,
  settings: SettingsDarkSvg,
  'double-burger-off': DoubleBurgerOffDarkSvg,
  'double-burger-on': DoubleBurgerOnDarkSvg,
  'human-network-off': HumanNetworkOffDarkSvg,
  'human-network-on': HumanNetworkOnDarkSvg,
  'money-bill-on': MoneyBillOnDarkSvg,
  'money-bill-off': MoneyBillOffDarkSvg,
  'message-on': MessageOnDarkSvg,
  'message-off': MessageOffDarkSvg,
  'three-dots-horizontal-on': ThreeDotsHorizontalOnDarkSvg,
  'three-dots-horizontal-off': ThreeDotsHorizontalOffDarkSvg,
  'arrow-up-solid': ArrowUpSolidDarkSvg,
  'arrow-down-solid': ArrowDownSolidDarkSvg,
  'arrow-down-outline': ArrowDownOutlineDarkSvg,
  'checkmark-p3': CheckmarkP3Svg,
  clock: ClockSvg,
  warning: WarningSvg,
  'user-outline': UserOutlineSvg,
  lock: LockSvg,
  'visible-on': VisibleOnSvg,
  'visible-off': VisibleOffSvg,
  'generic-chevron-down': GenericChevronDownSvg,
  'generic-chevron-right': GenericChevronRightSvg,
  'power-button-grey': PowerButtonGreySvg,
  'person-green': PersonGreenSvg,
  'sun-blue': SunBlueSvg,
};

interface WrapperProps {
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
}

const Wrapper: FC<WrapperProps> = (props) => {
  const { onPress, style, children } = props;

  if (onPress !== undefined) {
    return (
      <TouchableOpacity onPress={onPress} style={style}>
        {children}
      </TouchableOpacity>
    );
  }
  return <View style={style}>{children}</View>;
};

interface SvgImageProps {
  width: string;
  height: string;
  type: SvgNames;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
  variant: Themes;
  withPadding?: boolean;
}

export const SvgImage: FC<SvgImageProps> = (props) => {
  const { type, onPress, style, width, height, variant, withPadding } = props;
  const wrapperStyle = [];
  if (withPadding) {
    wrapperStyle.push(styles.padding);
  }
  if (style) {
    wrapperStyle.push(style);
  }

  const SvgComponent =
    variant === 'dark' ? DARK_SVG_MAPPING[type] : LIGHT_SVG_MAPPING[type];

  return (
    <Wrapper onPress={onPress} style={wrapperStyle}>
      <SvgComponent width={width} height={height} />
    </Wrapper>
  );
};

interface Styles {
  padding: ViewStyle;
}

const styles = StyleSheet.create<Styles>({
  padding: {
    paddingVertical: spacing[1],
    paddingHorizontal: spacing[1],
  },
});
