import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M20 8l-8 8-8-8"
        stroke="#171817"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default SvgComponent;
