import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={8} height={14} viewBox="0 0 8 14" fill="none" {...props}>
      <Path
        d="M7.094 9.492c0 .708-.266 1.294-.797 1.758-.531.458-1.274.747-2.227.867v1.813H3.062v-1.742c-.583 0-1.148-.045-1.695-.133-.547-.094-.995-.222-1.344-.383v-1.219c.433.193.93.352 1.493.477.567.12 1.083.18 1.546.18V7.671c-1.067-.339-1.817-.732-2.25-1.18-.427-.448-.64-1.026-.64-1.734 0-.682.263-1.242.789-1.68.531-.437 1.232-.703 2.101-.797V.86H4.07v1.407a7.93 7.93 0 012.774.578l-.407 1.023A7.934 7.934 0 004.07 3.32v3.39c.818.261 1.43.517 1.836.767.407.244.706.528.899.851.192.318.289.706.289 1.164zm-1.328.102c0-.375-.118-.677-.352-.906-.23-.235-.677-.467-1.344-.696v3.04c1.13-.157 1.696-.636 1.696-1.438zM1.492 4.742c0 .396.117.714.352.953.234.24.64.466 1.218.68V3.352c-.515.083-.906.247-1.171.492a1.16 1.16 0 00-.399.898z"
        fill="#FFFFFF"
      />
    </Svg>
  );
};

export default SvgComponent;
