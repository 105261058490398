import React, { FC, useCallback, useEffect } from 'react';
import { Text, StyleSheet, View } from 'react-native';

import { spacing } from '../../theme/spacing';
import { useTheme } from '../../hooks/use-theme';
import { HistoryChart } from '../../components/history-chart/history-chart';
import { LoadingScreen } from '../../components/loading-screen/loading-screen';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCommissionsHistoryActions } from '../../services/redux/actions/commissions';
import { RootState } from '../../services/redux/reducers';
import { CommissionsState } from '../../services/redux/reducers/commissions';
import { getPreviousThreeMonths } from '../../utils/format-date';

export const CommissionsHistoryScreen: FC = () => {
  const commissionsSelector = useSelector<RootState, CommissionsState>(
    (state) => state.commissions
  );
  const {
    historyLoading: loading,
    historyError: error,
    historyData: data,
  } = commissionsSelector;
  const { startDate, endDate } = getPreviousThreeMonths(new Date());

  const dispatch = useDispatch();
  const refetch = useCallback(() => {
    dispatch(
      fetchCommissionsHistoryActions.trigger({
        startDate,
        endDate,
        bonusType: 'monthly',
      })
    );
  }, [dispatch, startDate, endDate]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const { theme } = useTheme();
  const { textStyles } = theme;

  return (
    <LoadingScreen
      loading={loading}
      error={error}
      refetch={refetch}
      placeholderLoaderType="MESSAGES_SCREEN"
    >
      <View style={styles.container}>
        <Text style={[styles.margin, textStyles.H3Left]}>
          History of my commissions
        </Text>
        <View style={styles.chartContainer}>
          <HistoryChart
            startDate={startDate}
            endDate={endDate}
            height={240}
            xAxisUnit={'MONTHS'}
            data={data}
            showGradient
            showVerticalGrid
            showDashedLine
            showHorizontalGrid
            showYAxis
            showPoints
          />
        </View>
      </View>
    </LoadingScreen>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: spacing[2],
    paddingHorizontal: spacing[3],
    alignItems: 'center',
    justifyContent: 'center',
  },
  margin: {
    marginBottom: spacing[2],
  },
  chartContainer: {
    marginTop: 20,
  },
});
