import * as React from 'react';
import Svg, { Rect, Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={29} height={29} viewBox="0 0 29 29" fill="none" {...props}>
      <Rect x={14} width={1} height={4} rx={0.5} fill="#D95BB7" />
      <Rect x={14} y={25} width={1} height={4} rx={0.5} fill="#D95BB7" />
      <Rect
        y={15}
        width={1}
        height={4}
        rx={0.5}
        transform="rotate(-90 0 15)"
        fill="#D95BB7"
      />
      <Rect
        x={25}
        y={15}
        width={1}
        height={4}
        rx={0.5}
        transform="rotate(-90 25 15)"
        fill="#D95BB7"
      />
      <Path
        d="M18.094 16.492c0 .709-.266 1.294-.797 1.758-.531.458-1.274.747-2.227.867v1.813h-1.008v-1.742a10.6 10.6 0 01-1.695-.133c-.547-.094-.995-.222-1.344-.383v-1.219c.433.193.93.352 1.493.477.567.12 1.083.18 1.546.18v-3.438c-1.067-.339-1.817-.732-2.25-1.18-.427-.448-.64-1.026-.64-1.734 0-.682.263-1.242.789-1.68.531-.437 1.232-.703 2.101-.797V7.86h1.008v1.407a7.93 7.93 0 012.774.578l-.407 1.023a7.933 7.933 0 00-2.367-.547v3.39c.818.261 1.43.517 1.836.767.407.244.706.528.899.851.192.318.289.706.289 1.164zm-1.328.102c0-.375-.118-.677-.352-.907-.23-.234-.677-.466-1.344-.695v3.04c1.13-.157 1.696-.636 1.696-1.438zm-4.274-4.852c0 .396.117.714.352.953.234.24.64.466 1.219.68v-3.023c-.516.083-.907.247-1.172.492a1.16 1.16 0 00-.399.898z"
        fill="#2D9CDB"
      />
      <Rect
        x={3.894}
        y={4.6}
        width={1}
        height={4}
        rx={0.5}
        transform="rotate(-45 3.894 4.6)"
        fill="#D95BB7"
      />
      <Rect
        x={21.571}
        y={22.278}
        width={1}
        height={4}
        rx={0.5}
        transform="rotate(-45 21.571 22.278)"
        fill="#D95BB7"
      />
      <Rect
        x={24.399}
        y={3.893}
        width={1}
        height={4}
        rx={0.5}
        transform="rotate(45 24.4 3.893)"
        fill="#D95BB7"
      />
      <Rect
        x={6.722}
        y={21.571}
        width={1}
        height={4}
        rx={0.5}
        transform="rotate(45 6.722 21.57)"
        fill="#D95BB7"
      />
    </Svg>
  );
};

export default SvgComponent;
