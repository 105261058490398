import { PaginatedResponse, PaginationParams } from './types';
import * as Types from './types';
import qs from 'qs';

export async function fakeApiResponse<T>(data: T): Promise<Types.ApiResult<T>> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        success: true,
        data,
        error: undefined,
      });
    }, 1);
  });
}

export async function fakePaginatedApiResponse<T>(data: T) {
  return fakeApiResponse({
    total: 10,
    data,
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SendRequest = (configOverride: Partial<Types.ApiConfig>) => Promise<any>;

export const endpoints = (sendRequest: SendRequest) => ({
  getUser: (): Promise<Types.ApiResult<Types.User>> => {
    return sendRequest({ path: '/me' });
  },

  getCommissionsHistory: (
    params: Types.GetCommissionsHistoryParams
  ): Promise<
    Types.ApiResult<PaginatedResponse<Types.CommissionsHistory[]>>
  > => {
    return sendRequest({
      path: '/me/commission/history',
      params,
    });
  },
  getCommissionDetails: (
    params: Types.GetCommissionsDetailsParams
  ): Promise<Types.ApiResult<PaginatedResponse<Types.Commission[]>>> => {
    return sendRequest({ path: '/me/commissions/details-aggregated', params });
  },
  getLatestCommissions: (): Promise<
    Types.ApiResult<PaginatedResponse<Types.Commission[]>>
  > => {
    return sendRequest({
      path: '/me/commissions/details-aggregated',
      params: {
        sortBy: 'calculateDate',
        sortOrder: 'desc',
        page: 0,
        pageSize: 3,
      },
    });
  },

  getUnplacedMembersCount: (): Promise<
    Types.ApiResult<Types.UnplacedMembersCount>
  > => {
    return sendRequest({ path: '/me/members/unplaced/count' });
  },
  getLatestNews: (): Promise<Types.ApiResult<Types.News[]>> => {
    return sendRequest({
      path: '/me/news',
      params: {
        sortBy: 'date',
        sortOrder: 'asc',
        fields: 'all',
        limit: 3,
        page: 0,
        pageSize: 3,
      },
    });
  },
  getNews: (
    params: Types.GetNewsParams
  ): Promise<Types.ApiResult<PaginatedResponse<Types.News[]>>> => {
    // TODO: re-enable next lines and remove rest of function, when when api returns paginated response
    // return sendRequest({
    //   path: '/me/news',
    //   params,
    // })

    // NOTE: wrapping plain array response in PaginatedResponse
    return new Promise((resolve) => {
      sendRequest({
        path: '/me/news',
        params,
      }).then((response) => {
        resolve({
          success: true,
          data: { data: response.data, total: response.data?.length },
        });
      });
    });
  },
  getNewsDetail: (
    params: Types.GetNewsDetailParams
  ): Promise<Types.ApiResult<Types.News>> => {
    return sendRequest({
      path: '/me/news-detail',
      params,
    });
  },
  getLatestMessages: (): Promise<
    Types.ApiResult<PaginatedResponse<Types.MessageShort[]>>
  > => {
    return sendRequest({
      path: '/me/messages',
      params: {
        sortBy: 'date',
        sortOrder: 'desc',
        fields: 'header',
        limit: 3,
        page: 0,
        pageSize: 3,
      },
    });
  },
  getUnreadMessagesCount: (): Promise<
    Types.ApiResult<Types.UnreadMessagesCount>
  > => {
    return sendRequest({ path: '/me/messages/unread/count' });
  },
  getMessages: (
    params: PaginationParams
  ): Promise<Types.ApiResult<PaginatedResponse<Types.Message[]>>> => {
    return sendRequest({
      path: '/me/messages',
      params: params,
    });
  },
  getMembers: (
    params: PaginationParams
  ): Promise<Types.ApiResult<PaginatedResponse<Types.NetworkMember[]>>> => {
    return sendRequest({
      path: '/me/members',
      params: params,
    });
  },
  getCommissionsPerformance: (): Promise<
    Types.ApiResult<Types.CommissionsPerformance>
  > => {
    return sendRequest({
      path: '/me/commissions/pv-overview',
    });
  },
  getMembersPerformanceLeaderboard: (
    params: PaginationParams
  ): Promise<Types.ApiResult<PaginatedResponse<Types.MemberPerformance[]>>> => {
    return sendRequest({
      path: '/me/members/performance-leaderboard',
      params: {
        ...params,
      },
    });
  },
  getMembersCommissionLeaderboard: (
    params: PaginationParams
  ): Promise<Types.ApiResult<PaginatedResponse<Types.MemberCommission[]>>> => {
    return sendRequest({
      path: '/me/members/commissions-leaderboard',
      params: {
        ...params,
      },
    });
  },
  getTopPerformingMembers: (): Promise<
    Types.ApiResult<PaginatedResponse<Types.MemberPerformance[]>>
  > => {
    return sendRequest({
      path: '/me/members/performance-leaderboard',
      params: {
        limit: 3,
      },
    });
  },
  getTopCommissionMembers: (): Promise<
    Types.ApiResult<PaginatedResponse<Types.MemberCommission[]>>
  > => {
    return sendRequest({
      path: '/me/members/commissions-leaderboard',
      params: {
        limit: 3,
      },
    });
  },
  getLatestSupportTickets: (): Promise<
    Types.ApiResult<
      PaginatedResponse<Types.SupportTicketOrSupportTicketShort[]>
    >
  > => {
    return sendRequest({
      path: '/me/support-tickets',
      params: {
        sortBy: 'date',
        sortOrder: 'desc',
        fields: 'header',
        page: 0,
        pageSize: 3,
      },
    });
  },
  getOpenSupportTicketsCount: (): Promise<
    Types.ApiResult<Types.OpenSupportTicketsCount>
  > => {
    return sendRequest({ path: '/me/support-tickets/open/count' });
  },
  getSupportTickets: (
    params: Types.GetSupportTicketParams
  ): Promise<Types.ApiResult<PaginatedResponse<Types.SupportTicket[]>>> => {
    return sendRequest({
      path: '/me/support-tickets',
      params,
    });
  },
  getNetworkDirectSponsored: (): Promise<
    Types.ApiResult<Types.NetworkDirectSponsored>
  > => {
    return sendRequest({
      path: '/me/network/aggregated/direct-sponsored',
    });
  },
  getNetworkAggregatedDownline: (): Promise<
    Types.ApiResult<Types.NetworkAggregatedDownline>
  > => {
    return sendRequest({
      path: '/me/network/aggregated/downline',
    });
  },

  getLatestPayouts: (): Promise<
    Types.ApiResult<PaginatedResponse<Types.Payout[]>>
  > => {
    return sendRequest({
      path: '/me/payouts',
      params: {
        sortBy: 'date',
        sortOrder: 'desc',
        page: 0,
        pageSize: 3,
      },
    });
  },
  getPayouts: (
    params: PaginationParams
  ): Promise<Types.ApiResult<PaginatedResponse<Types.Payout[]>>> => {
    return sendRequest({
      path: '/me/payouts',
      params,
    });
  },
  getLatestOrders: (): Promise<
    Types.ApiResult<PaginatedResponse<Types.Order[]>>
  > => {
    return sendRequest({
      path: '/me/orders',
      params: {
        sortBy: 'date',
        sortOrder: 'desc',
        page: 0,
        pageSize: 3,
      },
    });
  },
  getOrders: (
    params: PaginationParams
  ): Promise<Types.ApiResult<PaginatedResponse<Types.Order[]>>> => {
    return sendRequest({
      path: '/me/orders',
      params,
    });
  },
  getLatestSubscriptions: (): Promise<
    Types.ApiResult<PaginatedResponse<Types.Subscription[]>>
  > => {
    return sendRequest({
      path: '/me/subscriptions',
      params: {
        sortBy: 'date',
        sortOrder: 'desc',
        page: 0,
        pageSize: 3,
      },
    });
  },
  getSubscriptions: (
    params: PaginationParams
  ): Promise<Types.ApiResult<PaginatedResponse<Types.Subscription[]>>> => {
    return sendRequest({
      path: '/me/subscriptions',
      params,
    });
  },
  postAuthToken: (
    params: Types.PostAuthTokenParams
  ): Promise<Types.ApiResult<Types.PostAuthTokenResponse>> => {
    return sendRequest({
      method: 'POST',
      path: '/auth/token',

      params,
      transformRequest: (request) => {
        return {
          ...request,
          params: undefined,
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: qs.stringify({
            ...params,
          }),
        };
      },
    });
  },
});
