import { call, put, select } from 'redux-saga/effects';
import { ApiType } from '../../api';
import { ApiError, ApiResult } from '../../api/types';

import { ApiActionCreators } from '../create-api-actions';
import { RootState } from '../reducers';
import { updateAuthHeader } from '../../api/update-auth-header';

const getAuthTokens = (state: RootState) => state.auth.data?.token;

function* loadTokens() {
  return yield select(getAuthTokens);
}

export function* callApiEndpoint<ResponseType, ParamsType>(
  actionsCreator: ApiActionCreators<ParamsType, ResponseType>,
  apiEndpoint: (p: ParamsType) => Promise<ApiResult<ResponseType>>,
  params: ParamsType,
  Api: ApiType
) {
  const tokens = yield call(loadTokens);
  yield call(updateAuthHeader, Api, tokens);

  yield put(actionsCreator.start());
  const result = yield call(apiEndpoint, params);
  if (result.success === true && result.data !== undefined) {
    yield put(actionsCreator.success(params, result.data));
  } else if (result.error !== undefined) {
    const error: ApiError = result.error;

    yield put(actionsCreator.failure(params, error, result.data));
  }
}
