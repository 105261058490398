import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../navigation/types';
import { RootState } from '../../services/redux/reducers';
import { MessagesState } from '../../services/redux/reducers/messages';
import { fetchLatestMessagesActions } from '../../services/redux/actions/messages';
import {
  DASHBOARD_TILE_HEIGHT_XS,
  DASHBOARD_TILE_WIDTH,
} from '../../theme/fixed-sizes';
import { useTheme } from '../../hooks/use-theme';
import { DashboardTileLoading } from '../../components/dashboard-tile-loading/dashboard-tile-loading';

export const DashboardLatestMessages: FC = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const latestMessagesSelector = useSelector<RootState, MessagesState>(
    (state) => state.messages
  );
  const {
    unreadCountLoading: loading,
    unreadCountError: error,
    data: { unreadCount },
  } = latestMessagesSelector;

  const { theme } = useTheme();
  const { textStyles } = theme;

  const dispatch = useDispatch();
  const refetch = () => {
    dispatch(fetchLatestMessagesActions.trigger(undefined));
  };

  const navigateToMessages = () => {
    navigation.navigate('BottomTabs', {
      screen: 'InboxOverview',
      selectedTabIndex: 1,
    });
  };

  const getButtonConfig = () => {
    return [
      {
        onPress: navigateToMessages,
        title: 'Show all messages',
      },
    ];
  };

  return (
    <DashboardTileLoading
      loading={loading}
      error={error}
      data={unreadCount}
      refetch={refetch}
      style={styles.container}
      icon="message"
      buttons={getButtonConfig()}
      theme={theme}
      placeholderLoaderType="DASHBOARD_LATEST_MESSAGES"
    >
      {unreadCount && unreadCount > 0 ? (
        <Text style={textStyles.H3Left}>
          <Text style={textStyles.H3LeftBold}>{unreadCount}</Text>
          <Text> Unread Messages</Text>
        </Text>
      ) : (
        <Text style={textStyles.H3Left}>No unread messages</Text>
      )}
    </DashboardTileLoading>
  );
};

const styles = StyleSheet.create({
  container: {
    height: DASHBOARD_TILE_HEIGHT_XS,
    width: DASHBOARD_TILE_WIDTH,
  },
});
