import React, { FC } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import { DashboardScreen } from '../screens/dashboard/dashboard-screen';
import { BottomTabParamList, RootStackParamList } from './types';
import { ThemeConfig, useTheme } from '../hooks/use-theme';
import { SvgImage, SvgNames } from '../components/svg-image/svg-image';
import { spacing } from '../theme/spacing';
import { MoreNavigationScreen } from '../screens/more-navigation/more-navigation-screen';
import { BOTTOM_TAB_NAVIGATION_HEIGHT } from '../theme/fixed-sizes';
import { CommissionsOverviewScreen } from '../screens/commissions-overview/commissions-overview-screen';
import { InboxOverviewScreen } from '../screens/inbox-overview/inbox-overview-screen';
import { NetworkOverviewScreen } from '../screens/network-overview/network-overview-screen';
import { InboxOverviewScreenSelectedTabContext } from './params-context';
import { RouteProp } from '@react-navigation/native';

const BottomTab = createBottomTabNavigator<BottomTabParamList>();
interface ActiveSvgImageProps {
  activeSource: SvgNames;
  inactiveSource: SvgNames;
  active: boolean;
  theme: ThemeConfig;
}

const ActiveSvgImage: FC<ActiveSvgImageProps> = (props) => {
  const { activeSource, inactiveSource, active, theme } = props;
  const svgType = active ? activeSource : inactiveSource;
  return (
    <SvgImage width={'25'} height={'25'} type={svgType} variant={theme.name} />
  );
};

const BottomTabNavigator: FC<{
  route: RouteProp<RootStackParamList, 'BottomTabs'>;
}> = ({ route }) => {
  const { theme } = useTheme();
  const { colors, textStyles } = theme;

  const tabBarOptions = {
    activeTintColor: colors.link,
    inactiveTintColor: colors.textAlternative,
    inactiveBackgroundColor: colors.background,
    activeBackgroundColor: colors.backgroundOpacity,
    labelStyle: {
      ...textStyles.BottomNavigationTextLeft,
      paddingBottom: spacing[1],
    },
    style: {
      backgroundColor: colors.background,
      height: BOTTOM_TAB_NAVIGATION_HEIGHT,
    },
  };

  return (
    <InboxOverviewScreenSelectedTabContext.Provider
      value={route?.params?.selectedTabIndex || 0}
    >
      <BottomTab.Navigator
        initialRouteName="Dashboard"
        tabBarOptions={tabBarOptions}
      >
        <BottomTab.Screen
          name="Dashboard"
          component={DashboardScreen}
          options={{
            tabBarIcon: ({ focused }) => (
              <ActiveSvgImage
                active={focused}
                activeSource={'double-burger-on'}
                inactiveSource={'double-burger-off'}
                theme={theme}
              />
            ),
          }}
        />
        <BottomTab.Screen
          name="CommissionsOverview"
          component={CommissionsOverviewScreen}
          options={{
            tabBarLabel: 'Commissions',
            tabBarIcon: ({ focused }) => (
              <ActiveSvgImage
                active={focused}
                activeSource={'money-bill-on'}
                inactiveSource={'money-bill-off'}
                theme={theme}
              />
            ),
          }}
        />
        <BottomTab.Screen
          name="InboxOverview"
          component={InboxOverviewScreen}
          initialParams={{ initialRouteName: 'News' }}
          options={{
            tabBarLabel: 'Inbox',
            tabBarIcon: ({ focused }) => (
              <ActiveSvgImage
                active={focused}
                activeSource={'message-on'}
                inactiveSource={'message-off'}
                theme={theme}
              />
            ),
          }}
        />
        <BottomTab.Screen
          name="NetworkOverview"
          component={NetworkOverviewScreen}
          options={{
            tabBarLabel: 'Network',
            tabBarIcon: ({ focused }) => (
              <ActiveSvgImage
                active={focused}
                activeSource={'human-network-on'}
                inactiveSource={'human-network-off'}
                theme={theme}
              />
            ),
          }}
        />
        <BottomTab.Screen
          name="MoreNavigation"
          component={MoreNavigationScreen}
          options={{
            tabBarLabel: 'More',
            tabBarIcon: ({ focused }) => (
              <ActiveSvgImage
                active={focused}
                activeSource={'three-dots-horizontal-on'}
                inactiveSource={'three-dots-horizontal-off'}
                theme={theme}
              />
            ),
          }}
        />
      </BottomTab.Navigator>
    </InboxOverviewScreenSelectedTabContext.Provider>
  );
};

export default BottomTabNavigator;
