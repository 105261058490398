import {
  GetMembersParams,
  MemberCommission,
  MemberPerformance,
  NetworkMember,
  PaginatedResponse,
} from '../../api/types';
import { createApiActions, ExtractActionTypes } from '../create-api-actions';

export const fetchTopPerformingMembersActions = createApiActions(
  'FETCH_TOP_PERFORMING_MEMBERS_TRIGGER',
  'FETCH_TOP_PERFORMING_MEMBERS_START',
  'FETCH_TOP_PERFORMING_MEMBERS_SUCCESS',
  'FETCH_TOP_PERFORMING_MEMBERS_FAILURE'
)<undefined, PaginatedResponse<MemberPerformance[]>>();

export const fetchTopCommissionMembersActions = createApiActions(
  'FETCH_TOP_COMMISSION_MEMBERS_TRIGGER',
  'FETCH_TOP_COMMISSION_MEMBERS_START',
  'FETCH_TOP_COMMISSION_MEMBERS_SUCCESS',
  'FETCH_TOP_COMMISSION_MEMBERS_FAILURE'
)<undefined, PaginatedResponse<MemberCommission[]>>();

export const fetchMembersActions = createApiActions(
  'FETCH_MEMBERS_TRIGGER',
  'FETCH_MEMBERS_START',
  'FETCH_MEMBERS_SUCCESS',
  'FETCH_MEMBERS_FAILURE'
)<GetMembersParams, PaginatedResponse<NetworkMember[]>>();

export type MembersActions =
  | ExtractActionTypes<typeof fetchTopPerformingMembersActions>
  | ExtractActionTypes<typeof fetchTopCommissionMembersActions>
  | ExtractActionTypes<typeof fetchMembersActions>;
