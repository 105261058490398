import React, { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet, Text, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { RootStackParamList } from '../../navigation/types';
import { RootState } from '../../services/redux/reducers';
import { SubscriptionsState } from '../../services/redux/reducers/subscriptions';
import { spacing } from '../../theme/spacing';
import { fetchLatestSubscriptionsActions } from '../../services/redux/actions/subscriptions';
import {
  DASHBOARD_TILE_FIRST_COLUMN_WIDTH,
  DASHBOARD_TILE_HEIGHT_LARGE,
  DASHBOARD_TILE_WIDTH,
  TABLE_COLUMN_WIDTH_CHECKBOX,
  TABLE_COLUMN_WIDTH_DATE,
} from '../../theme/fixed-sizes';
import { formatDateStandard } from '../../utils/format-date';
import { Table, TableConfigItem } from '../../components/table/table';
import { Subscription } from '../../services/api/types';
import { useTheme } from '../../hooks/use-theme';
import { deNormalize } from '../../services/data/normalize';
import { DashboardTileLoading } from '../../components/dashboard-tile-loading/dashboard-tile-loading';
import { Currency } from '../../components/currency/currency';
import { StatusIcon } from '../../components/status-icon/status-icon';

const SUBSCRIPTIONS_TABLE_CONFIG: TableConfigItem<Subscription>[] = [
  {
    width: TABLE_COLUMN_WIDTH_DATE,
    render: (subscription) => `${formatDateStandard(subscription.date)}`,
  },
  {
    width: 180,
    render: (subscription) => `${subscription.productName}`,
  },
  {
    width: 50,
    align: 'right',
    render: (subscription) => (
      <Currency
        amount={subscription.monthlyTotalAmount}
        currency={subscription.currency}
        symbolPosition={'right'}
      />
    ),
  },
  {
    width: TABLE_COLUMN_WIDTH_CHECKBOX,
    align: 'right',
    render: (subscription) => (
      <StatusIcon
        status={subscription.status}
        activeStatus={'active'}
        inactiveSvg={'close-x'}
        align={'right'}
      />
    ),
  },
];

export const DashboardLatestSubscriptions: FC = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const latestSubscriptionsSelector = useSelector<
    RootState,
    SubscriptionsState
  >((state) => state.subscriptions);
  const { loading, error, data } = latestSubscriptionsSelector;
  const { theme } = useTheme();
  const { textStyles } = theme;

  const entities = useMemo(() => {
    return deNormalize<Subscription>(data.entities, data.ids);
  }, [data.entities, data.ids]);

  const dispatch = useDispatch();
  const refetch = () => {
    dispatch(fetchLatestSubscriptionsActions.trigger(undefined));
  };

  const navigateToSubscriptions = () => {
    navigation.navigate('Subscriptions');
  };
  const navigateToSubscription = (subscription: Subscription) => {
    navigation.navigate('Wip', {
      headline: 'Subscription Details',
      bodyText:
        formatDateStandard(subscription.date) + ': ' + subscription.productName,
    });
  };

  const getButtonConfig = () => {
    return [
      {
        onPress: navigateToSubscriptions,
        title: 'Show all subscriptions',
      },
    ];
  };
  return (
    <DashboardTileLoading
      loading={loading}
      error={error}
      data={data}
      refetch={refetch}
      style={styles.container}
      icon="subscriptions"
      buttons={getButtonConfig()}
      theme={theme}
      placeholderLoaderType="DASHBOARD_LATEST_SUBSCRIPTIONS"
    >
      <Text style={[styles.margin, textStyles.H3Left]}>
        Latest subscriptions:
      </Text>
      <View
        style={{
          marginLeft: -DASHBOARD_TILE_FIRST_COLUMN_WIDTH,
        }}
      >
        <Table<Subscription>
          data={entities}
          onRowPress={navigateToSubscription}
          config={SUBSCRIPTIONS_TABLE_CONFIG}
          theme={theme}
        />
      </View>
    </DashboardTileLoading>
  );
};

const styles = StyleSheet.create({
  container: {
    height: DASHBOARD_TILE_HEIGHT_LARGE,
    width: DASHBOARD_TILE_WIDTH,
  },
  margin: {
    paddingBottom: spacing[1],
  },
});
