import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { StyleSheet, View } from 'react-native';

import { fetchUserActions } from '../../services/redux/actions/user-info';
import { fetchUnplacedMembersCountActions } from '../../services/redux/actions/unplaced-members-count';
import { DashboardReferralLinks } from './dashboard-referral-links';
import { DashboardUnplacedMembers } from './dashboard-unplaced-members';
import { spacing } from '../../theme/spacing';
import { DashboardRegisterMember } from './dashboard-register-member';
import { DashboardLatestNews } from './dashboard-latest-news';
import { fetchLatestNewsActions } from '../../services/redux/actions/news';
import { DashboardLatestMessages } from './dashboard-latest-messages';
import {
  fetchTopCommissionMembersActions,
  fetchTopPerformingMembersActions,
} from '../../services/redux/actions/members';
import { DashboardUserBonusProgram } from './dashboard-user-bonus-program';
import { DashboardLatestSupportTickets } from './dashboard-latest-support-tickets';
import { fetchLatestPayoutsActions } from '../../services/redux/actions/payouts';
import { DashboardLatestPayouts } from './dashboard-latest-payouts';
import { fetchLatestOrdersActions } from '../../services/redux/actions/orders';
import { fetchLatestSubscriptionsActions } from '../../services/redux/actions/subscriptions';
import { DashboardLatestSubscriptions } from './dashboard-latest-subscriptions';
import { DashboardLatestOrders } from './dashboard-latest-orders';
import { Screen } from '../../components/screen/screen';
import { DashboardGroup } from '../../components/dashboard-group/dashboard-group';
import { useTheme } from '../../hooks/use-theme';
import { HEADER_HEIGHT } from '../../theme/fixed-sizes';
import { fetchUnreadMessagesCountActions } from '../../services/redux/actions/messages';
import { DashboardLeaderboardPerformance } from './dashboard-leaderboard-performance';
import { DashboardLeaderboardCommission } from './dashboard-leaderboard-commission';
import { DashboardLatestCommissions } from './dashboard-latest-commissions';
import { DashboardCommissionsHistory } from './dashboard-commissions-history';
import { DashboardCommissionsGoal } from './dashboard-commissions-goal';
import { DashboardHeadline } from './dashboard-headline';
import { useSmallScreen } from '../../hooks/use-small-screen';
import { NetworkPerformanceOverview } from '../commissions-overview/network-performance-overview';

const useTriggerLoadDashboardData = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLatestNewsActions.trigger(undefined));
    dispatch(fetchUserActions.trigger(undefined));
    dispatch(fetchUnreadMessagesCountActions.trigger(undefined));
    dispatch(fetchUnplacedMembersCountActions.trigger(undefined));
    dispatch(fetchTopPerformingMembersActions.trigger(undefined));
    dispatch(fetchTopCommissionMembersActions.trigger(undefined));
    dispatch(fetchLatestPayoutsActions.trigger(undefined));
    dispatch(fetchLatestOrdersActions.trigger(undefined));
    dispatch(fetchLatestSubscriptionsActions.trigger(undefined));
  }, [dispatch]);
};

export const DashboardScreen: FC = () => {
  useTriggerLoadDashboardData();
  const { theme } = useTheme();

  const { isSmallScreen } = useSmallScreen();

  const curStyles = StyleSheet.create({
    container: {
      flexDirection: isSmallScreen() ? 'column' : 'row',
    },
    column: {
      flexDirection: isSmallScreen() ? 'row' : 'column',
      marginHorizontal: isSmallScreen() ? 0 : spacing[2],
    },
  });

  return (
    <Screen androidCoverBackground>
      <View style={[styles.container, curStyles.container]}>
        <View style={[styles.webColumn, curStyles.column]}>
          <DashboardHeadline theme={theme} />
          <DashboardGroup theme={theme}>
            <DashboardUserBonusProgram />
          </DashboardGroup>

          {/* <DashboardGroup textLeft={'Achievements'} theme={theme}>
            <DashboardUserHighestRank />
            <DashboardUserCurrentRank />
          </DashboardGroup> */}

          <DashboardGroup theme={theme} textLeft={'Commissions'}>
            <NetworkPerformanceOverview />
            <DashboardLatestCommissions />
            <DashboardCommissionsHistory />
            <DashboardCommissionsGoal />
            <DashboardLatestPayouts />
          </DashboardGroup>

          <DashboardGroup theme={theme} textLeft={'Messages & Support'}>
            <DashboardLatestMessages />
            <DashboardLatestSupportTickets />
            <DashboardLatestNews />
          </DashboardGroup>
        </View>
        <View style={[styles.webColumn, curStyles.column]}>
          <DashboardGroup theme={theme} textLeft={'Network'}>
            <DashboardUnplacedMembers />
            <DashboardReferralLinks />
            <DashboardRegisterMember />
            <DashboardLeaderboardPerformance />
            <DashboardLeaderboardCommission />
          </DashboardGroup>

          <DashboardGroup theme={theme} textLeft={'Orders & Subscriptions'}>
            <DashboardLatestOrders />
            <DashboardLatestSubscriptions />
          </DashboardGroup>

          {/* <DashboardGroup theme={theme} textLeft={'Academy'}>
            <DashboardUserCertificates />
          </DashboardGroup> */}
        </View>
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: HEADER_HEIGHT, // NOTE: necessary because of floating header
  },
  webColumn: {
    flex: 1,
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
