import { PaginatedResponse, Subscription } from '../../api/types';
import { createApiActions, ExtractActionTypes } from '../create-api-actions';

export const fetchLatestSubscriptionsActions = createApiActions(
  'FETCH_LATEST_SUBSCRIPTIONS_TRIGGER',
  'FETCH_LATEST_SUBSCRIPTIONS_START',
  'FETCH_LATEST_SUBSCRIPTIONS_SUCCESS',
  'FETCH_LATEST_SUBSCRIPTIONS_FAILURE'
)<undefined, PaginatedResponse<Subscription[]>>();

export type SubscriptionsActions = ExtractActionTypes<
  typeof fetchLatestSubscriptionsActions
>;
