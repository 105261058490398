import React, { FC } from 'react';
import { View, TouchableOpacity, StyleSheet, Platform } from 'react-native';
import { DASHBOARD_TILE_FIRST_COLUMN_WIDTH } from '../../theme/fixed-sizes';

import { shadows } from '../../theme/shadows';
import { spacing } from '../../theme/spacing';
import { Button } from '../button/button';
import { StaticDataBadge } from '../static-data-badge/static-data-badge';
import { SvgImage } from '../svg-image/svg-image';
import { DashboardTileProps, DashboardTileIconProps } from './types';

const DASHBOARD_TILE_ICON_SIZE = '40';

export const Icon: FC<DashboardTileIconProps> = (props) => {
  const { type, theme } = props;
  if (!type) {
    return null;
  }
  return (
    <View style={styles.iconContainer}>
      <SvgImage
        type={type}
        width={DASHBOARD_TILE_ICON_SIZE}
        height={DASHBOARD_TILE_ICON_SIZE}
        variant={theme.name}
      />
    </View>
  );
};

export const DashboardTile: FC<DashboardTileProps> = (props) => {
  const { children, buttons, onPress, style, icon, theme, showBadge } = props;
  const { colors } = theme;

  const backgroundColor =
    Platform.OS === 'android' && theme.name === 'light'
      ? colors.background
      : colors.backgroundOpacity;

  let shadowStyles;
  if (theme.name === 'dark' && Platform.OS === 'android') {
    // NOTE: in dark mode on android the elevation looks weird
    //       so we skip the shadows just in this case
    shadowStyles = [styles.shadowContainer, { backgroundColor }];
  } else {
    shadowStyles = [
      styles.shadowContainer,
      { backgroundColor },
      shadows.shadowGrey,
    ];
  }

  const containerStyles = style
    ? [style, styles.container]
    : [styles.container];

  if (typeof onPress === 'undefined') {
    return (
      <View style={shadowStyles}>
        {showBadge ? (
          <View style={styles.staticDataBadgeContainer}>
            <StaticDataBadge type={showBadge} size="small" />
          </View>
        ) : null}
        <View style={containerStyles}>
          <View style={styles.row}>
            <View style={styles.iconsContainer}>
              <Icon type={icon} theme={theme} />
            </View>

            <View style={styles.childrenContainer}>{children}</View>
          </View>

          <View style={styles.lastRow}>
            <View style={styles.buttonsContainer}>
              {buttons
                ? buttons.map((b) => (
                    <Button
                      key={b.title}
                      onPress={b.onPress}
                      text={b.title}
                      theme={theme}
                      // TODO: FIXME: button margin right
                      // eslint-disable-next-line react-native/no-inline-styles
                      style={{ marginRight: -10 }}
                    />
                  ))
                : null}
            </View>
          </View>
        </View>
      </View>
    );
  }
  return (
    <View style={shadowStyles}>
      <TouchableOpacity onPress={onPress} style={containerStyles}>
        <Icon type={icon} theme={theme} />
        <View style={styles.childrenContainer}>{children}</View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  shadowContainer: {
    borderRadius: 11,
    marginBottom: spacing[3],
    marginHorizontal: spacing[1],
  },
  container: {
    paddingHorizontal: spacing[2],
    paddingVertical: spacing[1],
    paddingBottom: spacing[4],
    borderRadius: 11,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  lastRow: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  iconContainer: {
    paddingRight: spacing[2],
  },
  iconsContainer: {
    width: DASHBOARD_TILE_FIRST_COLUMN_WIDTH,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  buttonsContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  childrenContainer: {
    paddingTop: spacing[2],
    flex: 1,
  },
  staticDataBadgeContainer: {
    position: 'absolute',
    right: 12,
    top: 0,
  },
});
