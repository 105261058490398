import { GetNewsDetailParams, News } from '../../api/types';
import { createApiActions, ExtractActionTypes } from '../create-api-actions';

export const fetchLatestNewsActions = createApiActions(
  'FETCH_LATEST_NEWS_TRIGGER',
  'FETCH_LATEST_NEWS_START',
  'FETCH_LATEST_NEWS_SUCCESS',
  'FETCH_LATEST_NEWS_FAILURE'
)<undefined, News[]>();

export const fetchNewsDetailsActions = createApiActions(
  'FETCH_NEWS_DETAIL_TRIGGER',
  'FETCH_NEWS_DETAIL_START',
  'FETCH_NEWS_DETAIL_SUCCESS',
  'FETCH_NEWS_DETAIL_FAILURE'
)<GetNewsDetailParams, News>();

export type NewsActions =
  | ExtractActionTypes<typeof fetchLatestNewsActions>
  | ExtractActionTypes<typeof fetchNewsDetailsActions>;
