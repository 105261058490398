import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={11} height={19} viewBox="0 0 11 19" fill="none" {...props}>
      <Path d="M1.5 18.75L0 17.25l8.5-8.5 1.5 1.5-8.5 8.5z" fill="#fff" />
      <Path
        d="M10.986 9.236l-1.5 1.5L0 1.75l1.5-1.5 9.486 8.987z"
        fill="#fff"
      />
    </Svg>
  );
};

export default SvgComponent;
