import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={37} height={31} viewBox="0 0 37 31" fill="none" {...props}>
      <Path
        d="M10.816 14.23L7.51 20.294a.5.5 0 01-.878 0l-3.307-6.062a.5.5 0 01.439-.74h6.613a.5.5 0 01.44.74z"
        stroke="#2D9CDB"
      />
      <Path
        d="M21.5 30C29.508 30 36 23.508 36 15.5S29.508 1 21.5 1C14.17 1 8.11 6.438 7.137 13.5"
        stroke="#D95BB7"
      />
      <Path fill="#2D9CDB" d="M21 6h1v10h-1z" />
      <Path fill="#2D9CDB" d="M20.964 15.672l.708-.707 6.363 6.364-.707.707z" />
    </Svg>
  );
};

export default SvgComponent;
