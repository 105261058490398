import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../navigation/types';
import { RootState } from '../../services/redux/reducers';
import { SupportTicketsState } from '../../services/redux/reducers/support-tickets';
import { fetchLatestSupportTicketsActions } from '../../services/redux/actions/support-tickets';
import {
  DASHBOARD_TILE_HEIGHT_XS,
  DASHBOARD_TILE_WIDTH,
} from '../../theme/fixed-sizes';
import { useTheme } from '../../hooks/use-theme';
import { DashboardTileLoading } from '../../components/dashboard-tile-loading/dashboard-tile-loading';

export const DashboardLatestSupportTickets: FC = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const latestSupportTicketsSelector = useSelector<
    RootState,
    SupportTicketsState
  >((state) => state.supportTickets);
  const {
    latestLoading: loading,
    latestError: error,
    data,
  } = latestSupportTicketsSelector;
  const { theme } = useTheme();
  const { textStyles } = theme;

  // TODO: fetch real data from api
  const count = 2;

  const dispatch = useDispatch();
  const refetch = () => {
    dispatch(fetchLatestSupportTicketsActions.trigger(undefined));
  };

  const navigateToSupportTickets = () => {
    navigation.navigate('BottomTabs', {
      screen: 'InboxOverview',
      selectedTabIndex: 2,
    });
  };

  const navigateToNewSupportTicket = () => {
    navigation.navigate('Wip', {
      headline: 'New Support Ticket',
    });
  };

  const getButtonConfig = () => {
    return [
      {
        onPress: navigateToSupportTickets,
        title: 'Show all open tickets',
      },
      {
        onPress: navigateToNewSupportTicket,
        title: 'Create new',
      },
    ];
  };

  return (
    <DashboardTileLoading
      loading={loading}
      error={error}
      data={data}
      refetch={refetch}
      style={styles.container}
      icon="support-ticket"
      buttons={getButtonConfig()}
      theme={theme}
      placeholderLoaderType="DASHBOARD_LATEST_SUPPORT_TICKETS"
      showBadge={'static-data'}
    >
      <Text style={textStyles.H3Left}>
        {count > 0 ? (
          <Text>You have open support tickets</Text>
        ) : (
          <Text>No open support tickets</Text>
        )}
      </Text>
    </DashboardTileLoading>
  );
};

const styles = StyleSheet.create({
  container: {
    height: DASHBOARD_TILE_HEIGHT_XS,
    width: DASHBOARD_TILE_WIDTH,
  },
});
