/* eslint-disable react-native/no-inline-styles */
import React, { FC, useRef, useState } from 'react';
import {
  View,
  StyleSheet,
  Text,
  Pressable,
  Platform,
  Image,
  Dimensions,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { OutlinedTextField } from 'react-native-material-textfield';
import Constants from 'expo-constants';

import { Screen2 } from '../../components/screen/screen2';
import { useTheme } from '../../hooks/use-theme';
import { doLoginActions } from '../../services/redux/actions/auth';
import { RootState } from '../../services/redux/reducers';
import { AuthState } from '../../services/redux/reducers/auth';
import { Button2 } from '../../components/button/button2';
import { PlaceHolderLoader } from '../../components/placeholder-loader/placeholder-loader';
import { SvgImage } from '../../components/svg-image/svg-image';
import { PostAuthTokenParams } from '../../services/api/types';

interface LoginData {
  username?: string;
  password?: string;
}

export const LoginScreen: FC = () => {
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const [loginData, setLoginData] = useState<LoginData>({});
  const [dirty, setDirty] = useState(true);
  const usernameFieldRef = useRef<OutlinedTextField>(null);
  const passwordFieldRef = useRef<OutlinedTextField>(null);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const authTokenSelector = useSelector<RootState, AuthState>(
    (state) => state.auth
  );

  const { loading, error } = authTokenSelector;
  const { textStyles2, colors2, images } = theme;

  const togglePasswordVisible = () => {
    setPasswordVisible(!passwordVisible);
  };

  const onSubmitUsername = () => {
    const { current: passwordField } = passwordFieldRef;
    passwordField?.focus();
  };

  const onSubmitPassword = () => {
    onSubmit();
  };

  const onChangeUsername = (username: string) => {
    setDirty(true);
    setLoginData({ username, password: loginData.password });
  };

  const onChangePassword = (password: string) => {
    setDirty(true);
    setLoginData({ password, username: loginData.username });
  };

  const onSubmit = () => {
    const { username, password } = loginData;
    if (username && password) {
      setDirty(false);
      const params: PostAuthTokenParams = {
        client_id: Constants.manifest.extra.api_client_id,
        client_secret: Constants.manifest.extra.api_client_secret,
        grant_type: 'password',
        username,
        password,
      };
      dispatch(doLoginActions.trigger(params));
    }
  };

  if (loading) {
    return (
      <Screen2>
        <View style={styles.container}>
          <PlaceHolderLoader displayType={'LOGIN_SCREEN'} />
        </View>
      </Screen2>
    );
  }
  return (
    <Screen2>
      <View style={styles.container}>
        <View style={styles.headlineContainer}>
          <Text style={textStyles2.H3LeftBold}>Healy Backoffice</Text>
        </View>
        <View style={styles.formContainer}>
          <View style={styles.subheadlineContainer}>
            <Text style={textStyles2.BodyTextLeft}>
              You can login using the same username and password that you use at
              the Healy Backoffice Website
            </Text>
          </View>
          <View style={styles.formFieldsContainer}>
            <OutlinedTextField
              label="Username"
              keyboardType="default"
              autoCapitalize="none"
              returnKeyType="next"
              onChangeText={onChangeUsername}
              onSubmitEditing={onSubmitUsername}
              ref={usernameFieldRef}
              inputContainerStyle={styles.formField}
              value={loginData.username}
              error={!dirty && error ? ' ' : undefined}
              errorColor={colors2.error}
              affixTextStyle={{
                fontFamily: 'opensans-regular',
              }}
              titleTextStyle={{
                fontFamily: 'opensans-regular',
              }}
              labelTextStyle={{
                fontFamily: 'opensans-bold',
              }}
              textColor={colors2.headlineText}
              tintColor={colors2.headlineText}
              baseColor={colors2.inactiveBorder}
              // renderLeftAccessory={() => (
              //   <SvgImage
              //     type="user-outline"
              //     variant={theme.name}
              //     width={'24'}
              //     height={'24'}
              //     style={styles.svgAccessory}
              //   />
              // )}
            />
            <OutlinedTextField
              label="Password"
              keyboardType="default"
              autoCapitalize="none"
              autoCorrect={false}
              enablesReturnKeyAutomatically={true}
              returnKeyType="done"
              secureTextEntry={!passwordVisible}
              onChangeText={onChangePassword}
              onSubmitEditing={onSubmitPassword}
              ref={passwordFieldRef}
              inputContainerStyle={styles.formField}
              value={loginData.password}
              error={!dirty && error ? ' ' : undefined}
              affixTextStyle={{
                fontFamily: 'opensans-regular',
              }}
              titleTextStyle={{
                fontFamily: 'opensans-regular',
              }}
              labelTextStyle={{
                fontFamily: 'opensans-bold',
              }}
              errorColor={colors2.error}
              textColor={colors2.headlineText}
              tintColor={colors2.headlineText}
              baseColor={colors2.inactiveBorder}
              // renderLeftAccessory={() => (
              //   <SvgImage
              //     type="lock"
              //     variant={theme.name}
              //     width={'24'}
              //     height={'24'}
              //     style={styles.svgAccessory}
              //   />
              // )}
              renderRightAccessory={() => (
                <Pressable onPress={togglePasswordVisible}>
                  {passwordVisible ? (
                    <SvgImage
                      type="visible-on"
                      variant={theme.name}
                      width={'24'}
                      height={'24'}
                      style={styles.svgAccessory}
                    />
                  ) : (
                    <SvgImage
                      type="visible-off"
                      variant={theme.name}
                      width={'24'}
                      height={'24'}
                      style={styles.svgAccessory}
                    />
                  )}
                </Pressable>
              )}
            />
            <View style={styles.warning}>
              {error && !dirty ? (
                <>
                  <SvgImage
                    type="warning"
                    variant={theme.name}
                    width={'24'}
                    height={'24'}
                  />
                  <Text
                    style={[
                      textStyles2.BodyTextLeft,
                      { color: colors2.errorText },
                    ]}
                  >
                    Invalid username or password!
                  </Text>
                </>
              ) : null}
            </View>
          </View>

          <Button2
            onPress={onSubmit}
            text={'Login'}
            theme={theme}
            disabled={!loginData.username || !loginData.password}
          />
        </View>
      </View>
      {Platform.OS !== 'web' ? (
        <Image
          source={images.backgroundHeroWatch}
          style={styles.backgroundHeroWatch}
        />
      ) : null}
    </Screen2>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'flex-start',
    paddingHorizontal: 24,
    paddingTop: 100,
  },
  formContainer: {},
  formFieldsContainer: {
    marginBottom: 22,
  },
  formField: {
    height: 48,
  },
  headlineContainer: {
    marginBottom: 10,
  },
  subheadlineContainer: {
    marginBottom: 16,
  },
  warning: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 30,
  },
  svgAccessory: {
    top: 8, // TODO: FIXME vertical center align
  },
  backgroundHeroWatch: {
    width: Dimensions.get('window').width,
    maxHeight: 200,
  },
});
