import React from 'react';
import { Text } from 'react-native';
import { storiesOf } from '@storybook/react-native';
import { CopyToClipboard } from './copy-to-clipboard';

storiesOf('CopyToClipboard', module).add('default', () => {
  return (
    <CopyToClipboard clipboardText={'http://www.healyworld.net'}>
      <Text>copy to clipboard</Text>
    </CopyToClipboard>
  );
});
