import { takeEvery } from 'redux-saga/effects';
import { ApiType } from '../../api';
import { fetchLatestSubscriptionsActions } from '../actions/subscriptions';
import { callApiEndpoint } from './api';

export const watchFetchLatestSubscriptions = (Api: ApiType) =>
  takeEvery(
    fetchLatestSubscriptionsActions.types.trigger,
    fetchLatestSubscriptions,
    Api
  );

function* fetchLatestSubscriptions(
  Api: ApiType,
  _: ReturnType<typeof fetchLatestSubscriptionsActions.trigger>
) {
  yield callApiEndpoint(
    fetchLatestSubscriptionsActions,
    Api.getLatestSubscriptions,
    undefined,
    Api
  );
}
