import React, { FC } from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';

import { RootStackParamList } from '../../navigation/types';

import { Screen } from '../../components/screen/screen';
import { useTheme } from '../../hooks/use-theme';

export const NotFoundScreen: FC = () => {
  const navigation = useNavigation<
    StackNavigationProp<RootStackParamList, 'not-found'>
  >();
  const { theme } = useTheme();
  const { textStyles } = theme;

  return (
    <Screen style={styles.container}>
      <Text style={textStyles.H1Left}>This screen does not exist.</Text>
      <TouchableOpacity onPress={() => navigation.popToTop()}>
        <Text style={textStyles.BodyTextLinkLeft}>Go to home screen!</Text>
      </TouchableOpacity>
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
});
