import {
  fetchLatestNewsActions,
  NewsActions,
  fetchNewsDetailsActions,
} from './../actions/news';
import produce from 'immer';
import { Reducer } from 'redux';
import { ApiError, News, NewsShort } from '../../api/types';

const initialState: NewsState = {
  latestLoading: false,
  latestData: undefined,
  detailsLoading: false,
  detailsData: undefined,
};

export interface NewsState {
  readonly latestLoading: boolean;
  readonly latestError?: ApiError;
  readonly latestData?: NewsShort[];
  readonly detailsLoading: boolean;
  readonly detailsError?: ApiError;
  readonly detailsData: News | undefined;
}

export const newsReducer: Reducer<NewsState, NewsActions> = (
  state = initialState,
  action
) =>
  produce(state, (d) => {
    switch (action.type) {
      case fetchLatestNewsActions.types.start:
        d.latestError = undefined;
        d.latestLoading = true;
        return;
      case fetchLatestNewsActions.types.success:
        d.latestData = action.payload.response;
        d.latestLoading = false;
        return;
      case fetchLatestNewsActions.types.failure:
        d.latestError = action.payload.error;
        d.latestLoading = false;
        return;

      case fetchNewsDetailsActions.types.start:
        d.detailsError = undefined;
        d.detailsLoading = true;
        return;
      case fetchNewsDetailsActions.types.success:
        d.detailsData = action.payload.response;
        d.detailsLoading = false;
        return;
      case fetchNewsDetailsActions.types.failure:
        d.detailsError = action.payload.error;
        d.detailsLoading = false;
        return;
    }
  });
