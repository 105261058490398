import React, { FC, ReactElement } from 'react';
import {
  View,
  Text,
  TextStyle,
  StyleSheet,
  TouchableOpacity,
  Pressable,
  ScrollView,
} from 'react-native';
import { OnChangeSort } from '../../hooks/use-pagination-params';
import { ThemeConfig, ThemeTextStyles } from '../../hooks/use-theme';
import {
  getTableDefaultWidth,
  TABLE_ROW_HEIGHT,
} from '../../theme/fixed-sizes';
import { colors } from '../../theme/light/colors';
import { withOpacity } from '../../utils/format-color';
import { SvgImage } from '../svg-image/svg-image';

type WidthConfig = number;

export interface TableSortConfig {
  default: {
    sortBy?: string;
    sortOrder?: string;
  };
  onChangeSort: OnChangeSort;
}

type Alignment = 'left' | 'right';
export interface TableConfigItem<T> {
  header?: string;
  width: WidthConfig;
  render: (element: T) => ReactElement | FC | string | null;
  sortField?: string;
  align?: Alignment;
}

interface TableProps<T> {
  data: T[];
  onRowPress?: (rowItem: T) => void;
  rowStyle?: TextStyle;
  config: TableConfigItem<T>[];
  horizontalScroll?: boolean;
  theme: ThemeConfig;
  sortConfig?: TableSortConfig;
}

function headerPresent<T>(config: TableConfigItem<T>[]) {
  return config.find((configItem) => configItem.header !== undefined);
}

const getWidthStyle = (width: WidthConfig) => {
  return { width };
};

export function tableTotalWidth<T>(config: TableConfigItem<T>[]) {
  return config.reduce((memo, configItem) => {
    memo += configItem.width;
    return memo;
  }, 0);
}

const getAlignStyle = (align: Alignment | undefined) => {
  if (align === 'left') {
    return styles.alignLeft;
  }
  if (align === 'right') {
    return styles.alignRight;
  }
  // return styles.alignLeft;
  return {};
};

function renderRow<T>(
  row: T,
  config: TableConfigItem<T>[],
  theme: ThemeConfig,
  idx: number
) {
  const rowBgStyle =
    idx % 2 === 0 ? {} : { backgroundColor: withOpacity(colors.border, 0.3) };
  const { textStyles } = theme;
  return (
    <View style={[styles.row, rowBgStyle]}>
      {config.map((configItem, idx2) => {
        const renderItem = configItem.render ? configItem.render(row) : null;
        if (!renderItem) {
          return null;
        }

        if (typeof renderItem === 'string') {
          return (
            <View
              key={idx2}
              style={[
                getWidthStyle(configItem.width),
                getAlignStyle(configItem.align),
                // eslint-disable-next-line react-native/no-inline-styles
                {
                  flexDirection: 'row',
                  alignItems: 'baseline',
                  height: TABLE_ROW_HEIGHT,
                },
              ]}
            >
              <Text numberOfLines={1} style={textStyles.TableTextLeft}>
                {renderItem}
              </Text>
            </View>
          );
        } else {
          return (
            <View
              key={idx2}
              style={[
                getWidthStyle(configItem.width),
                getAlignStyle(configItem.align),
                // eslint-disable-next-line react-native/no-inline-styles
                {
                  flexDirection: 'row',
                  alignItems: 'baseline',
                  height: TABLE_ROW_HEIGHT,
                },
              ]}
            >
              {renderItem}
            </View>
          );
        }
      })}
    </View>
  );
}
const isSortActive = (
  sortBy: string | undefined,
  sortConfig: TableSortConfig | undefined
) => {
  return sortBy && sortConfig && sortBy === sortConfig?.default.sortBy;
};

interface TableHeaderProps<T> {
  config: TableConfigItem<T>[];
  rowStyle?: TextStyle;
  textStyles: ThemeTextStyles;
  theme: ThemeConfig;
  sortConfig?: TableSortConfig;
}
function TableHeader<T>(props: TableHeaderProps<T>) {
  const { config, rowStyle, textStyles, sortConfig, theme } = props;

  return (
    <View style={styles.headerRow}>
      {config.map((row, idx) => {
        if (sortConfig && row.sortField) {
          return (
            row.header && (
              <Pressable
                key={idx}
                onPress={() => {
                  sortConfig?.onChangeSort(row.sortField);
                }}
                style={
                  rowStyle
                    ? [
                        styles.tableHeader,
                        rowStyle,
                        getWidthStyle(row.width),
                        getAlignStyle(row.align),
                      ]
                    : [
                        styles.tableHeader,
                        getWidthStyle(row.width),
                        getAlignStyle(row.align),
                      ]
                }
              >
                <View
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={{ flexDirection: 'row' }}
                >
                  <Text numberOfLines={1} style={textStyles.BodyBigTextLeft}>
                    {row.header}
                  </Text>
                  {isSortActive(row.sortField, sortConfig) ? (
                    <SvgImage
                      variant={theme.name}
                      width={'16'}
                      height={'14'}
                      type={
                        sortConfig?.default.sortOrder === 'asc'
                          ? 'arrow-up-solid'
                          : 'arrow-down-solid'
                      }
                    />
                  ) : (
                    <SvgImage
                      variant={theme.name}
                      width={'16'}
                      height={'14'}
                      type={'arrow-down-outline'}
                    />
                  )}
                </View>
              </Pressable>
            )
          );
        } else {
          return (
            row.header && (
              <View
                key={idx}
                style={
                  rowStyle
                    ? [
                        styles.tableHeader,
                        rowStyle,
                        getWidthStyle(row.width),
                        getAlignStyle(row.align),
                      ]
                    : [
                        styles.tableHeader,
                        getWidthStyle(row.width),
                        getAlignStyle(row.align),
                      ]
                }
              >
                <Text numberOfLines={1} style={textStyles.BodyBigTextLeft}>
                  {row.header}
                </Text>
              </View>
            )
          );
        }
      })}
    </View>
  );
}

interface TableBodyProps<T> {
  config: TableConfigItem<T>[];
  data: T[] | undefined;
  rowStyle?: TextStyle;
  onRowPress?: (rowItem: T) => void;
  theme: ThemeConfig;
}
function TableBody<T>(props: TableBodyProps<T>) {
  const { data, config, onRowPress, theme } = props;

  return (
    <View style={styles.body}>
      {data && data.map // NOTE: TODO remove hotfix for incompatible api changes
        ? data.map((row, idx) => {
            const isLastRow = idx === data.length - 1;

            const style = isLastRow
              ? [styles.tableRow]
              : [styles.tableRow, styles.borderBottom];

            if (onRowPress !== undefined) {
              const onPress = () => {
                onRowPress(row);
              };
              return (
                <TouchableOpacity key={idx} style={style} onPress={onPress}>
                  {renderRow<T>(row, config, theme, idx)}
                </TouchableOpacity>
              );
            } else {
              return (
                <View key={idx} style={style}>
                  {renderRow<T>(row, config, theme, idx)}
                </View>
              );
            }
          })
        : null}
    </View>
  );
}

function curTableWidth<T>(config: TableConfigItem<T>[]) {
  return Math.min(tableTotalWidth<T>(config), getTableDefaultWidth());
}

export function Table<DataType>(props: TableProps<DataType>) {
  const {
    data,
    onRowPress,
    rowStyle,
    config,
    horizontalScroll,
    theme,
    sortConfig,
  } = props;
  const { textStyles } = theme;

  const render = (renderConfig: TableConfigItem<DataType>[]) => {
    return (
      <View>
        {headerPresent(config) && (
          <TableHeader<DataType>
            config={renderConfig}
            rowStyle={rowStyle}
            textStyles={textStyles}
            sortConfig={sortConfig}
            theme={theme}
          />
        )}

        <TableBody<DataType>
          data={data}
          onRowPress={onRowPress}
          config={renderConfig}
          rowStyle={rowStyle}
          theme={theme}
        />
      </View>
    );
  };

  if (horizontalScroll) {
    if (config.length < 2) {
      throw new Error('Table requires two columns at least');
    }
    const [firstRow, ...otherRows] = config;

    return (
      <View style={[styles.scroll, { width: curTableWidth(config) }]}>
        <View style={{ width: firstRow.width }}>{render([firstRow])}</View>
        <ScrollView
          horizontal
          scrollEnabled={true}
          style={{
            width: getTableDefaultWidth() - firstRow.width,
          }}
          contentContainerStyle={[styles.scrollContainer]}
          stickyHeaderIndices={[0]}
        >
          {render(otherRows)}
        </ScrollView>
      </View>
    );
  }

  return <View>{render(config)}</View>;
}

const styles = StyleSheet.create({
  scroll: {
    flexDirection: 'row',
  },
  scrollContainer: {},
  headerRow: {
    flexDirection: 'row',
    height: TABLE_ROW_HEIGHT,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    height: TABLE_ROW_HEIGHT,
  },
  body: {},
  tableHeader: {},
  tableRow: {},
  borderBottom: {},
  alignLeft: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  alignRight: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
});
