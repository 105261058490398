import {
  clearUserAuthTokenAction,
  doLoginActions,
  setUserAuthTokenAction,
} from './../actions/auth';
import { AuthActions } from '../actions/auth';
import produce from 'immer';
import { Reducer } from 'redux';
import { ApiError, AuthToken } from '../../api/types';

const initialState: AuthState = {
  loading: false,
  data: undefined,
};

export interface AuthState {
  readonly loading: boolean;
  readonly error?: ApiError;
  readonly data?: AuthToken;
}

export const authReducer: Reducer<AuthState, AuthActions> = (
  state = initialState,
  action
) =>
  produce(state, (d) => {
    switch (action.type) {
      case clearUserAuthTokenAction.getType():
        d.error = undefined;
        d.loading = false;
        d.data = undefined;
        return;
      case setUserAuthTokenAction.getType():
        const { payload } = action;
        d.error = undefined;
        d.loading = false;
        d.data = { ...d.data, token: payload };
        return;
      case doLoginActions.types.start:
        d.error = undefined;
        d.loading = false;
        return;
      case doLoginActions.types.failure:
        d.error = action.payload.error;
        d.loading = false;
        return;
      // NOTE: doLoginActions.types.success is handled differently in auth saga
    }
  });
