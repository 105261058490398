import React, { FC } from 'react';
import { StyleSheet, TextStyle, Switch, View, Text } from 'react-native';
import { spacing } from '../../theme/spacing';
import { ThemeConfig } from '../../hooks/use-theme';

interface ThemeToggleProps {
  style?: TextStyle;
  onPress: () => void;
  theme: ThemeConfig;
}

export const ThemeToggle: FC<ThemeToggleProps> = (props) => {
  const { onPress, style, theme } = props;
  const { textStyles } = theme;
  const isEnabled = theme.name === 'dark';
  return (
    <View style={style ? [style, styles.container] : styles.container}>
      <Text style={textStyles.BodyTextRight}>light</Text>
      <Switch
        disabled={false}
        trackColor={{
          false: '#C4C4C4',
          true: '#C4C4C4',
        }}
        thumbColor={'#FFFFFF'}
        ios_backgroundColor="#3e3e3e"
        onValueChange={onPress}
        value={isEnabled}
        style={styles.switch}
      />
      <Text style={textStyles.BodyTextLeft}>dark</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 4,
    paddingHorizontal: spacing[2],
    flexDirection: 'row',
    alignItems: 'center',
  },
  switch: {
    marginHorizontal: spacing[2],
  },
});
