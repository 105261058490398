import {
  fetchCommissionsPerformanceActions,
  fetchLatestCommissionsActions,
} from './../actions/commissions';
import { takeEvery } from 'redux-saga/effects';
import { ApiType } from '../../api';
import {
  fetchCommissionDetailsActions,
  fetchCommissionsHistoryActions,
} from '../actions/commissions';
import { callApiEndpoint } from './api';

function* fetchCommissionDetails(
  Api: ApiType,
  action: ReturnType<typeof fetchCommissionDetailsActions.trigger>
) {
  yield callApiEndpoint(
    fetchCommissionDetailsActions,
    Api.getCommissionDetails,
    action.payload.params,
    Api
  );
}

export const watchFetchCommissionDetails = (Api: ApiType) =>
  takeEvery(
    fetchCommissionDetailsActions.types.trigger,
    fetchCommissionDetails,
    Api
  );

export const watchFetchCommissionsHistory = (Api: ApiType) =>
  takeEvery(
    fetchCommissionsHistoryActions.types.trigger,
    fetchCommissionsHistory,
    Api
  );

function* fetchCommissionsHistory(
  Api: ApiType,
  action: ReturnType<typeof fetchCommissionsHistoryActions.trigger>
) {
  yield callApiEndpoint(
    fetchCommissionsHistoryActions,
    Api.getCommissionsHistory,
    action.payload.params,
    Api
  );
}

export const watchFetchLatestCommissions = (Api: ApiType) =>
  takeEvery(
    fetchLatestCommissionsActions.types.trigger,
    fetchLatestCommissions,
    Api
  );

function* fetchLatestCommissions(
  Api: ApiType,
  _: ReturnType<typeof fetchLatestCommissionsActions.trigger>
) {
  yield callApiEndpoint(
    fetchLatestCommissionsActions,
    Api.getLatestCommissions,
    undefined,
    Api
  );
}

export const watchFetchCommissionsPerformance = (Api: ApiType) =>
  takeEvery(
    fetchCommissionsPerformanceActions.types.trigger,
    fetchCommissionsPerformance,
    Api
  );

function* fetchCommissionsPerformance(
  Api: ApiType,
  _action: ReturnType<typeof fetchCommissionsPerformanceActions.trigger>
) {
  yield callApiEndpoint(
    fetchCommissionsPerformanceActions,
    Api.getCommissionsPerformance,
    undefined,
    Api
  );
}
