import { StyleSheet } from 'react-native';
import { colorNames } from './color-names';

const BORDER_RADIUS_SMALL = 5;
const BORDER_RADIUS_LARGE = 10;

export const borderStyles = StyleSheet.create({
  borderRadiusLightGrey: {
    borderColor: colorNames.veryLightGrey,
    borderWidth: 2,
    borderRadius: BORDER_RADIUS_LARGE,
  },
  borderRadiusSmall: {
    borderWidth: 1,
    borderRadius: BORDER_RADIUS_SMALL,
  },
});

export const borderStyles2 = StyleSheet.create({
  borderRadiusSmall: {
    borderRadius: 6,
  },
});
