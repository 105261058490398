import React, { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet, Text, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../navigation/types';

import { RootState } from '../../services/redux/reducers';
import { OrdersState } from '../../services/redux/reducers/orders';

import { spacing } from '../../theme/spacing';
import { fetchLatestOrdersActions } from '../../services/redux/actions/orders';
import {
  DASHBOARD_TILE_HEIGHT_LARGE,
  DASHBOARD_TILE_WIDTH,
  TABLE_COLUMN_WIDTH_DATE,
  DASHBOARD_TILE_FIRST_COLUMN_WIDTH,
  TABLE_COLUMN_WIDTH_CHECKBOX,
} from '../../theme/fixed-sizes';
import { formatDateStandard } from '../../utils/format-date';

import { Table, TableConfigItem } from '../../components/table/table';
import { Order } from '../../services/api/types';
import { deNormalize } from '../../services/data/normalize';
import { useTheme } from '../../hooks/use-theme';
import { DashboardTileLoading } from '../../components/dashboard-tile-loading/dashboard-tile-loading';
import { StatusIcon } from '../../components/status-icon/status-icon';

const ORDERS_TABLE_CONFIG: TableConfigItem<Order>[] = [
  {
    width: TABLE_COLUMN_WIDTH_DATE,
    render: (order) => formatDateStandard(order.date),
  },
  {
    width: 230,
    render: (order) => {
      return order.productName;
    },
  },
  {
    width: TABLE_COLUMN_WIDTH_CHECKBOX,
    align: 'right',
    render: (order) => (
      <StatusIcon
        status={order.status}
        activeStatus={'active'}
        inactiveSvg={'close-x'}
        align={'right'}
      />
    ),
  },
];

export const DashboardLatestOrders: FC = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const latestOrdersSelector = useSelector<RootState, OrdersState>(
    (state) => state.orders
  );
  const { loading, error, data } = latestOrdersSelector;
  const { theme } = useTheme();
  const { textStyles } = theme;

  const entities = useMemo(() => {
    return deNormalize<Order>(data.entities, data.latestIds);
  }, [data.entities, data.latestIds]);

  const dispatch = useDispatch();
  const refetch = () => {
    dispatch(fetchLatestOrdersActions.trigger(undefined));
  };

  const navigateToOrders = () => {
    navigation.navigate('Orders');
  };

  const navigateToOrder = (order: Order) => {
    navigation.push('Wip', {
      headline: 'Order Details',
      bodyText: formatDateStandard(order.date) + ': ' + order.productName,
    });
  };

  const getButtonConfig = () => {
    return [
      {
        onPress: navigateToOrders,
        title: 'Show all orders',
      },
    ];
  };

  return (
    <DashboardTileLoading
      loading={loading}
      error={error}
      data={data}
      refetch={refetch}
      style={styles.container}
      icon="orders"
      buttons={getButtonConfig()}
      theme={theme}
      placeholderLoaderType="DASHBOARD_LATEST_SUBSCRIPTIONS"
    >
      <Text style={[styles.margin, textStyles.H3Left]}>Latest orders:</Text>
      <View style={styles.tableContainer}>
        <Table<Order>
          data={entities}
          onRowPress={navigateToOrder}
          config={ORDERS_TABLE_CONFIG}
          theme={theme}
        />
      </View>
    </DashboardTileLoading>
  );
};

const styles = StyleSheet.create({
  container: {
    height: DASHBOARD_TILE_HEIGHT_LARGE,
    width: DASHBOARD_TILE_WIDTH,
  },
  tableContainer: {
    marginLeft: -DASHBOARD_TILE_FIRST_COLUMN_WIDTH,
  },
  margin: {
    paddingBottom: spacing[1],
  },
});
