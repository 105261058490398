import React from 'react';
import { storiesOf } from '@storybook/react-native';
import { HistoryChart } from '../../components/history-chart/history-chart';
import { dummyDataCommissionHistory } from './demo-data';
import { Provider } from 'react-redux';
import { getStore } from '../../app/get-store';

const chartStartDate = '2020-12-01';
const chartEndDate = '2021-03-01';
const { store } = getStore({ getApiEndpointUrl: () => '' });

storiesOf('HistoryChart', module)
  .addDecorator((story: () => React.ReactNode) => (
    <Provider store={store}>{story()}</Provider>
  ))
  .add('History chart commissions', () => {
    return (
      <HistoryChart
        startDate={chartStartDate}
        endDate={chartEndDate}
        xAxisUnit={'MONTHS'}
        data={dummyDataCommissionHistory.data}
      />
    );
  })
  .add('History chart full features', () => {
    return (
      <HistoryChart
        startDate={chartStartDate}
        endDate={chartEndDate}
        xAxisUnit={'MONTHS'}
        data={dummyDataCommissionHistory.data}
        showGradient
        showDashedLine
        showVerticalGrid
        showHorizontalGrid
        showPoints
        showYAxis
      />
    );
  });
