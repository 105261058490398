import React from 'react';
import { storiesOf } from '@storybook/react-native';
import { View, Text, StyleSheet } from 'react-native';

import { colorNames, colorNames2 } from './color-names';

const renderColor = (colorName: string, colorCode: string) => {
  return (
    <View key={colorName} style={styles.item}>
      <Text style={[styles.colorName, { color: colorCode }]}>{colorName}</Text>
    </View>
  );
};

storiesOf('ColorNames', module)
  .add('colorNames V1', () => {
    return (
      <View>
        {Object.entries(colorNames).map((object) =>
          renderColor(object[0], object[1])
        )}
      </View>
    );
  })
  .add('colorNames V2', () => {
    return (
      <View>
        {Object.entries(colorNames2).map((object) =>
          renderColor(object[0], object[1])
        )}
      </View>
    );
  });

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
  },
  colorName: {
    width: 200,
  },
});
