import { StoreEnhancer } from 'redux';
import { SagaMonitor } from 'redux-saga';

import { configureStore } from '../services/redux/configure-store';
import { createApi } from './../services/api';
import { UrlConfig } from '../services/config';
import { RootState } from '../services/redux/reducers';
import { persistStore } from 'redux-persist';

export const makeStore = ({
  storeEnhancer,
  sagaMonitor,
  preloadedState,
  urlConfig,
}: {
  preloadedState?: Partial<RootState>;
  storeEnhancer?: StoreEnhancer;
  sagaMonitor?: SagaMonitor;
  urlConfig: UrlConfig;
}) => {
  const api = createApi(urlConfig.getApiEndpointUrl);
  const store = configureStore(
    { Api: api },
    preloadedState,
    storeEnhancer,
    sagaMonitor
  );
  const persistor = persistStore(store);
  return { store, persistor };
};
