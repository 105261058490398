import Constants from 'expo-constants';

export interface UrlConfig {
  getApiEndpointUrl: () => string;
}

const createUrlConfig = (): UrlConfig => {
  const getApiEndpointUrl = () => {
    return Constants.manifest.extra.api_endpoint_url;
  };
  return {
    getApiEndpointUrl,
  };
};

export const urlConfig = createUrlConfig();
