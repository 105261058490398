import React from 'react';
import { storiesOf } from '@storybook/react-native';
import { RankImage } from './rank-image';
import { View, Text } from 'react-native';
import { RankNames } from '../../services/api/types';

const rankNames: RankNames[] = [
  'Member',
  'Builder',
  'Team Leader',
  'Senior Team Leader',
  'Manager',
  'Senior Manager',
  'Director',
  'Senior Director',
  'Vice President',
  'Senior Vice President',
  'President',
  'Senior President',
];
storiesOf('RankImage', module).add('default', () => {
  return (
    <View>
      {rankNames.map((rankName) => (
        <View
          key={rankName}
          style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <RankImage rankName={rankName} />
          <Text>{rankName}</Text>
        </View>
      ))}
    </View>
  );
});
