import React, { FC, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';
import { Screen } from '../../components/screen/screen';
import { useSmallScreen } from '../../hooks/use-small-screen';
import { spacing } from '../../theme/spacing';
import { DashboardUserBonusProgram } from '../dashboard/dashboard-user-bonus-program';
import { DashboardCommissionsGoal } from '../dashboard/dashboard-commissions-goal';
import { DashboardLatestPayouts } from '../dashboard/dashboard-latest-payouts';
import { DashboardLatestCommissions } from '../dashboard/dashboard-latest-commissions';
import { DashboardLeaderboardCommission } from '../dashboard/dashboard-leaderboard-commission';
import { fetchLatestPayoutsActions } from '../../services/redux/actions/payouts';
import { NetworkCommissionsHistory } from './network-commissions-history';
import { fetchTopCommissionMembersActions } from '../../services/redux/actions/members';
import { NetworkPerformanceOverviewWithoutButtons } from './network-performance-overview';

const useTriggerLoadData = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLatestPayoutsActions.trigger(undefined));
    dispatch(fetchTopCommissionMembersActions.trigger(undefined));
  }, [dispatch]);
};
export const CommissionsOverviewScreen: FC = () => {
  useTriggerLoadData();
  const { isSmallScreen } = useSmallScreen();

  const curStyles = StyleSheet.create({
    container: {
      flexDirection: isSmallScreen() ? 'column' : 'row',
    },
    column: {
      flexDirection: isSmallScreen() ? 'row' : 'column',
      marginHorizontal: isSmallScreen() ? 0 : spacing[2],
    },
  });
  return (
    <Screen androidCoverBackground>
      <View style={[styles.container, curStyles.container]}>
        <View style={[styles.webColumn, curStyles.column]}>
          <NetworkCommissionsHistory />
          <NetworkPerformanceOverviewWithoutButtons />
          <DashboardLatestPayouts />
          <DashboardLatestCommissions />
          <DashboardLeaderboardCommission />
          <DashboardUserBonusProgram />
          <DashboardCommissionsGoal />
        </View>
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  webColumn: {
    flex: 1,
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
