import { makeUrl } from 'expo-linking';

export default {
  prefixes: [makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          Dashboard: {
            screens: {
              DashboardScreen: 'dashboard',
            },
          },
        },
      },
      'not-found': '*',
      Members: 'members',
      MembersCommissionLeaderboard: 'members-commission-leaderboard',
      MembersPerformanceLeaderboard: 'members-performance-leaderboard',
      RegisterMember: 'register-member',
      PlaceMember: 'place-member',
      News: 'news',
      Messages: 'messages',
      Payouts: 'payouts',
      Orders: 'orders',
    },
  },
};
