export const colorNames = {
  brandPrimary: '#A7B0A8',
  brandSecondary: '#D95BB7',
  white: '#FFFFFF',
  black: '#000000',
  darkBlue: '#003365',
  lightBlue: '#72C0F2',
  green: '#56BF99',
  lightGreen: '#56BFff',
  veryLightGrey: '#EDEDED',
  lightGrey: '#C4C4C4',
  grey: '#555555',
  mediumGrey: '#d8d8d8',
};

// TODO: re-define new colors here
const notYetDefinedColor = '#666666';
export const colorNames2 = {
  brandPrimary: notYetDefinedColor,
  brandSecondary: '#D53FAC',
  white: '#FFFFFF',
  almostWhite: '#F7F7F7',
  black: '#000000',
  almostBlack: '#171817',
  darkBlue: '#003365', // TODO: this was not defined yet - fixes login, more and settings screen in dark mode
  lightBlue: notYetDefinedColor,
  green: notYetDefinedColor,
  veryLightGrey: notYetDefinedColor,
  lightGrey: '#A8B0A8',
  grey: '#D8D8D8',
  red: '#D12A3D',
};
