import { StyleSheet } from 'react-native';
import {
  H1_FONT_SIZE,
  H1_LINE_HEIGHT,
  H2_FONT_SIZE,
  H2_LINE_HEIGHT,
  H3_FONT_SIZE,
  H3_LINE_HEIGHT,
  BODY_SMALL_FONT_SIZE,
  BODY_SMALL_LINE_HEIGHT,
  BODY_XS_FONT_SIZE,
  BODY_XS_LINE_HEIGHT,
  BODY_FONT_SIZE,
  BODY_LINE_HEIGHT,
  BODY_BIG_FONT_SIZE,
  BODY_BIG_LINE_HEIGHT,
  CURRENCY_BIG_GREEN_SIZE,
  CURRENCY_BIG_GREEN_LINE_HEIGHT,
  CURRENCY_SIZE,
  CURRENCY_LINE_HEIGHT,
  TAB_FONT_SIZE,
  TABLE_TEXT_FONT_SIZE,
  TABLE_TEXT_LINE_HEIGHT,
  TABLE_CURRENCY_FONT_SIZE,
} from '../typography-sizes';
import * as SIZES2 from '../typography-sizes2';
import { colors, colors2 } from './colors';
import { colorNames2 } from '../color-names';

export const typography = {
  bold: 'opensans-bold',
  semiBold: 'opensans-semibold',
  regular: 'opensans-regular',
  light: 'opensans-light',
};

export const textStyles = StyleSheet.create({
  H1Left: {
    fontFamily: typography.light,
    fontSize: H1_FONT_SIZE,
    lineHeight: H1_LINE_HEIGHT,
    textAlign: 'left',
    color: colors.text,
  },
  H1Center: {
    fontFamily: typography.light,
    fontSize: H1_FONT_SIZE,
    lineHeight: H1_LINE_HEIGHT,
    textAlign: 'center',
    color: colors.text,
  },
  H1LeftBold: {
    fontFamily: typography.semiBold,
    fontSize: H1_FONT_SIZE,
    lineHeight: H1_LINE_HEIGHT,
    textAlign: 'left',
    color: colors.text,
  },
  H2Center: {
    fontFamily: typography.light,
    fontSize: H2_FONT_SIZE,
    lineHeight: H2_LINE_HEIGHT,
    textAlign: 'center',
    color: colors.text,
  },
  H2CenterBold: {
    fontFamily: typography.semiBold,
    fontSize: H2_FONT_SIZE,
    lineHeight: H2_LINE_HEIGHT,
    textAlign: 'center',
    color: colors.text,
  },
  H3LeftBold: {
    fontFamily: typography.semiBold,
    fontSize: H3_FONT_SIZE,
    lineHeight: H3_LINE_HEIGHT,
    textAlign: 'left',
    color: colors.text,
  },
  H3RightBold: {
    fontFamily: typography.semiBold,
    fontSize: H3_FONT_SIZE,
    lineHeight: H3_LINE_HEIGHT,
    textAlign: 'right',
    color: colors.text,
  },
  H3Left: {
    fontFamily: typography.light,
    fontSize: H3_FONT_SIZE,
    lineHeight: H3_LINE_HEIGHT,
    textAlign: 'left',
    color: colors.text,
  },
  BodySmallTextLeft: {
    fontFamily: typography.light,
    fontSize: BODY_SMALL_FONT_SIZE,
    lineHeight: BODY_SMALL_LINE_HEIGHT,
    textAlign: 'left',
    color: colors.text,
  },
  BodySmallTextRight: {
    fontFamily: typography.light,
    fontSize: BODY_SMALL_FONT_SIZE,
    lineHeight: BODY_SMALL_LINE_HEIGHT,
    textAlign: 'right',
    color: colors.text,
  },
  BodyXSTextLeft: {
    fontFamily: typography.light,
    fontSize: BODY_XS_FONT_SIZE,
    lineHeight: BODY_XS_LINE_HEIGHT,
    textAlign: 'left',
    color: colors.text,
  },
  BodyTextLeft: {
    fontFamily: typography.light,
    fontSize: BODY_FONT_SIZE,
    lineHeight: BODY_LINE_HEIGHT,
    textAlign: 'left',
    color: colors.text,
  },
  BodyTextBoldLeft: {
    fontFamily: typography.semiBold,
    fontSize: BODY_FONT_SIZE,
    lineHeight: BODY_LINE_HEIGHT,
    textAlign: 'left',
    color: colors.text,
  },
  BodyTextRight: {
    fontFamily: typography.light,
    fontSize: BODY_FONT_SIZE,
    lineHeight: BODY_LINE_HEIGHT,
    textAlign: 'right',
    color: colors.text,
  },
  BodyBigTextLeft: {
    fontFamily: typography.light,
    fontSize: BODY_BIG_FONT_SIZE,
    lineHeight: BODY_BIG_LINE_HEIGHT,
    textAlign: 'left',
    color: colors.text,
  },
  CurrencyBigLeft: {
    fontFamily: typography.light,
    fontSize: CURRENCY_BIG_GREEN_SIZE,
    lineHeight: CURRENCY_BIG_GREEN_LINE_HEIGHT,
    textAlign: 'left',
    color: colors.text,
  },
  CurrencyBigRight: {
    fontFamily: typography.light,
    fontSize: CURRENCY_BIG_GREEN_SIZE,
    lineHeight: CURRENCY_BIG_GREEN_LINE_HEIGHT,
    textAlign: 'right',
    color: colors.text,
  },
  CurrencyLeft: {
    fontFamily: typography.light,
    fontSize: CURRENCY_SIZE,
    lineHeight: CURRENCY_LINE_HEIGHT,
    textAlign: 'left',
    color: colors.text,
  },
  CurrencyRight: {
    fontFamily: typography.light,
    fontSize: CURRENCY_SIZE,
    lineHeight: CURRENCY_LINE_HEIGHT,
    textAlign: 'right',
    color: colors.text,
  },
  BodyTextLinkLeft: {
    fontFamily: typography.semiBold,
    fontSize: 14,
    lineHeight: 19,
    textAlign: 'left',
    textTransform: 'uppercase',
    textDecorationStyle: 'solid',
    textDecorationLine: 'underline',
    textDecorationColor: colors.link,
    color: colors.link,
  },
  PlainTextLinkLeft: {
    fontFamily: typography.semiBold,
    fontSize: 14,
    lineHeight: 19,
    textAlign: 'left',
    textTransform: 'uppercase',
    color: colors.link,
  },
  BottomNavigationTextLeft: {
    fontFamily: typography.regular,
    fontSize: TAB_FONT_SIZE,
    lineHeight: BODY_LINE_HEIGHT,
    textAlign: 'left',
    // NOTE: color is intentionally not defined, used by e.g.: bottom bar
  },
  BorderTextLeft: {
    fontFamily: typography.light,
    fontSize: BODY_FONT_SIZE,
    lineHeight: BODY_LINE_HEIGHT,
    textAlign: 'left',
    color: colors.border,
  },
  InvertedTextLeft: {
    fontFamily: typography.light,
    fontSize: BODY_FONT_SIZE,
    lineHeight: BODY_LINE_HEIGHT,
    textAlign: 'left',
    color: colors.background,
  },
  BodySmallTextWhiteLeft: {
    fontFamily: typography.light,
    fontSize: BODY_SMALL_FONT_SIZE,
    lineHeight: BODY_SMALL_LINE_HEIGHT,
    textAlign: 'left',
    color: '#fff',
  },
  TableTextLeft: {
    fontFamily: typography.light,
    fontSize: TABLE_TEXT_FONT_SIZE,
    lineHeight: TABLE_TEXT_LINE_HEIGHT,
    textAlign: 'left',
    color: colors.text,
  },
  TableCurrencyRight: {
    fontFamily: typography.regular,
    fontSize: TABLE_CURRENCY_FONT_SIZE,
    lineHeight: TABLE_TEXT_LINE_HEIGHT, // use same line height to ensure baseline works
    textAlign: 'left',
    color: colors.text,
  },
});

export const textStyles2 = StyleSheet.create({
  H3LeftBold: {
    fontFamily: typography.bold,
    fontSize: SIZES2.H3_FONT_SIZE,
    lineHeight: SIZES2.H3_LINE_HEIGHT,
    letterSpacing: SIZES2.LETTER_SPACING1,
    textAlign: 'left',
    color: colors2.text,
  },
  ButtonCenter: {
    fontFamily: typography.bold,
    fontSize: SIZES2.BUTTON_FONT_SIZE,
    lineHeight: SIZES2.BUTTON_LINE_HEIGHT,
    textAlign: 'center',
    color: colorNames2.white, // TODO: theme colors?
  },
  BodyTextLeft: {
    fontFamily: typography.regular,
    fontSize: SIZES2.BODY_FONT_SIZE,
    lineHeight: SIZES2.BODY_LINE_HEIGHT,
    letterSpacing: SIZES2.LETTER_SPACING1,
    textAlign: 'left',
    color: colors2.text,
  },
  BodyTextBoldLeft: {
    fontFamily: typography.bold,
    fontSize: SIZES2.BODY_BOLD_FONT_SIZE,
    lineHeight: SIZES2.BODY_BOLD_LINE_HEIGHT,
    letterSpacing: SIZES2.LETTER_SPACING1,
    textAlign: 'left',
    color: colors2.text,
  },
  SmallHeaderTextLeft: {
    fontFamily: typography.semiBold,
    fontSize: SIZES2.BODY_FONT_SIZE,
    lineHeight: SIZES2.BODY_LINE_HEIGHT,
    letterSpacing: SIZES2.LETTER_SPACING1,
    textAlign: 'left',
    textTransform: 'uppercase',
    color: colors2.text,
  },
});
