import React, { FC } from 'react';
import { StyleSheet, View } from 'react-native';

import { useTheme } from '../../hooks/use-theme';
// import { Notification } from '../../components/notification/notification';
import { ThemeToggle } from '../../components/theme-toggle/theme-toggle';
import {
  getNavigationTileWidth,
  NAVIGATION_HEADLINE_HEIGHT,
} from '../../theme/fixed-sizes';
import { Screen2 } from '../../components/screen/screen2';
import {
  CTABox,
  CTABoxToggler,
  LinkBox,
} from '../../components/link-box/link-box';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../navigation/types';

export const SettingsScreen: FC = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const { theme, toggleTheme } = useTheme();
  const { colors2 } = theme;

  const navigateToProfile = () => {
    navigation.navigate('Wip', { headline: 'Profile' });
  };
  const navigateToLanguageSelector = () => {
    navigation.navigate('Wip', { headline: 'Language Selector' });
  };
  const navigateToNotifications = () => {
    navigation.navigate('Wip', { headline: 'Notifications' });
  };

  return (
    <Screen2>
      <View style={[styles.container, { backgroundColor: colors2.background }]}>
        <View style={styles.headlineContainer} />
        <CTABox
          label={'Profile'}
          type="light"
          icon={'person-green'}
          onPress={navigateToProfile}
          withChevronRight
        />
        <CTABoxToggler label={'Display'} icon={'sun-blue'}>
          <View
            style={[
              styles.themeToggleContainer,
              { backgroundColor: colors2.linkBoxLevel2 },
            ]}
          >
            <ThemeToggle onPress={toggleTheme} theme={theme} />
          </View>
        </CTABoxToggler>
        <LinkBox
          label={'Language Selector'}
          type="light"
          onPress={navigateToLanguageSelector}
        />
        <LinkBox
          label={'Notifications'}
          type="light"
          onPress={navigateToNotifications}
        />
        {/* <Notification /> */}
      </View>
    </Screen2>
  );
};

const styles = StyleSheet.create({
  container: {
    width: getNavigationTileWidth(),
  },
  headlineContainer: {
    height: NAVIGATION_HEADLINE_HEIGHT,
  },
  themeToggleContainer: {
    height: NAVIGATION_HEADLINE_HEIGHT,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
