import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FC, useEffect, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { PlaceHolderLoader } from '../../components/placeholder-loader/placeholder-loader';
import { RootStackParamList } from '../../navigation/types';
import {
  FullPaginationParams,
  Member,
  MemberCommission,
} from '../../services/api/types';

import { PaginationBar } from '../../components/pagination-bar/pagination-bar';
import {
  Table,
  TableConfigItem,
  TableSortConfig,
} from '../../components/table/table';
import { DEFAULT_PAGE_SIZE } from '../../hooks/use-pagination';
import { useTheme } from '../../hooks/use-theme';
import { spacing } from '../../theme/spacing';
import {
  getTableDefaultHeight,
  getTableDefaultWidth,
  TABLE_COLUMN_WIDTH_S,
} from '../../theme/fixed-sizes';
import { useFetchPaginated } from '../../hooks/use-fetch-paginated';
import { apiService } from '../../services/api/api-service';
import { LoadingScreen } from '../../components/loading-screen/loading-screen';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/redux/reducers';
import { UserState } from '../../services/redux/reducers/user-info';
import { Currency } from '../../components/currency/currency';

const MEMBERS_TABLE_CONFIG: TableConfigItem<MemberCommission>[] = [
  {
    header: 'Username',
    width: TABLE_COLUMN_WIDTH_S,
    render: (member) => member.username,
  },
  {
    header: 'Commissions',
    sortField: 'total',
    width: 170,
    align: 'right',
    render: (member) => (
      <Currency
        amount={member.total}
        currency={member.currency}
        symbolPosition={'left'}
      />
    ),
  },
];

export const CommissionsLeaderboardScreen: FC = () => {
  const {
    queryParams,
    onPaginate,
    onChangePageSize,
    onChangeSort,
    loading,
    error,
    data,
    refetch,
  } = useFetchPaginated<MemberCommission, FullPaginationParams>(
    apiService,
    apiService.getMembersCommissionLeaderboard,
    {
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
    }
  );
  const { theme } = useTheme();
  const userState = useSelector<RootState, UserState>((state) => state.user);
  const { data: userData } = userState;

  useEffect(() => {
    refetch();
  }, [refetch]);

  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const tableSortConfig: TableSortConfig = useMemo(() => {
    return {
      default: {
        sortBy: queryParams.sortBy,
        sortOrder: queryParams.sortOrder,
      },
      onChangeSort,
    };
  }, [onChangeSort, queryParams.sortBy, queryParams.sortOrder]);

  const navigateToMember = (member: Member) => {
    navigation.navigate('Wip', {
      headline: 'Member Details',
      bodyText: 'Show details of member ' + member.id + ':' + member.username,
    });
  };

  // TODO: remove GDoellers/4biohackers hack
  const filteredUsers = data.data.filter((u) => {
    if (
      userData?.user?.username === 'GDoellers' &&
      u.username === '4biohackers'
    ) {
      return false;
    }
    return true;
  });

  return (
    <LoadingScreen
      loading={loading}
      error={error}
      refetch={refetch}
      placeholderLoaderType="MESSAGES_SCREEN"
    >
      <View style={styles.container}>
        <View style={styles.innerContainer}>
          {loading && <PlaceHolderLoader displayType="MESSAGES_SCREEN" />}
          {!loading && (
            <Table<MemberCommission>
              data={filteredUsers}
              onRowPress={navigateToMember}
              config={MEMBERS_TABLE_CONFIG}
              theme={theme}
              sortConfig={tableSortConfig}
            />
          )}
        </View>
        <View style={styles.bottomBar}>
          <PaginationBar
            page={queryParams.page}
            pageSize={queryParams.pageSize}
            sliceSize={5}
            totalCount={data?.total || 0}
            onPaginate={onPaginate}
            onChangePageSize={onChangePageSize}
            theme={theme}
          />
        </View>
      </View>
    </LoadingScreen>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: spacing[2],
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    width: getTableDefaultWidth(),
    minHeight: getTableDefaultHeight(),
    alignItems: 'center',
  },
  bottomBar: {
    flex: 1,
  },
});
