import { numberOfDays } from '../../../utils/format-date';
import { UserBonusProgram } from '../../api/types';

export interface UserFastStartBonus {
  name: string;
  endDate: string;
  remainingDays: number;
  totalDays: number;
  remainingMembersToAcquire: number;
}

export const getUserFastStartBonus = (
  userBonus: UserBonusProgram
): UserFastStartBonus => {
  const totalDays = numberOfDays(userBonus.startDate, userBonus.endDate);

  // TODO: remove hard coded values, implement real values in backend api
  // const now = new Date().toString();
  // const remainingDays = numberOfDays(now, userBonus.endDate);
  const remainingDays = 10;

  return {
    name: userBonus.name,
    endDate: userBonus.endDate,
    remainingDays,
    totalDays,
    remainingMembersToAcquire: 1, // TODO: get from api
  };
};
