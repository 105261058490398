export interface EmptyAction<T extends string> {
  type: T;
}

export interface EmptyActionCreator<T extends string> {
  (): EmptyAction<T>;
  getType: () => T;
}

export interface PayloadAction<T extends string, P> {
  type: T;
  payload: P;
}

export interface PayloadActionCreator<T extends string, P> {
  (payload: P): PayloadAction<T, P>;
  getType: () => T;
}

export type ActionBuilderConstructor<T extends string, TPayload = undefined> = [
  TPayload
] extends [undefined]
  ? unknown extends TPayload
    ? PayloadActionCreator<T, TPayload>
    : EmptyActionCreator<T>
  : PayloadActionCreator<T, TPayload>;

export interface ActionBuilder<T extends string> {
  <P = undefined>(): ActionBuilderConstructor<T, P>;
}

export function createAction<T extends string>(type: T): ActionBuilder<T> {
  function constructor<P>(): ActionBuilderConstructor<T, P> {
    const actionCreator = (payload: P) => {
      return { type, payload };
    };

    actionCreator.getType = () => type;

    return actionCreator as ActionBuilderConstructor<T, P>;
  }

  return constructor;
}
