import { useState } from 'react';
import { LayoutChangeEvent } from 'react-native';

interface ContentSize {
  width: number;
  height: number;
}

export const useContentDimensions = () => {
  const [contentSize, setContentSize] = useState<ContentSize | undefined>(
    undefined
  );
  const onContentLayout = (event: LayoutChangeEvent) => {
    const { width, height } = event.nativeEvent.layout;
    setContentSize({ width, height });
  };
  return {
    onContentLayout,
    contentSize,
  };
};
