import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={14} height={8} viewBox="0 0 14 8" fill="none" {...props}>
      <Path d="M12.786 1L7 7.263 1.214 1h11.572z" stroke="#D95BB7" />
    </Svg>
  );
};

export default SvgComponent;
