// Auto-generated file created by react-native-storybook-loader
// Do not edit.
//
// https://github.com/elderfo/react-native-storybook-loader.git

function loadStories() {
  require('../assets/images/index.story');
  require('../components/api-error-info/api-error-info.story');
  require('../components/button/button.story');
  require('../components/button/button2.story');
  require('../components/copy-to-clipboard/copy-to-clipboard.story');
  require('../components/history-chart/history-chart.story');
  require('../components/placeholder-loader/placeholder-loader.story');
  require('../components/rank-image/rank-image.story');
  require('../components/svg-image/svg-image.story');
  require('../components/table/table.story');
  require('../theme/color-names.story');
  require('../theme/typography.story');
}

const stories = [
  '../assets/images/index.story',
  '../components/api-error-info/api-error-info.story',
  '../components/button/button.story',
  '../components/button/button2.story',
  '../components/copy-to-clipboard/copy-to-clipboard.story',
  '../components/history-chart/history-chart.story',
  '../components/placeholder-loader/placeholder-loader.story',
  '../components/rank-image/rank-image.story',
  '../components/svg-image/svg-image.story',
  '../components/table/table.story',
  '../theme/color-names.story',
  '../theme/typography.story',
];

module.exports = {
  loadStories,
  stories,
};
