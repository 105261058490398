import * as React from 'react';
import Svg, { Rect, Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={43} height={43} viewBox="0 0 43 43" fill="none" {...props}>
      <Path
        d="M35.213 24.213v3.5c0 4-4.5 7-13.5 7s-13.5-4-13.5-6.09v-3.41"
        stroke="#FFFFFF"
      />
      <Path fill="#FFFFFF" d="M3.213 22.213h1v8h-1z" />
      <Rect
        x={2.784}
        y={28.491}
        width={1}
        height={5}
        rx={0.5}
        transform="rotate(-45 2.784 28.491)"
        fill="#FFFFFF"
      />
      <Rect
        x={3.613}
        y={27.784}
        width={1}
        height={5}
        rx={0.5}
        transform="rotate(45 3.613 27.784)"
        fill="#FFFFFF"
      />
      <Rect x={3.052} y={28.552} width={1} height={5} rx={0.5} fill="#FFFFFF" />
      <Path
        d="M3.913 22.716c-1.112-.588-1.05-2.2.103-2.702l14.21-6.171a7.5 7.5 0 015.975 0l14.21 6.171c1.153.501 1.215 2.114.103 2.702l-12.861 6.798a9.5 9.5 0 01-8.88 0l-12.86-6.798z"
        stroke="#FFFFFF"
      />
    </Svg>
  );
};

export default SvgComponent;
