import * as React from 'react';
import Svg, { SvgProps, Rect, Path } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={35} height={22} viewBox="0 0 35 22" fill="none" {...props}>
      <Rect
        x={0.5}
        y={2.5}
        width={34}
        height={18}
        rx={4}
        fill="#fff"
        stroke="#D95BB7"
      />
      <Rect
        x={0.5}
        y={2.5}
        width={34}
        height={18}
        rx={3.5}
        fill="#fff"
        stroke="#D95BB7"
        strokeDasharray="2 2"
      />
      <Path d="M9 2v19" stroke="#D95BB7" strokeDasharray="2 2" />
      <Path
        d="M24.094 13.492c0 .708-.266 1.294-.797 1.758-.531.458-1.274.747-2.227.867v1.813h-1.008v-1.742a10.6 10.6 0 01-1.695-.133c-.547-.094-.995-.222-1.344-.383v-1.219c.433.193.93.352 1.493.477.567.12 1.083.18 1.547.18v-3.438c-1.068-.339-1.818-.732-2.25-1.18-.428-.448-.641-1.026-.641-1.734 0-.682.263-1.242.789-1.68.531-.437 1.232-.703 2.102-.797V4.86h1.007v1.407a7.93 7.93 0 012.774.578l-.407 1.023a7.933 7.933 0 00-2.367-.547v3.39c.818.261 1.43.517 1.836.767.407.244.706.528.899.851.192.318.289.706.289 1.164zm-1.328.102c0-.375-.118-.677-.352-.906-.23-.235-.677-.467-1.344-.696v3.04c1.13-.157 1.696-.636 1.696-1.438zm-4.274-4.852c0 .396.117.714.352.953.234.24.64.466 1.218.68V7.352c-.515.083-.906.247-1.171.492a1.16 1.16 0 00-.399.898z"
        fill="#2D9CDB"
      />
    </Svg>
  );
};

export default SvgComponent;
