import React, { FC } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useTheme } from '../../hooks/use-theme';

export const Screen2: FC = ({ children }) => {
  const { theme } = useTheme();
  const { colors2 } = theme;
  const backgroundColor = colors2.background;

  return (
    <View style={styles.container}>
      <ScrollView
        contentContainerStyle={[styles.contentContainer, { backgroundColor }]}
        bounces={false}
        scrollEventThrottle={10}
      >
        {children}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  contentContainer: {
    minHeight: '100%',
    alignItems: 'center',
  },
});
