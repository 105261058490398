import * as React from 'react';
import Svg, { Path, Circle, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={49} height={42} viewBox="0 0 49 42" fill="none" {...props}>
      <Path
        d="M14.569 20.387l-4.41 8.083a.833.833 0 01-1.463 0l-4.409-8.083a.833.833 0 01.732-1.232h8.818a.833.833 0 01.732 1.232z"
        stroke="#fff"
      />
      <Path
        d="M28.667 41.333C39.344 41.333 48 32.677 48 22c0-10.678-8.656-19.333-19.333-19.333-9.773 0-17.852 7.25-19.15 16.666"
        stroke="#fff"
      />
      <Path
        d="M27.52 21.47l.103.363h2.088l.103-.362a24.581 24.581 0 00.771-9.683l-.098-.816c-.258-2.153-3.382-2.153-3.64 0l-.098.816a24.585 24.585 0 00.77 9.683zM30.306 22.241l-.405-.166-.218.379-.445.77-.219.379.347.267 3.175 2.445a16.82 16.82 0 004.874 2.606c1.363.461 2.37-1.286 1.29-2.235a16.826 16.826 0 00-4.693-2.917l-3.706-1.527z"
        stroke="#fff"
      />
      <Circle cx={28.666} cy={22.333} r={2} fill="#fff" />
    </Svg>
  );
};

export default SvgComponent;
