import React, { FC } from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { ThemeConfig } from '../../hooks/use-theme';
import { spacing } from '../../theme/spacing';
import { SvgImage, SvgNames } from '../svg-image/svg-image';

const SVG_ARROW_WIDTH = 11;
const SVG_ARROW_HEIGHT = 18;
const INACTIVE_OPACITY = 0.5;

interface ArrowPaginationProps {
  arrowType: 'left' | 'right';
  currentPageNumbers: number[];
  page: number;
  doubleArrowsActive: boolean;
  onPressDoubleArrows: () => void;
  singleArrowActive: boolean;
  onPressSingleArrow: () => void;
  theme: ThemeConfig;
}

export const ArrowPagination: FC<ArrowPaginationProps> = (props) => {
  const {
    arrowType,
    currentPageNumbers,
    doubleArrowsActive,
    onPressDoubleArrows,
    singleArrowActive,
    onPressSingleArrow,
    theme,
  } = props;

  const svgImageType: SvgNames =
    arrowType === 'left' ? 'chevron-left' : 'chevron-right';

  const SvgArrow: FC<{ active: boolean }> = (currentProps) => {
    const currentStyles = StyleSheet.create({
      opacity: {
        opacity: INACTIVE_OPACITY,
      },
      padding: { paddingHorizontal: 0 },
    });
    const { active } = currentProps;
    const arrowStyle = active
      ? [currentStyles.padding]
      : [currentStyles.opacity, currentStyles.padding];
    return (
      <SvgImage
        width={SVG_ARROW_WIDTH.toString()}
        height={SVG_ARROW_HEIGHT.toString()}
        type={svgImageType}
        variant={theme.name}
        style={arrowStyle}
      />
    );
  };

  const containerStyle = arrowType === 'left' ? styles.row : styles.rowReverse;
  const itemStyle =
    arrowType === 'left' ? styles.leftContainer : styles.rightContainer;

  return currentPageNumbers.length > 1 ? (
    <View style={containerStyle}>
      <View style={styles.chevronContainer}>
        <TouchableOpacity
          onPress={onPressDoubleArrows}
          style={[styles.row, itemStyle]}
          disabled={!doubleArrowsActive}
        >
          <SvgArrow active={doubleArrowsActive} />
          <SvgArrow active={doubleArrowsActive} />
        </TouchableOpacity>
      </View>
      <View style={styles.chevronContainer}>
        <TouchableOpacity
          onPress={onPressSingleArrow}
          style={[styles.row, itemStyle]}
          disabled={!singleArrowActive}
        >
          <SvgArrow active={singleArrowActive} />
        </TouchableOpacity>
      </View>
    </View>
  ) : null;
};

const PAGINATION_ELEMENT_SIZE = 20;
const styles = StyleSheet.create({
  row: {
    flex: 1,
    flexDirection: 'row',
    paddingVertical: spacing[0],
  },
  rowReverse: {
    flex: 1,
    flexDirection: 'row-reverse',
    paddingVertical: spacing[0],
  },
  chevronContainer: {
    flex: 1,
    flexDirection: 'row',
    height: PAGINATION_ELEMENT_SIZE,
    alignItems: 'center',
  },
  leftContainer: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  rightContainer: {
    flex: 1,
    justifyContent: 'flex-end',
  },
});
