import * as React from 'react';
import Svg, { Circle, Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={12} height={12} viewBox="0 0 12 12" fill="none" {...props}>
      <Circle cx={6} cy={6} r={5.5} stroke="#A8B0A8" />
      <Path
        d="M6.5 3a.5.5 0 00-1 0h1zM6 6.5h-.5v.298l.262.142L6 6.5zm2.162 1.74a.5.5 0 00.476-.88l-.476.88zM5.5 3v3.5h1V3h-1zm.262 3.94l2.4 1.3.476-.88-2.4-1.3-.476.88z"
        fill="#A8B0A8"
      />
    </Svg>
  );
};

export default SvgComponent;
