import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../services/redux/reducers';
import { AuthState } from '../services/redux/reducers/auth';

export const useAuthentication = () => {
  const authState = useSelector<RootState, AuthState>((state) => state.auth);
  const isUserLoggedIn = useCallback(() => {
    return authState.data && authState.data.token !== undefined;
  }, [authState]);

  const token = authState?.data?.token || null;

  return {
    token,
    isUserLoggedIn,
  };
};
