import { takeEvery } from 'redux-saga/effects';
import { ApiType } from '../../api';
import { fetchUnplacedMembersCountActions } from '../actions/unplaced-members-count';
import { callApiEndpoint } from './api';

export const watchFetchUnplacedMembersCount = (Api: ApiType) =>
  takeEvery(
    fetchUnplacedMembersCountActions.types.trigger,
    fetchUnplacedMembersCount,
    Api
  );

function* fetchUnplacedMembersCount(
  Api: ApiType,
  _: ReturnType<typeof fetchUnplacedMembersCountActions.trigger>
) {
  yield callApiEndpoint(
    fetchUnplacedMembersCountActions,
    Api.getUnplacedMembersCount,
    undefined,
    Api
  );
}
