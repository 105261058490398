import React from 'react';
import { storiesOf } from '@storybook/react-native';

import { SvgImage } from './svg-image';

storiesOf('SvgImage', module)
  .add('light', () => {
    return <SvgImage type="dummy" width="40" height="40" variant="light" />;
  })
  .add('dark', () => {
    return <SvgImage type="dummy" width="40" height="40" variant="dark" />;
  })

  .add('clickable', () => {
    return (
      <SvgImage
        type="dummy"
        width="40"
        height="40"
        // eslint-disable-next-line no-console
        onPress={console.warn}
        variant="light"
      />
    );
  });
