import React, { FC } from 'react';
import { Dimensions, StyleSheet, View, Text } from 'react-native';
import { StackedAreaChart, Grid, YAxis, XAxis } from 'react-native-svg-charts';
import { scaleLinear, scaleUtc } from 'd3-scale';
import { shortDate, parseDate } from '../../utils/format-date';

import { useTheme } from '../../hooks/use-theme';
import { textStyles } from '../../theme/light/typography';

const defaultChartHeight = 100;
const chartWidth = Dimensions.get('window').width - 50;
const xAxisHeight = 30;
const chartVerticalInset = 12;
const chartHorizontalInset = 24;
const yScale = scaleLinear;
const xScale = scaleUtc; // scaleLinear | scaleTime | scaleUtc
const numberOfMonths = 3;
const axesSvg = {
  fontSize: 10,
  fill: 'grey',
};

export type XAxisUnit = 'MONTHS' | undefined;

export interface ChartData {
  left: number;
  right: number;
  date: DateType;
}

interface HistoryChartProps {
  startDate: string;
  endDate: string;
  xAxisUnit: XAxisUnit;
  data: ChartData[];
  height?: number;
  showGradient?: boolean;
  showDashedLine?: boolean;
  showVerticalGrid?: boolean;
  showHorizontalGrid?: boolean;
  showPoints?: boolean;
  showYAxis?: boolean;
}

type DateType = Date | string | undefined;

export const MultiHistoryChart: FC<HistoryChartProps> = (props) => {
  const { data, height, startDate, endDate } = props;

  const { theme } = useTheme();
  const { colors } = theme;
  type KeyType = 'right' | 'left' | 'date';
  const chartHeight = height || defaultChartHeight;
  const keys: KeyType[] = ['right', 'left'];
  const keyColors = [colors.chartLine, colors.chartLine2];

  /*@ts-ignore-next-line */
  return (
    <View style={styles.col}>
      <View style={styles.row}>
        <StackedAreaChart<ChartData>
          style={{
            height: chartHeight,
            width: chartWidth,
          }}
          data={data}
          keys={keys}
          colors={keyColors}
          contentInset={{
            top: chartVerticalInset,
            bottom: chartVerticalInset,
            left: chartHorizontalInset,
            right: chartHorizontalInset,
          }}
          svg={{
            fill: 'url(#gradient)',
            clipPath: 'url(#clip-path-1)',
          }}
        >
          <Grid />
        </StackedAreaChart>
        <YAxis
          scale={yScale}
          // eslint-disable-next-line react-native/no-inline-styles
          style={{ position: 'absolute', top: 0, bottom: 0 }}
          data={StackedAreaChart.extractDataPoints(data, keys)}
          contentInset={{ top: 10, bottom: 10 }}
          svg={{
            fontSize: 8,
            fill: 'white',
            stroke: 'black',
            strokeWidth: 0.1,
            alignmentBaseline: 'baseline',
            baselineShift: '3',
          }}
        />
      </View>
      <View style={styles.row}>
        <XAxis<ChartData>
          style={{
            height: xAxisHeight,
            width: chartWidth,
          }}
          data={data}
          xAccessor={({ item }) => item.date}
          scale={xScale}
          numberOfTicks={numberOfMonths}
          formatLabel={(date) => shortDate(date)}
          contentInset={{
            left: chartHorizontalInset,
            right: chartHorizontalInset,
          }}
          svg={axesSvg}
          /* @ts-ignore-next-line */
          min={parseDate(startDate)}
          max={parseDate(endDate)}
        />
      </View>
      <View style={styles.row}>
        <View style={styles.legendLeft}>
          <Text style={textStyles.BodyTextLeft}>Left Tree</Text>
        </View>
        <View style={[{ backgroundColor: keyColors[1] }, styles.legendRight]} />
      </View>
      <View style={styles.row}>
        <View style={styles.legendLeft}>
          <Text style={textStyles.BodyTextLeft}>Right Tree</Text>
        </View>
        <View style={[{ backgroundColor: keyColors[0] }, styles.legendRight]} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingBottom: 10,
  },
  col: {
    flexDirection: 'column',
    // alignItems: 'flex-end',
    // justifyContent: 'flex-end',
  },
  legendLeft: {
    width: 100,
    height: 20,
  },
  legendRight: {
    width: 20,
    height: 20,
  },
});
