import { PaginatedResponse } from '../../api/types';
import { GetPayoutsParams, Payout } from './../../api/types';
import { createApiActions, ExtractActionTypes } from '../create-api-actions';

export const fetchLatestPayoutsActions = createApiActions(
  'FETCH_LATEST_PAYOUTS_TRIGGER',
  'FETCH_LATEST_PAYOUTS_START',
  'FETCH_LATEST_PAYOUTS_SUCCESS',
  'FETCH_LATEST_PAYOUTS_FAILURE'
)<undefined, PaginatedResponse<Payout[]>>();

export const fetchPayoutsActions = createApiActions(
  'FETCH_PAYOUTS_TRIGGER',
  'FETCH_PAYOUTS_START',
  'FETCH_PAYOUTS_SUCCESS',
  'FETCH_PAYOUTS_FAILURE'
)<GetPayoutsParams, PaginatedResponse<Payout[]>>();

export type PayoutsActions =
  | ExtractActionTypes<typeof fetchLatestPayoutsActions>
  | ExtractActionTypes<typeof fetchPayoutsActions>;
