import * as React from 'react';
import Svg, { Rect, Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={27} height={22} viewBox="0 0 27 22" fill="none" {...props}>
      <Path
        d="M23 21H5a4 4 0 01-4-4V4a3 3 0 013-3h15a3 3 0 013 3v14c0 1.657.343 3 2 3s2-1.343 2-3V8a2 2 0 00-2-2h-1.5"
        stroke="#D95BB7"
      />
      <Rect
        x={5.25}
        y={6.25}
        width={12.5}
        height={0.5}
        rx={0.25}
        fill="#D95BB7"
        stroke="#2D9CDB"
        strokeWidth={0.5}
        strokeLinecap="round"
      />
      <Rect
        x={5.25}
        y={9.25}
        width={12.5}
        height={0.5}
        rx={0.25}
        fill="#D95BB7"
        stroke="#2D9CDB"
        strokeWidth={0.5}
        strokeLinecap="round"
      />
      <Rect
        x={5.25}
        y={12.25}
        width={12.5}
        height={0.5}
        rx={0.25}
        fill="#D95BB7"
        stroke="#2D9CDB"
        strokeWidth={0.5}
        strokeLinecap="round"
      />
      <Rect
        x={5.25}
        y={15.25}
        width={5.5}
        height={0.5}
        rx={0.25}
        fill="#D95BB7"
        stroke="#2D9CDB"
        strokeWidth={0.5}
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default SvgComponent;
