import * as React from 'react';
import Svg, { Rect, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={25} height={20} viewBox="0 0 25 20" fill="none" {...props}>
      <Rect
        x={0.5}
        y={0.5}
        width={24}
        height={8}
        rx={1.5}
        fill="#fff"
        stroke="#fff"
      />
      <Rect
        x={0.5}
        y={11.5}
        width={24}
        height={8}
        rx={1.5}
        fill="#fff"
        stroke="#fff"
      />
    </Svg>
  );
};

export default SvgComponent;
