import { MemberCommission, MemberPerformance } from './../../api/types';
import {
  fetchMembersActions,
  fetchTopCommissionMembersActions,
  fetchTopPerformingMembersActions,
  MembersActions,
} from '../actions/members';
import produce from 'immer';
import { Reducer } from 'redux';
import { ApiError, NetworkMember } from '../../api/types';
import { HashMap, normalize } from '../../data/normalize';

export interface MembersState {
  readonly topPerformingLoading: boolean;
  readonly topPerformingError?: ApiError;
  readonly topCommissionLoading: boolean;
  readonly topCommissionError?: ApiError;
  readonly loading: boolean;
  readonly error?: ApiError;
  readonly data: {
    entities: HashMap<NetworkMember>;
    ids: number[];
    topPerforming: HashMap<MemberPerformance>;
    topPerformingIds: number[];
    topCommission: HashMap<MemberCommission>;
    topCommissionIds: number[];
    totalCount: number;
  };
}

const initialState: MembersState = {
  topPerformingLoading: false,
  topCommissionLoading: false,
  loading: false,
  data: {
    topPerforming: {},
    topPerformingIds: [],
    topCommission: {},
    topCommissionIds: [],
    entities: {},
    ids: [],
    totalCount: 0,
  },
};

export const membersReducer: Reducer<MembersState, MembersActions> = (
  state = initialState,
  action
) =>
  produce(state, (d) => {
    switch (action.type) {
      // Members
      case fetchMembersActions.types.start:
        d.error = undefined;
        d.loading = true;
        return;
      case fetchMembersActions.types.success:
        const { ids, entities } = normalize<NetworkMember>(
          action.payload.response.data
        );
        d.data.ids = ids;
        d.data.totalCount = action.payload.response.total;
        d.data.entities = { ...state.data.entities, ...entities };
        d.loading = false;
        return;
      case fetchMembersActions.types.failure:
        d.error = action.payload.error;
        d.loading = false;
        return;

      // TopPerforming
      case fetchTopPerformingMembersActions.types.start:
        d.topPerformingError = undefined;
        d.topPerformingLoading = true;
        return;
      case fetchTopPerformingMembersActions.types.success:
        const {
          ids: topPerformingIds,
          entities: topPerformingEntities,
        } = normalize<MemberPerformance>(action.payload.response.data);
        d.data.topPerformingIds = topPerformingIds;
        d.data.topPerforming = {
          ...state.data.topPerforming,
          ...topPerformingEntities,
        };
        d.topPerformingLoading = false;
        return;
      case fetchTopPerformingMembersActions.types.failure:
        d.topPerformingError = action.payload.error;
        d.topPerformingLoading = false;
        return;

      // TopCommission
      case fetchTopCommissionMembersActions.types.start:
        d.topCommissionError = undefined;
        d.topCommissionLoading = true;
        return;
      case fetchTopCommissionMembersActions.types.success:
        const {
          ids: topCommissionIds,
          entities: topCommissionEntities,
        } = normalize<MemberCommission>(action.payload.response.data);
        d.data.topCommissionIds = topCommissionIds;
        d.data.topCommission = {
          ...state.data.topCommission,
          ...topCommissionEntities,
        };
        d.topCommissionLoading = false;
        return;
      case fetchTopCommissionMembersActions.types.failure:
        d.topCommissionError = action.payload.error;
        d.topCommissionLoading = false;
        return;
    }
  });
