import * as React from 'react';
import Svg, { Circle, Ellipse, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Ellipse
        cx={12.368}
        cy={12.321}
        rx={10.368}
        ry={6.221}
        stroke="#717570"
        strokeWidth={1.4}
      />
      <Circle
        cx={12.418}
        cy={12.27}
        r={3.439}
        stroke="#717570"
        strokeWidth={2}
      />
    </Svg>
  );
};

export default SvgComponent;
