import { RouteProp, useRoute } from '@react-navigation/native';
import React, { FC, useCallback, useEffect } from 'react';
import { View, StyleSheet, Text, Dimensions, Image } from 'react-native';
import { PlaceHolderLoader } from '../../components/placeholder-loader/placeholder-loader';
import { RootStackParamList } from '../../navigation/types';
import { spacing } from '../../theme/spacing';
import { useTheme } from '../../hooks/use-theme';
import { Screen } from '../../components/screen/screen';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/redux/reducers';
import { NewsState } from '../../services/redux/reducers/news';
import { fetchNewsDetailsActions } from '../../services/redux/actions/news';
import { WebView } from 'react-native-webview';
import { ApiErrorInfo } from '../../components/api-error-info/api-error-info';

export const NewsDetailScreen: FC = () => {
  const route = useRoute<RouteProp<RootStackParamList, 'NewsDetail'>>();
  const id = route?.params?.id;

  const newsDetail = useSelector<RootState, NewsState>((state) => state.news);
  const {
    detailsLoading: loading,
    detailsError: error,
    detailsData: data,
  } = newsDetail;
  const { theme } = useTheme();
  const { textStyles2 } = theme;

  const dispatch = useDispatch();
  const refetch = useCallback(() => {
    dispatch(fetchNewsDetailsActions.trigger({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const { width } = Dimensions.get('window');

  if (error) {
    return (
      <Screen>
        <View style={styles.container}>
          <ApiErrorInfo error={error} onButtonPress={refetch} />
        </View>
      </Screen>
    );
  }
  return (
    // NOTE: don't use <Screen/LoadingScreen> to avoid having a scrollview here
    //       Only the webview should scroll
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        {loading && <PlaceHolderLoader displayType="NEWS_DETAIL_SCREEN" />}
        {!loading && (
          <>
            {data?.headerImageUrl ? (
              <Image
                source={{ uri: data.headerImageUrl }}
                style={styles.image}
              />
            ) : null}
            <Text style={textStyles2.BodyTTextLeft}>{data?.headline}</Text>
            <Text style={textStyles2.BodySmallTextLeft}>{data?.date}</Text>
            {data?.contentHtml ? (
              <WebView
                originWhitelist={['*']}
                source={{ html: data.contentHtml }}
                textZoom={200}
                scrollEnabled
                // eslint-disable-next-line react-native/no-inline-styles
                style={{
                  width: width - 50,
                  marginTop: 10,
                  flex: 1,
                }}
              />
            ) : null}
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: spacing[2],
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    backgroundColor: 'white',
  },
  innerContainer: {
    alignItems: 'center',
  },
  image: {
    width: Dimensions.get('window').width - 50,
    height: 70,
    marginBottom: 10,
  },
});
