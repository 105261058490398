import axios, { AxiosRequestConfig } from 'axios';
import merge from 'lodash.merge';

import { ApiConfig, ApiResult } from './types';
import { createApiErrorNoNetwork } from './api-error';
import { DEFAULT_API_CONFIG } from './defaults';

interface Options {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const buildUrl = (
  baseURL: string,
  path: string,
  params: Record<string, unknown> | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paramsSerializer: (p: any) => string
) => {
  let url = baseURL + path;

  if (params) {
    url = url + '?' + paramsSerializer(params);
  }

  return url;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isNetworkUnavailableError = (error: any): boolean => {
  // NOTE: relies on the implementation of axios
  // TODO: add tests/improve
  return error && error instanceof Error && error.message === 'Network Error';
};

function sendRequestGenerator<T>(instanceConfig: Partial<ApiConfig>) {
  return async (endpointConfig: Partial<ApiConfig>): Promise<ApiResult<T>> => {
    const config = merge(
      {},
      DEFAULT_API_CONFIG,
      instanceConfig,
      endpointConfig
    );

    const {
      getBaseURL,
      path,
      params,
      paramsSerializer,
      method,
      headers,
    } = config;

    const baseUrl = getBaseURL();
    const url = buildUrl(baseUrl, path, params, paramsSerializer);

    const options: Options = config.transformRequest({
      method,
      headers,
    });

    return sendRequest<T>(url, options, config).catch(async (error) => {
      if (isNetworkUnavailableError(error)) {
        return {
          success: false,
          data: undefined,
          error: createApiErrorNoNetwork(),
        };
      }
      const transformedError = await config.transformError(error.response);

      return {
        success: false,
        data: undefined,
        error: transformedError,
      };
    });
  };
}

async function sendRequest<T>(
  url: string,
  options: Options,
  config: ApiConfig
): Promise<ApiResult<T>> {
  return sendJsonRequest(url, options, config);
}

async function sendJsonRequest<T>(
  url: string,
  options: AxiosRequestConfig,
  config: ApiConfig
): Promise<ApiResult<T>> {
  const response = await axios(url, options);
  const transformedResponse = await config.transformResponse(response);
  return { success: true, data: transformedResponse, error: undefined };
}

export const create = (instanceConfig: Partial<ApiConfig>) => ({
  sendRequest: sendRequestGenerator(instanceConfig),
  instanceConfig,
});
