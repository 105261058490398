import * as React from 'react';
import Svg, { Rect, Circle, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={39} height={37} viewBox="0 0 39 37" fill="none" {...props}>
      <Rect x={0.5} y={3.5} width={38} height={33} rx={6.5} stroke="#D95BB7" />
      <Circle cx={24} cy={14} r={2} fill="#2D9CDB" />
      <Circle cx={16} cy={28} r={2} fill="#2D9CDB" />
      <Circle cx={24} cy={21} r={2} fill="#2D9CDB" />
      <Circle cx={16} cy={14} r={2} fill="#2D9CDB" />
      <Circle cx={8} cy={28} r={2} fill="#2D9CDB" />
      <Circle cx={16} cy={21} r={2} fill="#2D9CDB" />
      <Circle cx={8} cy={21} r={2} fill="#2D9CDB" />
      <Circle cx={31} cy={14} r={2} fill="#2D9CDB" />
      <Circle cx={23} cy={28} r={2} fill="#2D9CDB" />
      <Circle cx={31} cy={21} r={2} fill="#2D9CDB" />
      <Rect x={8} width={2} height={7} rx={1} fill="#2D9CDB" />
      <Rect x={19} width={2} height={7} rx={1} fill="#2D9CDB" />
      <Rect x={30} width={2} height={7} rx={1} fill="#2D9CDB" />
    </Svg>
  );
};

export default SvgComponent;
