import * as React from 'react';
import Svg, { Rect, Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={26} height={19} viewBox="0 0 26 19" fill="none" {...props}>
      <Rect
        x={0.5}
        y={0.5}
        width={25}
        height={18}
        rx={3.5}
        fill="#fff"
        stroke="#003365"
      />
      <Path
        d="M.5 4v0c0-1.584 1.85-2.446 3.063-1.427l8.15 6.846a2 2 0 002.573 0l8.151-6.846C23.65 1.554 25.5 2.416 25.5 4v0"
        stroke="#003365"
      />
    </Svg>
  );
};

export default SvgComponent;
