import { useState, useCallback } from 'react';

export function useQueryParams<TParams>(initialParams: TParams) {
  const [queryParams, setQueryParams] = useState<TParams>(initialParams);
  const setQueryParamsPartial = useCallback(
    (params: Partial<TParams>) => {
      setQueryParams({ ...queryParams, ...params });
    },
    [queryParams]
  );

  return {
    queryParams,
    setQueryParams: setQueryParamsPartial,
  };
}
