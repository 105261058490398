import React, { FC, useMemo } from 'react';
import { Dimensions, StyleSheet } from 'react-native';
import {
  Circle,
  ClipPath,
  Defs,
  G,
  LinearGradient,
  Rect,
  Stop,
  Line as SvgLine,
} from 'react-native-svg';
import { AreaChart, Path, Grid, XAxis, YAxis } from 'react-native-svg-charts';
import { scaleLinear, scaleUtc } from 'd3-scale';
import { useTheme } from '../../hooks/use-theme';
import {
  formatDateDb,
  formatDateStandard,
  shortDate,
  numberOfDays,
  parseDate,
  isBefore,
  isSameDay,
} from '../../utils/format-date';
import { formatCurrency } from '../../utils/format-currency';
import { View } from 'react-native';
import { CommissionsHistory } from '../../services/api/types';

const defaultChartHeight = 100;

const axesSvg = {
  fontSize: 10,
  fill: 'grey',
};

// Config - TODO: consider make it props
const xAxisHeight = 30;
const yAxisWidth = 50;

const yScale = scaleLinear;
const xScale = scaleUtc; // scaleLinear | scaleTime | scaleUtc

const chartVerticalInset = 12;
const chartHorizontalInset = 24;

const topStopOpacity = 0.6;
const bottomStopOpacity = 0;
const gradientY2 = '100%';
const noOffset = '0%';
const bottomStopOffset = '100%';

export type XAxisUnit = 'MONTHS' | undefined;

export interface ChartData {
  numberOfDays: number;
  amount: number;
  dateDb: string;
  date: DateType;
}

interface HistoryChartProps {
  startDate: string;
  endDate: string;
  xAxisUnit: XAxisUnit;
  data: CommissionsHistory[];
  height?: number;
  showGradient?: boolean;
  showDashedLine?: boolean;
  showVerticalGrid?: boolean;
  showHorizontalGrid?: boolean;
  showPoints?: boolean;
  showYAxis?: boolean;
  maxWidth?: number;
}

type DateType = Date | string | undefined;

const buildConvertChartData = (curStartDate: DateType) => {
  const convertChartData = (curDate: DateType, amount: number) => {
    const curNumberOfDays = numberOfDays(curStartDate, curDate);
    const dateDb = formatDateDb(curDate);
    const date = parseDate(curDate);

    return {
      numberOfDays: curNumberOfDays,
      dateDb,
      date,
      amount,
    };
  };
  return convertChartData;
};

const interpolateZeroY = (x1: number, y1: number, x2: number, y2: number) => {
  // y = mx + b
  const m = (y2 - y1) / (x2 - x1);
  const b = y2 - m * x2;
  return b;
};

const useChartData = ({
  startDate,
  endDate,
  data: initialData,
}: Partial<HistoryChartProps>) => {
  return useMemo(() => {
    const curStartDate = formatDateDb(startDate);

    const convertChartData = buildConvertChartData(curStartDate);
    const chartData: ChartData[] = [];

    // NOTE: clone, because this could be state and .shift() will not work
    let data: CommissionsHistory[] = [];
    if (initialData) {
      data = [...initialData];
    }

    // remove all past dates
    let lastChartItem: ChartData | undefined;
    let lastItem: CommissionsHistory | undefined;
    const firstItemIsOnStartDate = isSameDay(data[0]?.date, startDate);
    if (!firstItemIsOnStartDate && data) {
      while (data.length > 0 && isBefore(data[0].date, curStartDate)) {
        lastItem = data.shift();
      }
      if (lastItem) {
        lastChartItem = convertChartData(lastItem.date, lastItem.amount);
      }
    }

    // interpolate amount for the startDate
    let startDateAmount = 0;
    const firstItem =
      data && data[0]
        ? convertChartData(data[0].date, data[0].amount)
        : undefined;
    if (firstItem && !isSameDay(firstItem.date, startDate)) {
      if (lastChartItem) {
        // use last amount from api

        startDateAmount = interpolateZeroY(
          lastChartItem.numberOfDays,
          lastChartItem.amount,
          firstItem.numberOfDays,
          firstItem.amount
        );
      } else {
        // use 0 amount on previous month's 15th
        startDateAmount = interpolateZeroY(
          -15, // TODO: calculate
          0,
          firstItem.numberOfDays,
          firstItem.amount
        );
      }
    }

    chartData.push(convertChartData(curStartDate, startDateAmount));
    const daysToRender = numberOfDays(startDate, endDate);
    const numberOfMonths = daysToRender > 0 ? Math.floor(daysToRender / 30) : 0;
    const todayValue = numberOfDays(startDate, new Date());
    let gridMin = 0;
    let gridMax = 0;

    data &&
      data.forEach((curData) => {
        const curDate2 = formatDateDb(curData.date);
        if (!curDate2) {
          // eslint-disable-next-line no-console
          console.warn('INVALID DATE', curData.date);
        } else {
          chartData.push(convertChartData(curData.date, curData.amount));
          gridMin = Math.min(gridMin, curData.amount);
          gridMax = Math.max(gridMax, curData.amount);
        }
      });
    const lastChartDay = chartData[chartData.length - 1]?.numberOfDays;

    // TODO: calculate
    const firstDaysOfMonths = [0, 31, 62, 90];

    return {
      chartFullData: chartData,
      gridMax,
      gridMin,
      daysToRender,
      numberOfMonths,
      todayValue,
      lastChartDay,
      firstDaysOfMonths,
    };
  }, [startDate, endDate, initialData]);
};

/*@ts-ignore-next-line */
export const CustomVerticalGrid = ({ x, svg, tickValues }) => {
  // NOTE: <Grid direction="VERTICAL" /> is not working
  // console.log('CVerticalGrid  tickValues', tickValues);
  const { theme } = useTheme();
  const { colors } = theme;

  return (
    <G>
      {/*@ts-ignore-next-line */}
      {tickValues.map((curDatum, index) => {
        return (
          <SvgLine
            key={index}
            y1={'0%'}
            y2={'100%'}
            x1={x(curDatum)}
            x2={x(curDatum)}
            strokeWidth={1}
            stroke={colors.chartBorder}
            {...svg}
          />
        );
      })}
    </G>
  );
};

const Gradient = () => {
  const { theme } = useTheme();
  const { colors } = theme;
  return (
    <Defs key={'defs'}>
      <LinearGradient
        id={'gradient'}
        x1={noOffset}
        x2={noOffset}
        y1={noOffset}
        y2={gradientY2}
      >
        <Stop
          offset={noOffset}
          stopColor={colors.chartLine}
          stopOpacity={topStopOpacity}
        />
        <Stop
          offset={bottomStopOffset}
          stopColor={colors.chartLine}
          stopOpacity={bottomStopOpacity}
        />
      </LinearGradient>
    </Defs>
  );
};

/*@ts-ignore-next-line */
const Clips = ({ x, width, todayValue }) => (
  <Defs key={'clips'}>
    <ClipPath id={'clip-path-1'} key={'0'}>
      <Rect x={0} y={'0'} width={x(todayValue)} height={'100%'} />
    </ClipPath>
    <ClipPath id="clip-path-2" key={'1'}>
      <Rect
        x={x(todayValue)}
        y={'0'}
        width={width - x(todayValue)}
        height={'100%'}
      />
    </ClipPath>
  </Defs>
);

/*@ts-ignore-next-line */
const Line = ({ line }) => {
  const { theme } = useTheme();
  const { colors } = theme;
  return (
    <Path
      key={'line'}
      /*@ts-ignore-next-line */
      d={line}
      stroke={colors.chartLine}
      strokeWidth={2}
      fill={'none'}
      clipPath={'url(#clip-path-1)'}
    />
  );
};

/*@ts-ignore-next-line */
const DashedLine = ({ line }) => {
  const { theme } = useTheme();
  const { colors } = theme;
  return (
    <Path
      key={'dashed-line'}
      stroke={colors.chartLine}
      strokeWidth={2}
      /*@ts-ignore-next-line */
      d={line}
      fill={'none'}
      clipPath={'url(#clip-path-2)'}
      strokeDasharray={[2, 2]}
    />
  );
};

/*@ts-ignore-next-line */
const Points = ({ x, y, data: pointsData }) => {
  const { theme } = useTheme();
  const { colors } = theme;
  let lastAmount = 0;
  /*@ts-ignore-next-line */
  return pointsData.map((curData, index) => {
    if (lastAmount !== curData.amount) {
      lastAmount = curData.amount;
      return (
        <Circle
          key={index}
          cx={x(curData.numberOfDays)}
          cy={y(curData.amount) ?? 0}
          r={4}
          stroke={colors.chartLine}
          strokeWidth={1}
          fill={'white'}
          onPress={() =>
            // eslint-disable-next-line no-console
            console.warn(
              `${formatDateStandard(curData.date)} ${formatCurrency(
                curData.amount,
                'EUR'
              )}`
            )
          }
        />
      );
    }
  });
};

export const HistoryChart: FC<HistoryChartProps> = (props) => {
  const {
    startDate,
    endDate,
    data,
    showGradient,
    showDashedLine,
    showVerticalGrid,
    showHorizontalGrid,
    showPoints,
    showYAxis,
    height,
    maxWidth,
  } = props;
  const {
    chartFullData,
    gridMax,
    gridMin,
    daysToRender,
    numberOfMonths,
    // todayValue,
    lastChartDay: todayValue,
    firstDaysOfMonths,
  } = useChartData({
    startDate,
    endDate,
    data,
  });

  const chartHeight = height || defaultChartHeight;
  const windowWidth = Dimensions.get('window').width;

  const maxChartWidth = windowWidth - 50;
  let chartWidth =
    maxWidth && maxWidth > 0
      ? Math.min(maxWidth, maxChartWidth)
      : maxChartWidth;
  chartWidth = showYAxis ? chartWidth - yAxisWidth : chartWidth;

  return (
    <View style={styles.col}>
      <View style={styles.row}>
        {showYAxis && (
          <YAxis<ChartData>
            style={{
              height: chartHeight,
              width: yAxisWidth,
            }}
            scale={yScale}
            data={chartFullData}
            yAccessor={({ item }) => item.amount}
            numberOfTicks={5}
            formatLabel={(value) => value}
            contentInset={{
              top: chartVerticalInset,
              bottom: chartVerticalInset,
            }}
            svg={axesSvg}
            min={gridMin}
            max={gridMax}
          />
        )}
        <AreaChart<ChartData>
          style={{
            height: chartHeight,
            width: chartWidth,
          }}
          data={chartFullData}
          xAccessor={({ item }) => item.numberOfDays}
          yAccessor={({ item }) => item.amount}
          // gridMin={0}
          // gridMax={daysToRender}
          xMin={0}
          xMax={daysToRender}
          yMin={gridMin}
          yMax={gridMax}
          yScale={yScale}
          xScale={xScale}
          // numberOfTicks={3} // NOTE: yAxis only
          contentInset={{
            top: chartVerticalInset,
            bottom: chartVerticalInset,
            left: chartHorizontalInset,
            right: chartHorizontalInset,
          }}
          svg={{
            fill: 'url(#gradient)',
            clipPath: 'url(#clip-path-1)',
          }}
        >
          {showGradient && <Gradient />}
          <Clips width={'100%'} x={0} todayValue={todayValue} />
          {/*@ts-ignore-next-line */}
          <Line />
          {/*@ts-ignore-next-line */}
          {showDashedLine && <DashedLine />}

          {showHorizontalGrid && <Grid<ChartData> direction="HORIZONTAL" />}

          {showVerticalGrid && (
            <CustomVerticalGrid
              tickValues={firstDaysOfMonths}
              svg={axesSvg}
              x={undefined}
            />
          )}
          {/*@ts-ignore-next-line */}
          {showPoints && <Points />}
        </AreaChart>
      </View>
      <View style={styles.row}>
        <XAxis<ChartData>
          style={{
            height: xAxisHeight,
            width: chartWidth,
          }}
          data={chartFullData}
          xAccessor={({ item }) => item.date}
          scale={xScale}
          numberOfTicks={numberOfMonths}
          formatLabel={(date) => shortDate(date)}
          contentInset={{
            left: chartHorizontalInset,
            right: chartHorizontalInset,
          }}
          svg={axesSvg}
          /* @ts-ignore-next-line */
          min={parseDate(startDate)}
          max={parseDate(endDate)}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingBottom: 10,
  },
  col: {
    flexDirection: 'column',
    // alignItems: 'flex-end',
    // justifyContent: 'flex-end',
  },
});
