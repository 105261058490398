import React from 'react';
import { storiesOf } from '@storybook/react-native';
import { ApiErrorInfo } from './api-error-info';
import {
  createApiErrorBadResponse,
  createApiErrorNoNetwork,
  createApiErrorUnknown,
} from '../../services/api/api-error';

storiesOf('ApiErrorInfo', module)
  .add('No Network Error', () => {
    return <ApiErrorInfo error={createApiErrorNoNetwork()} />;
  })
  .add('No Network Error with reload', () => {
    return (
      <ApiErrorInfo
        error={createApiErrorNoNetwork()}
        onButtonPress={() => {}}
      />
    );
  })
  .add('Bad Response Error', () => {
    return <ApiErrorInfo error={createApiErrorBadResponse()} />;
  })
  .add('Unknown Error', () => {
    return <ApiErrorInfo error={createApiErrorUnknown()} />;
  });
