import * as React from 'react';
import Svg, { Rect, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={25} height={20} viewBox="0 0 25 20" fill="none" {...props}>
      <Rect width={25} height={9} rx={2} fill="#D95BB7" />
      <Rect y={11} width={25} height={9} rx={2} fill="#D95BB7" />
    </Svg>
  );
};

export default SvgComponent;
