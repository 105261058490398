import { CommissionType } from '../../services/api/types';

export const dummyDataCommissionHistory = {
  total: 11,
  data: [
    { date: '2020-11-15 00:00:00', amount: 418.34 },
    { date: '2020-11-25 00:00:00', amount: 991.96 },
    { date: '2020-12-14 00:00:00', amount: 439.34 },
    { date: '2020-12-15 00:00:00', amount: 438.34 },
    { date: '2020-12-21 00:00:00', amount: 799.66 },
    { date: '2020-12-28 00:00:00', amount: 443.45 },
    { date: '2021-01-04 00:00:00', amount: 794.15 },
    { date: '2021-01-15 00:00:00', amount: 951.72 },
    { date: '2021-02-04 00:00:00', amount: 794.15 },
    { date: '2021-02-15 00:00:00', amount: 951.72 },
    { date: '2021-02-21 00:00:00', amount: 799.66 },
  ],
};

export const dummyDataNetworkHistory = {
  total: 11,
  data: [
    { date: '2020-11-15 00:00:00', left: 100, right: 80 },
    { date: '2020-11-25 00:00:00', left: 100, right: 79 },
    { date: '2020-12-07 00:00:00', left: 102, right: 79 },
    { date: '2020-12-15 00:00:00', left: 105, right: 80 },
    { date: '2020-12-25 00:00:00', left: 109, right: 79 },
    { date: '2021-01-07 00:00:00', left: 117, right: 79 },
    { date: '2021-01-15 00:00:00', left: 126, right: 80 },
    { date: '2021-02-07 00:00:00', left: 130, right: 79 },
    { date: '2021-02-25 00:00:00', left: 132, right: 78 },
  ],
};

export const dummyDataCommissionDetails = {
  total: 20,
  data: [
    {
      amount: 463.82,
      calculateDate: '2021-12-15',
      status: 'confirmed',
      type: 'rankAchievementBonus' as CommissionType,
    },
    {
      amount: 860.16,
      calculateDate: '2021-11-15',
      status: 'confirmed',
      type: 'matchingBonus' as CommissionType,
    },
    {
      amount: 906.37,
      calculateDate: '2021-11-15',
      status: 'confirmed',
      type: 'mobilityBonus' as CommissionType,
    },
    {
      amount: 365.72,
      calculateDate: '2021-10-15',
      status: 'confirmed',
      type: 'rankAchievementBonus' as CommissionType,
    },
    {
      amount: 612.65,
      calculateDate: '2021-02-22',
      status: 'pending',
      type: 'directBonus' as CommissionType,
    },
    {
      amount: 175.96,
      calculateDate: '2021-02-15',
      status: 'confirmed',
      type: 'directBonus' as CommissionType,
    },
    {
      amount: 64.65,
      calculateDate: '2021-02-12',
      status: 'confirmed',
      type: 'dualBonus' as CommissionType,
    },
    {
      amount: 51.97,
      calculateDate: '2021-02-12',
      status: 'confirmed',
      type: 'matchingBonus' as CommissionType,
    },
    {
      amount: 939.86,
      calculateDate: '2021-02-12',
      status: 'confirmed',
      type: 'mobilityBonus' as CommissionType,
    },
    {
      amount: 442.79,
      calculateDate: '2021-02-08',
      status: 'confirmed',
      type: 'directBonus' as CommissionType,
    },
    {
      amount: 180.74,
      calculateDate: '2021-02-01',
      status: 'confirmed',
      type: 'directBonus' as CommissionType,
    },
    {
      amount: 638,
      calculateDate: '2021-01-25',
      status: 'confirmed',
      type: 'directBonus' as CommissionType,
    },
    {
      amount: 254.62,
      calculateDate: '2021-01-18',
      status: 'confirmed',
      type: 'directBonus' as CommissionType,
    },
    {
      amount: 454.4,
      calculateDate: '2021-01-15',
      status: 'confirmed',
      type: 'dualBonus' as CommissionType,
    },
    {
      amount: 302.29,
      calculateDate: '2021-01-15',
      status: 'confirmed',
      type: 'matchingBonus' as CommissionType,
    },
    {
      amount: 612.77,
      calculateDate: '2021-01-15',
      status: 'confirmed',
      type: 'mobilityBonus' as CommissionType,
    },
    {
      amount: 766.69,
      calculateDate: '2021-01-15',
      status: 'confirmed',
      type: 'rankAchievementBonus' as CommissionType,
    },
    {
      amount: 206.73,
      calculateDate: '2021-01-15',
      status: 'confirmed',
      type: 'rankAchievementBonus' as CommissionType,
    },
    {
      amount: 817.07,
      calculateDate: '2021-01-11',
      status: 'confirmed',
      type: 'directBonus' as CommissionType,
    },
    {
      amount: 673.47,
      calculateDate: '2021-01-04',
      status: 'confirmed',
      type: 'directBonus' as CommissionType,
    },
  ],
};
