import { RouteProp, useRoute } from '@react-navigation/native';
import React, { FC } from 'react';

import { TabViewBar } from '../../components/tab-view-bar/tab-view-bar';
import { RootStackParamList } from '../../navigation/types';
import { CommissionsIncomeReportScreen } from './commissions-income-report';
import { CommissionsListScreen } from './commissions-list-screen';
import { CommissionsTaxStatementScreen } from './commissions-tax-statement-screen';

export const CommissionsScreen: FC = () => {
  const route = useRoute<RouteProp<RootStackParamList, 'MembersOverview'>>();
  const props = {
    selectedTabIndex: route?.params?.selectedTabIndex || 0,
    config: [
      {
        key: 'commissionsList',
        title: 'List',
        component: CommissionsListScreen,
      },
      {
        key: 'commissionsReport',
        title: 'Report',
        component: CommissionsIncomeReportScreen,
      },
      {
        key: 'commissionsTaxStatement',
        title: 'Tax',
        component: CommissionsTaxStatementScreen,
      },
    ],
  };

  return <TabViewBar {...props} />;
};
