import {
  ApiAuthToken,
  PostAuthTokenParams,
  PostAuthTokenResponse,
} from './../../api/types';
import { createAction } from '../create-action';
import { createApiActions, ExtractActionTypes } from '../create-api-actions';

export const clearUserAuthTokenAction = createAction('CLEAR_USER_AUTH_TOKEN')();

export const doLoginUserAction = createAction('DO_LOGIN_USER')<ApiAuthToken>();
export const doLogoutUserAction = createAction('DO_LOGOUT_USER')();

export const doLoginActions = createApiActions(
  'DO_LOGIN_TRIGGER',
  'DO_LOGIN_START',
  'DO_LOGIN_SUCCESS',
  'DO_LOGIN_FAILURE'
)<PostAuthTokenParams, PostAuthTokenResponse>();

export const setUserAuthTokenAction = createAction('SET_USER_AUTH_TOKEN')<
  ApiAuthToken
>();

export type AuthActions =
  | ReturnType<typeof setUserAuthTokenAction>
  | ExtractActionTypes<typeof doLoginActions>
  | ReturnType<typeof clearUserAuthTokenAction>
  | ReturnType<typeof doLoginUserAction>
  | ReturnType<typeof doLogoutUserAction>;
