import React, { FC } from 'react';
import { Text, StyleSheet, View } from 'react-native';

import { spacing } from '../../theme/spacing';
import {
  DASHBOARD_TILE_FIRST_COLUMN_WIDTH,
  DASHBOARD_TILE_HEIGHT_XXL,
  DASHBOARD_TILE_WIDTH,
} from '../../theme/fixed-sizes';
import { useTheme } from '../../hooks/use-theme';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../navigation/types';
import { DashboardTileLoading } from '../../components/dashboard-tile-loading/dashboard-tile-loading';
import { Currency } from '../../components/currency/currency';
import { Table, TableConfigItem } from '../../components/table/table';

interface MonthlyGoal {
  title: string;
  amount: number;
}

const TABLE_CONFIG: TableConfigItem<MonthlyGoal>[] = [
  {
    width: 260,
    render: (goal) => goal.title,
  },
  { width: 60, align: 'right', render: (goal) => goal.amount.toString() },
];

export const DashboardCommissionsGoal: FC = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const data = [{}];
  const error = undefined;
  const loading = false;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const refetch = () => {};

  const { theme } = useTheme();
  const { textStyles } = theme;

  const getGoalButtonConfig = () => {
    return [
      {
        onPress: () => {
          navigation.navigate('Wip', {
            headline: 'Monthly Goal',
            bodyText: 'Set new goal',
          });
        },
        title: 'Set New Goal',
      },
    ];
  };

  const fakeValue = 2600; //TODO: get real data from api
  const goalData = [
    {
      title: 'New Members',
      amount: 12,
    },
    {
      title: 'New Hardware Devices',
      amount: 6,
    },
    {
      title: 'New Subscriptions',
      amount: 3,
    },
    {
      title: 'New Bundles',
      amount: 3,
    },
  ];

  return (
    <DashboardTileLoading
      loading={loading}
      error={error}
      data={data}
      refetch={refetch}
      style={styles.container}
      icon={'flag'}
      buttons={getGoalButtonConfig()}
      theme={theme}
      placeholderLoaderType="DASHBOARD_TOTAL_COMMISSIONS"
      showBadge={'static-data'}
    >
      <Text style={[styles.headlineMargin, textStyles.H3Left]}>
        Monthly Goal
      </Text>
      <View style={styles.content}>
        <Table<MonthlyGoal>
          data={goalData}
          config={TABLE_CONFIG}
          theme={theme}
        />

        <View style={styles.currencyContainer}>
          <Text style={textStyles.BodyTextLeft}>Estimated commissions:</Text>
          <Currency
            amount={fakeValue}
            currency={'EUR'}
            textStyle={textStyles.CurrencyRight}
            symbolPosition={'right'}
          />
        </View>
      </View>
    </DashboardTileLoading>
  );
};

const styles = StyleSheet.create({
  container: {
    height: DASHBOARD_TILE_HEIGHT_XXL,
    width: DASHBOARD_TILE_WIDTH,
  },
  headlineMargin: {
    marginBottom: spacing[2],
  },
  currencyContainer: {
    marginTop: spacing[3],
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  content: {
    marginLeft: -DASHBOARD_TILE_FIRST_COLUMN_WIDTH,
  },
});
