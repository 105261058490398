import { fetchNewsDetailsActions as fetchNewsDetailActions } from './../actions/news';
import { takeEvery } from 'redux-saga/effects';
import { ApiType } from '../../api';
import { fetchLatestNewsActions } from '../actions/news';
import { callApiEndpoint } from './api';

export const watchFetchLatestNews = (Api: ApiType) =>
  takeEvery(fetchLatestNewsActions.types.trigger, fetchLatestNews, Api);

function* fetchLatestNews(
  Api: ApiType,
  _: ReturnType<typeof fetchLatestNewsActions.trigger>
) {
  yield callApiEndpoint(
    fetchLatestNewsActions,
    Api.getLatestNews,
    undefined,
    Api
  );
}

export const watchFetchNewsDetail = (Api: ApiType) =>
  takeEvery(fetchNewsDetailActions.types.trigger, fetchNewsDetail, Api);

function* fetchNewsDetail(
  Api: ApiType,
  action: ReturnType<typeof fetchNewsDetailActions.trigger>
) {
  yield callApiEndpoint(
    fetchNewsDetailActions,
    Api.getNewsDetail,
    { id: action.payload.params.id },
    Api
  );
}
