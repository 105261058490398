import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FC, useEffect, useCallback, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { PlaceHolderLoader } from '../../components/placeholder-loader/placeholder-loader';
import { RootStackParamList } from '../../navigation/types';
import { Message } from '../../services/api/types';
import { fetchMessagesActions } from '../../services/redux/actions/messages';
import { RootState } from '../../services/redux/reducers';
import { MessagesState } from '../../services/redux/reducers/messages';

import { PaginationBar } from '../../components/pagination-bar/pagination-bar';
import {
  Table,
  TableConfigItem,
  TableSortConfig,
} from '../../components/table/table';
import { formatDateStandard } from '../../utils/format-date';
import { usePagination } from '../../hooks/use-pagination';
import { useTheme } from '../../hooks/use-theme';
import { spacing } from '../../theme/spacing';
import {
  getTableDefaultHeight,
  getTableDefaultWidth,
  TABLE_COLUMN_WIDTH_S,
} from '../../theme/fixed-sizes';
import { LoadingScreen } from '../../components/loading-screen/loading-screen';

const MESSAGES_TABLE_CONFIG: TableConfigItem<Message>[] = [
  {
    header: 'Date',
    sortField: 'date',
    width: TABLE_COLUMN_WIDTH_S,

    render: (message) => formatDateStandard(message.date),
  },
  {
    header: 'From',
    sortField: 'senderUsername',
    width: TABLE_COLUMN_WIDTH_S,
    render: (message) => message.senderUsername,
  },
  {
    header: 'Subject',
    sortField: 'headline',
    width: 200,
    render: (message) => message.headline,
  },
  {
    header: 'Status',
    sortField: 'status',
    width: TABLE_COLUMN_WIDTH_S,
    render: (message) => message.status,
  },
];

export const MessagesScreen: FC = () => {
  const messagesSelector = useSelector<RootState, MessagesState>(
    (state) => state.messages
  );
  const {
    loading,
    error,
    data,
    entities,
    queryParams,
    onPaginate,
    onChangePageSize,
    onChangeSort,
  } = usePagination<Message>(messagesSelector, 'date', 'desc');

  const dispatch = useDispatch();
  const refetch = useCallback(() => {
    dispatch(fetchMessagesActions.trigger(queryParams));
  }, [dispatch, queryParams]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const { theme } = useTheme();

  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const tableSortConfig: TableSortConfig = useMemo(() => {
    return {
      default: {
        sortBy: queryParams.sortBy,
        sortOrder: queryParams.sortOrder,
      },
      onChangeSort,
    };
  }, [onChangeSort, queryParams.sortBy, queryParams.sortOrder]);

  const navigateToMessage = (message: Message) => {
    navigation.navigate('Wip', {
      headline: message.headline,
      bodyText: message.message,
    });
  };

  return (
    <LoadingScreen
      loading={loading}
      error={error}
      refetch={refetch}
      placeholderLoaderType="MESSAGES_SCREEN"
    >
      <View style={styles.container}>
        <View style={styles.innerContainer}>
          {loading && <PlaceHolderLoader displayType="MESSAGES_SCREEN" />}
          {!loading && (
            <Table<Message>
              data={entities}
              onRowPress={navigateToMessage}
              config={MESSAGES_TABLE_CONFIG}
              horizontalScroll
              theme={theme}
              sortConfig={tableSortConfig}
            />
          )}
        </View>
        <View style={styles.bottomBar}>
          <PaginationBar
            page={queryParams.page}
            pageSize={queryParams.pageSize}
            sliceSize={5}
            totalCount={data.totalCount || 0}
            onPaginate={onPaginate}
            onChangePageSize={onChangePageSize}
            theme={theme}
          />
        </View>
      </View>
    </LoadingScreen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: spacing[2],
    justifyContent: 'center',
  },
  innerContainer: {
    width: getTableDefaultWidth(),
    minHeight: getTableDefaultHeight(),
    alignItems: 'center',
  },
  bottomBar: {
    flex: 1,
  },
});
