import React, { FC, useCallback, useEffect } from 'react';
import { Text, StyleSheet, View } from 'react-native';

import { spacing } from '../../theme/spacing';
import {
  DASHBOARD_TILE_FIRST_COLUMN_WIDTH,
  DASHBOARD_TILE_HEIGHT_XL,
  DASHBOARD_TILE_WIDTH,
} from '../../theme/fixed-sizes';
import { useTheme } from '../../hooks/use-theme';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../navigation/types';
import { DashboardTileLoading } from '../../components/dashboard-tile-loading/dashboard-tile-loading';
import { HistoryChart } from '../../components/history-chart/history-chart';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCommissionsHistoryActions } from '../../services/redux/actions/commissions';
import { RootState } from '../../services/redux/reducers';
import { CommissionsState } from '../../services/redux/reducers/commissions';
import { getPreviousThreeMonths } from '../../utils/format-date';

export const NetworkCommissionsHistory: FC = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const commissionsSelector = useSelector<RootState, CommissionsState>(
    (state) => state.commissions
  );
  const {
    historyLoading: loading,
    historyError: error,
    historyData: data,
  } = commissionsSelector;
  const { startDate, endDate } = getPreviousThreeMonths(new Date());
  const { theme } = useTheme();
  const { textStyles } = theme;

  const dispatch = useDispatch();
  const refetch = useCallback(() => {
    dispatch(
      fetchCommissionsHistoryActions.trigger({
        startDate,
        endDate,
        bonusType: 'monthly',
      })
    );
  }, [dispatch, startDate, endDate]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const getHistoryButtonConfig = () => {
    return [
      {
        onPress: () => {
          navigation.navigate('CommissionsHistory');
        },
        title: 'Show history details',
      },
    ];
  };

  return (
    <DashboardTileLoading
      loading={loading}
      error={error}
      data={data}
      refetch={refetch}
      style={styles.container}
      icon={'time'}
      buttons={getHistoryButtonConfig()}
      theme={theme}
      placeholderLoaderType="DASHBOARD_TOTAL_COMMISSIONS"
    >
      <Text style={[styles.margin, textStyles.H3Left]}>
        Monthly Commissions History
      </Text>
      <View style={styles.chartContainer}>
        <HistoryChart
          startDate={startDate}
          endDate={endDate}
          xAxisUnit={'MONTHS'}
          data={data}
          showGradient
          showVerticalGrid
          maxWidth={DASHBOARD_TILE_WIDTH - 20}
        />
      </View>
    </DashboardTileLoading>
  );
};

const styles = StyleSheet.create({
  container: {
    width: DASHBOARD_TILE_WIDTH,
    minHeight: DASHBOARD_TILE_HEIGHT_XL,
  },
  margin: {
    marginBottom: spacing[2],
  },
  chartContainer: {
    marginLeft: -DASHBOARD_TILE_FIRST_COLUMN_WIDTH,
    marginTop: 20,
    alignItems: 'center',
  },
});
