import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={40} height={28} viewBox="0 0 40 28" fill="none" {...props}>
      <Path
        d="M23.596 16.493c0 .708-.265 1.294-.797 1.758-.53.458-1.273.747-2.226.867v1.812h-1.008v-1.742a10.6 10.6 0 01-1.695-.133c-.547-.093-.995-.221-1.344-.383v-1.218c.432.192.93.351 1.492.476.568.12 1.084.18 1.547.18v-3.438c-1.068-.338-1.818-.731-2.25-1.18-.427-.447-.64-1.025-.64-1.734 0-.682.262-1.242.788-1.68.532-.437 1.232-.702 2.102-.796V7.86h1.008v1.406a7.93 7.93 0 012.773.578l-.406 1.024a7.933 7.933 0 00-2.367-.547v3.39c.817.261 1.43.516 1.836.766.406.245.706.529.898.852.193.317.29.706.29 1.164zm-1.328.101c0-.375-.117-.677-.351-.906-.23-.234-.678-.466-1.344-.695v3.039c1.13-.156 1.695-.636 1.695-1.438zm-4.273-4.851c0 .396.117.713.351.953.235.24.64.466 1.219.68v-3.024c-.516.083-.906.248-1.172.492a1.16 1.16 0 00-.398.899z"
        fill="#2D9CDB"
      />
      <Path
        d="M36.731 14.243l-3.298 5.939a.5.5 0 01-.875-.001l-3.288-5.938a.5.5 0 01.438-.742h6.587a.5.5 0 01.436.742zM3.261 13.766l3.3-5.938a.5.5 0 01.874 0l3.288 5.939a.5.5 0 01-.438.742H3.7a.5.5 0 01-.438-.743z"
        fill="#fff"
        stroke="#D95BB7"
      />
      <Path
        d="M33.002 14c0-7.18-5.82-13-13-13m0 26c-7.18 0-13-5.82-13-13"
        stroke="#D95BB7"
      />
    </Svg>
  );
};

export default SvgComponent;
