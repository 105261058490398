import React, { FC } from 'react';
import { Text, TouchableOpacity, StyleSheet, TextStyle } from 'react-native';
import { spacing } from '../../theme/spacing';
import { ThemeConfig } from '../../hooks/use-theme';

interface ButtonProps {
  text: string;
  style?: TextStyle;
  onPress: () => void;
  theme: ThemeConfig;
}

export const Button: FC<ButtonProps> = (props) => {
  const { text, onPress, style, theme } = props;
  const { textStyles } = theme;
  return (
    <TouchableOpacity
      onPress={onPress}
      style={style ? [style, styles.container] : styles.container}
    >
      <Text style={textStyles.BodyTextLinkLeft} numberOfLines={1}>
        {text}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: spacing[2],
  },
});
