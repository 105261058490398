import React, { FC, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserActions } from '../../services/redux/actions/user-info';
import { RootState } from '../../services/redux/reducers';
import { UserState } from '../../services/redux/reducers/user-info';

import { spacing } from '../../theme/spacing';
import {
  DASHBOARD_TILE_HEIGHT_MEDIUM,
  DASHBOARD_TILE_WIDTH,
} from '../../theme/fixed-sizes';
import { ThemeConfig, useTheme } from '../../hooks/use-theme';
import { SvgImage } from '../../components/svg-image/svg-image';
import { RootStackParamList } from '../../navigation/types';
import { DashboardTileLoading } from '../../components/dashboard-tile-loading/dashboard-tile-loading';

const LinkItem: FC<{
  theme: ThemeConfig;
  title: string;
  onPress: () => void;
}> = (props) => {
  const { theme, title, onPress } = props;
  const { textStyles } = theme;

  return (
    <Pressable onPress={onPress} style={styles.row}>
      <Text style={textStyles.BodyTextLeft}>{title}</Text>
      <SvgImage
        type={'share'}
        width={'20'}
        height={'20'}
        variant={theme.name}
        style={styles.svgImage}
      />
    </Pressable>
  );
};

export const DashboardReferralLinks: FC = () => {
  const user = useSelector<RootState, UserState>((state) => state.user);
  const { loading, error, data } = user;
  const { theme } = useTheme();
  const { textStyles } = theme;

  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const dispatch = useDispatch();
  const refetch = () => {
    dispatch(fetchUserActions.trigger(undefined));
  };

  const buildOnPress = (
    sharedItem: string,
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    curNavigation: any
  ) => {
    return () => {
      curNavigation.navigate('Modal', {
        screen: 'ShareUrl',
        params: { url, sharedItem },
      });
    };
  };
  const referralLinks = useMemo(() => {
    return data ? data.referralLinks : undefined;
  }, [data]);

  return (
    <DashboardTileLoading
      loading={loading}
      error={error}
      data={data}
      refetch={refetch}
      style={styles.container}
      icon="human-share"
      theme={theme}
      placeholderLoaderType="DASHBOARD_REFERRAL_LINKS"
    >
      {referralLinks && (
        <>
          <Text style={[styles.margin, textStyles.H3Left]}>
            Share your sponsor link
          </Text>
          <View style={styles.table}>
            <View style={styles.row}>
              <View style={styles.col}>
                <LinkItem
                  theme={theme}
                  title={'Healy World'}
                  onPress={buildOnPress(
                    'website',
                    referralLinks.website,
                    navigation
                  )}
                />
              </View>
              <View style={styles.col}>
                <LinkItem
                  theme={theme}
                  title={'Roadshow'}
                  onPress={buildOnPress(
                    'roadshow',
                    referralLinks.roadshow,
                    navigation
                  )}
                />
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.col}>
                <LinkItem
                  theme={theme}
                  title={'Healy Shop'}
                  onPress={buildOnPress('shop', referralLinks.shop, navigation)}
                />
              </View>
              <View style={styles.col}>
                <LinkItem
                  theme={theme}
                  title={'Registration'}
                  onPress={buildOnPress(
                    'registration',
                    referralLinks.registration,
                    navigation
                  )}
                />
              </View>
            </View>
          </View>
        </>
      )}
    </DashboardTileLoading>
  );
};

const styles = StyleSheet.create({
  container: {
    height: DASHBOARD_TILE_HEIGHT_MEDIUM,
    width: DASHBOARD_TILE_WIDTH,
  },
  table: {
    marginTop: spacing[2],
    flexDirection: 'column',
  },
  col: {
    flex: 1,
    flexDirection: 'row',
  },
  row: {
    flexDirection: 'row',
    paddingBottom: spacing[1],
  },
  margin: {
    paddingBottom: spacing[1],
  },
  svgImage: {
    paddingLeft: spacing[1],
    marginTop: -4, // TODO: fixme
  },
});
