import React, { FC } from 'react';
import { View, Text, StyleSheet, ViewStyle, StyleProp } from 'react-native';
import { spacing } from '../../theme/spacing';
import { ThemeConfig } from '../../hooks/use-theme';
import { useSmallScreen } from '../../hooks/use-small-screen';

interface DashboardGroupProps {
  textLeft?: string;
  textRight?: string;
  style?: StyleProp<ViewStyle>;
  theme: ThemeConfig;
}

export const DashboardGroup: FC<DashboardGroupProps> = (props) => {
  const { textLeft, textRight, children, style, theme } = props;
  const { textStyles } = theme;
  const { isSmallScreen } = useSmallScreen();

  const textStyle = isSmallScreen() ? textStyles.H1LeftBold : textStyles.H1Left;

  return (
    <View style={style ? [style, styles.container] : styles.container}>
      {textLeft || textRight ? (
        <View style={styles.textLeftContainer}>
          {textLeft ? <Text style={textStyle}>{textLeft}</Text> : null}
          {textRight ? (
            <Text style={textStyles.BodyTextRight}>{textRight}</Text>
          ) : null}
        </View>
      ) : null}
      <View style={styles.childrenContainer}>{children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexShrink: 1,
  },
  textLeftContainer: {
    paddingTop: spacing[4],
    paddingBottom: spacing[3],
    paddingLeft: spacing[2],
  },
  childrenContainer: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
});
