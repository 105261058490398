import * as React from 'react';
import Svg, { Path, Circle, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={41} height={18} viewBox="0 0 41 18" fill="none" {...props}>
      <Path
        d="M18.61 6.96l4.694 2.65a.5.5 0 010 .871l-4.694 2.651a.5.5 0 01-.746-.435V7.395a.5.5 0 01.746-.435z"
        stroke="#2D9CDB"
      />
      <Circle cx={7} cy={5} r={4.5} stroke="#D95BB7" />
      <Path d="M13 18a6 6 0 00-12 0M9 5a2 2 0 11-4 0" stroke="#D95BB7" />
      <Circle cx={34} cy={5} r={4.5} stroke="#D95BB7" />
      <Path d="M40 18a6 6 0 00-12 0M36 5a2 2 0 11-4 0" stroke="#D95BB7" />
    </Svg>
  );
};

export default SvgComponent;
