import React, { FC } from 'react';
import { Text, StyleSheet, View } from 'react-native';

import { spacing } from '../../theme/spacing';
import { useTheme } from '../../hooks/use-theme';
import { dummyDataNetworkHistory } from '../../components/history-chart/demo-data';
import { LoadingScreen } from '../../components/loading-screen/loading-screen';
import { MultiHistoryChart } from '../../components/history-chart/multi-history-chart';

const chartStartDate = '2020-12-01';
const chartEndDate = '2021-03-01';

export const MembersHistoryScreen: FC = () => {
  const { theme } = useTheme();
  const { textStyles } = theme;

  // NOTE: TODO: implement
  const loading = false;
  const error = undefined;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const refetch = () => {};

  return (
    <LoadingScreen
      loading={loading}
      error={error}
      refetch={refetch}
      placeholderLoaderType="MESSAGES_SCREEN"
    >
      <View style={styles.container}>
        <Text style={[styles.margin, textStyles.H3Left]}>
          History of my network
        </Text>
        <View style={styles.chartContainer}>
          <MultiHistoryChart
            startDate={chartStartDate}
            endDate={chartEndDate}
            height={360}
            xAxisUnit={'MONTHS'}
            data={dummyDataNetworkHistory.data}
            showGradient
            showVerticalGrid
            showDashedLine
            showHorizontalGrid
            showYAxis
            showPoints
          />
        </View>
      </View>
    </LoadingScreen>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: spacing[2],
    paddingHorizontal: spacing[3],
    justifyContent: 'center',
    alignItems: 'center',
  },
  margin: {
    marginBottom: spacing[2],
  },
  chartContainer: {
    marginTop: 20,
    marginHorizontal: spacing[3],
  },
});
