import {
  watchFetchNetworkAggregatedDownline,
  watchFetchNetworkDirectSponsored,
} from './network';
import {
  watchLoginUser,
  watchDoLogoutUser,
  watchLoginUserSuccess,
} from './auth';
import { watchFetchLatestSubscriptions } from './subscriptions';
import { watchFetchLatestOrders, watchFetchOrders } from './orders';
import { all } from 'redux-saga/effects';
import { watchFetchUser } from './user-info';
import { StoreDependencies } from './../configure-store';
import {
  watchFetchCommissionDetails,
  watchFetchCommissionsHistory,
  watchFetchCommissionsPerformance,
  watchFetchLatestCommissions,
} from './commissions';
import { watchFetchUnplacedMembersCount } from './unplaced-member-count';
import {
  watchFetchMembers,
  watchFetchTopCommissionMembers,
  watchFetchTopPerformingMembers,
} from './members';
import {
  watchFetchLatestSupportTickets,
  watchFetchOpenSupportTicketsCount,
} from './support-tickets';
import {
  watchFetchLatestMessages,
  watchFetchMessages,
  watchFetchUnreadMessagesCount,
} from './messages';
import { watchFetchLatestNews, watchFetchNewsDetail } from './news';
import { watchFetchLatestPayouts, watchFetchPayouts } from './payouts';

export function createRootSaga(dependencies: StoreDependencies) {
  const { Api } = dependencies;
  return function* rootSaga() {
    yield all([watchFetchUser(Api)]);
    yield all([watchFetchCommissionDetails(Api)]);
    yield all([watchFetchUnplacedMembersCount(Api)]);
    yield all([watchFetchLatestNews(Api)]);
    yield all([watchFetchNewsDetail(Api)]);
    yield all([watchFetchLatestMessages(Api)]);
    yield all([watchFetchMessages(Api)]);
    yield all([watchFetchUnreadMessagesCount(Api)]);
    yield all([watchFetchMembers(Api)]);
    yield all([watchFetchTopCommissionMembers(Api)]);
    yield all([watchFetchTopPerformingMembers(Api)]);
    yield all([watchFetchLatestSupportTickets(Api)]);
    yield all([watchFetchOpenSupportTicketsCount(Api)]);
    yield all([watchFetchLatestPayouts(Api)]);
    yield all([watchFetchPayouts(Api)]);
    yield all([watchFetchLatestOrders(Api)]);
    yield all([watchFetchOrders(Api)]);
    yield all([watchFetchLatestSubscriptions(Api)]);
    yield all([watchLoginUser(Api)]);
    yield all([watchLoginUserSuccess(Api)]);
    yield all([watchDoLogoutUser()]);
    yield all([watchFetchCommissionsHistory(Api)]);
    yield all([watchFetchNetworkAggregatedDownline(Api)]);
    yield all([watchFetchNetworkDirectSponsored(Api)]);
    yield all([watchFetchCommissionDetails(Api)]);
    yield all([watchFetchLatestCommissions(Api)]);
    yield all([watchFetchCommissionsPerformance(Api)]);
  };
}
