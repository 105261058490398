import * as React from 'react';
import Svg, { Path, Rect, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={54} height={54} viewBox="0 0 54 54" fill="none" {...props}>
      <Path
        d="M18.385 41.366v0a4 4 0 005.657 0l8.656-8.656a23.473 23.473 0 006.761-14.29v0a4.108 4.108 0 00-4.492-4.493v0a23.473 23.473 0 00-14.29 6.762l-7.949 7.949a5 5 0 000 7.07v0"
        stroke="#D95BB7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.718 29.698l-1.536-1.535c-.945-.945-.276-2.56 1.06-2.56h3.072c1.336 0 2.005 1.615 1.06 2.56L8.84 29.698a1.5 1.5 0 01-2.121 0zM23.688 44.548l1.536-1.536c.945-.945 2.56-.276 2.56 1.06v3.072c0 1.336-1.615 2.006-2.56 1.06l-1.536-1.535a1.5 1.5 0 010-2.121z"
        stroke="#2D9CDB"
      />
      <Rect
        x={18.385}
        y={34.295}
        width={2}
        height={7}
        rx={1}
        transform="rotate(45 18.385 34.295)"
        stroke="#2D9CDB"
      />
      <Rect
        x={28.284}
        y={22.981}
        width={4}
        height={7}
        rx={2}
        transform="rotate(45 28.284 22.981)"
        stroke="#2D9CDB"
      />
    </Svg>
  );
};

export default SvgComponent;
