import {
  Commission,
  CommissionsHistory,
  CommissionsPerformance,
} from './../../api/types';
import {
  CommissionsActions,
  fetchCommissionDetailsActions,
  fetchCommissionsHistoryActions,
  fetchCommissionsPerformanceActions,
  fetchLatestCommissionsActions,
} from '../actions/commissions';
import produce from 'immer';
import { Reducer } from 'redux';
import { ApiError } from '../../api/types';

const initialState: CommissionsState = {
  historyLoading: false,
  historyData: [],
  detailsLoading: false,
  detailsData: [],
  detailsTotal: 0,
  latestLoading: false,
  latestData: [],
  performanceLoading: false,
};

export interface CommissionsState {
  readonly historyLoading: boolean;
  readonly historyError?: ApiError;
  readonly historyData: CommissionsHistory[];
  readonly detailsLoading: boolean;
  readonly detailsError?: ApiError;
  readonly detailsData: Commission[];
  readonly detailsTotal: number;
  readonly latestLoading: boolean;
  readonly latestError?: ApiError;
  readonly latestData: Commission[];
  readonly performanceData?: CommissionsPerformance;
  readonly performanceLoading: boolean;
  readonly performanceError?: ApiError;
}

export const userCommissionsReducer: Reducer<
  CommissionsState,
  CommissionsActions
> = (state = initialState, action) =>
  produce(state, (d) => {
    switch (action.type) {
      // CommissionsHistory
      case fetchCommissionsHistoryActions.types.start:
        d.historyError = undefined;
        d.historyLoading = true;
        return;
      case fetchCommissionsHistoryActions.types.success:
        d.historyData = action.payload.response.data;
        d.historyLoading = false;
        d.historyError = undefined;
        return;
      case fetchCommissionsHistoryActions.types.failure:
        d.historyError = action.payload.error;
        d.historyLoading = false;
        return;

      case fetchLatestCommissionsActions.types.start:
        d.latestError = undefined;
        d.latestLoading = true;
        return;
      case fetchLatestCommissionsActions.types.success:
        d.latestData = action.payload.response.data;
        d.latestLoading = false;
        d.latestError = undefined;
        return;
      case fetchLatestCommissionsActions.types.failure:
        d.latestError = action.payload.error;
        d.latestLoading = false;
        return;

      case fetchCommissionDetailsActions.types.start:
        d.detailsError = undefined;
        d.detailsLoading = true;
        return;
      case fetchCommissionDetailsActions.types.success:
        d.detailsData = action.payload.response.data;
        d.detailsTotal = action.payload.response.total;
        d.detailsLoading = false;
        d.detailsError = undefined;
        return;
      case fetchCommissionDetailsActions.types.failure:
        d.detailsError = action.payload.error;
        d.detailsLoading = false;
        return;

      case fetchCommissionsPerformanceActions.types.start:
        d.performanceError = undefined;
        d.performanceLoading = true;
        return;
      case fetchCommissionsPerformanceActions.types.success:
        d.performanceData = action.payload.response;
        d.performanceLoading = false;
        d.performanceError = undefined;
        return;
      case fetchCommissionsPerformanceActions.types.failure:
        d.performanceError = action.payload.error;
        d.performanceLoading = false;
        return;
    }
  });
