import { useCallback } from 'react';

import { FullPaginationParams } from '../services/api/types';
import { useQueryParams } from './use-query-params';

// TODO: refactor with use-pagination
export type OnChangeSort = (sortBy: string | undefined) => void;

export function usePaginationParams(initialQueryParams: FullPaginationParams) {
  const { queryParams, setQueryParams } = useQueryParams<FullPaginationParams>(
    initialQueryParams
  );
  const onPaginate = useCallback(
    (page: number) => {
      setQueryParams({ page });
    },
    [setQueryParams]
  );
  const onChangePageSize = useCallback(
    (pageSize: number) => {
      setQueryParams({ pageSize, page: 0 });
    },
    [setQueryParams]
  );
  const onChangeSort: OnChangeSort = useCallback(
    (sortBy) => {
      if (sortBy !== queryParams.sortBy) {
        setQueryParams({ sortBy, sortOrder: 'desc' });
      } else {
        const sortOrder = queryParams.sortOrder === 'asc' ? 'desc' : 'asc';
        setQueryParams({ sortBy, sortOrder });
      }
    },
    [queryParams.sortOrder, queryParams.sortBy, setQueryParams]
  );

  return {
    queryParams,
    onPaginate,
    onChangePageSize,
    onChangeSort,
  };
}
