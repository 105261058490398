import { useCallback } from 'react';
import { SMALL_SCREEN_MAX_WIDTH } from '../theme/fixed-sizes';
import { useWindowDimensions } from './use-window-dimensions';

export const useSmallScreen = () => {
  const { width: windowWidth } = useWindowDimensions();

  const isSmallScreen = useCallback(() => {
    return windowWidth < SMALL_SCREEN_MAX_WIDTH;
  }, [windowWidth]);

  return { isSmallScreen };
};
