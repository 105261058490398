import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={14} height={8} viewBox="0 0 14 8" fill="none" {...props}>
      <Path d="M7 8L.072.5h13.856L7 8z" fill="#fff" />
    </Svg>
  );
};

export default SvgComponent;
