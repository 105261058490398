import {
  GetMessagesParams,
  Message,
  MessageShort,
  PaginatedResponse,
  UnplacedMembersCount,
} from '../../api/types';
import { createApiActions, ExtractActionTypes } from '../create-api-actions';

export const fetchLatestMessagesActions = createApiActions(
  'FETCH_LATEST_MESSAGES_TRIGGER',
  'FETCH_LATEST_MESSAGES_START',
  'FETCH_LATEST_MESSAGES_SUCCESS',
  'FETCH_LATEST_MESSAGES_FAILURE'
)<undefined, PaginatedResponse<MessageShort[]>>();

export const fetchMessagesActions = createApiActions(
  'FETCH_MESSAGES_TRIGGER',
  'FETCH_MESSAGES_START',
  'FETCH_MESSAGES_SUCCESS',
  'FETCH_MESSAGES_FAILURE'
)<GetMessagesParams, PaginatedResponse<Message[]>>();

export const fetchUnreadMessagesCountActions = createApiActions(
  'FETCH_LATEST_MESSAGES_COUNT_TRIGGER',
  'FETCH_LATEST_MESSAGES_COUNT_START',
  'FETCH_LATEST_MESSAGES_COUNT_SUCCESS',
  'FETCH_LATEST_MESSAGES_COUNT_FAILURE'
)<undefined, UnplacedMembersCount>();

export type MessagesActions =
  | ExtractActionTypes<typeof fetchLatestMessagesActions>
  | ExtractActionTypes<typeof fetchMessagesActions>
  | ExtractActionTypes<typeof fetchUnreadMessagesCountActions>;
