import * as React from 'react';
import Svg, { SvgProps, Circle } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={27} height={7} viewBox="0 0 27 7" fill="none" {...props}>
      <Circle cx={3.5} cy={3.5} r={3} fill="#fff" stroke="#fff" />
      <Circle cx={13.5} cy={3.5} r={3} fill="#fff" stroke="#fff" />
      <Circle cx={23.5} cy={3.5} r={3} fill="#fff" stroke="#fff" />
    </Svg>
  );
};

export default SvgComponent;
