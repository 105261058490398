import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FC, useEffect, useCallback, useMemo } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { PlaceHolderLoader } from '../../components/placeholder-loader/placeholder-loader';
import { RootStackParamList } from '../../navigation/types';
import { Payout } from '../../services/api/types';
import { fetchPayoutsActions } from '../../services/redux/actions/payouts';
import { RootState } from '../../services/redux/reducers';
import { PayoutsState } from '../../services/redux/reducers/payouts';

import { PaginationBar } from '../../components/pagination-bar/pagination-bar';
import {
  Table,
  TableConfigItem,
  TableSortConfig,
} from '../../components/table/table';
import { formatDateStandard } from '../../utils/format-date';
import { usePagination } from '../../hooks/use-pagination';
import { useTheme } from '../../hooks/use-theme';
import { spacing } from '../../theme/spacing';
import {
  getTableDefaultHeight,
  getTableDefaultWidth,
  TABLE_COLUMN_WIDTH_S,
} from '../../theme/fixed-sizes';
import { LoadingScreen } from '../../components/loading-screen/loading-screen';
import { Currency } from '../../components/currency/currency';
import { StatusBadge } from '../../components/status-badge/status-badge';

export const PayoutStatus: FC<{ status: string; width?: number }> = (props) => {
  const { status, width } = props;
  const { theme } = useTheme();
  const { textStyles } = theme;

  let color = '#C4C4C4';
  if (status === 'released') {
    color = '#56BF99';
  }

  if (status === 'Failed') {
    color = '#FF6A6A';
  }
  const style = StyleSheet.create({
    statusStyle: {
      color: color,
      borderColor: color,
      borderRadius: 15,
      borderWidth: 1,
      height: 17,
      width: width ? width : 66,
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
  return (
    <View style={style.statusStyle}>
      <Text style={[textStyles.BodyXSTextLeft, { color }]} numberOfLines={1}>
        {status ? status.toLocaleUpperCase() : null}
      </Text>
    </View>
  );
};

const PAYOUTS_TABLE_CONFIG: TableConfigItem<Payout>[] = [
  {
    header: 'Date',
    sortField: 'date',
    width: TABLE_COLUMN_WIDTH_S,

    render: (payout) => formatDateStandard(payout.date),
  },
  {
    header: ' ',
    width: 80,
    align: 'right',
    render: (payout) => (
      <StatusBadge
        status={payout.status}
        colorMap={{
          released: '#56BF99',
          failed: '#FF6A6A',
        }}
        defaultColor={'#C4C4C4'}
      />
    ),
  },
  {
    header: 'Total Amount',
    sortField: 'totalAmount',
    width: 180,
    align: 'right',
    render: (payout) => {
      return (
        <Currency
          amount={payout.totalAmount}
          currency={payout.currency}
          symbolPosition={'left'}
        />
      );
      // TODO: ` (incl. ${
      //   payout.taxPercent
      // }% Tax)`;
    },
  },
];

export const PayoutsScreen: FC = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const payoutsSelector = useSelector<RootState, PayoutsState>(
    (state) => state.payouts
  );
  const {
    loading,
    error,
    data,
    entities,
    queryParams,
    onPaginate,
    onChangePageSize,
    onChangeSort,
  } = usePagination<Payout>(payoutsSelector, 'date', 'desc');
  const { theme } = useTheme();

  const dispatch = useDispatch();
  const refetch = useCallback(() => {
    dispatch(fetchPayoutsActions.trigger(queryParams));
  }, [dispatch, queryParams]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const tableSortConfig: TableSortConfig = useMemo(() => {
    return {
      default: {
        sortBy: queryParams.sortBy,
        sortOrder: queryParams.sortOrder,
      },
      onChangeSort,
    };
  }, [onChangeSort, queryParams.sortBy, queryParams.sortOrder]);

  const navigateToPayout = (payout: Payout) => {
    navigation.navigate('Wip', {
      headline: 'Payout Details',
      bodyText: 'Show details of payout ' + payout.id + ':' + payout.netAmount,
    });
  };

  return (
    <LoadingScreen
      loading={loading}
      error={error}
      refetch={refetch}
      placeholderLoaderType="PAYOUTS_SCREEN"
    >
      <View style={styles.container}>
        <View style={styles.innerContainer}>
          {loading && <PlaceHolderLoader displayType="PAYOUTS_SCREEN" />}
          {!loading && (
            <Table<Payout>
              data={entities}
              onRowPress={navigateToPayout}
              config={PAYOUTS_TABLE_CONFIG}
              theme={theme}
              sortConfig={tableSortConfig}
            />
          )}
        </View>
        <View style={styles.bottomBar}>
          <PaginationBar
            page={queryParams.page}
            pageSize={queryParams.pageSize}
            sliceSize={5}
            totalCount={data.totalCount || 0}
            onPaginate={onPaginate}
            onChangePageSize={onChangePageSize}
            theme={theme}
          />
        </View>
      </View>
    </LoadingScreen>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: spacing[2],
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    width: getTableDefaultWidth(),
    minHeight: getTableDefaultHeight(),
    alignItems: 'center',
  },
  bottomBar: {
    flex: 1,
  },
});
