import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.44 21.533h23.12L12 1.133.44 21.533zM12 18.813a1.36 1.36 0 110-2.72 1.36 1.36 0 010 2.72zm0-4.08a1.36 1.36 0 01-1.36-1.36v-2.72a1.36 1.36 0 012.72 0v2.72a1.36 1.36 0 01-1.36 1.36z"
        fill="#D12A3D"
      />
    </Svg>
  );
};

export default SvgComponent;
