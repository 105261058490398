import * as React from 'react';
import Svg, { Path, Rect, Circle, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={39} height={22} viewBox="0 0 39 22" fill="none" {...props}>
      <Path
        d="M28.017 18.5H22a3.5 3.5 0 01-3.5-3.5V9.3c0-3.073 3.676-4.655 5.907-2.541l6.017 5.7c2.297 2.176.757 6.041-2.407 6.041z"
        stroke="#D95BB7"
      />
      <Rect x={0.5} y={2.5} width={18} height={16} rx={3.5} stroke="#2D9CDB" />
      <Path
        d="M24.985 13.03H23a1.5 1.5 0 01-1.5-1.5V9.65c0-1.317 1.576-1.995 2.532-1.09l1.984 1.881c.985.933.325 2.589-1.031 2.589z"
        stroke="#D95BB7"
      />
      <Circle cx={7} cy={19} r={2.5} fill="#fff" stroke="#D95BB7" />
      <Circle cx={24} cy={19} r={2.5} fill="#fff" stroke="#D95BB7" />
    </Svg>
  );
};

export default SvgComponent;
