import * as React from 'react';
import Svg, { Circle, Path, Rect, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={31} height={31} viewBox="0 0 31 31" fill="none" {...props}>
      <Circle cx={18} cy={12} r={4.5} stroke="#FFFFFF" />
      <Path d="M24 25a6 6 0 00-12 0M20 12a2 2 0 11-4 0" stroke="#FFFFFF" />
      <Circle cx={18} cy={13} r={12.5} stroke="#FFFFFF" />
      <Circle cx={7.5} cy={23.5} r={7} fill="none" stroke="#FFFFFF" />
      <Rect x={7} y={20} width={1} height={7} rx={0.5} fill="#FFFFFF" />
      <Rect
        x={4}
        y={24}
        width={1}
        height={7}
        rx={0.5}
        transform="rotate(-90 4 24)"
        fill="#FFFFFF"
      />
    </Svg>
  );
};

export default SvgComponent;
