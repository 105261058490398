import App from './app';

// Should we show storybook instead of our app?
//
// ⚠️ Leave this as `false` when checking into git.
const SHOW_STORYBOOK = false;
const getStorybookUIRoot = () => {
  // We require Storybook lazily here to not ship the Storybook with the main app's js bundle
  // Please don't use import { StorybookUIRoot } from "../storybook/storybook" in this file
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { StorybookUIRoot } = require('../storybook');
  return StorybookUIRoot;
};

const RootComponent = SHOW_STORYBOOK && __DEV__ ? getStorybookUIRoot() : App;

export default RootComponent;
