import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={14} height={14} viewBox="0 0 14 14" fill="none" {...props}>
      <Path
        d="M2.333 7.348L6.09 9.895l5.553-8.562"
        stroke="#00E695"
        strokeWidth={1.4}
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default SvgComponent;
