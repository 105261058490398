import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={20} height={15} viewBox="0 0 20 15" fill="none" {...props}>
      <Path
        d="M0 1.5A1.5 1.5 0 011.5 0h17a1.5 1.5 0 010 3h-17A1.5 1.5 0 010 1.5zM0 7.5A1.5 1.5 0 011.5 6h17a1.5 1.5 0 010 3h-17A1.5 1.5 0 010 7.5zM0 13.5A1.5 1.5 0 011.5 12h17a1.5 1.5 0 010 3h-17A1.5 1.5 0 010 13.5z"
        fill="#FFFFFF"
      />
    </Svg>
  );
};

export default SvgComponent;
