import {
  ApiError,
  NetworkError,
  UnknownError,
  BadResponseError,
  LoginExpiredError,
  TooManyRequestsError,
} from './types';

export const isNetworkError = (error: ApiError | undefined): boolean => {
  return error !== undefined && error.type === 'NETWORK_ERROR';
};

export const createCustomErrorNotLoggedIn = (): LoginExpiredError => {
  return {
    type: 'LOGIN_EXPIRED',
  };
};

export const createCustomErrorTooManyRequests = (): TooManyRequestsError => {
  return {
    type: 'TOO_MANY_REQUESTS',
  };
};

export const createApiErrorNoNetwork = (): NetworkError => {
  return {
    type: 'NETWORK_ERROR',
  };
};

export const createApiErrorUnknown = (): UnknownError => {
  return {
    type: 'UNKNOWN_ERROR',
  };
};

export const createApiErrorBadResponse = (): BadResponseError => {
  return {
    type: 'BAD_RESPONSE',
  };
};
