import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M11.865 13.479c1.603 0 3.379.368 5.326 1.165 1.947.798 3.035 1.902 3.207 3.435v3.25M3.331 21.33v-3.251c.171-1.534 1.252-2.637 3.243-3.435 1.934-.797 3.698-1.165 5.29-1.165v0"
        stroke="#171817"
      />
      <Path
        clipRule="evenodd"
        d="M11.894 10.903c-1.12-.061-2.004-.43-2.711-1.165-.708-.736-1.061-1.656-1.061-2.821 0-1.104.353-2.024 1.06-2.76.708-.736 1.592-1.104 2.712-1.104 1.061 0 1.945.368 2.653 1.104.707.736 1.06 1.656 1.06 2.76 0 1.165-.353 2.085-1.06 2.82-.708.737-1.592 1.105-2.653 1.166z"
        stroke="#171817"
      />
    </Svg>
  );
};

export default SvgComponent;
