import * as React from 'react';
import Svg, { Circle, Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={36} height={21} viewBox="0 0 36 21" fill="none" {...props}>
      <Path
        d="M6.958 3.48C5.548 2.658 6.132.5 7.764.5h21.19c1.595 0 2.214 2.073.881 2.947a2.605 2.605 0 00-.457 3.975l1.046 1.098a2.87 2.87 0 010 3.96l-1.046 1.098a2.605 2.605 0 00.457 3.975c1.333.874.714 2.947-.88 2.947H8.045c-1.594 0-2.213-2.073-.88-2.947a2.605 2.605 0 00.457-3.975L6.51 12.41a2.77 2.77 0 010-3.82l1.02-1.072a2.599 2.599 0 00-.572-4.038z"
        stroke="#FFFFFF"
      />
      <Circle cx={18.5} cy={14.5} r={2} stroke="#FFFFFF" />
      <Circle cx={18.5} cy={5.5} r={2} stroke="#FFFFFF" />
    </Svg>
  );
};

export default SvgComponent;
