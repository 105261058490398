import { takeEvery } from 'redux-saga/effects';
import { ApiType } from '../../api';
import { fetchUserActions } from '../actions/user-info';
import { callApiEndpoint } from './api';

export const watchFetchUser = (Api: ApiType) =>
  takeEvery(fetchUserActions.types.trigger, fetchUser, Api);

function* fetchUser(
  Api: ApiType,
  _: ReturnType<typeof fetchUserActions.trigger>
) {
  yield callApiEndpoint(fetchUserActions, Api.getUser, undefined, Api);
}
