import {
  Commission,
  CommissionsHistory,
  CommissionsPerformance,
  GetCommissionsDetailsParams,
  GetCommissionsHistoryParams,
  PaginatedResponse,
} from './../../api/types';
import { createApiActions, ExtractActionTypes } from '../create-api-actions';

export const fetchCommissionDetailsActions = createApiActions(
  'FETCH_COMMISSIONS_DETAILS_TRIGGER',
  'FETCH_COMMISSIONS_DETAILS_START',
  'FETCH_COMMISSIONS_DETAILS_SUCCESS',
  'FETCH_COMMISSIONS_DETAILS_FAILURE'
)<GetCommissionsDetailsParams, PaginatedResponse<Commission[]>>();

export const fetchCommissionsHistoryActions = createApiActions(
  'FETCH_COMMISSIONS_HISTORY_TRIGGER',
  'FETCH_COMMISSIONS_HISTORY_START',
  'FETCH_COMMISSIONS_HISTORY_SUCCESS',
  'FETCH_COMMISSIONS_HISTORY_FAILURE'
)<GetCommissionsHistoryParams, PaginatedResponse<CommissionsHistory[]>>();

export const fetchLatestCommissionsActions = createApiActions(
  'FETCH_COMMISSIONS_LATEST_TRIGGER',
  'FETCH_COMMISSIONS_LATEST_START',
  'FETCH_COMMISSIONS_LATEST_SUCCESS',
  'FETCH_COMMISSIONS_LATEST_FAILURE'
)<undefined, PaginatedResponse<Commission[]>>();

export const fetchCommissionsPerformanceActions = createApiActions(
  'FETCH_COMMISSIONS_PERFORMANCE_TRIGGER',
  'FETCH_COMMISSIONS_PERFORMANCE_START',
  'FETCH_COMMISSIONS_PERFORMANCE_SUCCESS',
  'FETCH_COMMISSIONS_PERFORMANCE_FAILURE'
)<undefined, CommissionsPerformance>();

export type CommissionsActions =
  | ExtractActionTypes<typeof fetchCommissionsHistoryActions>
  | ExtractActionTypes<typeof fetchCommissionDetailsActions>
  | ExtractActionTypes<typeof fetchLatestCommissionsActions>
  | ExtractActionTypes<typeof fetchCommissionsPerformanceActions>;
