import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FC, useEffect, ReactElement, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { PlaceHolderLoader } from '../../components/placeholder-loader/placeholder-loader';
import { RootStackParamList } from '../../navigation/types';
import {
  FullPaginationParams,
  Member,
  NetworkMember,
} from '../../services/api/types';
import { spacing } from '../../theme/spacing';

import { PaginationBar } from '../../components/pagination-bar/pagination-bar';
import {
  Table,
  TableConfigItem,
  TableSortConfig,
} from '../../components/table/table';
import { formatDateStandard } from '../../utils/format-date';
import { Button } from '../../components/button/button';
import { apiService } from '../../services/api/api-service';
import { useFetchPaginated } from '../../hooks/use-fetch-paginated';
import { useTheme } from '../../hooks/use-theme';
import {
  getTableDefaultHeight,
  getTableDefaultWidth,
  TABLE_COLUMN_WIDTH_S,
} from '../../theme/fixed-sizes';
import { DEFAULT_PAGE_SIZE } from '../../hooks/use-pagination';
import { LoadingScreen } from '../../components/loading-screen/loading-screen';

type ButtonBuilder<T> = (element: T, text: string) => ReactElement | null;

const getTableConfig = (placementBuilder: ButtonBuilder<NetworkMember>) => {
  const config: TableConfigItem<NetworkMember>[] = [
    {
      header: 'Signup',
      sortField: 'createdAt',
      width: TABLE_COLUMN_WIDTH_S,
      render: (member) => formatDateStandard(member.createdAt),
    },
    {
      header: 'Placement',
      sortField: 'parentUsername',
      width: 140,
      render: (member) =>
        member.parentUsername
          ? member.parentUsername
          : placementBuilder(member, 'Place now'),
    },
    {
      header: 'Name',
      sortField: 'lastName',
      width: 140,
      render: (member) => {
        return `${member.firstName} ${member.lastName} ${
          member.active === 'no' ? 'INACTIVE' : null
        }`;
      },
    },
    {
      header: 'Username',
      sortField: 'username',
      width: 140,
      render: (member) => member.username,
    },

    {
      header: 'Sponsor',
      sortField: 'sponsorUsername',
      width: 120,
      render: (member) => member.sponsorUsername,
    },
    {
      header: 'Current Rank',
      sortField: 'currentRankName',
      width: 140,
      render: (message) => message.currentRankName,
    },
    {
      header: 'Title',
      sortField: 'highestRankName',
      width: TABLE_COLUMN_WIDTH_S,

      render: (message) => message.highestRankName,
    },
  ];
  return config;
};

export const MembersListScreen: FC = () => {
  const {
    queryParams,
    onPaginate,
    onChangePageSize,
    onChangeSort,
    loading,
    error,
    data,
    refetch,
  } = useFetchPaginated<NetworkMember, FullPaginationParams>(
    apiService,
    apiService.getMembers,
    {
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      sortBy: 'createdAt',
      sortOrder: 'desc',
    }
  );
  const { theme } = useTheme();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const tableSortConfig: TableSortConfig = useMemo(() => {
    return {
      default: {
        sortBy: queryParams.sortBy,
        sortOrder: queryParams.sortOrder,
      },
      onChangeSort,
    };
  }, [onChangeSort, queryParams.sortBy, queryParams.sortOrder]);

  const buildPlaceNowButton: ButtonBuilder<NetworkMember> = (
    member: NetworkMember,
    text: string
  ) => {
    const navigateToPlaceMember = () => {
      navigation.push<'PlaceMember'>('PlaceMember', { id: member.id });
    };

    return (
      <Button
        onPress={navigateToPlaceMember}
        text={text}
        theme={theme}
        style={{ marginLeft: -spacing[2] }}
      />
    );
  };
  const tableConfig = getTableConfig(buildPlaceNowButton);
  const navigateToMember = (member: Member) => {
    navigation.navigate('Wip', {
      headline: 'Member Details',
      bodyText: member.username,
    });
  };

  return (
    <LoadingScreen
      loading={loading}
      error={error}
      refetch={refetch}
      placeholderLoaderType="MESSAGES_SCREEN"
    >
      <View style={styles.container}>
        <View style={styles.innerContainer}>
          {loading && <PlaceHolderLoader displayType="MESSAGES_SCREEN" />}
          {!loading && (
            <Table<NetworkMember>
              data={data ? data.data : []}
              config={tableConfig}
              onRowPress={navigateToMember}
              sortConfig={tableSortConfig}
              horizontalScroll
              theme={theme}
            />
          )}
        </View>
        <View style={styles.bottomBar}>
          <PaginationBar
            page={queryParams.page}
            pageSize={queryParams.pageSize}
            sliceSize={5}
            totalCount={data?.total || 0}
            onPaginate={onPaginate}
            onChangePageSize={onChangePageSize}
            theme={theme}
          />
        </View>
      </View>
    </LoadingScreen>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: spacing[2],
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    width: getTableDefaultWidth(),
    minHeight: getTableDefaultHeight(),
    alignItems: 'center',
  },
  bottomBar: {
    flex: 1,
  },
});
