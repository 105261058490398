import { StringHash, DualTreeItem } from './types';

export const extractDualTreeData = ({
  data,
  labelMapping,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  labelMapping: StringHash;
}) => {
  if (!data) {
    return null;
  }

  return Object.keys(data).reduce((memo: DualTreeItem[], key) => {
    if (labelMapping[key]) {
      /*@ts-ignore-next-line */
      const curData = data[key];
      memo.push({
        label: labelMapping[key],
        left: Math.floor(curData.left),
        right: Math.floor(curData.right),
      });
    }
    return memo;
  }, []);
};
