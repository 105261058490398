import * as React from 'react';
import Svg, { Path, Rect, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Rect x={5} y={9} width={14} height={13} rx={1} stroke="#000" />
      <Path d="M8 5a4 4 0 014-4v0a4 4 0 014 4v4H8V5z" stroke="#000" />
    </Svg>
  );
};

export default SvgComponent;
