import React from 'react';
import { storiesOf } from '@storybook/react-native';
import { Button } from './button';
import { lightTheme, darkTheme } from '../../hooks/use-theme';

storiesOf('Button', module)
  .add('light', () => {
    return <Button text={'Place now'} onPress={() => {}} theme={lightTheme} />;
  })
  .add('dark', () => {
    return <Button text={'Place now'} onPress={() => {}} theme={darkTheme} />;
  });
