/* eslint-disable @typescript-eslint/ban-ts-comment */

export type HashMap<TData> = { [key: number]: TData };

export interface NormalizedData<T> {
  ids: number[];
  entities: HashMap<T>;
}
export function normalize<Data>(data: Data[] | undefined) {
  const initialData: NormalizedData<Data> = {
    ids: [],
    entities: {},
  };
  if (data === undefined) {
    return initialData;
  }
  return data.reduce((memo, entity) => {
    // @ts-ignore
    const id = entity.id as number;
    memo.entities[id] = entity;
    memo.ids.push(id);
    return memo;
  }, initialData);
}

export function deNormalize<TData>(
  data: HashMap<TData>,
  ids: number[]
): TData[] {
  return ids.map((id) => data[id]);
}
