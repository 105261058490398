import React, { FC, useCallback, useMemo } from 'react';
import { Text, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../services/redux/reducers';
import { MembersState } from '../../services/redux/reducers/members';
import { fetchTopPerformingMembersActions } from '../../services/redux/actions/members';

import { spacing } from '../../theme/spacing';
import {
  DASHBOARD_TILE_HEIGHT_LARGE,
  DASHBOARD_TILE_WIDTH,
} from '../../theme/fixed-sizes';
import { useTheme } from '../../hooks/use-theme';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../navigation/types';
import { MemberPerformance } from '../../services/api/types';
import { deNormalize } from '../../services/data/normalize';
import { DashboardTileLoading } from '../../components/dashboard-tile-loading/dashboard-tile-loading';
import { UserState } from '../../services/redux/reducers/user-info';
import { Table, TableConfigItem } from '../../components/table/table';

const TABLE_CONFIG: TableConfigItem<MemberPerformance>[] = [
  {
    width: 170,
    render: (member) => member.username,
  },
  {
    width: 100,
    align: 'right',
    render: (member) => `${member.score} members`,
  },
];

export const DashboardLeaderboardPerformance: FC = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const membersState = useSelector<RootState, MembersState>(
    (state) => state.members
  );
  const userState = useSelector<RootState, UserState>((state) => state.user);
  const { data: userData } = userState;
  const { topPerformingLoading, topPerformingError, data } = membersState;
  const { theme } = useTheme();
  const { textStyles } = theme;

  const topPerformingEntities = useMemo(() => {
    return deNormalize<MemberPerformance>(
      data.topPerforming,
      data.topPerformingIds
    );
  }, [data.topPerforming, data.topPerformingIds]);

  const dispatch = useDispatch();
  const refetch = useCallback(() => {
    dispatch(fetchTopPerformingMembersActions.trigger(undefined));
  }, [dispatch]);

  const getPerformersButtonConfig = () => {
    return [
      {
        onPress: () => {
          navigation.navigate('MembersPerformanceLeaderboard');
        },
        title: 'Show performance',
      },
    ];
  };

  // TODO: remove GDoellers/4biohackers hack
  const filteredUsers = topPerformingEntities.filter((u) => {
    if (
      userData?.user?.username === 'GDoellers' &&
      u.username === '4biohackers'
    ) {
      return false;
    }
    return true;
  });

  // NOTE: TODO: api does not handle limit parameter yet
  const MAX_LIMIT_ROWS = 3;

  return (
    <DashboardTileLoading
      loading={topPerformingLoading}
      error={topPerformingError}
      data={topPerformingEntities}
      refetch={refetch}
      style={styles.container}
      icon="glowing-star"
      buttons={getPerformersButtonConfig()}
      theme={theme}
      placeholderLoaderType="DASHBOARD_MEMBER_LEADERBOARD"
    >
      <>
        <Text style={[styles.margin, textStyles.H3Left]}>Top performers</Text>
        <Table<MemberPerformance>
          data={filteredUsers.slice(0, MAX_LIMIT_ROWS)}
          config={TABLE_CONFIG}
          theme={theme}
        />
      </>
    </DashboardTileLoading>
  );
};

const styles = StyleSheet.create({
  container: {
    height: DASHBOARD_TILE_HEIGHT_LARGE,
    width: DASHBOARD_TILE_WIDTH,
  },
  margin: {
    paddingBottom: spacing[1],
  },
});
