import React, { FC } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useTheme } from '../../hooks/use-theme';

type ColorMap = { [key: string]: string };

interface StatusBadgeProps {
  status: string;
  width?: number;
  colorMap: ColorMap;
  defaultColor?: string;
}

const getColorValue = (
  colorMap: ColorMap,
  value: string,
  defaultColor?: string
) => {
  return colorMap[value] || defaultColor;
};

export const StatusBadge: FC<StatusBadgeProps> = (props) => {
  const { status, width, colorMap, defaultColor } = props;
  const { theme } = useTheme();
  const { textStyles } = theme;

  const color = getColorValue(colorMap, status, defaultColor);

  const style = StyleSheet.create({
    statusStyle: {
      color: color,
      borderColor: color,
      borderRadius: 15,
      borderWidth: 1,
      height: 17,
      width: width ? width : 66,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 7, // NOTE: half the icon height TODO:FIXME
    },
  });
  return (
    <View style={style.statusStyle}>
      <Text style={[textStyles.BodyXSTextLeft, { color }]} numberOfLines={1}>
        {status ? status.toLocaleUpperCase() : null}
      </Text>
    </View>
  );
};
