import { useAuthentication } from './use-authentication';
import { PaginatedResponse } from './../services/api/types';
import produce, { castDraft } from 'immer';
import { useCallback, useReducer, Reducer } from 'react';
import { ApiError, ApiResult } from '../services/api/types';
import { ApiType } from '../services/api';
import { updateAuthHeader } from '../services/api/update-auth-header';

interface State<T> {
  loading: boolean;
  error: ApiError | undefined;
  data: {
    total: number;
    data: T[];
  };
}
type Action<TEntity> =
  | { type: 'start' }
  | {
      type: 'success';
      payload: {
        response: {
          data: TEntity[];
          total: number;
        };
      };
    }
  | {
      type: 'failure';
      payload: { error?: ApiError };
    };

export function reducer<TEntity>(
  state: State<TEntity>,
  action: Action<TEntity>
) {
  return produce(state, (d) => {
    switch (action.type) {
      case 'start':
        d.error = undefined;
        d.loading = true;
        return;

      case 'success':
        const { data, total } = action.payload.response;
        d.data.total = total;
        d.data.data = castDraft(data);
        d.loading = false;
        d.error = undefined;
        return;

      case 'failure':
        d.error = action.payload.error;
        d.loading = false;
        return;
    }
  });
}

export function useFetchData<TEntity>(
  Api: ApiType,
  apiEndpoint: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params?: any
  ) => Promise<ApiResult<PaginatedResponse<TEntity[]>>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialQueryParams: any
) {
  const initialState: State<TEntity> = {
    loading: false,
    error: undefined,
    data: {
      total: 0,
      data: [],
    },
  };
  const [data, dispatch] = useReducer<Reducer<State<TEntity>, Action<TEntity>>>(
    reducer,
    initialState
  );

  const { token } = useAuthentication();
  updateAuthHeader(Api, token);

  const refetch = useCallback(() => {
    dispatch({ type: 'start' });
    apiEndpoint(initialQueryParams)
      .then((result) => {
        if (result.success === true && result.data !== undefined) {
          dispatch({ type: 'success', payload: { response: result.data } });
        } else if (result.error !== undefined) {
          dispatch({
            type: 'failure',
            payload: { error: result.error },
          });
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.warn('CAUGHT api error', e);
        dispatch({
          type: 'failure',
          payload: { error: e },
        });
      });
  }, [initialQueryParams, apiEndpoint]);
  return {
    refetch,
    loading: data.loading,
    error: data.error,
    data: data.data,
  };
}
