import { takeEvery } from 'redux-saga/effects';
import { ApiType } from '../../api';
import {
  fetchLatestSupportTicketsActions,
  fetchOpenSupportTicketsCountActions,
} from '../actions/support-tickets';
import { callApiEndpoint } from './api';

export const watchFetchLatestSupportTickets = (Api: ApiType) =>
  takeEvery(
    fetchLatestSupportTicketsActions.types.trigger,
    fetchLatestSupportTickets,
    Api
  );

function* fetchLatestSupportTickets(
  Api: ApiType,
  _: ReturnType<typeof fetchLatestSupportTicketsActions.trigger>
) {
  yield callApiEndpoint(
    fetchLatestSupportTicketsActions,
    Api.getLatestSupportTickets,
    undefined,
    Api
  );
}

// open support tickets

export const watchFetchOpenSupportTicketsCount = (Api: ApiType) =>
  takeEvery(
    fetchOpenSupportTicketsCountActions.types.trigger,
    fetchOpenSupportTicketsCount,
    Api
  );

function* fetchOpenSupportTicketsCount(
  Api: ApiType,
  _: ReturnType<typeof fetchOpenSupportTicketsCountActions.trigger>
) {
  yield callApiEndpoint(
    fetchOpenSupportTicketsCountActions,
    Api.getOpenSupportTicketsCount,
    undefined,
    Api
  );
}
