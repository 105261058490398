import React, { FC, ReactNode, useCallback, useRef, useState } from 'react';
import {
  Pressable,
  Text,
  View,
  StyleSheet,
  Animated,
  Easing,
  LayoutChangeEvent,
} from 'react-native';
import { useTheme } from '../../hooks/use-theme';
import { NAVIGATION_TILE_HEIGHT } from '../../theme/fixed-sizes';
import { spacing } from '../../theme/spacing';
import { SvgImage, SvgNames } from '../svg-image/svg-image';

interface LinkBoxProps {
  label: string;
  type: 'light' | 'dark';
  onPress: () => void;
}

interface CTABoxProps {
  icon: SvgNames;
  label: string;
  type: 'light' | 'dark';
  onPress: () => void;
  withChevronRight?: boolean;
}

interface CTABoxTogglerProps {
  label: string;
  icon: SvgNames;
}

interface LinkBoxTogglerProps {
  label: string;
}

const useSlideDownAnimation = (initialHeight: number) => {
  const [open, setOpen] = useState(false);
  const [childrenHeight, setChildrenHeight] = useState(initialHeight);
  const animatedController = useRef(new Animated.Value(0)).current;

  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, childrenHeight],
  });

  const toggleChildren = () => {
    if (open) {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 0,
        useNativeDriver: false,
        easing: Easing.sin,
        // TODO: consider refactoring to transform: [{translateY}] and useNativeDriver: true
      }).start();
    } else {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 1,
        useNativeDriver: false,
        easing: Easing.sin,
      }).start();
    }
    setOpen(!open);
  };

  const onChildrenLayout = useCallback(
    (event: LayoutChangeEvent) => {
      const newHeight = event.nativeEvent.layout.height;
      setChildrenHeight(newHeight);
    },
    [setChildrenHeight]
  );

  return {
    open,
    bodyHeight,
    toggleChildren,
    onChildrenLayout,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isReactNodeArray(data: any): data is ReactNode[] {
  return data && typeof data.length !== undefined;
}

export const LinkBoxToggler: FC<LinkBoxTogglerProps> = (props) => {
  const { label, children } = props;
  const { theme } = useTheme();
  const { colors2, textStyles2 } = theme;

  const childrenHeight = isReactNodeArray(children)
    ? (children?.length || 1) * (NAVIGATION_TILE_HEIGHT + spacing[0])
    : NAVIGATION_TILE_HEIGHT + spacing[0];

  const { open, bodyHeight, toggleChildren } = useSlideDownAnimation(
    childrenHeight
  );

  return (
    <View style={styles.linkBoxTogglerContainer}>
      <Pressable
        style={[styles.linkBoxText, { backgroundColor: colors2.linkBoxLevel1 }]}
        onPress={toggleChildren}
      >
        <View style={styles.flex}>
          <Text style={textStyles2.BodyTextLeft}>{label}</Text>
        </View>

        <SvgImage
          type={open ? 'generic-chevron-down' : 'generic-chevron-right'}
          variant={theme.name}
          width="24"
          height="24"
        />
      </Pressable>
      <Animated.View
        style={[styles.animatedHeightContainer, { height: bodyHeight }]}
      >
        <View>{children}</View>
      </Animated.View>
    </View>
  );
};

export const LinkBoxSpacer: FC = () => {
  return <View style={styles.container} />;
};

export const LinkBox: FC<LinkBoxProps> = (props) => {
  const { label, onPress, type } = props;
  const { theme } = useTheme();
  const { colors2, textStyles2 } = theme;

  const getStyle = useCallback(() => {
    switch (type) {
      case 'light':
        return { backgroundColor: colors2.linkBoxLevel1 };
      case 'dark':
        return { backgroundColor: colors2.linkBoxLevel2 };
    }
  }, [type, colors2]);

  const style = getStyle();

  return (
    <Pressable onPress={onPress} style={[styles.container, style]}>
      <View style={styles.flex}>
        <Text style={textStyles2.BodyTextLeft}>{label}</Text>
      </View>
      <SvgImage
        type={'generic-chevron-right'}
        variant={theme.name}
        width="24"
        height="24"
      />
    </Pressable>
  );
};

export const CTABox: FC<CTABoxProps> = (props) => {
  const { label, onPress, type, icon, withChevronRight } = props;
  const { theme } = useTheme();
  const { colors2, textStyles2 } = theme;

  const getStyle = useCallback(() => {
    switch (type) {
      case 'light':
        return { backgroundColor: colors2.linkBoxLevel1 };
      case 'dark':
        return { backgroundColor: colors2.linkBoxLevel2 };
    }
  }, [type, colors2]);

  const style = getStyle();

  return (
    <Pressable onPress={onPress} style={[styles.container, style]}>
      {icon ? (
        <View style={styles.ctaIconContainer}>
          <SvgImage type={icon} variant={theme.name} width="32" height="32" />
        </View>
      ) : null}
      <View style={styles.flex}>
        <Text style={textStyles2.BodyTextLeft}>{label}</Text>
      </View>
      {withChevronRight ? (
        <SvgImage
          type={'generic-chevron-right'}
          variant={theme.name}
          width="24"
          height="24"
        />
      ) : null}
    </Pressable>
  );
};

export const CTABoxToggler: FC<CTABoxTogglerProps> = (props) => {
  const { label, children, icon } = props;
  const { theme } = useTheme();
  const { colors2, textStyles2 } = theme;

  const childrenHeight = isReactNodeArray(children)
    ? (children?.length || 1) * (NAVIGATION_TILE_HEIGHT + spacing[0])
    : NAVIGATION_TILE_HEIGHT + spacing[0];

  const { open, bodyHeight, toggleChildren } = useSlideDownAnimation(
    childrenHeight
  );

  return (
    <View style={styles.linkBoxTogglerContainer}>
      <Pressable
        style={[styles.linkBoxText, { backgroundColor: colors2.linkBoxLevel1 }]}
        onPress={toggleChildren}
      >
        {icon ? (
          <View style={styles.ctaIconContainer}>
            <SvgImage type={icon} variant={theme.name} width="32" height="32" />
          </View>
        ) : null}

        <View style={styles.flex}>
          <Text style={textStyles2.BodyTextLeft}>{label}</Text>
        </View>

        <SvgImage
          type={open ? 'generic-chevron-down' : 'generic-chevron-right'}
          variant={theme.name}
          width="24"
          height="24"
        />
      </Pressable>
      <Animated.View
        style={[styles.animatedHeightContainer, { height: bodyHeight }]}
      >
        <View>{children}</View>
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  linkBoxTogglerContainer: {
    marginBottom: spacing[0],
  },
  linkBoxText: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    height: NAVIGATION_TILE_HEIGHT,
    paddingHorizontal: spacing[3],
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: NAVIGATION_TILE_HEIGHT,
    paddingHorizontal: spacing[3],
    marginBottom: spacing[0],
  },
  flex: {
    flex: 1,
  },
  ctaIconContainer: {
    marginLeft: -4,
    marginRight: spacing[2],
  },
  animatedHeightContainer: {
    overflow: 'hidden',
  },
});
