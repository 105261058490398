import {
  PaginatedResponse,
  SupportTicketOrSupportTicketShort,
  UnplacedMembersCount,
} from '../../api/types';
import { createApiActions, ExtractActionTypes } from '../create-api-actions';

export const fetchLatestSupportTicketsActions = createApiActions(
  'FETCH_LATEST_SUPPORT_TICKETS_TRIGGER',
  'FETCH_LATEST_SUPPORT_TICKETS_START',
  'FETCH_LATEST_SUPPORT_TICKETS_SUCCESS',
  'FETCH_LATEST_SUPPORT_TICKETS_FAILURE'
)<undefined, PaginatedResponse<SupportTicketOrSupportTicketShort[]>>();

export const fetchOpenSupportTicketsCountActions = createApiActions(
  'FETCH_OPEN_SUPPORT_TICKETS_COUNT_TRIGGER',
  'FETCH_OPEN_SUPPORT_TICKETS_COUNT_START',
  'FETCH_OPEN_SUPPORT_TICKETS_COUNT_SUCCESS',
  'FETCH_OPEN_SUPPORT_TICKETS_COUNT_FAILURE'
)<undefined, UnplacedMembersCount>();

export type SupportTicketsActions =
  | ExtractActionTypes<typeof fetchLatestSupportTicketsActions>
  | ExtractActionTypes<typeof fetchOpenSupportTicketsCountActions>;
