import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={19} height={17} viewBox="0 0 19 17" fill="none" {...props}>
      <Path
        d="M11 7v0a4.99 4.99 0 00-4.98 4.673L6 12"
        stroke="#fff"
        strokeLinecap="round"
      />
      <Path
        d="M12.246 3.778L16.94 6.43a.5.5 0 010 .87l-4.694 2.652a.5.5 0 01-.746-.436V4.214a.5.5 0 01.746-.436z"
        stroke="#fff"
      />
      <Path
        d="M4 7.5H2.5a2 2 0 00-2 2v5a2 2 0 002 2h7a2 2 0 002-2V13"
        stroke="#fff"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default SvgComponent;
