import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import useCachedResources from '../hooks/use-cached-resources';
import { Navigation } from '../navigation';
import { urlConfig } from '../services/config';
import { getStore } from './get-store';
import { useTheme } from '../hooks/use-theme';
import { PersistGate } from 'redux-persist/integration/react';

const { store, persistor } = getStore(urlConfig);

const AppContent: FC = () => {
  const { theme } = useTheme();
  const { colors2 } = theme;
  const statusBarStyle = theme.name === 'dark' ? 'light' : 'dark';
  return (
    <SafeAreaProvider>
      <StatusBar
        style={statusBarStyle}
        translucent
        backgroundColor={colors2.backgroundHeader}
      />
      <Navigation />
    </SafeAreaProvider>
  );
};

const App: FC = () => {
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppContent />
        </PersistGate>
      </Provider>
    );
  }
};

export default App;
