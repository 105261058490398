import { colorNames, colorNames2 } from '../color-names';
import { withOpacity } from '../../utils/format-color';

export const colors = {
  primary: colorNames.brandPrimary,
  background: colorNames.darkBlue,
  backgroundOpacity: withOpacity(colorNames.white, 0.15),
  backgroundOpacity2: withOpacity(colorNames.darkBlue, 0.75),
  card: colorNames.darkBlue,
  text: colorNames.white,
  textAlternative: colorNames.white,
  border: colorNames.veryLightGrey,
  notification: colorNames.brandSecondary,
  link: colorNames.white,
  linkAlternative: colorNames.brandSecondary,
  chartLine: colorNames.green,
  chartLine2: colorNames.lightGreen,
  chartBorder: colorNames.mediumGrey,
};

export const colors2 = {
  primary: colorNames2.brandPrimary,
  secondary: colorNames2.brandSecondary,
  errorText: colorNames2.red,
  inactiveBorder: colorNames2.lightGrey,
  headlineText: colorNames2.white,
  background: colorNames2.darkBlue,
  backgroundHeader: 'transparent',

  // TODO: re-define new colors below here
  backgroundOpacity: withOpacity(colorNames2.white, 0.15),
  backgroundOpacity2: withOpacity(colorNames2.darkBlue, 0.75),
  card: colorNames2.darkBlue,
  text: colorNames2.white,
  textAlternative: colorNames2.white,
  border: colorNames2.veryLightGrey,
  notification: colorNames2.brandSecondary,
  link: colorNames2.white,
  linkAlternative: colorNames2.brandSecondary,

  linkBoxLevel1: withOpacity(colorNames2.white, 0.15),
  linkBoxLevel2: withOpacity(colorNames2.darkBlue, 0.75),
};
