import React, { Component, FC, useCallback, useState } from 'react';
import {
  Text,
  StyleSheet,
  View,
  Share,
  Platform,
  Animated,
  Pressable,
} from 'react-native';

import { spacing } from '../../theme/spacing';
import { Screen } from '../../components/screen/screen';
import { ThemeConfig, useTheme } from '../../hooks/use-theme';
import { Button } from '../../components/button/button';
import { CopyToClipboard } from '../../components/copy-to-clipboard/copy-to-clipboard';
import { RouteProp, useRoute } from '@react-navigation/native';
import { RootStackParamList } from '../../navigation/types';
import QRCode, { QRCodeProps } from 'react-native-qrcode-svg';
import { useWindowDimensions } from '../../hooks/use-window-dimensions';

class QRCodeWrapper extends Component<QRCodeProps> {
  render() {
    return <QRCode {...this.props} />;
  }
}
const AnimatedQRCode = Animated.createAnimatedComponent(QRCodeWrapper);
const QRCodeFullscreen: FC<{
  size: number;
  enlargedSize: number;
  value: string;
}> = (props) => {
  const { size: startSize, enlargedSize, value } = props;
  const { width: windowWith } = useWindowDimensions();
  // const {
  //   animatedValue,
  //   onAnimateEnlarge,
  //   onAnimateSmaller,
  // } = useSizeAnimation(startSize, enlargedSize);
  const [size, setSize] = useState(startSize);
  const maxEnlargedSize = Math.min(windowWith - 40, enlargedSize);
  const onAnimatePlain = () => {
    const nextSize = size === startSize ? maxEnlargedSize : startSize;
    setSize(nextSize);
  };

  // const onAnimate = useCallback(() => {
  //   if (size === startSize) {
  //     onAnimateEnlarge();
  //   } else {
  //     onAnimateSmaller();
  //   }
  // }, [size, startSize, onAnimateEnlarge, onAnimateSmaller]);

  return (
    <Pressable onPress={onAnimatePlain}>
      <AnimatedQRCode value={value} size={size} />
    </Pressable>
  );
};

const Separator: FC<{ text: string; theme: ThemeConfig }> = (props) => {
  const { text, theme } = props;
  const { textStyles } = theme;
  const backGroundStyle = { backgroundColor: theme.colors.border };
  return (
    <View style={styles.separatorContainer}>
      <View style={[styles.separatorLine, backGroundStyle]} />
      <View style={styles.separatorTextContainer}>
        <Text style={textStyles.BodyTextLeft}>{text}</Text>
      </View>
      <View style={[styles.separatorLine, backGroundStyle]} />
    </View>
  );
};

const buildOnPressShare = (url: string) => {
  return async () => {
    if (url) {
      try {
        const result = await Share.share({
          message: 'Become a member of the Healy World: Visit ' + url,
        });
        if (result.action === Share.sharedAction) {
          if (result.activityType) {
            // eslint-disable-next-line no-console
            console.warn('shared with activity type of result.activityType');
          } else {
            // eslint-disable-next-line no-console
            console.warn('shared');
          }
        } else if (result.action === Share.dismissedAction) {
          // eslint-disable-next-line no-console
          console.warn('dismissed');
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn(err.message);
      }
    }
  };
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useSizeAnimation = (actualSize: number, largeSize: number) => {
  const [animatedValue] = useState(new Animated.Value(actualSize));
  const onAnimateEnlarge = useCallback(() => {
    Animated.timing(animatedValue, {
      duration: 1000,
      toValue: largeSize,
      useNativeDriver: true,
    });
  }, [largeSize, animatedValue]);

  const onAnimateSmaller = useCallback(() => {
    Animated.timing(animatedValue, {
      duration: 1000,
      toValue: actualSize,
      useNativeDriver: true,
    });
  }, [actualSize, animatedValue]);

  return {
    animatedValue,
    onAnimateEnlarge,
    onAnimateSmaller,
  };
};

export const ShareUrlScreen: FC = () => {
  const { theme } = useTheme();
  const { textStyles, colors } = theme;

  const route = useRoute<RouteProp<RootStackParamList, 'ShareUrl'>>();
  const { url, sharedItem } = route.params;

  const backgroundStyle = { backgroundColor: colors.background };
  const QR_CODE_SIZE = 180;
  const QR_CODE_SIZE_ENLARGED = 480;

  return (
    <Screen style={backgroundStyle}>
      <View style={styles.container}>
        <View style={[styles.column, styles.margin]}>
          <Text style={textStyles.H3Left}>Let QR-Code scan</Text>
        </View>
        <View style={[styles.column, styles.margin]}>
          <QRCodeFullscreen
            size={QR_CODE_SIZE}
            enlargedSize={QR_CODE_SIZE_ENLARGED}
            value={url}
          />
        </View>
        <Separator text={'OR'} theme={theme} />
        <View style={[styles.column, styles.margin]}>
          <CopyToClipboard clipboardText={url} successMessage={'Link copied'}>
            <Text style={textStyles.BodyTextLinkLeft}>{'Copy the link'}</Text>
          </CopyToClipboard>
        </View>
        <View style={[styles.column, styles.subtitle]}>
          <Text style={textStyles.BodyTextLeft}>of the {sharedItem}</Text>
        </View>
        {Platform.OS !== 'web' && (
          <>
            <Separator text={'OR'} theme={theme} />
            <View style={[styles.column, styles.margin]}>
              <Button
                theme={theme}
                onPress={buildOnPressShare(url)}
                text={'Share'}
              />
            </View>
          </>
        )}
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 55,
  },
  column: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  separatorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  separatorTextContainer: {
    paddingHorizontal: spacing[2],
  },
  separatorLine: {
    height: 1,
    width: 90,
  },
  margin: {
    paddingVertical: spacing[2],
  },
  subtitle: {
    marginTop: -spacing[2],
    paddingBottom: spacing[2],
  },
});
