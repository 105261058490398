import { Platform } from 'react-native';
import { useMemo, useCallback } from 'react';
import { FullPaginationParams } from './../services/api/types';
import { deNormalize } from '../services/data/normalize';

import { useQueryParams } from './use-query-params';
import { NormalizedState } from '../services/redux/reducers/shared.types';

// TODO: refactor with use-pagination-params
export const DEFAULT_PAGE_SIZE =
  Platform.OS === 'android' || Platform.OS === 'ios' ? 10 : 20;
type OnChangeSort = (sortBy: string | undefined) => void;

export function usePagination<TEntity>(
  entityRootSelector: NormalizedState<TEntity>,
  defaultSortBy?: string,
  defaultOrder?: string
) {
  const { loading, error, data } = entityRootSelector;

  const entities = useMemo(() => {
    return deNormalize<TEntity>(data.entities, data.ids);
  }, [data.entities, data.ids]);

  const { queryParams, setQueryParams } = useQueryParams<FullPaginationParams>({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    sortBy: defaultSortBy,
    sortOrder: defaultOrder,
  });

  const onPaginate = useCallback(
    (page: number) => {
      setQueryParams({ page });
    },
    [setQueryParams]
  );
  const onChangePageSize = useCallback(
    (pageSize: number) => {
      setQueryParams({ pageSize, page: 0 });
    },
    [setQueryParams]
  );
  const onChangeSort: OnChangeSort = useCallback(
    (sortBy) => {
      if (sortBy !== queryParams.sortBy) {
        setQueryParams({ sortBy, sortOrder: 'desc' });
      } else {
        const sortOrder = queryParams.sortOrder === 'asc' ? 'desc' : 'asc';
        setQueryParams({ sortBy, sortOrder });
      }
    },
    [queryParams.sortOrder, queryParams.sortBy, setQueryParams]
  );
  return {
    loading,
    error,
    data,
    entities,
    queryParams,
    onPaginate,
    onChangePageSize,
    onChangeSort,
  };
}

export function usePaginationData<TEntity>(
  data: TEntity[],
  defaultSortBy?: string,
  defaultOrder?: string
) {
  const { queryParams, setQueryParams } = useQueryParams<FullPaginationParams>({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    sortBy: defaultSortBy,
    sortOrder: defaultOrder,
  });

  const onPaginate = useCallback(
    (page: number) => {
      setQueryParams({ page });
    },
    [setQueryParams]
  );
  const onChangePageSize = useCallback(
    (pageSize: number) => {
      setQueryParams({ pageSize, page: 0 });
    },
    [setQueryParams]
  );
  const onChangeSort: OnChangeSort = useCallback(
    (sortBy) => {
      if (sortBy !== queryParams.sortBy) {
        setQueryParams({ sortBy, sortOrder: 'desc' });
      } else {
        const sortOrder = queryParams.sortOrder === 'asc' ? 'desc' : 'asc';
        setQueryParams({ sortBy, sortOrder });
      }
    },
    [queryParams.sortOrder, queryParams.sortBy, setQueryParams]
  );
  return {
    queryParams,
    onPaginate,
    onChangePageSize,
    onChangeSort,
  };
}
