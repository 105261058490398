import {
  fetchLatestPayoutsActions,
  fetchPayoutsActions,
  PayoutsActions,
} from '../actions/payouts';
import produce from 'immer';
import { Reducer } from 'redux';
import { ApiError, Payout } from '../../api/types';
import { HashMap, normalize } from '../../data/normalize';

const initialState: PayoutsState = {
  loading: false,
  latestLoading: false,
  data: {
    entities: {},
    ids: [],
    totalCount: 0,
    latestIds: [],
  },
};

export interface PayoutsState {
  readonly latestLoading: boolean;
  readonly loading: boolean;
  readonly error?: ApiError;
  readonly latestError?: ApiError;
  readonly data: {
    entities: HashMap<Payout>;
    ids: number[];
    totalCount: number;
    latestIds: number[];
  };
}

export const payoutsReducer: Reducer<PayoutsState, PayoutsActions> = (
  state = initialState,
  action
) =>
  produce(state, (d) => {
    switch (action.type) {
      // latestPayouts
      case fetchLatestPayoutsActions.types.start:
        d.latestError = undefined;
        d.latestLoading = true;
        return;
      case fetchLatestPayoutsActions.types.success:
        const { ids: latestIds, entities: latestEntities } = normalize<Payout>(
          action.payload.response.data
        );
        d.data.latestIds = latestIds;
        d.data.entities = { ...state.data.entities, ...latestEntities };
        d.loading = false;

        d.latestLoading = false;
        return;
      case fetchLatestPayoutsActions.types.failure:
        d.latestError = action.payload.error;
        d.latestLoading = false;
        return;

      // Payouts
      case fetchPayoutsActions.types.start:
        d.error = undefined;
        d.loading = true;
        return;
      case fetchPayoutsActions.types.success:
        const { ids, entities } = normalize<Payout>(
          action.payload.response.data
        );
        d.data.ids = ids;
        d.data.totalCount = action.payload.response.total;
        d.data.entities = { ...state.data.entities, ...entities };
        d.loading = false;
        return;
      case fetchPayoutsActions.types.failure:
        d.error = action.payload.error;
        d.loading = false;
        return;
    }
  });
