import { Message, MessageShort } from './../../api/types';
import {
  fetchLatestMessagesActions,
  fetchMessagesActions,
  fetchUnreadMessagesCountActions,
  MessagesActions,
} from '../actions/messages';
import produce from 'immer';
import { Reducer } from 'redux';
import { ApiError } from '../../api/types';
import { HashMap, normalize } from '../../data/normalize';

export interface MessagesState {
  readonly loading: boolean;
  readonly error?: ApiError;
  readonly latestLoading: boolean;
  readonly latestError?: ApiError;
  readonly unreadCountLoading: boolean;
  readonly unreadCountError?: ApiError;

  readonly data: {
    ids: number[];
    entities: HashMap<Message>;
    latestIds: number[];
    latestEntities: HashMap<MessageShort>;
    totalCount?: number;
    unreadCount?: number;
  };
}

const initialState: MessagesState = {
  loading: false,
  latestLoading: false,
  unreadCountLoading: false,
  data: {
    entities: {},
    latestEntities: {},
    ids: [],
    totalCount: 0,
    latestIds: [],
  },
};

export const messagesReducer: Reducer<MessagesState, MessagesActions> = (
  state = initialState,
  action
) =>
  produce(state, (d) => {
    switch (action.type) {
      // latestMessages
      case fetchLatestMessagesActions.types.start:
        d.latestError = undefined;
        d.latestLoading = true;
        return;
      case fetchLatestMessagesActions.types.success:
        const { ids: latestIds, entities: latestEntities } = normalize<
          MessageShort
        >(action.payload.response.data);
        d.data.latestIds = latestIds;
        d.data.latestEntities = {
          ...state.data.latestEntities,
          ...latestEntities,
        };
        d.loading = false;

        d.latestLoading = false;
        return;
      case fetchLatestMessagesActions.types.failure:
        d.latestError = action.payload.error;
        d.latestLoading = false;
        return;

      // Messages
      case fetchMessagesActions.types.start:
        d.error = undefined;
        d.loading = true;
        return;
      case fetchMessagesActions.types.success:
        const { ids, entities } = normalize<Message>(
          action.payload.response.data
        );
        d.data.ids = ids;
        d.data.totalCount = action.payload.response.total;
        d.data.entities = { ...state.data.entities, ...entities };
        d.loading = false;
        return;
      case fetchMessagesActions.types.failure:
        d.error = action.payload.error;
        d.loading = false;
        return;

      // Unread messages
      case fetchUnreadMessagesCountActions.types.start:
        d.unreadCountError = undefined;
        d.unreadCountLoading = true;
        return;
      case fetchUnreadMessagesCountActions.types.success:
        d.data.unreadCount = action.payload.response?.count;
        d.unreadCountLoading = false;
        return;
      case fetchUnreadMessagesCountActions.types.failure:
        d.unreadCountError = action.payload.error;
        d.unreadCountLoading = false;
        return;
    }
  });
