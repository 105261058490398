import React, { FC, useCallback } from 'react';
import {
  TouchableOpacity,
  Clipboard,
  Platform,
  ToastAndroid,
} from 'react-native';

export interface TextToClipboardProps {
  clipboardText: string;
  successMessage?: string;
}

export const CopyToClipboard: FC<TextToClipboardProps> = (props) => {
  const { clipboardText, successMessage, children } = props;
  const onCopyToClipboard = useCallback(() => {
    Clipboard.setString(clipboardText);
    if (successMessage && Platform.OS === 'android') {
      ToastAndroid.showWithGravity(
        successMessage,
        ToastAndroid.SHORT,
        ToastAndroid.CENTER
      );
    }
  }, [clipboardText, successMessage]);

  return (
    <TouchableOpacity onPress={onCopyToClipboard}>{children}</TouchableOpacity>
  );
};
