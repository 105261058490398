import { RouteProp, useRoute } from '@react-navigation/native';
import React, { FC } from 'react';

import { TabViewBar } from '../../components/tab-view-bar/tab-view-bar';
import { RootStackParamList } from '../../navigation/types';
import { MembersListScreen } from './members-list-screen';
import { MembersTreeScreen } from './members-tree-screen';

export const MembersOverviewScreen: FC = () => {
  const route = useRoute<RouteProp<RootStackParamList, 'MembersOverview'>>();
  const props = {
    selectedTabIndex: route?.params?.selectedTabIndex || 0,
    config: [
      { key: 'membersList', title: 'List', component: MembersListScreen },
      {
        key: 'membersTree',
        title: 'Tree',
        component: MembersTreeScreen,
      },
    ],
  };

  return <TabViewBar {...props} />;
};
