import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={43} height={24} viewBox="0 0 43 24" fill="none" {...props}>
      <Path
        d="M32 4H5a4 4 0 00-4 4v11a4 4 0 004 4h26.5a4 4 0 004-4v0"
        stroke="#FFFFFF"
      />
      <Path d="M1 8a4 4 0 014-4h5v19H5a4 4 0 01-4-4V8z" stroke="#FFFFFF" />
      <Path
        d="M25.094 15.492c0 .709-.266 1.294-.797 1.758-.531.458-1.274.747-2.227.867v1.813h-1.008v-1.742a10.6 10.6 0 01-1.695-.133c-.547-.094-.995-.222-1.344-.383v-1.219c.433.193.93.352 1.493.477.567.12 1.083.18 1.547.18v-3.438c-1.068-.339-1.818-.732-2.25-1.18-.428-.448-.641-1.026-.641-1.734 0-.682.263-1.242.789-1.68.531-.437 1.232-.703 2.102-.797V6.86h1.007v1.407a7.93 7.93 0 012.774.578l-.407 1.023a7.933 7.933 0 00-2.367-.547v3.39c.818.261 1.43.517 1.836.767.407.244.706.528.899.851.192.318.289.706.289 1.164zm-1.328.102c0-.375-.118-.677-.352-.906-.23-.235-.677-.467-1.344-.696v3.04c1.13-.157 1.696-.636 1.696-1.438zm-4.274-4.852c0 .396.117.714.352.953.234.24.64.466 1.218.68V9.352c-.515.083-.906.247-1.171.492a1.16 1.16 0 00-.399.898z"
        fill="#FFFFFF"
      />
      <Path
        d="M39.237 9.243l-3.299 5.938a.5.5 0 01-.874 0l-3.288-5.939a.5.5 0 01.438-.742H38.8a.5.5 0 01.437.743z"
        fill="#fff"
        stroke="#FFFFFF"
      />
      <Path fill="#FFFFFF" d="M35 0h1v9h-1z" />
    </Svg>
  );
};

export default SvgComponent;
