import { SettingsActions, toggleThemeAction } from '../actions/settings';
import produce from 'immer';
import { Reducer } from 'redux';
import { darkTheme, lightTheme, ThemeConfig } from '../../../hooks/use-theme';

const initialState: SettingsState = {
  theme: lightTheme,
};

export interface SettingsState {
  readonly theme: ThemeConfig;
}

export const settingsReducer: Reducer<SettingsState, SettingsActions> = (
  state = initialState,
  action
) =>
  produce(state, (d) => {
    switch (action.type) {
      case toggleThemeAction.getType():
        d.theme = d.theme.name === lightTheme.name ? darkTheme : lightTheme;
        return;
    }
  });
