import React, { FC } from 'react';
import { View, Text } from 'react-native';
import { useTheme } from '../../hooks/use-theme';
import { ApiError } from '../../services/api/types';
import { Button } from '../button/button';

interface ApiErrorInfoProps {
  error: ApiError;
  onButtonPress?: () => void;
}

export const ApiErrorInfo: FC<ApiErrorInfoProps> = (props) => {
  const { error, onButtonPress } = props;
  const { theme } = useTheme();
  const { textStyles } = theme;
  return (
    <View>
      <Text style={textStyles.H1Left}>Api Error: {error && error.type}</Text>
      {onButtonPress ? (
        <Button onPress={onButtonPress} text={'Retry'} theme={theme} />
      ) : null}
    </View>
  );
};
