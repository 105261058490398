import { Dimensions, Platform } from 'react-native';
import { ThemeConfig } from '../hooks/use-theme';

// Window
export const SMALL_SCREEN_MAX_WIDTH = 768;
const isSmallScreen = () =>
  Dimensions.get('window').width < SMALL_SCREEN_MAX_WIDTH;

// Navigation
const IOS_HEADER_HEIGHT = 75;
const ANDROID_HEADER_HEIGHT = 80;
const WEB_HEADER_HEIGHT = 57;

const getHeaderHeight = () => {
  switch (Platform.OS) {
    case 'ios':
      return IOS_HEADER_HEIGHT;
    case 'android':
      return ANDROID_HEADER_HEIGHT;
    default:
      return WEB_HEADER_HEIGHT;
  }
};

export const HEADER_HEIGHT = getHeaderHeight();
export const NAVIGATION_HEADER_TOP_PADDING =
  Platform.OS === 'ios' || Platform.OS === 'android' ? 38 : 17;
export const BOTTOM_TAB_NAVIGATION_HEIGHT = 60;

// Dashboard
export const DASHBOARD_TILE_WIDTH = 350;
export const DASHBOARD_TILE_HEIGHT_XXL = 280;
export const DASHBOARD_TILE_HEIGHT_XL = 240;
export const DASHBOARD_TILE_HEIGHT_LARGE = 190;
export const DASHBOARD_TILE_HEIGHT_MEDIUM = 168;
export const DASHBOARD_TILE_HEIGHT_SMALL = 144;
export const DASHBOARD_TILE_HEIGHT_XS = 118;
export const DASHBOARD_TILE_HEIGHT_XXS = 92;

export const DASHBOARD_TILE_FIRST_COLUMN_WIDTH = 60;

// Tables
const MOBILE_TABLE_WIDTH = 330;
const MOBILE_TABLE_HEIGHT = 380;
export const TABLE_ROW_HEIGHT = 32;
export const getTableDefaultWidth = () => {
  const screenWidth = Dimensions.get('window').width;
  const ret = isSmallScreen() ? MOBILE_TABLE_WIDTH : screenWidth * 0.9;
  return ret;
};
export const getTableDefaultHeight = () => {
  const screenHeight = Dimensions.get('window').height;
  const ret = isSmallScreen() ? MOBILE_TABLE_HEIGHT : screenHeight * 0.8;
  return ret;
};

export const TABLE_COLUMN_WIDTH_DATE = 80;
export const TABLE_COLUMN_WIDTH_CURRENCY_SYMBOL_RIGHT = 90;
export const TABLE_COLUMN_WIDTH_CURRENCY_SYMBOL_LEFT = 90;
export const TABLE_COLUMN_WIDTH_CHECKBOX = 25;

export const TABLE_COLUMN_WIDTH_XS = 70;
export const TABLE_COLUMN_WIDTH_S = 80;
export const TABLE_COLUMN_WIDTH_SM = 85;

// Background Image
export const BACKGROUND_IMAGE_LIGHT_WIDTH = 1200;
export const BACKGROUND_IMAGE_LIGHT_HEIGHT = 2999;

export const BACKGROUND_IMAGE_DARK_WIDTH = 1200;
export const BACKGROUND_IMAGE_DARK_HEIGHT = 3430;

export const getBackgroundImageSize = (theme: ThemeConfig) => {
  switch (theme.name) {
    case 'dark':
      return {
        width: BACKGROUND_IMAGE_DARK_WIDTH,
        height: BACKGROUND_IMAGE_DARK_HEIGHT,
      };
    case 'light':
      return {
        width: BACKGROUND_IMAGE_LIGHT_WIDTH,
        height: BACKGROUND_IMAGE_LIGHT_HEIGHT,
      };
  }
};

// more navigation
export const NAVIGATION_HEADLINE_HEIGHT = 72;
export const NAVIGATION_TILE_HEIGHT = 62;
const NAVIGATION_TILE_WIDTH_WEB = 400;
export const getNavigationTileWidth = () => {
  const screenWidth = Dimensions.get('window').width;
  return isSmallScreen() ? screenWidth : NAVIGATION_TILE_WIDTH_WEB;
};
