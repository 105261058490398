import React, { FC } from 'react';
import { StyleSheet, ActivityIndicator, View } from 'react-native';

export type DisplayType =
  | 'DASHBOARD_USER_INFO'
  | 'DASHBOARD_SPONSOR_INFO'
  | 'DASHBOARD_REFERRAL_LINKS'
  | 'DASHBOARD_TOTAL_COMMISSIONS'
  | 'DASHBOARD_UNPLACED_MEMBERS'
  | 'DASHBOARD_LATEST_NEWS'
  | 'DASHBOARD_LATEST_MESSAGES'
  | 'DASHBOARD_MEMBER_LEADERBOARD'
  | 'DASHBOARD_USER_BONUS_PROGRAM'
  | 'DASHBOARD_LATEST_PAYOUTS'
  | 'DASHBOARD_USER_CERTIFICATES'
  | 'DASHBOARD_LATEST_SUPPORT_TICKETS'
  | 'DASHBOARD_LATEST_SUBSCRIPTIONS'
  | 'DASHBOARD_LATEST_ORDERS'
  | 'PAYOUTS_SCREEN'
  | 'ORDERS_SCREEN'
  | 'MESSAGES_SCREEN'
  | 'MEMBERS_SCREEN'
  | 'LOGIN_SCREEN'
  | 'NEWS_DETAIL_SCREEN';

export interface PlaceHolderLoaderProps {
  displayType: DisplayType;
}

export const PlaceHolderLoader: FC<PlaceHolderLoaderProps> = () => {
  // TODO: remove dummy implementation
  //       and implement different loaders based on props.displayType
  return (
    <View style={styles.container}>
      <ActivityIndicator />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'transparent',
  },
});
