import { FullPaginationParams } from './../services/api/types';
import { PaginatedResponse, ApiResult } from '../services/api/types';
import { useFetchData } from './use-fetch-data';
import { usePaginationParams } from './use-pagination-params';
import { ApiType } from '../services/api';

export function useFetchPaginated<
  TEntity,
  TParams extends FullPaginationParams
>(
  Api: ApiType,
  apiEndpoint: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params?: any
  ) => Promise<ApiResult<PaginatedResponse<TEntity[]>>>,
  initialQueryParams: TParams
) {
  const {
    queryParams,
    onPaginate,
    onChangePageSize,
    onChangeSort,
  } = usePaginationParams(initialQueryParams);
  const { loading, error, data, refetch } = useFetchData<TEntity>(
    Api,
    apiEndpoint,
    queryParams
  );

  return {
    queryParams,
    onPaginate,
    onChangePageSize,
    onChangeSort,
    loading,
    error,
    data,
    refetch,
  };
}
