import React from 'react';
import {
  View,
  Image,
  Text,
  StyleSheet,
  ImageSourcePropType,
} from 'react-native';

import { storiesOf } from '@storybook/react-native';
import { images } from './';

const renderImage = (
  imageName: string,
  imageSource:
    | ImageSourcePropType
    | { [imageName: string]: ImageSourcePropType }
) => {
  const isImageSource = typeof imageSource !== 'object';
  return (
    <View key={imageName} style={styles.item}>
      <Text style={styles.imageName}>{imageName}</Text>
      {isImageSource && <Image style={styles.image} source={imageSource} />}
    </View>
  );
};

storiesOf('Images', module).add('All images', () => {
  return (
    <View>
      {Object.entries(images).map((object) =>
        renderImage(object[0], object[1])
      )}
      {Object.entries(images.ranks).map((object) =>
        renderImage(object[0], object[1])
      )}
      {Object.entries(images.icons).map((object) =>
        renderImage(object[0], object[1])
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
  },
  imageName: {
    width: 200,
  },
  image: {
    flex: 1,
    resizeMode: 'contain',
    height: 53,
  },
});
