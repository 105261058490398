import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg viewBox="-30 -210 2570 2248" {...props}>
      <Path
        fill="currentColor"
        d="M1073 1102v47h228v-31q0-73 28-123 29-50 146-146 60-49 100-85.5t60-62.5q42-53 62-111.5t20-141.5q0-89-31-160t-91.5-120.5-149-76T1242 65q-203 0-436 122l86 189q216-103 346-103 103 0 162 50.5t59 141.5q0 85-35 143-17 30-56 68t-101 87q-57 45-95 85.5t-58 79.5q-41 79-41 174zm308 16v110H994v-126q0-114 47-205 25-47 69-95t109-101q100-80 122-109t31-59 9-68q0-50-34-82.5T1238 351q-36 0-75.5 8.5T1081 382q-42 15-98 39.5T855 481L703 155q299-169 539-169 257 0 405 124 75 63 112.5 146t37.5 192q0 137-59 238-29 51-82.5 106.5T1523 910q-49 39-79 68t-41 46q-12 17-17 41t-5 53zm-180 310q-166 0-166 158 0 44 12.5 74.5t34.5 49.5 52 27.5 65 8.5q81 0 123-42.5t42-117.5q0-158-163-158zm0 396q-115 0-180.5-61.5T955 1586t66-175.5 178-60.5q116 0 180.5 62t64.5 174q0 54-16.5 98t-48 75-76.5 48-102 17z"
      />
    </Svg>
  );
};

export default SvgComponent;
