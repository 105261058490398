import { withOpacity } from '../../utils/format-color';
import { colorNames, colorNames2 } from '../color-names';

export const colors = {
  primary: colorNames.brandPrimary,
  background: colorNames.white,
  backgroundOpacity: withOpacity(colorNames.white, 0.9),
  backgroundOpacity2: withOpacity(colorNames.white, 0.9),
  card: colorNames.white,
  text: colorNames.darkBlue,
  textAlternative: colorNames.grey,
  border: colorNames.veryLightGrey,
  notification: colorNames.darkBlue,
  link: colorNames.brandSecondary,
  linkAlternative: colorNames.brandSecondary,
  chartLine: colorNames.green,
  chartLine2: colorNames.lightGreen,
  chartBorder: colorNames.mediumGrey,
};

export const colors2 = {
  primary: colorNames2.brandPrimary,
  secondary: colorNames2.brandSecondary,
  errorText: colorNames2.red,
  inactiveBorder: colorNames2.lightGrey,
  text: colorNames2.black,
  background: colorNames2.almostWhite,
  backgroundHeader: colorNames2.white,

  // TODO: re-define new colors below here
  backgroundOpacity: withOpacity(colorNames2.white, 0.9),
  backgroundOpacity2: withOpacity(colorNames2.white, 0.9),
  card: colorNames2.white,
  headlineText: colorNames2.almostBlack,

  textAlternative: colorNames2.grey,
  border: colorNames2.lightGrey,
  notification: colorNames2.darkBlue,
  link: colorNames2.brandSecondary,
  linkAlternative: colorNames2.brandSecondary,

  linkBoxLevel1: colorNames2.white,
  linkBoxLevel2: colorNames2.grey,
};
