import numbro from 'numbro';

export const formatCurrency = (
  currencyAmount?: number,
  currencyISOCode?: string
) => {
  if (currencyAmount === undefined || currencyISOCode === undefined) {
    return '';
  }

  return numbro(currencyAmount).format({
    thousandSeparated: true,
    mantissa: 2,
  });

  // NOTE: code below not working on android
  // https://blog.bam.tech/developer-news/formating-numbers-in-react-native
  //
  // return currencyAmount.toLocaleString('en-US', {
  //   minimumFractionDigits: 2,
  //   maximumFractionDigits: 2,
  // });
};
