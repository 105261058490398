import React, { FC } from 'react';
import { Image, TouchableOpacity, StyleSheet, TextStyle } from 'react-native';
import { getRankImage } from '../../assets/images';
import { RankNames } from '../../services/api/types';
import { spacing } from '../../theme/spacing';

interface RankImageProps {
  rankName: RankNames;
  style?: TextStyle;
  onPress?: () => void;
}
const RANK_IMAGE_SIZE = 40;

export const RankImage: FC<RankImageProps> = (props) => {
  const { rankName, onPress, style } = props;
  const rankImage = getRankImage(rankName);
  return (
    <TouchableOpacity
      key={rankName}
      onPress={onPress}
      style={style ? [style, styles.container] : styles.container}
    >
      {rankImage && (
        <Image
          source={rankImage}
          style={{ width: RANK_IMAGE_SIZE, height: RANK_IMAGE_SIZE }}
        />
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: spacing[1],
    paddingHorizontal: spacing[1],
  },
});
