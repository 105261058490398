import { takeEvery } from 'redux-saga/effects';
import { ApiType } from '../../api';
import {
  fetchLatestMessagesActions,
  fetchMessagesActions,
  fetchUnreadMessagesCountActions,
} from '../actions/messages';
import { callApiEndpoint } from './api';

export const watchFetchLatestMessages = (Api: ApiType) =>
  takeEvery(fetchLatestMessagesActions.types.trigger, fetchLatestMessages, Api);

function* fetchLatestMessages(
  Api: ApiType,
  _: ReturnType<typeof fetchLatestMessagesActions.trigger>
) {
  yield callApiEndpoint(
    fetchLatestMessagesActions,
    Api.getLatestMessages,
    undefined,
    Api
  );
}

export const watchFetchMessages = (Api: ApiType) =>
  takeEvery(fetchMessagesActions.types.trigger, fetchMessages, Api);

function* fetchMessages(
  Api: ApiType,
  action: ReturnType<typeof fetchMessagesActions.trigger>
) {
  yield callApiEndpoint(
    fetchMessagesActions,
    Api.getMessages,
    action.payload.params,
    Api
  );
}

// unread messages

export const watchFetchUnreadMessagesCount = (Api: ApiType) =>
  takeEvery(
    fetchUnreadMessagesCountActions.types.trigger,
    fetchUnreadMessagesCount,
    Api
  );

function* fetchUnreadMessagesCount(
  Api: ApiType,
  _: ReturnType<typeof fetchUnreadMessagesCountActions.trigger>
) {
  yield callApiEndpoint(
    fetchUnreadMessagesCountActions,
    Api.getUnreadMessagesCount,
    undefined,
    Api
  );
}
