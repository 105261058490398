import * as React from 'react';
import Svg, { Rect, Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={32} height={29} viewBox="0 0 32 29" fill="none" {...props}>
      <Rect
        x={14.384}
        y={0.755}
        width={1}
        height={31}
        rx={0.5}
        transform="rotate(27 14.384 .755)"
        fill="#FFFFFF"
      />
      <Path
        d="M14.84 6.185c.682-1.414 2.64-1.555 3.519-.255l.383.567a8.843 8.843 0 008.074 3.858 1.725 1.725 0 011.727 2.407l-2.15 4.94A4.454 4.454 0 0123.1 20.31 10.398 10.398 0 0112.64 15.9l-.326-.483a3.5 3.5 0 01-.253-3.483l2.777-5.75z"
        stroke="#FFFFFF"
      />
    </Svg>
  );
};

export default SvgComponent;
