import {
  fetchLatestSupportTicketsActions,
  SupportTicketsActions,
} from '../actions/support-tickets';
import produce from 'immer';
import { Reducer } from 'redux';
import { ApiError, SupportTicketOrSupportTicketShort } from '../../api/types';
import { HashMap, normalize } from '../../data/normalize';
import { fetchOpenSupportTicketsCountActions } from '../actions/support-tickets';

const initialState: SupportTicketsState = {
  latestLoading: false,
  openCountLoading: false,
  data: {
    entities: {},
    totalCount: 0,
    latestIds: [],
  },
};

export interface SupportTicketsState {
  readonly latestLoading: boolean;
  readonly latestError?: ApiError;
  readonly openCountLoading: boolean;
  readonly openCountError?: ApiError;
  readonly data: {
    entities: HashMap<SupportTicketOrSupportTicketShort>;
    latestIds?: number[];
    totalCount?: number;
    openCount?: number;
  };
}

export const supportTicketsReducer: Reducer<
  SupportTicketsState,
  SupportTicketsActions
> = (state = initialState, action) =>
  produce(state, (d) => {
    switch (action.type) {
      case fetchLatestSupportTicketsActions.types.start:
        d.latestError = undefined;
        d.latestLoading = true;
        return;
      case fetchLatestSupportTicketsActions.types.success:
        const { ids: latestIds, entities: latestEntities } = normalize<
          SupportTicketOrSupportTicketShort
        >(action.payload.response.data);
        d.data = {
          latestIds,
          entities: { ...state?.data?.entities, ...latestEntities },
          totalCount: action.payload.response.total,
        };
        d.latestLoading = false;
        return;
      case fetchLatestSupportTicketsActions.types.failure:
        d.latestError = action.payload.error;
        d.latestLoading = false;
        return;

      // open support tickets
      case fetchOpenSupportTicketsCountActions.types.start:
        d.openCountError = undefined;
        d.openCountLoading = true;
        return;
      case fetchOpenSupportTicketsCountActions.types.success:
        d.data.openCount = action.payload.response?.count;
        d.openCountLoading = false;
        return;
      case fetchOpenSupportTicketsCountActions.types.failure:
        d.openCountError = action.payload.error;
        d.openCountLoading = false;
        return;
    }
  });
