import React, { FC } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { useTheme } from '../../hooks/use-theme';
import { Screen } from '../screen/screen';

export interface WipScreenProps {
  headline?: string;
  bodyText?: string;
}

export const WipScreen: FC<WipScreenProps> = (props) => {
  const { children, bodyText, headline } = props;
  const {
    theme: { textStyles, colors },
  } = useTheme();
  const backgroundStyle = {
    backgroundColor: colors.background,
  };

  return (
    <Screen style={backgroundStyle}>
      <View style={styles.container}>
        <Text style={textStyles.H1Left}>{headline ? headline : null}</Text>
        <Text style={textStyles.BorderTextLeft}>(work in progress)</Text>
        {bodyText ? (
          <Text style={textStyles.BodyTextLeft}>{bodyText}</Text>
        ) : null}
      </View>
      {children}
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    marginBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 10,
  },
});
