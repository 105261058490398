import { takeEvery } from 'redux-saga/effects';
import { ApiType } from '../../api';
import {
  fetchLatestOrdersActions,
  fetchOrdersActions,
} from '../actions/orders';
import { callApiEndpoint } from './api';

export const watchFetchLatestOrders = (Api: ApiType) =>
  takeEvery(fetchLatestOrdersActions.types.trigger, fetchLatestOrders, Api);

function* fetchLatestOrders(
  Api: ApiType,
  _: ReturnType<typeof fetchLatestOrdersActions.trigger>
) {
  yield callApiEndpoint(
    fetchLatestOrdersActions,
    Api.getLatestOrders,
    undefined,
    Api
  );
}

export const watchFetchOrders = (Api: ApiType) =>
  takeEvery(fetchOrdersActions.types.trigger, fetchOrders, Api);

function* fetchOrders(
  Api: ApiType,
  action: ReturnType<typeof fetchOrdersActions.trigger>
) {
  yield callApiEndpoint(
    fetchOrdersActions,
    Api.getOrders,
    action.payload.params,
    Api
  );
}
