import * as React from 'react';
import Svg, { Circle, Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <Circle cx={16} cy={16} r={16} fill="#717570" />
      <Path
        d="M11.55 10.508a7.055 7.055 0 004.506 12.485v0a7.055 7.055 0 004.233-12.7M16.055 8v9.701"
        stroke="#fff"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default SvgComponent;
