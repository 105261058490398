import React, { useEffect } from 'react';
import { loadAsync } from 'expo-font';
import { preventAutoHideAsync, hideAsync } from 'expo-splash-screen';

const useCachedResources = (): boolean => {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    const loadResourcesAndDataAsync = async () => {
      try {
        preventAutoHideAsync();

        // Load fonts
        await loadAsync({
          'opensans-regular': require('../assets/fonts/OpenSans-Regular.ttf'),
          'opensans-bold': require('../assets/fonts/OpenSans-Bold.ttf'),
          'opensans-semibold': require('../assets/fonts/OpenSans-SemiBold.ttf'),
          'opensans-light': require('../assets/fonts/OpenSans-Light.ttf'),
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        // eslint-disable-next-line no-console
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        hideAsync();
      }
    };

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
};

export default useCachedResources;
