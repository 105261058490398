import AsyncStorage from '@react-native-async-storage/async-storage';

// More info here:  https://shift.infinite.red/shipping-persistant-reducers-7341691232b1
export const REDUX_PERSIST_CONFIG = {
  reducerVersion: '1.0',
  storeConfig: {
    key: 'primary',
    storage: AsyncStorage,
    whitelist: ['auth'],
  },
};
