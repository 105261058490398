import qs from 'qs';
import { ApiConfig, Response } from './types';
import {
  createCustomErrorNotLoggedIn,
  createApiErrorUnknown,
  createCustomErrorTooManyRequests,
} from './api-error';

export const DEFAULT_API_CONFIG: ApiConfig = {
  getBaseURL: () => '',
  path: '/',
  method: 'GET',
  paramsSerializer: (params) => qs.stringify(params),
  transformRequest: (request) => {
    const { body, headers } = request;

    return {
      ...request,
      body: JSON.stringify(body),
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
    };
  },
  transformResponse: (response: Response) => {
    return response.data;
  },
  transformError: async (response: Response) => {
    const loginExpired = 401;
    const tooManyRequests = 429;

    switch (response.status) {
      case loginExpired: {
        return createCustomErrorNotLoggedIn();
      }
      case tooManyRequests: {
        return createCustomErrorTooManyRequests();
      }
      default: {
        return createApiErrorUnknown();
      }
    }
  },
  validateStatus: (status) => {
    return status >= 200 && status < 300;
  },
};
