import React, { FC } from 'react';
import { View, StyleSheet } from 'react-native';
import { SvgNames, SvgImage } from '../svg-image/svg-image';
import { useTheme } from '../../hooks/use-theme';

const DEFAULT_ACTIVE_STATUS_SVG: SvgNames = 'checkmark-p3';

export interface StatusIconProps {
  status: string;
  activeStatus: string;
  activeSvg?: SvgNames;
  inactiveSvg?: SvgNames;
  align?: 'left' | 'right';
}

export const StatusIcon: FC<StatusIconProps> = (props) => {
  const { status, activeStatus, activeSvg, inactiveSvg, align } = props;
  const { theme } = useTheme();

  const svgImageType: SvgNames | undefined =
    status === activeStatus
      ? activeSvg || DEFAULT_ACTIVE_STATUS_SVG
      : inactiveSvg;

  if (svgImageType === undefined) {
    return null;
  }

  const containerStyle =
    align === 'left' ? styles.containerAlignLeft : styles.containerAlignRight;

  return (
    <View style={[styles.container, containerStyle]}>
      <SvgImage
        type={svgImageType}
        variant={theme.name}
        width={'14'}
        height={'14'}
        withPadding={true}
      />
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'baseline',
    marginTop: 4, // NOTE: half the icon height TODO:FIXME
  },
  containerAlignLeft: {
    justifyContent: 'flex-start',
  },
  containerAlignRight: {
    justifyContent: 'flex-end',
  },
});
