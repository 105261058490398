import { ApiType } from '.';

export const updateAuthHeader = (
  Api: ApiType,
  tokens: string | null | undefined
) => {
  if (tokens === null || tokens === undefined) {
    if (Api.client.instanceConfig.headers !== undefined) {
      delete Api.client.instanceConfig.headers.Authorization;
    }
  } else {
    Api.client.instanceConfig.headers = {
      ...Api.client.instanceConfig.headers,
      Authorization: `Bearer ${tokens}`,
    };
  }
};
