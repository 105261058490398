import React, { FC } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

import { RootState } from '../../services/redux/reducers';
import { UserState } from '../../services/redux/reducers/user-info';
import { ThemeConfig } from '../../hooks/use-theme';
import {
  DASHBOARD_TILE_WIDTH,
  DASHBOARD_TILE_HEIGHT_XS,
} from '../../theme/fixed-sizes';

interface DashboardHeadlineProps {
  theme: ThemeConfig;
}

export const DashboardHeadline: FC<DashboardHeadlineProps> = (props) => {
  const {
    theme: { textStyles },
  } = props;

  const userState = useSelector<RootState, UserState>((state) => state.user);
  const { data: userData } = userState;
  return (
    <View style={styles.container}>
      <Text style={textStyles.H1Center}>
        {userData ? `Welcome ${userData.user.username}!` : ''}
      </Text>
      <Text style={textStyles.H2Center}>
        {userData ? 'Your current rank is: ' : ''}
      </Text>
      <Text style={textStyles.H2CenterBold}>
        {userData ? userData?.user?.currentRankName : ''}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: DASHBOARD_TILE_WIDTH,
    height: DASHBOARD_TILE_HEIGHT_XS,
    justifyContent: 'center',
  },
});
