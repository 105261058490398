import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={12} height={19} viewBox="0 0 12 19" fill="none" {...props}>
      <Path d="M10.002 0l1.5 1.5-8.5 8.5-1.5-1.5 8.5-8.5z" fill="#fff" />
      <Path
        d="M.516 9.513l1.5-1.5L11.502 17l-1.5 1.5L.515 9.513z"
        fill="#fff"
      />
    </Svg>
  );
};

export default SvgComponent;
