import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, StyleSheet, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../navigation/types';

import { RootState } from '../../services/redux/reducers';
import { NewsState } from '../../services/redux/reducers/news';
import { fetchLatestNewsActions } from '../../services/redux/actions/news';
import { spacing } from '../../theme/spacing';
import {
  DASHBOARD_TILE_FIRST_COLUMN_WIDTH,
  DASHBOARD_TILE_HEIGHT_LARGE,
  DASHBOARD_TILE_WIDTH,
  TABLE_COLUMN_WIDTH_DATE,
} from '../../theme/fixed-sizes';
import { formatDateStandard } from '../../utils/format-date';

import { Table, TableConfigItem } from '../../components/table/table';
import { NewsOrNewsShort, NewsShort } from '../../services/api/types';
import { useTheme } from '../../hooks/use-theme';
import { DashboardTileLoading } from '../../components/dashboard-tile-loading/dashboard-tile-loading';

const NEWS_TABLE_CONFIG: TableConfigItem<NewsOrNewsShort>[] = [
  {
    width: TABLE_COLUMN_WIDTH_DATE,
    render: (newsItem) => formatDateStandard(newsItem.date),
  },
  {
    width: 250,
    render: (newsItem) => newsItem.headline,
  },
];

export const DashboardLatestNews: FC = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const latestNewsSelector = useSelector<RootState, NewsState>(
    (state) => state.news
  );
  const {
    latestLoading: loading,
    latestError: error,
    latestData: data,
  } = latestNewsSelector;
  const { theme } = useTheme();
  const { textStyles } = theme;

  const dispatch = useDispatch();
  const refetch = () => {
    dispatch(fetchLatestNewsActions.trigger(undefined));
  };

  const navigateToNews = () => {
    navigation.navigate('BottomTabs', {
      screen: 'InboxOverview',
      selectedTabIndex: 0,
    });
  };

  const navigateToNewsItem = (news: NewsShort) => {
    navigation.navigate('NewsDetail', {
      id: news.id,
    });
  };

  const getButtonConfig = () => {
    return [
      {
        onPress: navigateToNews,
        title: 'Show all news',
      },
    ];
  };
  return (
    <DashboardTileLoading
      loading={loading}
      error={error}
      data={data}
      refetch={refetch}
      style={styles.container}
      icon="news"
      buttons={getButtonConfig()}
      theme={theme}
      placeholderLoaderType="DASHBOARD_LATEST_NEWS"
    >
      <Text style={[styles.margin, textStyles.H3Left]}>Latest news</Text>
      <View style={styles.tableContainer}>
        {data ? (
          <Table<NewsShort>
            data={data && data.slice ? data.slice(0, 3) : []}
            onRowPress={navigateToNewsItem}
            config={NEWS_TABLE_CONFIG}
            theme={theme}
          />
        ) : null}
      </View>
    </DashboardTileLoading>
  );
};

const styles = StyleSheet.create({
  container: {
    height: DASHBOARD_TILE_HEIGHT_LARGE,
    width: DASHBOARD_TILE_WIDTH,
  },
  margin: {
    paddingBottom: spacing[1],
  },
  tableContainer: {
    marginLeft: -DASHBOARD_TILE_FIRST_COLUMN_WIDTH,
  },
});
