import React from 'react';
import { storiesOf } from '@storybook/react-native';
import { Table, TableConfigItem } from './table';
import { Payout } from '../../services/api/types';
import { formatDateStandard } from '../../utils/format-date';
import { darkTheme, lightTheme } from '../../hooks/use-theme';
import { TABLE_COLUMN_WIDTH_S } from '../../theme/fixed-sizes';
import { Currency } from '../currency/currency';
const TEST_PAYOUTS: Payout[] = Object.values({
  '49829': {
    id: 49829,
    netAmount: 276.11,
    taxPercent: 19,
    totalAmount: 328.5709,
    currency: 'EUR',
    status: 'released',
    date: '2020-09-27T20:05:58',
  },
  '49832': {
    id: 49832,
    netAmount: 847.82,
    taxPercent: 19,
    totalAmount: 1008.9058,
    currency: 'EUR',
    status: 'released',
    date: '2020-10-16T06:53:39',
  },
  '49834': {
    id: 49834,
    netAmount: 168.8,
    taxPercent: 19,
    totalAmount: 200.872,
    currency: 'EUR',
    status: 'released',
    date: '2020-10-15T21:36:26',
  },
  '49847': {
    id: 49847,
    netAmount: 420.28,
    taxPercent: 19,
    totalAmount: 500.13319999999993,
    currency: 'EUR',
    status: 'released',
    date: '2020-09-26T11:22:20',
  },
  '49850': {
    id: 49850,
    netAmount: 976.82,
    taxPercent: 19,
    totalAmount: 1162.4158,
    currency: 'EUR',
    status: 'released',
    date: '2020-10-06T16:25:41',
  },
  '49851': {
    id: 49851,
    netAmount: 78.47,
    taxPercent: 19,
    totalAmount: 93.3793,
    currency: 'EUR',
    status: 'released',
    date: '2020-10-11T06:45:05',
  },
  '49888': {
    id: 49888,
    netAmount: 525.3,
    taxPercent: 19,
    totalAmount: 625.107,
    currency: 'EUR',
    status: 'released',
    date: '2020-10-10T03:09:20',
  },
  '49904': {
    id: 49904,
    netAmount: 540.92,
    taxPercent: 19,
    totalAmount: 643.6947999999999,
    currency: 'EUR',
    status: 'released',
    date: '2020-10-01T22:56:53',
  },
  '49906': {
    id: 49906,
    netAmount: 264.8,
    taxPercent: 19,
    totalAmount: 315.112,
    currency: 'EUR',
    status: 'released',
    date: '2020-10-09T10:21:38',
  },
  '49909': {
    id: 49909,
    netAmount: 893.8,
    taxPercent: 19,
    totalAmount: 1063.6219999999998,
    currency: 'EUR',
    status: 'released',
    date: '2020-09-25T13:03:39',
  },
});
const dummyOnRowPress = () => {};

const PAYOUTS_TABLE_CONFIG: TableConfigItem<Payout>[] = [
  {
    header: 'Date',
    width: TABLE_COLUMN_WIDTH_S,

    render: (payout) => formatDateStandard(payout.date),
  },
  {
    header: 'Total Amount',
    width: 150,
    align: 'right',
    render: (payout) => (
      <Currency
        amount={payout.totalAmount}
        currency={payout.currency}
        symbolPosition={'left'}
      />
    ),
  },
  {
    header: 'Status',
    width: 200,
    render: (payout) => `status: ${payout.status}`,
  },
];

storiesOf('Table', module)
  .add('Payouts table light', () => {
    return (
      <Table<Payout>
        data={TEST_PAYOUTS}
        onRowPress={dummyOnRowPress}
        config={PAYOUTS_TABLE_CONFIG}
        theme={lightTheme}
        horizontalScroll
      />
    );
  })
  .add('Payouts table dark', () => {
    return (
      <Table<Payout>
        data={TEST_PAYOUTS}
        onRowPress={dummyOnRowPress}
        config={PAYOUTS_TABLE_CONFIG}
        theme={darkTheme}
      />
    );
  });
