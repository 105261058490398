import { RankNames } from '../../services/api/types';

/* eslint-disable @typescript-eslint/no-var-requires */
export const images = {
  healyTypographySmall: require('./healy-typography-small.png') as number,

  icons: {
    chevronLeft: require('./icons/chevron-left.png') as number,
    chevronRight: require('./icons/chevron-right.png') as number,
  },
  ranks: {
    member: require('./ranks/member.png') as number,
    builder: require('./ranks/builder.png') as number,
    teamLeader: require('./ranks/team-leader.png') as number,
    seniorTeamLeader: require('./ranks/senior-team-leader.png') as number,
    manager: require('./ranks/manager.png') as number,
    seniorManager: require('./ranks/senior-manager.png') as number,
    director: require('./ranks/director.png') as number,
    seniorDirector: require('./ranks/senior-director.png') as number,
    vicePresident: require('./ranks/vice-president.png') as number,
    seniorVicePresident: require('./ranks/senior-vice-president.png') as number,
    president: require('./ranks/president.png') as number,
    seniorPresident: require('./ranks/senior-president.png') as number,
  },
};

export type ThemeImages = { [key: string]: number };
export type ThemeImagesConfig = {
  [key: string]: ThemeImages;
};
export const themeImages: ThemeImagesConfig = {
  dark: {
    background: require('../theme/images/dark/background-repeatable-y.png') as number,
    healyLogoHorizontal: require('../theme/images/dark/healy-logo-horizontal.png') as number,
    commissionsHistoryDummy: require('../theme/images/dark/commissions-history-dummy.png') as number,
    healyLogo1: require('./healy-logo1.png') as number,
    backgroundWave: require('./background-wave.png') as number,
    backgroundHeroWatch: require('./background-hero-watch.png') as number,
  },
  light: {
    background: require('../theme/images/light/background-repeatable-y.png') as number,
    healyLogoHorizontal: require('../theme/images/light/healy-logo-horizontal.png') as number,
    commissionsHistoryDummy: require('../theme/images/light/commissions-history-dummy.png') as number,
    healyLogo1: require('./healy-logo1.png') as number,
    backgroundWave: require('./background-wave.png') as number,
    backgroundHeroWatch: require('./background-hero-watch.png') as number,
  },
};
type RankImageMap = { [index in RankNames]: number };

export const getRankImage = (memberRank: RankNames) => {
  const rankImageMap: RankImageMap = {
    // NOTE: ⚠️⚠️⚠️ DO NOT CHANGE THESE KEYS ⚠️⚠️⚠️
    // ⚠️ keep RankNames aligned with values being sent from the API
    // @see: https://gitlab.com/healyworld/backoffice/-/blob/develop/database/seeds/RankSettingTableSeeder.php
    Member: images.ranks.member,
    Builder: images.ranks.builder,
    'Team Leader': images.ranks.teamLeader,
    'Senior Team Leader': images.ranks.seniorTeamLeader,
    Manager: images.ranks.manager,
    'Senior Manager': images.ranks.seniorManager,
    Director: images.ranks.director,
    'Senior Director': images.ranks.seniorDirector,
    'Vice President': images.ranks.vicePresident,
    'Senior Vice President': images.ranks.seniorVicePresident,
    President: images.ranks.president,
    'Senior President': images.ranks.seniorPresident,
  };

  return rankImageMap[memberRank];
};

export type ImageName = keyof typeof images;
