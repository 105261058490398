import { takeEvery } from 'redux-saga/effects';
import { ApiType } from '../../api';
import {
  fetchMembersActions,
  fetchTopCommissionMembersActions,
  fetchTopPerformingMembersActions,
} from '../actions/members';

import { callApiEndpoint } from './api';

// Top Performing
export const watchFetchTopPerformingMembers = (Api: ApiType) =>
  takeEvery(
    fetchTopPerformingMembersActions.types.trigger,
    fetchTopPerformingMembers,
    Api
  );

function* fetchTopPerformingMembers(
  Api: ApiType,
  _: ReturnType<typeof fetchTopPerformingMembersActions.trigger>
) {
  yield callApiEndpoint(
    fetchTopPerformingMembersActions,
    Api.getTopPerformingMembers,
    undefined,
    Api
  );
}

// Top Commission
export const watchFetchTopCommissionMembers = (Api: ApiType) =>
  takeEvery(
    fetchTopCommissionMembersActions.types.trigger,
    fetchTopCommissionMembers,
    Api
  );

function* fetchTopCommissionMembers(
  Api: ApiType,
  _: ReturnType<typeof fetchTopCommissionMembersActions.trigger>
) {
  yield callApiEndpoint(
    fetchTopCommissionMembersActions,
    Api.getTopCommissionMembers,
    undefined,
    Api
  );
}

export const watchFetchMembers = (Api: ApiType) =>
  takeEvery(fetchMembersActions.types.trigger, fetchMembers, Api);
function* fetchMembers(
  Api: ApiType,
  action: ReturnType<typeof fetchMembersActions.trigger>
) {
  yield callApiEndpoint(
    fetchMembersActions,
    Api.getMembers,
    action.payload.params,
    Api
  );
}
