import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { FC, useEffect, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { PlaceHolderLoader } from '../../components/placeholder-loader/placeholder-loader';
import { RootStackParamList } from '../../navigation/types';
import { FullPaginationParams, Subscription } from '../../services/api/types';
import { spacing } from '../../theme/spacing';
import {
  Table,
  TableConfigItem,
  TableSortConfig,
} from '../../components/table/table';
import { formatDateStandard } from '../../utils/format-date';
import { apiService } from '../../services/api/api-service';
import { useFetchPaginated } from '../../hooks/use-fetch-paginated';
import { useTheme } from '../../hooks/use-theme';
import {
  getTableDefaultHeight,
  getTableDefaultWidth,
  TABLE_COLUMN_WIDTH_DATE,
} from '../../theme/fixed-sizes';
import { DEFAULT_PAGE_SIZE } from '../../hooks/use-pagination';
import { PaginationBar } from '../../components/pagination-bar/pagination-bar';
import { LoadingScreen } from '../../components/loading-screen/loading-screen';
import { Currency } from '../../components/currency/currency';
import { StatusBadge } from '../../components/status-badge/status-badge';

const tableConfig: TableConfigItem<Subscription>[] = [
  {
    header: 'Date',
    sortField: 'date',
    width: TABLE_COLUMN_WIDTH_DATE,
    render: (subscription) => formatDateStandard(subscription.date),
  },
  {
    header: 'Status',
    sortField: 'status',
    width: 90,
    render: (subscription) => (
      <StatusBadge
        status={subscription.status}
        width={80}
        colorMap={{
          active: '#56BF99',
          cancelled: '#FF6A6A',
        }}
        defaultColor={'#C4C4C4'}
      />
    ),
  },
  {
    header: 'Product Name',
    sortField: 'productName',
    width: 250,
    render: (subscription) => subscription.productName,
  },
  {
    header: 'Monthly Costs',
    sortField: 'monthlyTotalAmount',
    align: 'right',
    width: 120,
    render: (subscription) => (
      <Currency
        amount={subscription.monthlyTotalAmount}
        currency={subscription.currency}
        symbolPosition={'left'}
      />
    ),
  },
];

export const SubscriptionsScreen: FC = () => {
  const {
    queryParams,
    onPaginate,
    onChangePageSize,
    onChangeSort,
    loading,
    error,
    data,
    refetch,
  } = useFetchPaginated<Subscription, FullPaginationParams>(
    apiService,
    apiService.getSubscriptions,
    {
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      sortBy: 'date',
      sortOrder: 'desc',
    }
  );
  const { theme } = useTheme();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const navigateToSubscription = (subscription: Subscription) => {
    navigation.navigate('Wip', {
      headline: subscription.id + ':' + subscription.date,
      bodyText: subscription.productName,
    });
  };
  const tableSortConfig: TableSortConfig = useMemo(() => {
    return {
      default: {
        sortBy: queryParams.sortBy,
        sortOrder: queryParams.sortOrder,
      },
      onChangeSort,
    };
  }, [onChangeSort, queryParams.sortBy, queryParams.sortOrder]);

  return (
    <LoadingScreen
      loading={loading}
      error={error}
      refetch={refetch}
      placeholderLoaderType="MESSAGES_SCREEN"
    >
      <View style={styles.container}>
        <View style={styles.innerContainer}>
          {loading && <PlaceHolderLoader displayType="MESSAGES_SCREEN" />}
          {!loading && (
            <Table<Subscription>
              data={data.data || []}
              config={tableConfig}
              onRowPress={navigateToSubscription}
              sortConfig={tableSortConfig}
              horizontalScroll
              theme={theme}
            />
          )}
        </View>
        <View style={styles.bottomBar}>
          <PaginationBar
            page={queryParams.page}
            pageSize={queryParams.pageSize}
            sliceSize={5}
            totalCount={data?.total || 0}
            onPaginate={onPaginate}
            onChangePageSize={onChangePageSize}
            theme={theme}
          />
        </View>
      </View>
    </LoadingScreen>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: spacing[2],
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    width: getTableDefaultWidth(),
    minHeight: getTableDefaultHeight(),
    alignItems: 'center',
  },
  bottomBar: {
    flex: 1,
  },
});
