import React, { FC, useMemo, useRef } from 'react';
import {
  View,
  StyleSheet,
  ViewStyle,
  ScrollView,
  StyleProp,
  Platform,
  Animated,
} from 'react-native';

import { useTheme } from '../../hooks/use-theme';
import { useContentDimensions } from '../../hooks/use-content-dimensions';
import { useWindowDimensions } from '../../hooks/use-window-dimensions';
import { BackgroundParallax } from '../background-parallax/background-parallax';
import { getBackgroundImageSize } from '../../theme/fixed-sizes';

interface ScreenProps {
  style?: StyleProp<ViewStyle>;
  flex?: boolean;
  androidCoverBackground?: boolean;
}

// const BackgroundGradient: FC = (props) => {
//   const { children } = props;
//   return (
//     <View style={styles.backgroundImageContainer}>
//       {/* css gradient background:
//       linear-gradient(
//         182.18deg, #56BF99 -8.23%,
//       #003365 17.11%,
//       #83035F 44.23%,
//       #002952 100.25%);
//       */}
//       <LinearGradient
//         colors={['#56BF99', '#003365', '#83035F', '#002952']}
//         start={{ x: 0, y: 0 }}
//         end={{ x: 0.1, y: 1 }}
//         locations={[0, 0.1711, 0.4423, 1]}
//         // locations={[-0.0823, 0.1711, 0.4423, 1.0025]}
//         style={{
//           height: 1200,
//           paddingTop: 90,
//         }}
//       >
//         {children}
//       </LinearGradient>
//     </View>
//   );
// };

export const Screen: FC<ScreenProps> = ({ children }) => {
  const { theme } = useTheme();
  const { colors } = theme;

  const scrollY = useRef(new Animated.Value(0)).current;
  const onScroll = Animated.event(
    [{ nativeEvent: { contentOffset: { y: scrollY } } }],
    {
      useNativeDriver: true,
    }
  );

  const scrollViewRef = useRef<ScrollView>(null);

  const PADDING_BOTTOM = 200;
  const {
    width: backgroundImageWidth,
    height: backgroundImageHeight,
  } = getBackgroundImageSize(theme);
  const { width: screenWidth, height: screenHeight } = useWindowDimensions();
  const { contentSize, onContentLayout } = useContentDimensions();

  // TODO: refactor
  const backgroundSize = useMemo(() => {
    return {
      width: Math.max(
        backgroundImageWidth,
        Math.max(contentSize?.width || 0, screenWidth)
      ),
      height:
        Math.max(
          backgroundImageHeight,
          Math.max(contentSize?.height || 0, screenHeight)
        ) + PADDING_BOTTOM,
    };
  }, [
    contentSize?.width,
    contentSize?.height,
    screenHeight,
    screenWidth,
    backgroundImageWidth,
    backgroundImageHeight,
  ]);

  const { width, height } = backgroundSize;

  const backgroundColor =
    Platform.OS === 'android' && theme.name === 'light'
      ? colors.background
      : colors.backgroundOpacity;

  return (
    <View style={[styles.container, { backgroundColor }]}>
      {!(Platform.OS === 'android' && theme.name === 'light') ? (
        <BackgroundParallax width={width} height={height} scrollY={scrollY} />
      ) : null}
      <Animated.ScrollView
        bounces={false}
        scrollEventThrottle={10}
        ref={scrollViewRef}
        onScroll={onScroll}
      >
        <View
          style={[
            styles.contentContainer,
            {
              minHeight: screenHeight, // NOTE: setting minHeight does the trick, height would disable scrolling
            },
          ]}
        >
          <View style={styles.innerContainer} onLayout={onContentLayout}>
            {children}
          </View>
        </View>
      </Animated.ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    zIndex: 1,
    flex: 1,
  },
  contentContainer: {
    zIndex: 1,
    flex: 1,

    paddingTop: 10,
    alignItems: 'center',
  },
  innerContainer: {
    zIndex: 1,
    alignItems: 'center',
  },
});
