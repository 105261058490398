import * as React from 'react';
import Svg, { Rect, Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={32} height={19} viewBox="0 0 32 19" fill="none" {...props}>
      <Rect
        x={0.5}
        y={1.5}
        width={31}
        height={17}
        rx={3.5}
        fill="#fff"
        stroke="#fff"
      />
      <Path d="M8 1v18" stroke="#003365" />
      <Path
        d="M22.082 11.93c0 .62-.232 1.133-.697 1.539-.465.4-1.115.654-1.949.758v1.586h-.881V14.29c-.51 0-1.005-.039-1.484-.116-.478-.082-.87-.194-1.176-.335v-1.066c.379.168.814.307 1.306.416a6.61 6.61 0 001.354.158v-3.008c-.935-.296-1.59-.64-1.97-1.032-.373-.392-.56-.898-.56-1.518 0-.597.23-1.087.69-1.47.466-.382 1.079-.615 1.84-.697V4.377h.881v1.23a6.938 6.938 0 012.427.506l-.355.896a6.942 6.942 0 00-2.072-.479v2.967c.716.228 1.252.451 1.607.67.355.214.617.463.786.745.169.278.253.618.253 1.019zm-1.162.09c0-.329-.103-.593-.308-.793-.2-.206-.592-.408-1.175-.609v2.66c.988-.137 1.483-.557 1.483-1.258zm-3.74-4.246c0 .347.103.625.308.834.205.21.56.408 1.067.595V6.558c-.451.073-.793.216-1.026.43-.232.21-.348.472-.348.786z"
        fill="#003365"
      />
    </Svg>
  );
};

export default SvgComponent;
