import { GetOrdersParams, Order, PaginatedResponse } from '../../api/types';
import { createApiActions, ExtractActionTypes } from '../create-api-actions';

export const fetchLatestOrdersActions = createApiActions(
  'FETCH_LATEST_ORDERS_TRIGGER',
  'FETCH_LATEST_ORDERS_START',
  'FETCH_LATEST_ORDERS_SUCCESS',
  'FETCH_LATEST_ORDERS_FAILURE'
)<undefined, PaginatedResponse<Order[]>>();

export const fetchOrdersActions = createApiActions(
  'FETCH_ORDERS_TRIGGER',
  'FETCH_ORDERS_START',
  'FETCH_ORDERS_SUCCESS',
  'FETCH_ORDERS_FAILURE'
)<GetOrdersParams, PaginatedResponse<Order[]>>();

export type OrdersActions =
  | ExtractActionTypes<typeof fetchOrdersActions>
  | ExtractActionTypes<typeof fetchLatestOrdersActions>;
