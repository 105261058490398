import React, { FC } from 'react';
import { View, StyleSheet } from 'react-native';

import { ApiError } from '../../services/api/types';
import { spacing } from '../../theme/spacing';
import { ApiErrorInfo } from '../api-error-info/api-error-info';
import { DisplayType } from '../placeholder-loader/placeholder-loader';
import { Screen } from '../screen/screen';

interface LoadingScreenProps {
  error: ApiError | undefined;
  loading: boolean;
  refetch: () => void;
  placeholderLoaderType: DisplayType;
}

export const LoadingScreen: FC<LoadingScreenProps> = (props) => {
  const { error, refetch, children } = props;
  if (error) {
    return (
      <Screen>
        <View style={styles.container}>
          <ApiErrorInfo error={error} onButtonPress={refetch} />
        </View>
      </Screen>
    );
  }

  // if (loading) {
  //   return (
  //     <Screen>
  //       <View style={styles.container}>
  //         <PlaceHolderLoader displayType={placeholderLoaderType} />
  //       </View>
  //     </Screen>
  //   );
  // }

  return <Screen>{children}</Screen>;
};

const styles = StyleSheet.create({
  container: {
    marginTop: spacing[2],
    justifyContent: 'center',
    alignItems: 'center',
  },
});
