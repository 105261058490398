import { Platform } from 'react-native';

interface GetHeightProps {
  web: number;
  native: number;
}
const getHeight = (props: GetHeightProps) => {
  const { native, web } = props;
  switch (Platform.OS) {
    case 'ios':
    case 'android':
      return native;

    default:
      return web;
  }
};

export const H1_FONT_SIZE = getHeight({ native: 20, web: 25 });
export const H1_LINE_HEIGHT = getHeight({ native: 30, web: 38 });
export const H2_FONT_SIZE = getHeight({ native: 17, web: 20 });
export const H2_LINE_HEIGHT = getHeight({ native: 26, web: 30 });
export const H3_FONT_SIZE = getHeight({ native: 20, web: 20 });
export const H3_LINE_HEIGHT = getHeight({ native: 30, web: 30 });
export const TAB_FONT_SIZE = 11;
export const BODY_FONT_SIZE = 14;
export const BODY_LINE_HEIGHT = 20;
export const BODY_XS_FONT_SIZE = 10;
export const BODY_XS_LINE_HEIGHT = 15;
export const BODY_SMALL_FONT_SIZE = 14;
export const BODY_SMALL_LINE_HEIGHT = 18;
export const BODY_BIG_FONT_SIZE = 16;
export const BODY_BIG_LINE_HEIGHT = 24;
export const CURRENCY_SIZE = 20;
export const CURRENCY_LINE_HEIGHT = 27;
export const CURRENCY_BIG_GREEN_SIZE = 37;
export const CURRENCY_BIG_GREEN_LINE_HEIGHT = 50;
export const TABLE_TEXT_FONT_SIZE = 14;
export const TABLE_TEXT_LINE_HEIGHT = 32;
export const TABLE_CURRENCY_FONT_SIZE = 14;
