import { ThemeImages, themeImages } from './../assets/images/index';
import {
  textStyles as lightTextStyles,
  textStyles2 as lightTextStyles2,
} from '../theme/light/typography';
import {
  textStyles as darkTextStyles,
  textStyles2 as darkTextStyles2,
} from '../theme/dark/typography';
import {
  colors as lightColors,
  colors2 as lightColors2,
} from '../theme/light/colors';
import {
  colors as darkColors,
  colors2 as darkColors2,
} from '../theme/dark/colors';
import { createContext, Context, useMemo } from 'react';
import { TextStyle } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../services/redux/reducers';
import { SettingsState } from '../services/redux/reducers/settings-reducer';
import { toggleThemeAction } from '../services/redux/actions/settings';

export type Themes = 'light' | 'dark';
export type ThemeTextStyles = { [key: string]: TextStyle };
export type ThemeColors = { [key: string]: string };
export type ThemeConfig = {
  name: Themes;
  colors: ThemeColors;
  colors2: ThemeColors;
  textStyles: ThemeTextStyles;
  textStyles2: ThemeTextStyles;
  images: ThemeImages;
};

export const lightTheme: ThemeConfig = {
  name: 'light',
  colors: lightColors,
  colors2: lightColors2,
  textStyles: lightTextStyles,
  textStyles2: lightTextStyles2,
  images: themeImages.light,
};

export const darkTheme: ThemeConfig = {
  name: 'dark',
  colors: darkColors,
  colors2: darkColors2,
  textStyles: darkTextStyles,
  textStyles2: darkTextStyles2,
  images: themeImages.dark,
};

export const ThemeContext: Context<ThemeConfig> = createContext<ThemeConfig>(
  lightTheme
);

export const useTheme = () => {
  const settingsState = useSelector<RootState, SettingsState>(
    (state) => state.settings
  );
  const theme = useMemo(() => {
    return settingsState.theme;
  }, [settingsState.theme]);

  const dispatch = useDispatch();

  const toggleTheme = () => {
    dispatch(toggleThemeAction());
  };

  return {
    theme,
    toggleTheme,
  };
};
