import React, { FC, useCallback, useMemo } from 'react';
import {
  Text,
  TouchableOpacity,
  StyleSheet,
  View,
  TextInput,
} from 'react-native';
import { ThemeConfig, useTheme } from '../../hooks/use-theme';
import { borderStyles } from '../../theme/borders';
import { getTableDefaultWidth } from '../../theme/fixed-sizes';
import { spacing } from '../../theme/spacing';
import { ArrowPagination } from './arrow-pagination';

const PAGINATION_ELEMENT_SIZE = 20;
const PAGE_SIZE_ALL = -1;
const PAGE_SIZES = [
  {
    value: 5,
    label: 5,
  },
  {
    value: 10,
    label: 10,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 100,
    label: 100,
  },
  {
    value: PAGE_SIZE_ALL,
    label: 'all',
  },
];

interface PaginationBarProps {
  page: number;
  pageSize: number;
  sliceSize: number;
  totalCount: number;
  onPaginate: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  theme: ThemeConfig;
}
const getLastPageNumber = (total: number, pageSize: number) => {
  if (pageSize === PAGE_SIZE_ALL) {
    return 0;
  }

  return total >= 0 ? Math.ceil(total / pageSize) - 1 : 0;
};

export interface GetNumberSliceProps {
  current: number;
  sliceSize: number;
  lastNumber: number;
}
// @returns an array of numbers around current
export const getNumberSlice = (props: GetNumberSliceProps) => {
  const { current, lastNumber, sliceSize } = props;
  let left = 0;
  let right = 0;

  if (current < sliceSize / 2) {
    left = 0;
    right = Math.min(sliceSize - 1, lastNumber);
  } else {
    const maxDelta = Math.floor(sliceSize / 2);
    right = Math.min(current + maxDelta, lastNumber);
    left = Math.max(0, right - sliceSize) + 1;
  }

  const numbers: number[] = [];
  for (let i = left; i <= right; i++) {
    numbers.push(i);
  }
  return numbers;
};

interface NumbersPaginationProps {
  currentPageNumbers: number[];
  onPaginate: (pageNumber: number) => void;
  page: number;
  theme: ThemeConfig;
}

const NumbersPagination: FC<NumbersPaginationProps> = (props) => {
  const { currentPageNumbers, onPaginate, page, theme } = props;
  const { colors, textStyles } = theme;
  return (
    <View style={styles.numberPaginationContainer}>
      {currentPageNumbers.length > 1 &&
        currentPageNumbers.map((pageNumber, index) => {
          const paginate = () => {
            onPaginate(pageNumber);
          };
          const isActive = page === pageNumber;

          const themeStyles = StyleSheet.create({
            activeContainer: {
              backgroundColor: colors.link,
              borderColor: colors.link,
            },
            inactiveContainer: {
              borderColor: colors.link,
            },
          });

          const textStyle = isActive
            ? textStyles.InvertedTextLeft
            : textStyles.PlainTextLinkLeft;

          const containerStyle = isActive
            ? themeStyles.activeContainer
            : themeStyles.inactiveContainer;
          const itemStyle =
            index === currentPageNumbers.length - 1
              ? styles.pageNumberLinkLast
              : styles.pageNumberLink;

          return (
            <TouchableOpacity
              key={pageNumber}
              style={[
                containerStyle,
                borderStyles.borderRadiusSmall,
                itemStyle,
              ]}
              onPress={paginate}
            >
              <Text style={textStyle}>{pageNumber + 1}</Text>
            </TouchableOpacity>
          );
        })}
    </View>
  );
};
export const PaginationBar: FC<PaginationBarProps> = (props) => {
  const {
    page,
    pageSize,
    sliceSize,
    totalCount,
    onPaginate,
    onChangePageSize,
  } = props;
  const { theme } = useTheme();
  const { textStyles, colors } = theme;

  const paginateToFirstPage = useCallback(() => onPaginate(0), [onPaginate]);
  const paginateToPreviousPage = useCallback(() => onPaginate(page - 1), [
    page,
    onPaginate,
  ]);
  const paginateToNextPage = useCallback(() => onPaginate(page + 1), [
    page,
    onPaginate,
  ]);

  const lastPageNumber = useMemo(
    () => getLastPageNumber(totalCount, pageSize),
    [totalCount, pageSize]
  );

  const isLastPage = useMemo(() => {
    return page === lastPageNumber;
  }, [page, lastPageNumber]);

  const paginateToLastPage = useCallback(() => {
    onPaginate(lastPageNumber);
  }, [onPaginate, lastPageNumber]);

  const onChangePageNumber = useCallback(
    (p) => {
      const nextPage = parseInt(p, 10) - 1;
      if (nextPage >= 0 && nextPage <= lastPageNumber) {
        onPaginate(nextPage);
      }
    },
    [onPaginate, lastPageNumber]
  );

  const currentPageNumbers = useCallback(() => {
    if (!(totalCount >= 0)) {
      return [];
    }
    return getNumberSlice({
      current: page,
      sliceSize,
      lastNumber: lastPageNumber,
    });
  }, [page, lastPageNumber, sliceSize, totalCount]);

  const getPageInfoText = useCallback(() => {
    if (pageSize === PAGE_SIZE_ALL) {
      return `all ${totalCount}`;
    }
    const lastNumber = Math.min(page * pageSize + pageSize, totalCount);
    return `${page * pageSize} - ${lastNumber} of ${totalCount}`;
  }, [page, pageSize, totalCount]);

  return (
    <View style={styles.container}>
      <View style={styles.paginationContainer}>
        <ArrowPagination
          arrowType={'left'}
          page={page}
          currentPageNumbers={currentPageNumbers()}
          doubleArrowsActive={page > 0}
          onPressDoubleArrows={paginateToFirstPage}
          singleArrowActive={page > 0}
          onPressSingleArrow={paginateToPreviousPage}
          theme={theme}
        />
        <NumbersPagination
          page={page}
          currentPageNumbers={currentPageNumbers()}
          theme={theme}
          onPaginate={onPaginate}
        />
        <ArrowPagination
          arrowType={'right'}
          page={page}
          currentPageNumbers={currentPageNumbers()}
          doubleArrowsActive={!isLastPage}
          onPressDoubleArrows={paginateToLastPage}
          singleArrowActive={!isLastPage}
          onPressSingleArrow={paginateToNextPage}
          theme={theme}
        />
      </View>
      <View style={[styles.bottomContainer, styles.margin]}>
        <View style={styles.col}>
          <View>
            <View style={styles.row}>
              <Text style={textStyles.BodySmallTextLeft}>Showing:</Text>
            </View>
            <View style={styles.row}>
              <Text style={textStyles.BodySmallTextLeft}>
                {getPageInfoText()}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.col}>
          <View>
            <View style={styles.row}>
              <Text style={textStyles.BodySmallTextLeft}>
                Select Page Size:
              </Text>
            </View>
            <View style={styles.row}>
              {PAGE_SIZES.map((nextPageSize) => {
                const onPressChangePageSize = () => {
                  onChangePageSize(nextPageSize.value);
                };
                return (
                  <TouchableOpacity
                    key={nextPageSize.value}
                    onPress={onPressChangePageSize}
                    style={styles.pageSizeContainer}
                    hitSlop={{ top: 20, left: 2, bottom: 20, right: 2 }}
                  >
                    <Text
                      style={[
                        textStyles.BodySmallTextLeft,
                        { color: colors.link },
                        nextPageSize.value === pageSize
                          ? {
                              fontFamily:
                                textStyles.BodyTextLinkLeft.fontFamily,
                            }
                          : {},
                      ]}
                    >
                      {nextPageSize.label}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </View>
          </View>
        </View>
        <View style={styles.col}>
          {currentPageNumbers().length > 1 && (
            <View>
              <View style={styles.row}>
                <Text style={textStyles.BodySmallTextLeft}>Goto page: </Text>
              </View>

              <View style={styles.row}>
                <TextInput
                  style={[
                    textStyles.BodySmallTextLeft,
                    styles.pageNumberInput,
                    borderStyles.borderRadiusSmall,
                    { borderColor: colors.link },
                  ]}
                  onChangeText={onChangePageNumber}
                  value={page >= 0 ? (page + 1).toString() : undefined}
                  keyboardType={'number-pad'}
                  selectTextOnFocus
                />

                <Text style={textStyles.BodySmallTextLeft}>
                  {' '}
                  of {lastPageNumber + 1}
                </Text>
              </View>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    width: getTableDefaultWidth(),
    paddingTop: spacing[3],
  },
  paginationContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bottomContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  col: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingVertical: spacing[0],
  },
  margin: {
    marginTop: spacing[4],
  },
  numberPaginationContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pageNumberLink: {
    width: PAGINATION_ELEMENT_SIZE,
    height: PAGINATION_ELEMENT_SIZE,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: spacing[4],
  },
  pageNumberLinkLast: {
    width: PAGINATION_ELEMENT_SIZE,
    height: PAGINATION_ELEMENT_SIZE,
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageSizeContainer: {
    height: PAGINATION_ELEMENT_SIZE,
    paddingRight: spacing[2],
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  pageNumberInput: {
    height: PAGINATION_ELEMENT_SIZE,
    width: PAGINATION_ELEMENT_SIZE,
    textAlign: 'center',
    paddingBottom: spacing[0],
  },
});
