import { doLoginActions, setUserAuthTokenAction } from './../actions/auth';
import { resetRootState } from './../actions/reset';
import { put, takeEvery } from 'redux-saga/effects';
import { clearUserAuthTokenAction, doLogoutUserAction } from '../actions/auth';
import { ApiType } from '../../api';
import { callApiEndpoint } from './api';
import { PostAuthTokenResponse } from '../../api/types';

export const watchDoLogoutUser = () => {
  return takeEvery(doLogoutUserAction.getType(), doLogoutUser);
};

function* doLogoutUser() {
  // NOTE: we need to manually clear the auth token,
  //       because this cleared auth / token state will be persisted by redux persist
  yield put(clearUserAuthTokenAction());

  // NOTE: resetting the root state results in loss of persistance versions
  //       which is not optimal, but good enough for now - relates to comment above
  yield put(resetRootState());
}

export const watchLoginUser = (Api: ApiType) =>
  takeEvery(doLoginActions.types.trigger, postLoginUser, Api);

function* postLoginUser(
  Api: ApiType,
  action: ReturnType<typeof doLoginActions.trigger>
) {
  yield callApiEndpoint(
    doLoginActions,
    Api.postAuthToken,
    action.payload.params,
    Api
  );
}

export const watchLoginUserSuccess = (Api: ApiType) =>
  takeEvery(doLoginActions.types.success, doLoginUser, Api);

function* doLoginUser(
  Api: ApiType,
  action: ReturnType<typeof doLoginActions.success>
) {
  yield authApiSuccess(action.payload.response);
}

function* authApiSuccess(response: PostAuthTokenResponse | undefined) {
  const accessToken = response?.access_token;
  if (accessToken && isValidToken(accessToken)) {
    yield put(setUserAuthTokenAction(accessToken));
  }
}

const isValidToken = (token: string | undefined | null) => {
  return token && token?.length > 0;
};
