import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Linking from 'expo-linking';
import { View, StyleSheet, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../navigation/types';

import { RootState } from '../../services/redux/reducers';
import { PayoutsState } from '../../services/redux/reducers/payouts';

import { fetchLatestPayoutsActions } from '../../services/redux/actions/payouts';
import {
  DASHBOARD_TILE_FIRST_COLUMN_WIDTH,
  DASHBOARD_TILE_HEIGHT_LARGE,
  DASHBOARD_TILE_WIDTH,
  TABLE_COLUMN_WIDTH_CHECKBOX,
  TABLE_COLUMN_WIDTH_CURRENCY_SYMBOL_RIGHT,
  TABLE_COLUMN_WIDTH_DATE,
} from '../../theme/fixed-sizes';

import { deNormalize } from '../../services/data/normalize';
import { Payout } from '../../services/api/types';
import { formatDateStandard } from '../../utils/format-date';
import { useTheme } from '../../hooks/use-theme';
import { DashboardTileLoading } from '../../components/dashboard-tile-loading/dashboard-tile-loading';
import { Table, TableConfigItem } from '../../components/table/table';
import { Currency } from '../../components/currency/currency';
import { StatusIcon } from '../../components/status-icon/status-icon';
import { spacing } from '../../theme/spacing';

export const PayoutAmount: FC<{ payout: Payout }> = (props) => {
  const { payout } = props;
  const { theme } = useTheme();
  const { textStyles } = theme;

  let opacity = 0.6;
  if (payout.status === 'released') {
    opacity = 1.0;
  }

  return (
    <View style={{ opacity }}>
      <Currency
        amount={payout.totalAmount}
        currency={payout.currency}
        symbolPosition={'right'}
        textStyle={textStyles.TableCurrencyRight}
      />
    </View>
  );
};

const PAYOUTS_TABLE_CONFIG: TableConfigItem<Payout>[] = [
  {
    width: TABLE_COLUMN_WIDTH_DATE,
    render: (payout) => formatDateStandard(payout.date),
  },
  {
    width: 140,
    render: () => 'Commission Payment',
  },
  {
    width: TABLE_COLUMN_WIDTH_CURRENCY_SYMBOL_RIGHT,
    align: 'right',
    render: (payout) => <PayoutAmount payout={payout} />,
  },
  {
    width: TABLE_COLUMN_WIDTH_CHECKBOX,
    align: 'right',
    render: (payout) => (
      <StatusIcon
        status={payout.status}
        activeStatus={'released'}
        inactiveSvg={'clock'}
        align={'right'}
      />
    ),
  },
];

export const DashboardLatestPayouts: FC = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const latestPayoutsSelector = useSelector<RootState, PayoutsState>(
    (state) => state.payouts
  );
  const { loading, error, data } = latestPayoutsSelector;
  const { theme } = useTheme();
  const { textStyles } = theme;

  const entities = useMemo(() => {
    return deNormalize<Payout>(data.entities, data.latestIds);
  }, [data.entities, data.latestIds]);

  const dispatch = useDispatch();
  const refetch = () => {
    dispatch(fetchLatestPayoutsActions.trigger(undefined));
  };

  const navigateToPayouts = () => {
    navigation.navigate('Payouts');
  };

  const openExternalIPayout = async () => {
    const iPayoutUrl = 'https://healy.globalewallet.com/'; // TODO: get from API / user should be auto-logged in
    if (await Linking.canOpenURL(iPayoutUrl)) {
      Linking.openURL(iPayoutUrl);
    }
  };

  const getButtonConfig = () => {
    return [
      {
        onPress: openExternalIPayout,
        title: 'Go to iPayout',
      },
      {
        onPress: navigateToPayouts,
        title: 'Show all payouts',
      },
    ];
  };

  const renderTile = useCallback(() => {
    return entities && entities.length > 0;
  }, [entities]);

  return (
    <DashboardTileLoading
      loading={loading}
      error={error}
      data={data}
      refetch={refetch}
      renderIf={renderTile}
      style={styles.container}
      icon="money-bill-down-arrow"
      buttons={getButtonConfig()}
      theme={theme}
      placeholderLoaderType="DASHBOARD_LATEST_PAYOUTS"
    >
      <>
        <Text style={[styles.margin, textStyles.H3Left]}>Latest Payouts</Text>
        <View
          style={{
            marginLeft: -DASHBOARD_TILE_FIRST_COLUMN_WIDTH,
          }}
        >
          <Table<Payout>
            data={entities}
            onRowPress={navigateToPayouts}
            config={PAYOUTS_TABLE_CONFIG}
            theme={theme}
          />
        </View>
      </>
    </DashboardTileLoading>
  );
};

const styles = StyleSheet.create({
  container: {
    height: DASHBOARD_TILE_HEIGHT_LARGE,
    width: DASHBOARD_TILE_WIDTH,
  },
  margin: {
    marginBottom: spacing[1],
  },
});
