import {
  addDecorator,
  configure,
  getStorybookUI,
} from '@storybook/react-native';
import React, { FC } from 'react';
import { ScrollView, ViewStyle } from 'react-native';
import { loadStories } from './story-loader';

import useCachedResources from '../hooks/use-cached-resources';

addDecorator((story: () => React.ReactNode) => {
  const decoratorStyle: ViewStyle = {
    flex: 1,
    padding: 10,
    backgroundColor: '#f9f9f9',
  };

  return <ScrollView style={decoratorStyle}>{story()}</ScrollView>;
});

configure(loadStories, module);

const StorybookUI = getStorybookUI({
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  asyncStorage: require('@react-native-async-storage/async-storage').default,
});

export const StorybookUIRoot: FC = () => {
  const isLoadingComplete = useCachedResources();
  if (!isLoadingComplete) {
    return null;
  }
  return <StorybookUI />;
};
