import React, { FC } from 'react';
import { Text } from 'react-native';
import { useTheme } from '../../hooks/use-theme';

export const NetworkSize: FC<{ amount: number }> = (props) => {
  const { amount } = props;
  const {
    theme: { textStyles },
  } = useTheme();
  return <Text style={textStyles.H3RightBold}>{'' + amount}</Text>;
};
