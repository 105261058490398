import { takeEvery } from 'redux-saga/effects';
import { ApiType } from '../../api';
import {
  fetchLatestPayoutsActions,
  fetchPayoutsActions,
} from '../actions/payouts';
import { callApiEndpoint } from './api';

export const watchFetchLatestPayouts = (Api: ApiType) =>
  takeEvery(fetchLatestPayoutsActions.types.trigger, fetchLatestPayouts, Api);

function* fetchLatestPayouts(
  Api: ApiType,
  _: ReturnType<typeof fetchLatestPayoutsActions.trigger>
) {
  yield callApiEndpoint(
    fetchLatestPayoutsActions,
    Api.getLatestPayouts,
    undefined,
    Api
  );
}

export const watchFetchPayouts = (Api: ApiType) =>
  takeEvery(fetchPayoutsActions.types.trigger, fetchPayouts, Api);

function* fetchPayouts(
  Api: ApiType,
  action: ReturnType<typeof fetchPayoutsActions.trigger>
) {
  yield callApiEndpoint(
    fetchPayoutsActions,
    Api.getPayouts,
    action.payload.params,
    Api
  );
}
