import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { View, Text, StyleSheet } from 'react-native';

import { spacing } from '../../theme/spacing';
import {
  DASHBOARD_TILE_FIRST_COLUMN_WIDTH,
  DASHBOARD_TILE_HEIGHT_LARGE,
  DASHBOARD_TILE_WIDTH,
} from '../../theme/fixed-sizes';
import { useTheme } from '../../hooks/use-theme';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../navigation/types';
import { DashboardTileLoading } from '../../components/dashboard-tile-loading/dashboard-tile-loading';
import { DashboardTileButton } from '../../components/dashboard-tile/types';
import { Table, TableConfigItem } from '../../components/table/table';
import { DualTreeItem, StringHash } from '../network-overview/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/redux/reducers';
import { NetworkSize } from '../network-overview/network-size';
import { fetchCommissionsPerformanceActions } from '../../services/redux/actions/commissions';
import { CommissionsState } from '../../services/redux/reducers/commissions';
import { useDataBadge } from '../network-overview/use-data-badge';
import { extractDualTreeData } from '../network-overview/selectors';

const LABEL_MAPPING: StringHash = {
  total: 'Total PV',
  directSubtree: 'Personal Sponsor Tree',
};

export const NetworkPerformanceOverviewWithoutButtons: FC = () => {
  const buttonConfig: DashboardTileButton[] = [];
  return <NetworkPerformanceOverview buttonOverrides={buttonConfig} />;
};

interface NetworkPerformanceOverviewProps {
  buttonOverrides?: DashboardTileButton[];
}

const TABLE_CONFIG: TableConfigItem<DualTreeItem>[] = [
  {
    header: ' ',
    align: 'left',
    width: 170,
    render: (data) => data.label,
  },
  {
    header: 'Left Leg',
    align: 'right',
    width: 80,
    render: (d) => <NetworkSize amount={d.left} />,
  },
  {
    header: 'Right Leg',
    align: 'right',
    width: 80,
    render: (d) => <NetworkSize amount={d.right} />,
  },
];

export const NetworkPerformanceOverview: FC<NetworkPerformanceOverviewProps> = ({
  buttonOverrides,
}) => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const commissions = useSelector<RootState, CommissionsState>(
    (state) => state.commissions
  );
  const {
    performanceLoading: loading,
    performanceError: error,
    performanceData: data,
  } = commissions;
  const { theme } = useTheme();
  const { textStyles } = theme;

  const dispatch = useDispatch();
  const refetch = useCallback(() => {
    dispatch(fetchCommissionsPerformanceActions.trigger(undefined));
  }, [dispatch]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const tableData = useMemo<DualTreeItem[] | null>(() => {
    return extractDualTreeData({
      data,
      labelMapping: LABEL_MAPPING,
    });
  }, [data]);

  const navigateToCommissionsOverview = () => {
    navigation.navigate('BottomTabs', {
      screen: 'CommissionsOverview',
    });
  };

  const getButtonConfig = () => {
    return [
      {
        onPress: navigateToCommissionsOverview,
        title: 'See Commissions',
      },
    ];
  };

  const badgeType = useDataBadge(data?.isLiveData);

  return (
    <DashboardTileLoading
      loading={loading}
      error={error}
      data={data}
      refetch={refetch}
      style={styles.container}
      icon={'human-network-on'}
      buttons={
        buttonOverrides !== undefined ? buttonOverrides : getButtonConfig()
      }
      theme={theme}
      placeholderLoaderType="DASHBOARD_TOTAL_COMMISSIONS"
      showBadge={badgeType}
    >
      <Text style={[styles.margin, textStyles.H3Left]}>Current PV</Text>
      <View
        style={{
          marginLeft: -DASHBOARD_TILE_FIRST_COLUMN_WIDTH,
        }}
      >
        {tableData ? (
          <Table<DualTreeItem>
            data={tableData}
            config={TABLE_CONFIG}
            theme={theme}
          />
        ) : null}
      </View>
    </DashboardTileLoading>
  );
};

const styles = StyleSheet.create({
  container: {
    height: DASHBOARD_TILE_HEIGHT_LARGE,
    width: DASHBOARD_TILE_WIDTH,
  },
  margin: {
    marginBottom: spacing[1],
  },
});
