import React, { FC } from 'react';

import { ContextTabViewBar } from '../../components/tab-view-bar/tab-view-bar';
import { InboxOverviewScreenSelectedTabContext } from '../../navigation/params-context';
import { MessagesScreen } from '../messages/messages-screen';
import { NewsScreen } from '../news/news-screen';
import { SupportTicketsScreen } from '../support-tickets/support-tickets-screen';

export const InboxOverviewScreen: FC = () => {
  const props = {
    selectedTabContext: InboxOverviewScreenSelectedTabContext,
    config: [
      { key: 'news', title: 'News', component: NewsScreen },
      { key: 'messages', title: 'Messages', component: MessagesScreen },
      {
        key: 'supportTickets',
        title: 'Support',
        component: SupportTicketsScreen,
      },
    ],
  };

  return <ContextTabViewBar {...props} />;
};
