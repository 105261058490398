import React, { FC, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';
import { Screen } from '../../components/screen/screen';
import { useSmallScreen } from '../../hooks/use-small-screen';
import { NetworkMemberHistory } from './network-member-history';
import { spacing } from '../../theme/spacing';
import { NetworkDirectOverview } from './network-direct-overview';
import { DashboardUnplacedMembers } from '../dashboard/dashboard-unplaced-members';
import { fetchUnplacedMembersCountActions } from '../../services/redux/actions/unplaced-members-count';
import { NetworkCustomers } from './network-customers';
import { NetworkDualGenealogy } from './network-dual-genealogy';
import { DashboardLeaderboardPerformance } from '../dashboard/dashboard-leaderboard-performance';
import { fetchTopPerformingMembersActions } from '../../services/redux/actions/members';
import { DashboardUserBonusProgram } from '../dashboard/dashboard-user-bonus-program';
import { fetchUserActions } from '../../services/redux/actions/user-info';
import { DashboardReferralLinks } from '../dashboard/dashboard-referral-links';
import { DashboardCommissionsGoal } from '../dashboard/dashboard-commissions-goal';
import {
  fetchNetworkAggregatedDownlineActions,
  fetchNetworkDirectSponsoredActions,
} from '../../services/redux/actions/network';

const useTriggerLoadData = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUnplacedMembersCountActions.trigger(undefined));
    dispatch(fetchTopPerformingMembersActions.trigger(undefined));
    dispatch(fetchUserActions.trigger(undefined));
    dispatch(fetchNetworkAggregatedDownlineActions.trigger(undefined));
    dispatch(fetchNetworkDirectSponsoredActions.trigger(undefined));
  }, [dispatch]);
};
export const NetworkOverviewScreen: FC = () => {
  useTriggerLoadData();
  const { isSmallScreen } = useSmallScreen();

  const curStyles = StyleSheet.create({
    container: {
      flexDirection: isSmallScreen() ? 'column' : 'row',
    },
    column: {
      flexDirection: isSmallScreen() ? 'row' : 'column',
      marginHorizontal: isSmallScreen() ? 0 : spacing[2],
    },
  });
  return (
    <Screen androidCoverBackground>
      <View style={[styles.container, curStyles.container]}>
        <View style={[styles.webColumn, curStyles.column]}>
          <NetworkMemberHistory />
          <NetworkDirectOverview />
          <DashboardUnplacedMembers />
          <NetworkDualGenealogy />
          <NetworkCustomers />
          <DashboardLeaderboardPerformance />
          <DashboardUserBonusProgram />
          <DashboardCommissionsGoal />
          <DashboardReferralLinks />
        </View>
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  webColumn: {
    flex: 1,
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
