import { applyMiddleware, createStore, StoreEnhancer } from 'redux';
import createSagaMiddleware, { SagaMonitor } from 'redux-saga';
import { rootReducer, RootState } from './reducers';
import { createRootSaga } from './sagas';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { ApiType } from '../api';

export interface StoreDependencies {
  Api: ApiType;
}
export const configureStore = (
  dependencies: StoreDependencies,
  preloadedState?: Partial<RootState>,
  storeEnhancer?: StoreEnhancer,
  sagaMonitor?: SagaMonitor
) => {
  const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
  const middleware = applyMiddleware(sagaMiddleware);

  const enhancers =
    storeEnhancer !== undefined
      ? composeWithDevTools(middleware, storeEnhancer) // Enable redux-devtools-extension
      : composeWithDevTools(middleware); // Enable redux-devtools-extension

  const store = createStore(rootReducer, preloadedState, enhancers);
  const rootSaga = createRootSaga(dependencies);

  sagaMiddleware.run(rootSaga);

  return store;
};
