import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { PlaceHolderLoader } from '../../components/placeholder-loader/placeholder-loader';
import { Commission } from '../../services/api/types';
import { spacing } from '../../theme/spacing';
import {
  Table,
  TableConfigItem,
  TableSortConfig,
} from '../../components/table/table';
import { formatDateStandard } from '../../utils/format-date';
import { useTheme } from '../../hooks/use-theme';
import {
  getTableDefaultHeight,
  getTableDefaultWidth,
  TABLE_COLUMN_WIDTH_CHECKBOX,
  TABLE_COLUMN_WIDTH_CURRENCY_SYMBOL_LEFT,
  TABLE_COLUMN_WIDTH_DATE,
} from '../../theme/fixed-sizes';
import { LoadingScreen } from '../../components/loading-screen/loading-screen';
import { commissionDisplayName } from '../dashboard/dashboard-latest-commissions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/redux/reducers';
import { CommissionsState } from '../../services/redux/reducers/commissions';
import { Currency } from '../../components/currency/currency';
import { fetchCommissionDetailsActions } from '../../services/redux/actions/commissions';
import { StatusIcon } from '../../components/status-icon/status-icon';
import { usePaginationData } from '../../hooks/use-pagination';
import { PaginationBar } from '../../components/pagination-bar/pagination-bar';

const COMMISSIONS_TABLE_CONFIG: TableConfigItem<Commission>[] = [
  {
    width: TABLE_COLUMN_WIDTH_DATE,
    render: (commission) => `${formatDateStandard(commission.calculateDate)}`,
    header: 'Date',
    sortField: 'calculateDate',
  },
  {
    width: 140,
    render: (commission) => `${commissionDisplayName(commission.type)}`,
    header: 'Bonus',
    sortField: 'type',
  },
  {
    width: TABLE_COLUMN_WIDTH_CURRENCY_SYMBOL_LEFT,
    render: (commission) => (
      <Currency
        amount={commission.amount}
        currency={'EUR'}
        symbolPosition={'left'}
      />
    ),
    header: 'Earnings',
    sortField: 'amount',
    align: 'left',
  },
  {
    width: TABLE_COLUMN_WIDTH_CHECKBOX,
    align: 'right',
    render: (commission) => (
      <StatusIcon
        status={commission.status}
        activeStatus={'confirmed'}
        align={'right'}
      />
    ),
  },
];

export const CommissionsListScreen: FC = () => {
  // const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const commissions = useSelector<RootState, CommissionsState>(
    (state) => state.commissions
  );
  const {
    detailsLoading: loading,
    detailsError: error,
    detailsData: data,
    detailsTotal: total,
  } = commissions;

  const {
    queryParams,
    onPaginate,
    onChangePageSize,
    onChangeSort,
  } = usePaginationData<Commission>(data, 'calculateDate', 'desc');

  const tableSortConfig: TableSortConfig = useMemo(() => {
    return {
      default: {
        sortBy: queryParams.sortBy,
        sortOrder: queryParams.sortOrder,
      },
      onChangeSort,
    };
  }, [onChangeSort, queryParams]);

  const { theme } = useTheme();

  const dispatch = useDispatch();
  const refetch = useCallback(() => {
    dispatch(fetchCommissionDetailsActions.trigger(queryParams));
  }, [dispatch, queryParams]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <LoadingScreen
      loading={loading}
      error={error}
      refetch={refetch}
      placeholderLoaderType="MESSAGES_SCREEN"
    >
      <View style={styles.container}>
        <View style={styles.innerContainer}>
          {loading && <PlaceHolderLoader displayType="MESSAGES_SCREEN" />}
          {!loading && (
            <Table<Commission>
              data={data}
              config={COMMISSIONS_TABLE_CONFIG}
              theme={theme}
              horizontalScroll
              sortConfig={tableSortConfig}
            />
          )}
        </View>
        <View style={styles.bottomBar}>
          <PaginationBar
            page={queryParams.page}
            pageSize={queryParams.pageSize}
            sliceSize={5}
            totalCount={total}
            onPaginate={onPaginate}
            onChangePageSize={onChangePageSize}
            theme={theme}
          />
        </View>
      </View>
    </LoadingScreen>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: spacing[2],
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  innerContainer: {
    width: getTableDefaultWidth(),
    minHeight: getTableDefaultHeight(),
    alignItems: 'center',
  },
  bottomBar: {
    flex: 1,
  },
});
