import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={27} height={14} viewBox="0 0 27 14" fill="none" {...props}>
      <Path
        d="M8 9H5a4 4 0 01-4-4v0a4 4 0 014-4h8a4 4 0 014 4v0a4 4 0 01-4 4v0"
        stroke="#FFFFFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M19 5h3a4 4 0 014 4v0a4 4 0 01-4 4h-8a4 4 0 01-4-4v0a4 4 0 014-4v0"
        stroke="#FFFFFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default SvgComponent;
