import React, { FC } from 'react';
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
  getFocusedRouteNameFromRoute,
  RouteProp,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { ColorSchemeName } from 'react-native';

import { NotFoundScreen } from '../screens/not-found/not-found-screen';
import { RootStackParamList } from './types';
import BottomTabNavigator from './bottom-tab-navigator';
import LinkingConfiguration from './linking-configuration';
import { RegisterMemberScreen } from '../screens/register-member/register-member-screen';
import { PayoutsScreen } from '../screens/payouts/payouts-screen';
import { OrdersScreen } from '../screens/orders/orders-screen';
import { MembersListScreen } from '../screens/members/members-list-screen';
import { PlaceMembersScreen } from '../screens/members/place-members-screen';
import { useTheme } from '../hooks/use-theme';
import { ShareUrlScreen } from '../screens/share-url/share-url-screen';

import { WipScreen } from '../screens/wip/wip-screen';
import {
  getDashboardHeader,
  getLoginScreenHeader,
  getOverviewScreenHeader,
  getPopupHeader,
  getStandardHeader,
} from './screen-options';
import { SubscriptionsScreen } from '../screens/subscriptions/subscriptions-screen';
import { PerformanceLeaderboardScreen } from '../screens/leaderboard/performance-leaderboard-screen';
import { CommissionsLeaderboardScreen } from '../screens/leaderboard/commissions-leaderboard-screen';
import { SettingsScreen } from '../screens/settings/settings-screen';
import { LoginScreen } from '../screens/login/login-screen';
import { useAuthentication } from '../hooks/use-authentication';
import { CommissionsListScreen } from '../screens/commissions/commissions-list-screen';
import { MembersTreeScreen } from '../screens/members/members-tree-screen';
import { MembersOverviewScreen } from '../screens/members/members-overview-screen';
import { CommissionsScreen } from '../screens/commissions/commissions-screen';
import { CommissionsHistoryScreen } from '../screens/commissions-history/commissions-history-screen';
import { MembersHistoryScreen } from '../screens/members-history/members-history-screen';
import { NewsDetailScreen } from '../screens/news-detail/news-detail';

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
type NavigationProps = {
  colorScheme?: ColorSchemeName;
};

export const Navigation: FC<NavigationProps> = ({ colorScheme }) => {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
    >
      <RootNavigator />
    </NavigationContainer>
  );
};

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

function getRouteName(route: RouteProp<RootStackParamList, 'BottomTabs'>) {
  const routeName = getFocusedRouteNameFromRoute(route);
  return routeName;
}

const CardNavigator: FC = () => {
  const { theme } = useTheme();
  return (
    <Stack.Navigator
      mode={'card'}
      headerMode={'screen'}
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen
        name="BottomTabs"
        component={BottomTabNavigator}
        options={({ navigation, route }) => {
          const routeName = getRouteName(route);
          const options =
            routeName === undefined ||
            routeName === 'Dashboard' ||
            routeName === 'BottomTabs'
              ? getDashboardHeader(navigation, theme)
              : getOverviewScreenHeader(routeName, navigation, theme);
          return {
            ...options,
          };
        }}
      />
      {/* NOTE: all screens below here
          are opened "card",
          coming in right */}
      <Stack.Screen
        name="MembersOverview"
        component={MembersOverviewScreen}
        options={({ navigation }) =>
          getStandardHeader('Members', navigation, theme)
        }
      />
      <Stack.Screen
        name="MembersList"
        component={MembersListScreen}
        options={({ navigation }) =>
          getStandardHeader('Members', navigation, theme)
        }
      />
      <Stack.Screen
        name="MembersTree"
        component={MembersTreeScreen}
        options={({ navigation }) =>
          getStandardHeader('Members', navigation, theme)
        }
      />
      <Stack.Screen
        name="Payouts"
        component={PayoutsScreen}
        options={({ navigation }) =>
          getStandardHeader('Payouts', navigation, theme)
        }
      />
      <Stack.Screen
        name="not-found"
        component={NotFoundScreen}
        options={{ title: 'Oops!', headerStyle: { height: 30 } }}
      />
      <Stack.Screen
        name="PlaceMember"
        component={PlaceMembersScreen}
        options={({ navigation }) =>
          getStandardHeader('Place Member', navigation, theme)
        }
      />
      <Stack.Screen
        name="Orders"
        component={OrdersScreen}
        options={({ navigation }) =>
          getStandardHeader('Orders', navigation, theme)
        }
      />
      <Stack.Screen
        name="Subscriptions"
        component={SubscriptionsScreen}
        options={({ navigation }) =>
          getStandardHeader('Subscriptions', navigation, theme)
        }
      />
      <Stack.Screen
        name="RegisterMember"
        component={RegisterMemberScreen}
        options={({ navigation }) =>
          getStandardHeader('Register Member', navigation, theme)
        }
      />
      <Stack.Screen
        name="Wip"
        component={WipScreen}
        options={({ navigation }) => getStandardHeader('', navigation, theme)}
      />
      <Stack.Screen
        name="MembersCommissionLeaderboard"
        component={CommissionsLeaderboardScreen}
        options={({ navigation }) =>
          getStandardHeader('Top Earners', navigation, theme)
        }
      />
      <Stack.Screen
        name="MembersPerformanceLeaderboard"
        component={PerformanceLeaderboardScreen}
        options={({ navigation }) =>
          getStandardHeader('Top Performers', navigation, theme)
        }
      />
      <Stack.Screen
        name="Settings"
        component={SettingsScreen}
        options={({ navigation }) =>
          getStandardHeader('Settings', navigation, theme)
        }
      />
      <Stack.Screen
        name="CommissionsList"
        component={CommissionsListScreen}
        options={({ navigation }) =>
          getStandardHeader('Commissions list', navigation, theme)
        }
      />
      <Stack.Screen
        name="Commissions"
        component={CommissionsScreen}
        options={({ navigation }) =>
          getStandardHeader('Commissions', navigation, theme)
        }
      />
      <Stack.Screen
        name="CommissionsHistory"
        component={CommissionsHistoryScreen}
        options={({ navigation }) =>
          getStandardHeader('Commissions', navigation, theme)
        }
      />
      <Stack.Screen
        name="MembersHistory"
        component={MembersHistoryScreen}
        options={({ navigation }) =>
          getStandardHeader('Commissions', navigation, theme)
        }
      />
      <Stack.Screen
        name="NewsDetail"
        component={NewsDetailScreen}
        options={({ navigation }) =>
          getStandardHeader('Healy News', navigation, theme)
        }
      />
    </Stack.Navigator>
  );
};
const ModalNavigator: FC = () => {
  const { theme } = useTheme();
  return (
    <Stack.Navigator>
      {/* NOTE: all screens below here
                are opened "modal",
                coming in from bottom */}
      <Stack.Screen
        name="ShareUrl"
        component={ShareUrlScreen}
        options={({ navigation }) =>
          getPopupHeader('Share Healy World', navigation, theme)
        }
      />
    </Stack.Navigator>
  );
};

const UnauthorizedNavigator: FC = () => {
  const { theme } = useTheme();
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Login"
        component={LoginScreen}
        options={() => getLoginScreenHeader(theme)}
      />
    </Stack.Navigator>
  );
};

const RootNavigator: FC = () => {
  const { isUserLoggedIn } = useAuthentication();
  return (
    <Stack.Navigator mode={'modal'} screenOptions={{ headerShown: false }}>
      {isUserLoggedIn() ? (
        <>
          <Stack.Screen name="Root" component={CardNavigator} />
          <Stack.Screen name="Modal" component={ModalNavigator} />
        </>
      ) : (
        <Stack.Screen name="Unauthorized" component={UnauthorizedNavigator} />
      )}
    </Stack.Navigator>
  );
};
