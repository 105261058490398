import React, { ReactNode } from 'react';

import { ApiError } from '../../services/api/types';
import { ApiErrorInfo } from '../api-error-info/api-error-info';
import { DashboardTile } from '../dashboard-tile/dashboard-tile';
import { DashboardTileProps } from '../dashboard-tile/types';
import {
  DisplayType,
  PlaceHolderLoader,
} from '../placeholder-loader/placeholder-loader';

export interface DashboardTileLoadingProps<DataType>
  extends DashboardTileProps {
  error: ApiError | undefined;
  loading: boolean;
  data: DataType;
  refetch: () => void;
  placeholderLoaderType: DisplayType;
  renderIf?: (data: DataType) => boolean;
  children: ReactNode;
}

export function DashboardTileLoading<DataType>(
  props: DashboardTileLoadingProps<DataType>
) {
  const {
    loading,
    error,
    data,
    refetch,
    style,
    theme,
    icon,
    buttons,
    placeholderLoaderType,
    renderIf,
    onPress,
    children,
    showBadge,
  } = props;

  if (renderIf) {
    if (!renderIf(data)) {
      return null;
    }
  }
  if (error) {
    return (
      <DashboardTile style={style} theme={theme} showBadge={showBadge}>
        <ApiErrorInfo error={error} onButtonPress={refetch} />
      </DashboardTile>
    );
  }

  if (loading || data === undefined) {
    return (
      <DashboardTile style={style} theme={theme} showBadge={showBadge}>
        <PlaceHolderLoader displayType={placeholderLoaderType} />
      </DashboardTile>
    );
  }
  return (
    <DashboardTile
      style={style}
      icon={icon}
      buttons={buttons}
      theme={theme}
      onPress={onPress}
      showBadge={showBadge}
    >
      {children}
    </DashboardTile>
  );
}
