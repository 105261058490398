import React, { FC } from 'react';
import { StyleSheet, Text, TextStyle, View } from 'react-native';
import { useTheme } from '../../hooks/use-theme';
import { formatCurrency } from '../../utils/format-currency';

const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
  }
};

export interface CurrencyProps {
  amount: number;
  currency: string;
  textStyle?: TextStyle;
  symbolPosition: 'left' | 'right';
}

export const Currency: FC<CurrencyProps> = ({
  amount,
  currency,
  textStyle,
  symbolPosition,
}) => {
  const { theme } = useTheme(); // TODO: make theme a prop
  const { textStyles } = theme;
  const currencySymbol = getCurrencySymbol(currency);

  const curTextStyle =
    textStyle !== undefined ? textStyle : textStyles.TableCurrencyRight;

  const factor = 6.2;
  let width = 90;
  if (curTextStyle?.fontSize !== undefined) {
    width = curTextStyle.fontSize * factor;
  }

  const containerStyle =
    symbolPosition === 'left'
      ? styles.containerCurrencyLeft
      : styles.containerCurrencyRight;
  const firstTextStyle = symbolPosition === 'right' ? { marginRight: 3 } : {};
  const widthStyle = symbolPosition === 'left' ? { width } : {};
  return (
    <View style={[containerStyle, widthStyle]}>
      <Text style={curTextStyle}>{currencySymbol}</Text>
      <Text style={firstTextStyle} />
      <Text style={curTextStyle}>{formatCurrency(amount, currency)}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  containerCurrencyLeft: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  containerCurrencyRight: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
  },
});
