import React, { FC, useEffect, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { PlaceHolderLoader } from '../../components/placeholder-loader/placeholder-loader';
import { GetNewsParams, News } from '../../services/api/types';
import { spacing } from '../../theme/spacing';
import {
  Table,
  TableConfigItem,
  TableSortConfig,
} from '../../components/table/table';
import { formatDateStandard } from '../../utils/format-date';
import { apiService } from '../../services/api/api-service';
import { useFetchPaginated } from '../../hooks/use-fetch-paginated';
import { useTheme } from '../../hooks/use-theme';
import {
  getTableDefaultHeight,
  getTableDefaultWidth,
  TABLE_COLUMN_WIDTH_S,
} from '../../theme/fixed-sizes';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../navigation/types';
import { LoadingScreen } from '../../components/loading-screen/loading-screen';

const tableConfig: TableConfigItem<News>[] = [
  {
    header: 'Date',
    // sortField: 'date',
    width: TABLE_COLUMN_WIDTH_S,
    render: (news) => formatDateStandard(news.date),
  },
  {
    header: 'Headline',
    // sortField: 'headline',
    width: 260,
    render: (news) => news.headline,
  },
];

export const NewsScreen: FC = () => {
  const {
    queryParams,
    onChangeSort,
    loading,
    error,
    data,
    refetch,
  } = useFetchPaginated<News, GetNewsParams>(apiService, apiService.getNews, {
    sortBy: 'date',
    sortOrder: 'desc',
    page: 0,
    pageSize: 3,
    fields: 'all',
  });
  const { theme } = useTheme();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  useEffect(() => {
    refetch();
  }, [refetch]);
  const navigateToNewsItem = (news: News) => {
    navigation.navigate('NewsDetail', { id: news.id });
  };
  const tableSortConfig: TableSortConfig = useMemo(() => {
    return {
      default: {
        sortBy: queryParams.sortBy,
        sortOrder: queryParams.sortOrder,
      },
      onChangeSort,
    };
  }, [onChangeSort, queryParams.sortBy, queryParams.sortOrder]);

  return (
    <LoadingScreen
      loading={loading}
      error={error}
      refetch={refetch}
      placeholderLoaderType="MESSAGES_SCREEN"
    >
      <View style={styles.container}>
        <View style={styles.innerContainer}>
          {loading && <PlaceHolderLoader displayType="MESSAGES_SCREEN" />}
          {!loading && (
            <Table<News>
              data={data.data || []}
              config={tableConfig}
              onRowPress={navigateToNewsItem}
              sortConfig={tableSortConfig}
              horizontalScroll
              theme={theme}
            />
          )}
        </View>
        {/* <View style={styles.bottomBar}>
          <PaginationBar
            page={queryParams.page}
            pageSize={queryParams.pageSize}
            sliceSize={5}
            totalCount={data?.total || 0}
            onPaginate={onPaginate}
            onChangePageSize={onChangePageSize}
            theme={theme}
          />
        </View> */}
      </View>
    </LoadingScreen>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: spacing[2],
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    width: getTableDefaultWidth(),
    minHeight: getTableDefaultHeight(),
    alignItems: 'center',
  },
});
