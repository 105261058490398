import React, { FC } from 'react';
import { StyleSheet, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { RootStackParamList } from '../../navigation/types';
import { spacing } from '../../theme/spacing';
import {
  DASHBOARD_TILE_HEIGHT_XS,
  DASHBOARD_TILE_WIDTH,
} from '../../theme/fixed-sizes';
import { DashboardTile } from '../../components/dashboard-tile/dashboard-tile';
import { useTheme } from '../../hooks/use-theme';

export const DashboardRegisterMember: FC = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const navigateToRegisterMember = () => {
    navigation.navigate('Wip', {
      headline: 'Register Member',
    });
  };
  const { theme } = useTheme();
  const { textStyles } = theme;
  const getButtonConfig = () => {
    return [
      {
        onPress: navigateToRegisterMember,
        title: 'Add now',
      },
    ];
  };
  return (
    <DashboardTile
      style={styles.container}
      icon="add-member"
      buttons={getButtonConfig()}
      theme={theme}
    >
      <Text style={[styles.margin, textStyles.H3Left]}>
        Register new member
      </Text>
    </DashboardTile>
  );
};

const styles = StyleSheet.create({
  container: {
    height: DASHBOARD_TILE_HEIGHT_XS,
    width: DASHBOARD_TILE_WIDTH,
  },
  margin: {
    paddingBottom: spacing[1],
  },
});
