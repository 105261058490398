import * as React from 'react';
import Svg, { Circle, Path, SvgProps } from 'react-native-svg';

const SvgComponent: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width={26} height={19} viewBox="0 0 26 19" fill="none" {...props}>
      <Circle cx={7} cy={5} r={4.5} fill="#D95BB7" stroke="#D95BB7" />
      <Circle cx={19} cy={5} r={4.5} fill="#D95BB7" stroke="#D95BB7" />
      <Path
        d="M7 12a6 6 0 00-6 6h12a6 6 0 00-6-6zM19 12a6 6 0 00-6 6h12a6 6 0 00-6-6z"
        fill="#D95BB7"
        stroke="#D95BB7"
      />
      <Path d="M9 5a2 2 0 11-4 0M21 5a2 2 0 11-4 0" stroke="#fff" />
    </Svg>
  );
};

export default SvgComponent;
