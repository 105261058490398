import { UnplacedMembersCount } from './../../api/types';
import { createApiActions, ExtractActionTypes } from '../create-api-actions';

export const fetchUnplacedMembersCountActions = createApiActions(
  'FETCH_UNPLACED_MEMBERS_COUNT_TRIGGER',
  'FETCH_UNPLACED_MEMBERS_COUNT_START',
  'FETCH_UNPLACED_MEMBERS_COUNT_SUCCESS',
  'FETCH_UNPLACED_MEMBERS_COUNT_FAILURE'
)<undefined, UnplacedMembersCount>();

export type UnplacedMembersCountActions = ExtractActionTypes<
  typeof fetchUnplacedMembersCountActions
>;
