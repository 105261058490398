import {
  NetworkAggregatedDownline,
  NetworkDirectSponsored,
} from '../../api/types';
import {
  NetworkActions,
  fetchNetworkAggregatedDownlineActions,
  fetchNetworkDirectSponsoredActions,
} from '../actions/network';
import produce from 'immer';
import { Reducer } from 'redux';
import { ApiError } from '../../api/types';

const initialState: NetworkState = {
  downlineLoading: false,
  directSponsoredLoading: false,
};

export interface NetworkState {
  readonly downlineLoading: boolean;
  readonly downlineError?: ApiError;
  readonly downlineData?: NetworkAggregatedDownline;
  readonly directSponsoredLoading: boolean;
  readonly directSponsoredError?: ApiError;
  readonly directSponsoredData?: NetworkDirectSponsored;
}

export const networkReducer: Reducer<NetworkState, NetworkActions> = (
  state = initialState,
  action
) =>
  produce(state, (d) => {
    switch (action.type) {
      case fetchNetworkAggregatedDownlineActions.types.start:
        d.downlineError = undefined;
        d.downlineLoading = true;
        return;
      case fetchNetworkAggregatedDownlineActions.types.success:
        d.downlineData = action.payload.response;
        d.downlineLoading = false;
        return;
      case fetchNetworkAggregatedDownlineActions.types.failure:
        d.downlineError = action.payload.error;
        d.downlineLoading = false;
        return;

      case fetchNetworkDirectSponsoredActions.types.start:
        d.directSponsoredError = undefined;
        d.directSponsoredLoading = true;
        return;
      case fetchNetworkDirectSponsoredActions.types.success:
        d.directSponsoredData = action.payload.response;
        d.directSponsoredLoading = false;
        d.directSponsoredError = undefined;
        return;
      case fetchNetworkDirectSponsoredActions.types.failure:
        d.directSponsoredError = action.payload.error;
        d.directSponsoredLoading = false;
        return;
    }
  });
